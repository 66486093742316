import { styled } from "@mui/material";

export const ReferYourFriendStyleWrapper = styled("div")`
  .template-container {
    display: grid;
    grid-template-areas:
      "image-bg pop-title" "image-bg form-section"
      "image-bg form-section" "image-bg button-section";
    gap: 1rem;
    .pop-title {
      margin: 1rem;
    }
    .form-field-section {
      display: grid;
      row-gap: 1.5rem;
    }
    ${({ theme }) => theme.breakpoints.down("sm")} {
      grid-template-areas:
        "pop-title pop-title" "image-bg image-bg"
        "form-section form-section" "button-section button-section";
      .submit-button-section {
        margin-top: 1rem;
        text-align: center;
      }
      .pop-title {
        text-align: center;
      }
    }
    ${({ theme }) => theme.breakpoints.down("lg")} {
      .form-field-section {
        row-gap: 2rem;
      }
    }
  }
`;
