import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Grid, Typography } from "@mui/material";
import MuiRadioButton, {
  StyledRadio,
} from "components/mui-radio-button/MuiRadioButton";
import { Fragment, useState } from "react";
import { issueSelections } from "./login/LoginInitialValues";
import MuiTextField from "components/mui-textfield/MuiTextfield";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { handleNumberFieldChange } from "utils/formHelperUtils";

const TroubleSigningForm = ({ formProps, handleAdormentClick }: any) => {
  const { handleBlur, handleChange, setFieldValue, values, errors, touched } =
    formProps;
  const [descError, setDescError] = useState("");

  const handleDescriptionFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue(`${event.target.name}`, event.target.value.trimStart());
    if (event.target.value.length === 250) {
      setDescError("Character limit exceeds");
    } else {
      setDescError("");
    }
  };

  return (
    <Fragment>
      <Typography variant={"body1"} py={"1rem"}>
        What is your issue?
      </Typography>
      <Grid container spacing={2}>
        {issueSelections.map((issue: typeof issueSelections[0]) => (
          <Grid key={issue.value} item xs={12} sm={12} md={4} lg={3} xl={3}>
            <FormControl fullWidth component="fieldset">
              <MuiRadioButton
                name={"issueType"}
                value={values.issueType}
                onChange={handleChange}
              >
                <FormControlLabel
                  control={<StyledRadio />}
                  value={issue.label}
                  label={issue.label}
                  checked={values.issueType === issue.label}
                />
              </MuiRadioButton>
            </FormControl>
          </Grid>
        ))}
      </Grid>

      <Box my={"2rem"}>
        <MuiTextField
          multiline
          rows={4}
          name="description"
          inputProps={{ maxLength: 250 }}
          value={values.description}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleDescriptionFieldChange(event)
          }
          onBlur={handleBlur}
          error={descError || (errors.description && touched.description)}
          helperText={
            descError ||
            (errors.description && touched.description && errors.description)
          }
          placeholder="Describe your issue in few words"
        />
      </Box>
      <Typography variant={"body1"} pb={"2rem"}>
        Your details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <MuiTextField
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleNumberFieldChange(event, setFieldValue)
            }
            label={"Enter Your Phone Number"}
            placeholder={"Type here"}
            inputProps={{ maxLength: 10 }}
            onBlur={handleBlur}
            error={errors.phoneNumber && touched.phoneNumber}
            helperText={
              errors.phoneNumber && touched.phoneNumber && errors.phoneNumber
            }
            InputProps={{
              startAdornment: (
                <InputAdornment
                  sx={{ cursor: "pointer" }}
                  position="start"
                  onClick={handleAdormentClick}
                >
                  <Typography component={"span"} variant={"body2"}>
                    {values.countryCode}
                    <IconButton edge="start">
                      <ExpandMoreIcon
                        fontSize="small"
                        sx={{ color: "common.black" }}
                      />
                    </IconButton>
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={6} xl={6}>
          <MuiTextField
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email}
            helperText={errors.email && touched.email && errors.email}
            label={"Enter your Email Address"}
            placeholder={"Enter your Email Address (optional)"}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default TroubleSigningForm;
