import { Carousel } from "@mantine/carousel";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";
import { Grid, Box, Typography, Theme, useMediaQuery } from "@mui/material";
import PromiseCard from "pages/promises/promise-card/PromiseCard";
import { getPageAction } from "redux-container/page-management-redux/PageManagementRedux";
import { useDispatch } from "react-redux";
import { numOfItems } from "utils/helperFunctions";

const actionDispatch = (dispatch: any) => {
  return {
    setPageState: (key: string, value: any) =>
      dispatch(getPageAction.setPageState(key, value)),
  };
};

const PromiseSection = ({
  projectPromises,
  onseeallclick,
  promiseTitle,
}: any) => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { setPageState } = actionDispatch(useDispatch());

  const handlePromiseDrawer = (promise: typeof projectPromises[0]) => () => {
    setPageState("promiseDetails", promise);
    setPageState("isDialogOpen", true);
  };

  return (
    <Grid
      container
      spacing={isSmallScreen ? 0 : 2}
      m={isSmallScreen ? "2rem 0 0" : "2rem 0 4rem"}
    >
      <Grid
        display={"grid"}
        alignItems={"flex-start"}
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        xl={3}
      >
        <Box>
          <Typography variant="h5" fontFamily={"Jost-Medium"} gutterBottom>
            {promiseTitle}
          </Typography>
          {!isSmallScreen && (
            <Typography variant="body1" fontFamily={"Jost-Light"}>
              Peace of land assured by promises
            </Typography>
          )}
        </Box>
        {!isSmallScreen && (
          <MuiLoadingButton onClick={onseeallclick}>SEE ALL</MuiLoadingButton>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
        <MantineCarousel
          slideSize={numOfItems(3)}
          height={isSmallScreen ? 300 : 340}
          slidesToScroll={3}
          breakpoints={[
            { maxWidth: "md", slideSize: numOfItems(3) },
            { maxWidth: "sm", slideSize: numOfItems(3), slideGap: "lg" },
          ]}
        >
          {projectPromises?.map((promise: typeof projectPromises[0]) => (
            <Carousel.Slide>
              <PromiseCard
                cardheight={300}
                key={promise?.id}
                name={promise?.name}
                description={promise?.shortDescription}
                iconsrc={promise.displayMedia?.value?.url}
                handlecardclick={handlePromiseDrawer(promise)}
              />
            </Carousel.Slide>
          ))}
        </MantineCarousel>
      </Grid>
    </Grid>
  );
};

export default PromiseSection;
