import styles from "./ShareDialog.module.css";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";
import { Constants } from "constants/Constants";
import { ReactNode, useState } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailIcon,
  EmailShareButton,
  LinkedinShareButton,
  LinkedinIcon,
} from "next-share";
import {
  IconButton,
  Grid,
  Avatar,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import MediaAssets from "assets";

interface IActionItemProps {
  onShareDialogClose?: () => void;
}

const SocialIcons = [
  { Icon: WhatsappIcon, ShareButton: WhatsappShareButton },
  { Icon: FacebookIcon, ShareButton: FacebookShareButton },
  { Icon: TwitterIcon, ShareButton: TwitterShareButton },
  { Icon: EmailIcon, ShareButton: EmailShareButton },
  { Icon: LinkedinIcon, ShareButton: LinkedinShareButton },
];

export const CloseIcon = () => (
  <Avatar src={MediaAssets.ic_close} sx={{ width: 16, height: 16 }} />
);

const ShareDialog = ({ onShareDialogClose }: IActionItemProps) => {
  const [isTextCopiedToClipboard, setIsTextCopiedToClipboard] = useState(false);

  const handleCloseSnackbar = () => {
    setIsTextCopiedToClipboard(false);
  };

  return (
    <Dialog open={true} onClose={onShareDialogClose} fullWidth maxWidth={"sm"}>
      <DialogTitle className={styles.heading}>
        <IconButton
          onClick={onShareDialogClose}
          aria-label="close"
          className={styles["close-icon"]}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography className={styles["dialog-title"]} variant="body1">
          Share with your friends
        </Typography>
        <Grid container justifyContent={"center"}>
          {SocialIcons.map(({ Icon, ShareButton }: any, index) => (
            <Grid
              key={index + 1}
              item
              className={styles["social-media-logo"]}
              xs={2}
              sm={2}
              md={2}
              lg={2}
              xl={2}
            >
              <ShareButton url={Constants.HOABL_WEB_LINK}>
                <Icon className={styles["social-icons"]} round />
              </ShareButton>
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            variant="body2"
            fontFamily={"Jost-Medium"}
            component={Divider}
          >
            Or Copy Link
          </Typography>
        </Grid>

        <div className={styles["link-copy-btn-holder"]}>
          <p>{Constants.HOABL_WEB_LINK}</p>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              setIsTextCopiedToClipboard(true);
              navigator.clipboard.writeText(Constants.HOABL_WEB_LINK);
            }}
          >
            Copy
          </Button>
        </div>
      </DialogContent>

      <MuiSnackbar
        shouldOpen={isTextCopiedToClipboard}
        message={"Text copied to Clipboard"}
        isSuccess={isTextCopiedToClipboard}
        closeHandler={handleCloseSnackbar}
      />
    </Dialog>
  );
};

export default ShareDialog;
