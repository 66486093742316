import { Fragment, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import Footer from "components/footer/Footer";
import Header from "../header/Header";
import styles from "./Layout.module.css";
import MuiPageSectionDrawer from "components/mui-page-section-drawer/MuiPageSectionDrawer";
import { Overlay } from "@mantine/core";
import { useSelector } from "react-redux";
import Notifications from "pages/notifications/Notifications";

const Layout = ({ children }: any) => {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [pathname]);
  const {
    isDialogOpen,
    isInsightDialogOpen,
    isNotificationDialogOpen,
    isSeeAllNewLaunchDialogOpen,
    isLastFewPlotsDialogOpen,
    isTrendingProjectDialogOpen,
  } = useSelector((state: any) => ({
    isDialogOpen: state?.pageManagement?.isDialogOpen,
    isInsightDialogOpen: state?.insights?.isDialogOpen,
    isNotificationDialogOpen: state?.notifications?.isDialogOpen,
    isSeeAllNewLaunchDialogOpen: state.newLaunch?.isSeeAllNewLaunchDialogOpen,
    isLastFewPlotsDialogOpen: state?.newLaunch?.isLastFewPlotsDialogOpen,
    isTrendingProjectDialogOpen: state?.newLaunch?.isTrendingProjectDialogOpen,
  }));

  return (
    <Fragment>
      <Header />
      <Notifications />

      <div className={styles["page"]}>
        <div
          className={
            styles[
              pathname === "/" || pathname === RouteConfigurations.home
                ? "home-container"
                : "main-container"
            ]
          }
        >
          {(isDialogOpen ||
            isInsightDialogOpen ||
            isNotificationDialogOpen ||
            isTrendingProjectDialogOpen) && (
            <Overlay opacity={0.2} color="#000" blur={5} />
          )}
          {children}
        </div>
      </div>
      <Footer />
      <MuiPageSectionDrawer />
    </Fragment>
  );
};

export default Layout;
