import {
  Drawer,
  Stack,
  IconButton,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
  useMediaQuery,
  Link,
  DrawerProps,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import MediaAssets from "assets";
import { useDispatch, useSelector } from "react-redux";
// import { getPageAction } from "redux-container/page-management-redux/PageManagementRedux";
import { DocumentsActions } from "redux-container/documents/DocumentsRedux";
import { getPortfolioAction } from "redux-container/portfolio-redux/PortfolioRedux";
import { useEffect, useState } from "react";
import Loader from "components/loader/Loader";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";
import { useClickOutside } from "@mantine/hooks";
import { Overlay } from "@mantine/core";

const actionDispatch = (dispatch: any) => {
  return {
    getCustomerDocument: async (params: any) => {
      dispatch(DocumentsActions.getDownloadDocDataRequest(params));
    },
    setPortfolioState: (key: any, value: any) =>
      dispatch(getPortfolioAction.setPortfolioState(key, value)),
    setDocumentsState: (key: any, value: any) =>
      dispatch(DocumentsActions.setDocumentsState(key, value)),
  };
};

const SeeAllDocumentDrawer = ({
  documents,
  onClose,
  ...restDrawerProps
}: {
  documents?: { name: string; id: number; path: string }[];
} & DrawerProps) => {
  const {
    showPDFPreview,
    documentBaseString,
    isDocLoading,
    isDocError,
    isDrawerOpen,
  } = useSelector((state: any) => ({
    showPDFPreview: state?.documents?.showPDFPreview,
    documentBaseString: state?.documents?.downloadDocData,
    isDocLoading: state?.documents?.isLoading,
    isDocError: state?.documents?.error,
    isDrawerOpen: state?.portfolio?.isDrawerOpen,
  }));
  const { getCustomerDocument, setDocumentsState, setPortfolioState } =
    actionDispatch(useDispatch());
  const { breakpoints } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("md"));

  const [showPdf, setShowPdf] = useState(false);

  const handleViewClick = async (pathName?: string) => {
    await getCustomerDocument(pathName);
    setShowPdf(true);
  };

  function dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  const handleSnackBarclose = () => {
    setDocumentsState("error", null);
  };

  useEffect(() => {
    const blob = dataURItoBlob(documentBaseString);
    const url = URL.createObjectURL(blob);

    if (showPDFPreview && showPdf) {
      window.open(url);
      setDocumentsState("showPDFPreview", false);
      setShowPdf(false);
    } else if (showPdf && !documentBaseString) {
      setDocumentsState("error", `Couldn't Fetch Data. Try Again!`);
    }
  }, [showPDFPreview]);
  const ref = useClickOutside(
    () => setPortfolioState("isDrawerOpen", false),
    ["mouseup", "mousedown"]
  );
  return (
    <>
      {isDrawerOpen && <Overlay opacity={0.2} color="#000" blur={5} />}
      <Drawer
        {...restDrawerProps}
        anchor={"right"}
        PaperProps={{
          ref: ref,
          sx: {
            width: fullScreen ? "100%" : breakpoints.values.sm,
            padding: fullScreen ? "1rem 2rem" : "1rem 2rem 1rem 4rem",
          },
        }}
      >
        <MuiSnackbar
          shouldOpen={!!isDocError}
          message={isDocError}
          isSuccess={false}
          closeHandler={handleSnackBarclose}
        />
        <Stack>
          <Typography variant={"h6"} fontFamily={"Jost-Medium"}>
            Document
          </Typography>
          <IconButton onClick={() => setPortfolioState("isDrawerOpen", false)}>
            <CancelIcon color="error" />
          </IconButton>
        </Stack>
        {isDocLoading ? (
          <Loader />
        ) : (
          <List>
            {documents?.map((doc) => (
              <ListItem
                sx={{
                  boxShadow: "0 11px 27px 0 rgba(0, 0, 0, 0.06)",
                  margin: "1rem 0",
                  padding: "1rem",
                  borderRadius: "10px",
                }}
                key={doc?.id}
                secondaryAction={
                  <Link
                    onClick={() => handleViewClick(doc?.path)}
                    component="button"
                    variant="body2"
                    underline="always"
                    color={"primary"}
                  >
                    View
                  </Link>
                }
              >
                <ListItemAvatar>
                  <Avatar src={MediaAssets.ic_docs} variant="rounded" />
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{ variant: "h6" }}
                  primary={doc?.name}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Drawer>
    </>
  );
};

export default SeeAllDocumentDrawer;
