import {
  Box,
  Container,
  Divider,
  IconButton,
  Stack,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment } from "react";
import styles from "./BookingJourneySection.module.css";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";

const BookingJourneySection = (props: any) => {
  const {
    children,
    isInvestmentCompleted,
    isAnyPaymentCompleted,
    isSectionCompleted,
    isDisabled,
    onClickViewAllReceipts,
    isViewReceiptDisabled,
    viewPaymentReciepts,
  } = props;
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Fragment>
      <div>
        <Box
          mb={3}
          className={
            isSectionCompleted
              ? styles["journey-completed-section-container"]
              : styles["journey-section-container"]
          }
        >
          <Stack mt={-1} mb={1}>
            <Stack
              sx={{ paddingTop: "15px" }}
              justifyContent={"start"}
              spacing={2}
            >
              <IconButton>
                {isSectionCompleted ? (
                  <CircleRoundedIcon
                    className={`${styles["large-icon"]} ${styles["circle-outlined"]}`}
                    color="inherit"
                  />
                ) : (
                  <CircleTwoToneIcon className={styles["large-icon"]} />
                )}
              </IconButton>
              <Typography
                className={
                  isDisabled
                    ? `${styles["disabled-text"]} ${styles["typography-title"]} ${styles["line-ellipsis"]}`
                    : styles["typography-title"]
                }
              >
                {props.titleText}
              </Typography>
            </Stack>

            {viewPaymentReciepts && (
              <MuiStyledButton
                sx={{ textDecoration: "underline", marginTop: "0.5rem" }}
                variant="text"
                color="primary"
                onClick={onClickViewAllReceipts}
                disabled={isViewReceiptDisabled}
                className={styles["view-payment-receipt-btn"]}
              >
                VIEW RECEIPTS
              </MuiStyledButton>
            )}
          </Stack>

          {/* <Divider className={styles["divider-main-container"]} /> */}

          {children}
        </Box>
      </div>
    </Fragment>
  );
};

export default BookingJourneySection;
