import { Fragment } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Theme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

interface IPageHeaderProps {
  title: string;
  subtitle: string;
  shouldbackbuttonvisible?: boolean;
}

const PageHeader = ({
  title,
  subtitle,
  shouldbackbuttonvisible = true,
}: IPageHeaderProps) => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Fragment>
      <Grid
        container
        alignItems={"center"}
        justifyContent={shouldbackbuttonvisible ? "flex-start" : "center"}
        marginBottom={"0.5rem"}
      >
        {shouldbackbuttonvisible ? (
          <Grid item xs={0.3} sm={0.3} md={0.3} lg={0.3} xl={0.3}>
            <IconButton edge={"start"} onClick={() => navigate(-1)}>
              <ChevronLeftIcon />
            </IconButton>
          </Grid>
        ) : null}
        <Grid margin={"auto"} item xs={11} sm={11} md={11} lg={10} xl={10}>
          <Typography variant="h6" component={Divider} color={"primary.main"}>
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Typography
        variant={isSmallScreen ? "body2" : "body1"}
        fontFamily={"Jost-Light"}
        textAlign="center"
        marginLeft={isSmallScreen ? 0 : "2rem"}
        marginBottom={isSmallScreen ? "1.5rem" : "2rem"}
      >
        {subtitle}
      </Typography>
    </Fragment>
  );
};

export default PageHeader;
