import { Fragment } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RouteConfigurations } from "./RouteConfigurations";
import { Constants } from "../constants/Constants";
import { getItemFromLocalStorage } from "../utils/LocalStorageUtils";
import ProtectedRoute from "./ProtectedRoutes";
import Login from "pages/authentication/login/Login";
import Home from "pages/home/Home";
import Promises from "pages/promises/Promises";
import Profile from "pages/profile/Profile";
import Portfolio from "pages/portfolio/Portfolio";
import Investments from "pages/investments/Investments";
import AllTestimonials from "pages/all-testimonials/AllTestimonials";
import Insights from "pages/insights/Insights";
import Updates from "pages/updates/Updates";
import HelpCenter from "pages/profile/help-center/HelpCenter";
import AboutUs from "pages/profile/about-us/AboutUs";
import FaqSection from "pages/profile/faq-section/FaqSection";
import SecurityAndSetting from "pages/profile/security-settings/SecuritySettings";
import PortfolioDetails from "pages/portfolio/PortfolioDetails";
import Chat from "pages/chat/Chat";
import MyAccount from "pages/profile/my-account/MyAccount";
import AllDocuments from "pages/profile/my-account/AllDocuments";
import ProjectTimeline from "pages/portfolio/project-timeline/ProjectTimeline";
import MediaGallery from "../pages/media-gallery/MediaGallery";
import BookingJourney from "../pages/portfolio/booking-journey/BookingJourney";
import InvestmentDetails from "pages/investment-details/InvestmentDetails";
import InvestmentOpportunityDoc from "pages/investment-opportunity-doc/InvestmentOpportunityDoc";
import InvestmentLocationMap from "pages/investment-location-map/InvestmentLocationMap";
import AllPaymentDetails from "pages/profile/my-account/AllPayments";
import Search from "pages/search/Search";
import LandSku from "pages/investments/land-sku-list/LandSku";
import InvestmentSummaryMap from "pages/portfolio/portfolio-sub-components/InvestmentSummaryMap";
import MyService from "pages/my-services/MyService";

const AppRoutes = () => {
  const isLoggedIn = useSelector((state: any) => state?.verifyOtp.isLoggedIn);
  const isLoggedInAfterNewUsername = useSelector(
    (state: any) => state?.newUserNameInfo.isLoggedIn
  );
  const isOtpVerified = useSelector(
    (state: any) => state?.verifyOtp.isOtpVerified
  );

  let routes = (
    <Fragment>
      <Route path="/" element={<Login />} />
      <Route path={RouteConfigurations.login} element={<Login />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Fragment>
  );

  const token = getItemFromLocalStorage(Constants.AUTHORIZATION_TOKEN);

  if (
    (!isOtpVerified && token && isLoggedIn === true) ||
    (!isOtpVerified &&
      token &&
      isLoggedIn === false &&
      isLoggedInAfterNewUsername === true)
  ) {
    routes = (
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path={"*"} element={<Home />} />
        <Route path={RouteConfigurations.home} element={<Home />} />
        <Route
          path={RouteConfigurations.investments}
          element={<Investments />}
        />
        <Route
          path={RouteConfigurations.investmentDetails}
          element={<InvestmentDetails />}
        />
        <Route
          path={RouteConfigurations.investmentOpportunityDoc}
          element={<InvestmentOpportunityDoc />}
        />
        <Route
          path={RouteConfigurations.investmentLocation}
          element={<InvestmentLocationMap />}
        />
        <Route
          path={RouteConfigurations.investmentSummaryMap}
          element={<InvestmentSummaryMap />}
        />
        <Route path={RouteConfigurations.insights} element={<Insights />} />
        <Route path={RouteConfigurations.portfolio} element={<Portfolio />} />
        <Route
          path={RouteConfigurations.portfolioDetails}
          element={<PortfolioDetails />}
        />
        <Route path={RouteConfigurations.promise} element={<Promises />} />
        <Route path={RouteConfigurations.myService} element={<MyService />} />
        <Route path={RouteConfigurations.profile} element={<Profile />} />
        <Route
          path={RouteConfigurations.projectTimeline}
          element={<ProjectTimeline />}
        />
        <Route
          path={RouteConfigurations.testimonials}
          element={<AllTestimonials />}
        />
        <Route path={RouteConfigurations.updates} element={<Updates />} />

        <Route path={RouteConfigurations.helpCenter} element={<HelpCenter />} />
        <Route path={RouteConfigurations.aboutUs} element={<AboutUs />} />
        <Route path={RouteConfigurations.faqs} element={<FaqSection />} />
        <Route
          path={RouteConfigurations.securityAndSettings}
          element={<SecurityAndSetting />}
        />
        <Route path={RouteConfigurations.chat} element={<Chat />} />
        <Route
          path={RouteConfigurations.securityAndSettings}
          element={<SecurityAndSetting />}
        />
        <Route path={RouteConfigurations.myAccount} element={<MyAccount />} />
        <Route
          path={RouteConfigurations.documentDetails}
          element={<AllDocuments />}
        />
        <Route
          path={RouteConfigurations.paymentHistory}
          element={<AllPaymentDetails />}
        />
        <Route
          path={RouteConfigurations.documentDetails}
          element={<AllDocuments />}
        />
        <Route
          path={RouteConfigurations.paymentHistory}
          element={<AllPaymentDetails />}
        />
        <Route path={RouteConfigurations.search} element={<Search />} />
        <Route
          path={RouteConfigurations.mediaGallery}
          element={<MediaGallery />}
        />
        <Route
          path={RouteConfigurations.bookingJourney}
          element={<BookingJourney />}
        />

        <Route path={RouteConfigurations.landSkuList} element={<LandSku />} />
      </Route>
    );
  }

  return <Routes>{routes}</Routes>;
};

export default AppRoutes;
