import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  editProfileSuccess: ["response"],
  editProfileFailure: ["error"],
  editProfileRequest: ["data"],

  getCountriesSuccess: ["response"],
  getCountriesFailure: ["error"],
  getCountriesRequest: ["data"],

  getStatesSuccess: ["response"],
  getStatesFailure: ["error"],
  getStatesRequest: ["countryCode"],

  getCitiesSuccess: ["response"],
  getCitiesFailure: ["error"],
  getCitiesRequest: ["countryCode", "stateIsoCode"],

  deleteProfilePictureSuccess: ["response"],
  deleteProfilePictureFailure: ["error"],
  deleteProfilePictureRequest: ["data"],

  updateProfilePictureSuccess: ["response"],
  updateProfilePictureFailure: ["error"],
  updateProfilePictureRequest: ["data"],

  setEditProfileState: ["key", "value"],
});

export const EditProfileTypes = Types;

export const EditProfileActions = Creators;

export const INITIAL_STATE = {
  isEditProfileDialogOpen: false,
  isLoading: false,
  isProfilePicLoading: false,
  isProfileUpdateSuccess: false,
  message: null,
  error: null,
  cities: [],
  countries: [],
  states: [],
};

// Edit Profile
export const editProfileRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const editProfileSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
      isProfileUpdateSuccess: true,
      message: response.message,
    };
  }
};

export const editProfileFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

// Get Countries
export const getCountriesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getCountriesSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      countries: response.data,
      isLoading: false,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getCountriesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

// Get States
export const getStatesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getStatesSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      states: response.data,
      isLoading: false,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getStatesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

// Get Cities
export const getCitiesRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getCitiesSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      cities: response.data,
      isLoading: false,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getCitiesFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

// Delete Profile Picture
export const deleteProfilePictureRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const deleteProfilePictureSuccess = (state: any, action: any) => {
  const response = action.response;

  if (response) {
    return {
      ...state,
      error: null,
      isLoading: false,
    };
  }
};

export const deleteProfilePictureFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

// Update Profile Picture
export const updateProfilePictureRequest = (state: any) => {
  return {
    ...state,
    isProfilePicLoading: true,
  };
};

export const updateProfilePictureSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      state: response.data,
      isProfilePicLoading: false,
    };
  }
  return {
    ...state,
    isProfilePicLoading: false,
  };
};

export const updateProfilePictureFailure = (state: any, action: any) => {
  return {
    ...state,
    isProfilePicLoading: false,
    error: action.error.message,
  };
};

// Set Edit Profile Redux State
export const setEditProfileState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EDIT_PROFILE_SUCCESS]: editProfileSuccess,
  [Types.EDIT_PROFILE_FAILURE]: editProfileFailure,
  [Types.EDIT_PROFILE_REQUEST]: editProfileRequest,

  [Types.GET_COUNTRIES_SUCCESS]: getCountriesSuccess,
  [Types.GET_COUNTRIES_FAILURE]: getCountriesFailure,
  [Types.GET_COUNTRIES_REQUEST]: getCountriesRequest,

  [Types.GET_STATES_SUCCESS]: getStatesSuccess,
  [Types.GET_STATES_FAILURE]: getStatesFailure,
  [Types.GET_STATES_REQUEST]: getStatesRequest,

  [Types.GET_CITIES_SUCCESS]: getCitiesSuccess,
  [Types.GET_CITIES_FAILURE]: getCitiesFailure,
  [Types.GET_CITIES_REQUEST]: getCitiesRequest,

  [Types.DELETE_PROFILE_PICTURE_SUCCESS]: deleteProfilePictureSuccess,
  [Types.DELETE_PROFILE_PICTURE_FAILURE]: deleteProfilePictureFailure,
  [Types.DELETE_PROFILE_PICTURE_REQUEST]: deleteProfilePictureRequest,

  [Types.UPDATE_PROFILE_PICTURE_SUCCESS]: updateProfilePictureSuccess,
  [Types.UPDATE_PROFILE_PICTURE_FAILURE]: updateProfilePictureFailure,
  [Types.UPDATE_PROFILE_PICTURE_REQUEST]: updateProfilePictureRequest,

  [Types.SET_EDIT_PROFILE_STATE]: setEditProfileState,
});
