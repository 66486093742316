import { styled } from "@mui/material";

export const PortfolioDetailStyleWrapper = styled("div")`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    .MuiListItemAvatar-root {
      margin-top: 0;
    }
  }
  .estimate-badge,
  .view-count-chip,
  .timer-chip {
    height: auto;
    padding: 0;
    background-color: transparent;
    & .MuiChip-icon {
      margin-left: 0;
      font-size: 1rem;
    }
  }
  & .view-count-chip {
    & .MuiChip-label,
    .MuiChip-icon {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .timer-chip {
    & .MuiChip-label,
    .MuiChip-icon {
      color: ${({ theme }) => theme.palette.error.contrastText};
    }
  }
  & .estimate-badge {
    & .MuiChip-label,
    .MuiChip-icon {
      color: ${({ theme }) => theme.palette.success.dark};
    }
    & .MuiChip-label {
      padding: 0 0.3rem;
      font: 500 1rem "Jost-Bold";
    }
  }
  .investment-detail-container {
    margin-top: 2rem;
    border-radius: 4px;
    padding: 0.5rem;
    background-image: ${({ theme }) => theme.palette.gradient?.yellowShades};
    & .with-icon {
      display: flex;
      align-items: center;
      column-gap: 0.3rem;
      & .info-icon {
        font-size: 1rem;
      }
    }
    & .increment-badge {
      height: auto;
      padding: 0;
      background-color: transparent;
      & .MuiChip-label,
      .MuiChip-icon {
        margin-left: 0;
        font: 500 1rem "Jost-Bold";
        color: ${({ theme }) => theme.palette.primary.main};
      }
      & .MuiChip-label {
        padding-left: 5px;
      }
    }
    ${({ theme }) => theme.breakpoints.down("sm")} {
      margin-top: 1rem;
    }
  }
  .large-outlined-button {
    background-color: transparent;
    color: ${({ theme }) => theme.palette.common.black};
    border-color: ${({ theme }) => theme.palette.common.black};
    ${({ theme }) => theme.breakpoints.up("lg")} {
      padding: 1rem 0;
    }
    &:hover {
      background-color: transparent;
      color: ${({ theme }) => theme.palette.common.black};
      border-color: ${({ theme }) => theme.palette.common.black};
    }
  }
  .status-badge {
    background-color: ${({ theme }) => theme.palette.warning.main};
    & .MuiChip-label,
    .MuiChip-icon {
      color: #b19502;
    }
    & .MuiChip-icon {
      border-right: 1px solid #b19502;
      padding-right: 0.3rem;
    }
  }
  .download-card {
    padding: 2rem;
    column-gap: 2rem;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    background-image: ${({ theme }) => theme.palette.gradient?.blackShades};
    & .download-link {
      font: 500 1.5rem "Jost-Medium";
      line-height: 5;
      column-gap: 1rem;
      justify-content: flex-start;
      color: ${({ theme }) => theme.palette.common.white};
    }
    & .home-setting {
      width: 12rem;
      height: 12rem;
    }
  }
  .document-item {
    margin: 1rem 0;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 11px 27px 0 rgba(0, 0, 0, 0.06);
    ${({ theme }) => theme.breakpoints.down("sm")} {
      padding: 0.5rem;
    }
  }
  .column-header {
    color: #928336;
  }
  .column-text {
    color: #262312;
    font-family: "Jost-Light";
  }
  .view-booking-journey-btn {
    background: linear-gradient(0deg, #d2c37a, #f5ebb3 80%) no-repeat;
    width: 90%;
    padding: 7px;
    font-size: 1.2rem;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    .view-booking-journey-btn {
      width: 100%;
      font-size: 1rem;
      padding: 3px;
    }
    .investment-detail-container {
      margin-top: 8px;
    }
  }
`;
