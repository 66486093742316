import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./ShareFeedback.module.css";

interface IShareFeedbackIconCard {
  title: string;
  activeIcon: any;
  inActiveIcon: any;
  isActive: boolean;
  handleClick: any;
}

const ShareFeedbackIconCard = (props: IShareFeedbackIconCard) => {
  const { title, activeIcon, inActiveIcon, isActive, handleClick } = props;
  return (
    <Stack
      flexDirection={"column"}
      className={styles["icon-card-section"]}
      onClick={handleClick}
    >
      <Box className={styles["icon-card-content"]}>
        {isActive ? (
          <img className={styles["icon-card-active-image"]} src={activeIcon} />
        ) : (
          <img className={styles["icon-card-image"]} src={inActiveIcon} />
        )}
      </Box>
      <Typography
        className={
          isActive
            ? styles["image-title-text-active"]
            : styles["image-title-text"]
        }
      >
        {title}
      </Typography>
    </Stack>
  );
};

export default ShareFeedbackIconCard;
