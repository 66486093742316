import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Tabs,
  Tab,
  Box,
  styled,
  Stack,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styles from "./IEAGraphContent.module.css";
import LineChart from "components/line-chart/LineChart";
import { useSelector } from "react-redux";

const IEAGraphContent = (props: any) => {
  const { tabValue, handleChange, tabsContent } = props;

  const projectContent = useSelector(
    (state: any) => state?.projectContent?.projectContents
  );
  const fiterActiveEscationGraph = projectContent?.filter(
    (item: any) => item?.isEscalationGraphActive
  );
  const tabscontentList = fiterActiveEscationGraph?.map((project: any) => {
    return {
      launchName: project?.launchName,
      escalationGraphDetails: project?.generalInfoEscalationGraph,
      thumbnail: project?.projectCoverImages?.homePageMedia?.value?.url,
    };
  });
  const [graphDetails, setGraphDetails] = useState();
  const theme = useTheme();
  const ismobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (tabscontentList?.length > 0) {
      setGraphDetails(tabscontentList[0]?.escalationGraphDetails);
    }
  }, [tabscontentList?.length]);

  const handleOnTabChangeGraphData = (data: any) => {
    setGraphDetails(data);
  };

  return (
    <Box className={styles["paper-content"]}>
      <Box textAlign={"center"} className={styles["line-graph-container"]}>
        <LineChart customizableGraphData={graphDetails} />
      </Box>

      <Box textAlign={"center"}>
        <Box mt={ismobileScreen ? 0 : 3} mb={ismobileScreen ? 0 : 3}>
          <Tabs
            className={styles["tabs-content"]}
            sx={{
              "& .MuiTabs-flexContainer": {
                justifyContent: isMediumScreen ? "flex-start" : "center",
                paddingLeft: "90px",
              },
              "& .MuiTab-root": {
                textTransform: "capitalize",
                color: "#9192a0",
                fontSize: ismobileScreen ? "0.65rem" : "0.9rem",
                transition: "font-size 0.2s ease-in",
              },
              "& .MuiTab-root.Mui-selected": {
                textTransform: "capitalize",
                color: "black",
                fontWeight: "bold",
                fontSize: ismobileScreen ? "0.7rem" : "1rem",
              },
              "& .MuiTabs-indicator": {
                height: "3px",
              },
            }}
            value={tabValue}
            onChange={handleChange}
            aria-label="scrollable auto tabs"
            indicatorColor={"primary"}
            textColor="inherit"
            variant={"scrollable"}
            scrollButtons
          >
            {tabscontentList?.map((item: any, index: any) => (
              <Tab
                key={index}
                onClick={() =>
                  handleOnTabChangeGraphData(item?.escalationGraphDetails)
                }
                icon={
                  <img
                    className={styles["tab-icon-image"]}
                    alt={""}
                    src={item?.thumbnail}
                  />
                }
                label={
                  item?.launchName?.length > 20
                    ? item?.launchName?.slice(0, 16) + "..."
                    : item?.launchName
                }
              />
            ))}
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
};

export default IEAGraphContent;
