import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import { Carousel } from "@mantine/carousel";
import { useDispatch, useSelector } from "react-redux";
import MuiCard from "components/mui-card/MuiCard";
import {
  IconButton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { numOfItems } from "utils/helperFunctions";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useHandleCardNavigation } from "utils/helperFunctions";
import { InvestmentDetailsActions } from "redux-container/investments/investment-details/InvestmentDetailsRedux";
import EastIcon from "@mui/icons-material/East";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import SimiliarInvestmentsDrawer from "./SimiliarInvestmentsDrawer";

const actionDispatch = (dispatch: any) => {
  return {
    setInvestmentDetailsState: (key: any, value: any) =>
      dispatch(InvestmentDetailsActions.setInvestmentDetailsState(key, value)),
  };
};

const SimilarInvestments = () => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { setInvestmentDetailsState } = actionDispatch(useDispatch());

  const { investmentDetails } = useSelector((state: any) => ({
    investmentDetails: state?.investmentDetails?.investmentDetails,
  }));

  const { handleNavigation } = useHandleCardNavigation();

  return (
    <section style={{ marginTop: "50px" }}>
      <div className="section-heading-holder">
        <h1>
          {investmentDetails?.projectContent?.similarInvestmentSectionHeading}
        </h1>

        <MuiStyledButton
          endIcon={<EastIcon />}
          variant="text"
          color="primary"
          onClick={() =>
            setInvestmentDetailsState("isSimiliarInvestmentDrawerOpen", true)
          }
        >
          SEE ALL
        </MuiStyledButton>
      </div>

      {Boolean(
        investmentDetails?.projectContent?.similarInvestments.length
      ) && (
        <MantineCarousel
          slidesToScroll={1}
          controlsOffset={0}
          includeGapInSize={false}
          slideSize={numOfItems(4)}
          breakpoints={[
            { maxWidth: "xl", slideSize: numOfItems(3.3) },
            { maxWidth: "lg", slideSize: numOfItems(2.3) },
            { maxWidth: "md", slideSize: numOfItems(2) },
            { maxWidth: "sm", slideSize: numOfItems(2) },
            { maxWidth: "xs", slideSize: numOfItems(1.2) },
          ]}
        >
          {investmentDetails?.projectContent?.similarInvestments
            ?.slice(
              0,
              investmentDetails?.projectContent
                ?.numberOfSimilarInvestmentsToShow
            )
            .map(
              (
                detail: typeof investmentDetails.projectContent.similarInvestments[0],
                detailIndex: number
              ) => (
                <Carousel.Slide>
                  <MuiCard
                    sx={{
                      height: "100%",
                    }}
                    key={detailIndex + 1}
                    src={detail?.projectCoverImages?.homePageMedia?.value?.url}
                    buttonwitharrow={true}
                    customheight={"25vh"}
                    detail={detail}
                    onbuttonclick={() =>
                      handleNavigation(
                        RouteConfigurations.landSkuList,
                        detail?.id,
                        detail?.launchName
                      )
                    }
                  >
                    <Stack columnGap={isSmallScreen ? "0.5rem" : "1rem"}>
                      <Typography
                        variant={isSmallScreen ? "body2" : "body1"}
                        color={"grey.A400"}
                        fontFamily={"Jost-Light"}
                      >
                        {detail?.shortDescription}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          handleNavigation(
                            RouteConfigurations.investmentDetails,
                            detail?.id
                          )
                        }
                        color="inherit"
                      >
                        <EastIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                  </MuiCard>
                </Carousel.Slide>
              )
            )}
        </MantineCarousel>
      )}

      <SimiliarInvestmentsDrawer />
    </section>
  );
};

export default SimilarInvestments;
