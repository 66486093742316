import {
  Box,
  CardActionArea,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";

enum cardTypes {
  PROJECT_IEA = "OEA",
  ESTIMATED = "Estimated",
  InvestDetail = "Detail",
}
interface IpercentageEstimateCardProps {
  percentage?: string;
  maintext?: string;
  subtext?: string;
  cardtype?: string;
  isinvestdetail?: boolean;
  oncardclick?: () => void;
}

const EstimationCard = (props: IpercentageEstimateCardProps) => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const {
    maintext,
    percentage,
    subtext,
    cardtype,
    isinvestdetail = false,
    oncardclick,
  } = props;
  return (
    <Stack
      component={CardActionArea}
      borderRadius={"8px"}
      bgcolor={"grey.600"}
      m={"0 0 0.5rem"}
      onClick={oncardclick}
    >
      <Box padding={isSmallScreen ? "0.5rem" : "1rem"}>
        <Typography
          variant={isSmallScreen ? "body2" : "body1"}
          fontFamily={"Jost-semiBold"}
        >
          {maintext}
        </Typography>
        <Typography
          variant={isinvestdetail ? "body1" : "body2"}
          color={"grey.900"}
        >
          {subtext}
        </Typography>
      </Box>
      <Box
        width={isSmallScreen ? "30%" : "15%"}
        height={"100%"}
        borderRadius={"50% 0 0 50%"}
        bgcolor={"rgba(255, 255, 255, 0.51)"}
      >
        {isinvestdetail ? (
          <Typography
            component={Stack}
            columnGap={"0.5rem"}
            justifyContent={"center"}
            fontFamily={"Jost-Medium"}
            padding={"2.5rem 1rem"}
            textAlign={"center"}
          >
            {cardTypes.InvestDetail}
            <EastIcon />
          </Typography>
        ) : (
          <Typography
            fontFamily={"Jost-Medium"}
            padding={"3rem 2rem"}
            textAlign={"center"}
            width={"10ch"}
            color={
              cardTypes.PROJECT_IEA === cardtype ? "primary.main" : "#23a740"
            }
          >
            {percentage}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};

export default EstimationCard;
