import { caseTypeEnum } from "enumerations/CaseTypeEnums";
import { isEmailValid, isMobileNumberValid } from "utils/ValidationUtils";

export const issueSelections = [
  { label: "Unable to enter phone number", value: 1 },
  { label: "Unable to change country code", value: 2 },
  { label: "Did not get OTP", value: 3 },
  { label: "OTP Expired", value: 4 },
  { label: "Number Blocked", value: 5 },
  { label: "My Issue is not listed", value: 6 },
  { label: "Others", value: 7 },
];

export const troubleSigninPayload = {
  description: "",
  countryCode: "+91",
  email: "",
  phoneNumber: "",
  caseType: caseTypeEnum.SIGNIN,
  issueType: "",
};

export const TroubleSigninValidate = (values: typeof troubleSigninPayload) => {
  const errors: any = {};
  if (values.email) {
    if (!isEmailValid(values.email)) {
      return { ...errors, email: "Please enter a valid email address" };
    }
  }

  if (!values.issueType) {
    return { ...errors, issueType: "Please select issue type" };
  }

  if (values.issueType === "Others" && !values.description) {
    return { ...errors, description: "Describe your issue." };
  }

  if (!values.phoneNumber) {
    return { ...errors, phoneNumber: "Please enter a phone number" };
  } else if (values.phoneNumber.length < 10) {
    return { ...errors, phoneNumber: "Please enter a valid 10-digit number." };
  }

  return errors;
};
