import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { SecurityTipsAction } from "../../redux-container/profile/SecurityTipsRedux";
import { call, put } from "redux-saga/effects";

export function* getSecurityTips(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getPageDetails, action.pageType);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(SecurityTipsAction.getSecurityTipsSuccess(response.data));
    } else {
      yield put(SecurityTipsAction.getSecurityTipsFailure(response.data));
    }
  } catch (error) {
    yield put(SecurityTipsAction.getSecurityTipsFailure(error));
  }
}
