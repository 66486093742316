import styles from "./InsightsHomeScreen.module.css";
import { handleUrlLink, splitOutPara } from "utils/helperFunctions";
import { Avatar, Theme, useMediaQuery } from "@mui/material";
import PlayCircleTwoToneIcon from "@mui/icons-material/PlayCircleTwoTone";
import { MediaTypeEnum } from "enumerations/MediaTypeEnum";

const InsightsItem = (insightData: any) => {
  const insight = insightData?.insight;
  const insightMedia = insight?.insightsMedia[0];
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <div
      className={styles["insights-card"]}
      onClick={insightData?.handlecardclick}
    >
      {insightMedia?.media?.value?.mediaType === MediaTypeEnum.Video && (
        <PlayCircleTwoToneIcon
          sx={{
            color: "common.white",
            position: "absolute",
            left: isSmallScreen ? "10%" : "18%",
            top: isSmallScreen ? "30%" : "35%",
            zIndex: 1,
          }}
          fontSize={"large"}
        />
      )}
      <Avatar
        src={handleUrlLink(insightMedia?.media?.value)}
        alt={insightMedia?.media?.name}
      />
      <div className={styles["insights-content-holder"]}>
        <h2 className={styles["insights-heading"]}>
          {insight?.displayTitle.substring(0, 29)}
        </h2>
        <p
          dangerouslySetInnerHTML={splitOutPara(insightMedia?.description)}
        ></p>
      </div>
    </div>
  );
};

export default InsightsItem;
