import TestimonialItem from "./TestimonialItem";
import { ButtonArrowIcon, MuiStyledButton } from "components/mui-buttons/MuiButtons";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useSelector } from "react-redux";
import { Carousel } from "@mantine/carousel";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import { useMediaQuery, Theme } from "@mui/material";
import { useMemoizedValue } from "app/hooks/useMemoizedValue";

const Testimonials = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { pageData, totalTestimonialsToShow } = useSelector((state: any) => ({
    pageData: state?.pageManagement?.pageData,
    totalTestimonialsToShow:
      state?.pageManagement?.pageData?.page?.totalTestimonialsOnHomeScreen,
  }));

  const slicedData = useMemoizedValue(
    pageData?.pageManagementsOrTestimonials,
    totalTestimonialsToShow
  );

  return (
    <section>
      <div className={"section-heading-holder"}>
        <h1>{pageData?.page?.testimonialsHeading}</h1>
        <MuiStyledButton
          endIcon={<ButtonArrowIcon />}
          variant="text"
          color="primary"
          onClick={() => navigate(RouteConfigurations.testimonials)}
        >
          SEE ALL
        </MuiStyledButton>
      </div>
      {slicedData && !!slicedData?.length && (
        <MantineCarousel
          dragFree={false}
          mx={"auto"}
          sx={{ maxWidth: isSmallScreen ? "100%" : "80%" }}
          slideSize={"100%"}
          withIndicators
          containermargin={isSmallScreen ? "" : "1rem 0 3rem"}
        >
          {slicedData.map((testimonial: any) => {
            return (
              <Carousel.Slide key={testimonial?.id}>
                <TestimonialItem testimonial={testimonial} />
              </Carousel.Slide>
            );
          })}
        </MantineCarousel>
      )}
    </section>
  );
};

export default Testimonials;
