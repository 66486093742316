import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactNode } from "react";
import styles from "./CustomerRegistrationDetailsDialog.module.css";
import ClearIcon from "@mui/icons-material/Clear";
import { formatDateTime, dateConstants } from "utils/DateFormatter";

export interface ICustomerRegistration {
  fieldTitle: string;
  value: string | number;
}

interface ICustomerRegistrationDetailsDialog {
  size?: any;
  shouldOpen: boolean;
  content: any;
  cancelHandler: (e?: any) => any;
  cancelBtnText?: string;
}

const CustomerPaymentCompletedDialog = ({
  size = "md",
  shouldOpen,
  content,
  cancelHandler,
  cancelBtnText = "OKAY",
}: ICustomerRegistrationDetailsDialog) => {
  const handleClose = () => {
    cancelHandler();
  };

  return (
    <Dialog
      maxWidth={size}
      fullWidth
      open={shouldOpen}
      disableEscapeKeyDown={true}
    >
      <Stack
        className={styles["pending-payment-dialog-header"]}
        justifyContent={"space-between"}
      >
        <DialogTitle sx={{ maxWidth: "90%" }}>
          <Typography variant="body2">
            {content?.paymentMilestone ? content?.paymentMilestone : ""}
          </Typography>
        </DialogTitle>

        <IconButton
          aria-label="cancel"
          onClick={handleClose}
          onMouseDown={(e: any) => e.preventDefault()}
        >
          <ClearIcon className={styles["clear-icon"]} />
        </IconButton>
      </Stack>

      <DialogContent className={styles["dialog-content-section"]}>
        <Box className={styles["pending-amount-mention-section"]}>
          <div>
            <Typography
              variant="body2"
              className={`${styles["typography-title-2"]} ${styles["text-dark-yellow"]}`}
            >
              Amount Paid
            </Typography>
            <Typography className={styles["typography-title-bold"]}>
              ₹{content?.paidAmount ? content?.paidAmount : " 0"}
            </Typography>
          </div>
        </Box>

        <Stack mt={3} flexDirection={"column"}>
          <Stack columnGap={1}>
            <Typography className={styles["light-gray-color"]}>
              Pending Amount:
            </Typography>
            <Typography mt={0.5}>
              ₹{content?.pendingAmount ? content?.pendingAmount : "0"}
            </Typography>
          </Stack>

          <Stack columnGap={2}>
            <Typography
              fontFamily={"Jost-medium"}
              className={styles["light-gray-color"]}
            >
              Due Date :{" "}
            </Typography>
            <Typography>
              {content?.targetDate
                ? formatDateTime(
                    content?.targetDate,
                    dateConstants.dateFormatDDmmYYYY
                  )
                : "-"}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <Divider />
      <div className={styles["dialog-actions"]}>
        <Button
          className={styles["okay-btn"]}
          variant="text"
          color="inherit"
          onClick={handleClose}
        >
          {cancelBtnText}
        </Button>
      </div>
    </Dialog>
  );
};

export default CustomerPaymentCompletedDialog;
