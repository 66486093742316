import {
  styled,
  Alert,
  Paper,
  Stack,
  Typography,
  IconButton,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { ButtonArrowIcon, MuiLoadingButton, MuiStyledButton } from "components/mui-buttons/MuiButtons";
interface IActionItemProps {
  seealloncard?: boolean;
  title?: string;
  actionstatus?: string;
  description?: string;
  onseeallclick?: () => void;
}

const ActionItemStyleWrapper = styled("div")`
  padding: 0 2.5rem;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 0;
    margin-bottom: 3rem;
  }
  .MuiAlert-root {
    color: #b19502;
    background-color: ${({ theme }) => theme.palette.warning.main};
    border-radius: 50px;
    padding: 0 ${({ theme }) => theme.typography.pxToRem(16)};
    align-items: center;
    .MuiAlert-icon {
      color: #b19502;
    }
    .MuiAlert-message {
      border-left: 1px solid #b19502;
      padding-left: 0.5rem;
    }
    ${({ theme }) => theme.breakpoints.down("sm")} {
      .MuiAlert-icon {
        margin-right: ${({ theme }) => theme.typography.pxToRem(6)};
        padding: ${({ theme }) => theme.typography.pxToRem(4)} 0;
      }
      .MuiAlert-message {
        font-size: 0.8rem;
      }
    }
  }
`;

const ActionItem = (props: IActionItemProps) => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const {
    seealloncard = true,
    actionstatus = "Action Required",
    title = "Payment is due on 29th April, 2022",
    description = "Your payment towards Tomorrowland is due on 29th April. Please pay on time to maintain healthy relation.",
    onseeallclick,
  } = props;

  return (
    <ActionItemStyleWrapper>
      <Paper
        sx={{
          bgcolor: "common.white",
          padding: isSmallScreen ? "1rem" : "2rem",
          boxShadow: "0 11px 29px 0 rgba(0, 0, 0, 0.11)",
          minHeight: "26vh",
        }}
      >
        <Stack>
          <Alert severity="error" color="warning">
            {actionstatus}
          </Alert>
          {seealloncard && (
            <MuiStyledButton
              variant="text"
              size="small"
              endIcon={<ButtonArrowIcon />}
              onClick={onseeallclick}
            >
              SEE ALL
            </MuiStyledButton>
          )}
        </Stack>
        <Stack justifyContent={"flex-start"} columnGap={"1rem"}>
          <Typography
            variant={isSmallScreen ? "body2" : "body1"}
            fontFamily={"Jost-Medium"}
            margin={"1rem 0"}
          >
            {title}
          </Typography>
          {!isSmallScreen && (
            <IconButton onClick={onseeallclick}>
              <ButtonArrowIcon />
            </IconButton>
          )}
        </Stack>
        <Typography variant="body2" color={"text.primary"}>
          {description}{" "}
          {seealloncard && (
            <Typography
              sx={{ cursor: "pointer" }}
              component={"span"}
              color="primary.main"
              variant="body2"
              onClick={onseeallclick}
            >
              Click to know more
            </Typography>
          )}
        </Typography>
      </Paper>
    </ActionItemStyleWrapper>
  );
};

export default ActionItem;
