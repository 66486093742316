import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getProjectContentsSuccess: ["response"],
  getProjectContentsFailure: ["error"],
  getProjectContentsRequest: ["data"],

  getProjectContentByIdSuccess: ["response"],
  getProjectContentByIdFailure: ["error"],
  getProjectContentByIdRequest: ["data"],

  getMediaByCategoryAndProjectIdSuccess: ["response"],
  getMediaByCategoryAndProjectIdFailure: ["error"],
  getMediaByCategoryAndProjectIdRequest: ["data"],

  setProjectContentsState: ["key", "value"],
});

export const getProjectContentsTypes = Types;

export const getProjectContentsAction = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  projectContents: null,
  isSuccess: false,
  projectContentById: null,
  projectMediaGallery: null,
};

export const getProjectContentsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getProjectContentsSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      projectContents: response.data,
      isLoading: false,
      isSuccess: true,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getProjectContentsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};

// get project by id
export const getProjectContentByIdRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getProjectContentByIdSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      projectContentById: response.data,
      isLoading: false,
      isSuccess: true,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getProjectContentByIdFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};


//get media by category and projectcontent id
export const getMediaByCategoryAndProjectIdRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getMediaByCategoryAndProjectIdSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      projectMediaGallery: response.data,
      isLoading: false,
      isSuccess: true,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getMediaByCategoryAndProjectIdFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    isSuccess: false,
    error: action.error.message,
  };
};

export const setProjectContentsState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};


export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PROJECT_CONTENTS_SUCCESS]: getProjectContentsSuccess,
  [Types.GET_PROJECT_CONTENTS_FAILURE]: getProjectContentsFailure,
  [Types.GET_PROJECT_CONTENTS_REQUEST]: getProjectContentsRequest,

  [Types.GET_PROJECT_CONTENT_BY_ID_SUCCESS]: getProjectContentByIdSuccess,
  [Types.GET_PROJECT_CONTENT_BY_ID_FAILURE]: getProjectContentByIdFailure,
  [Types.GET_PROJECT_CONTENT_BY_ID_REQUEST]: getProjectContentByIdRequest,

  [Types.GET_MEDIA_BY_CATEGORY_AND_PROJECT_ID_SUCCESS]: getMediaByCategoryAndProjectIdSuccess,
  [Types.GET_MEDIA_BY_CATEGORY_AND_PROJECT_ID_FAILURE]: getMediaByCategoryAndProjectIdFailure,
  [Types.GET_MEDIA_BY_CATEGORY_AND_PROJECT_ID_REQUEST]: getMediaByCategoryAndProjectIdRequest,

  [Types.SET_PROJECT_CONTENTS_STATE]: setProjectContentsState
});
