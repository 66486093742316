import { Fragment, useMemo } from "react";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import MediaAssets from "assets";
import styles from "./Footer.module.css";

let footerMenus = [
  {
    id: 1,
    pathName: "HOABL",
    path: RouteConfigurations.home,
    activeIcon: MediaAssets.ic_home_active,
    inactiveIcon: MediaAssets.ic_home_inactive,
  },
  {
    id: 2,
    pathName: "Invest",
    path: RouteConfigurations.investments,
    activeIcon: MediaAssets.ic_investments_active,
    inactiveIcon: MediaAssets.ic_investments_inactive,
  },
  {
    id: 3,
    pathName: "Portfolio",
    path: RouteConfigurations.portfolio,
    activeIcon: MediaAssets.ic_portfolio_active,
    inactiveIcon: MediaAssets.ic_portfolio_inactive,
  },
  {
    id: 4,
    pathName: "Promises",
    path: RouteConfigurations.promise,
    activeIcon: MediaAssets.ic_promise_active,
    inactiveIcon: MediaAssets.ic_promise_inactive,
  },
  {
    id: 5,
    pathName: "My Services",
    path: RouteConfigurations.myService,
    activeIcon: MediaAssets.ic_my_service_active,
    inactiveIcon: MediaAssets.ic_my_service_inactive,
  },
  {
    id: 6,
    pathName: "Profile",
    path: RouteConfigurations.profile,
    activeIcon: MediaAssets.ic_profile_active,
    inactiveIcon: MediaAssets.ic_profile_inactive,
  },
];

const Footer = () => {
  const { isFacilityVisible } = useSelector((state: any) => ({
    isFacilityVisible: state?.pageManagement?.pageData?.isFacilityVisible,
  }));

  const filteredMenu = useMemo(() => {
    if (isFacilityVisible) {
      return footerMenus.filter((menus) => {
        return menus.pathName !== footerMenus[3].pathName;
      });
    } else {
      return footerMenus.filter((menus) => {
        return menus.pathName !== footerMenus[4].pathName;
      });
    }
  }, [isFacilityVisible]);

  return (
    <footer className={styles["footer-dimension"]}>
      <ul>
        {filteredMenu.map((menu) => (
          <NavLink
            key={menu.id}
            to={menu.path}
            className={({ isActive }) =>
              isActive ? styles["menu-item-active"] : styles["menu-item"]
            }
            children={({ isActive }) => (
              <Fragment>
                <img
                  src={isActive ? menu.activeIcon : menu.inactiveIcon}
                  alt=""
                />
                <span className={isActive ? styles["active-menu-text"] : ""}>
                  {menu.pathName}
                </span>
              </Fragment>
            )}
          />
        ))}
      </ul>
    </footer>
  );
};

export default Footer;
