import {
  Drawer,
  Stack,
  IconButton,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
  useMediaQuery,
  Typography,
  styled,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import MediaAssets from "assets";
import { useDispatch, useSelector } from "react-redux";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import styles from "./ViewReceiptDrawer.module.css";
import { useState, useEffect } from "react";
import { DocumentsActions } from "redux-container/documents/DocumentsRedux";
import Loader from "components/loader/Loader";
import { useClickOutside } from "@mantine/hooks";
import { Overlay } from "@mantine/core";

const actionDispatch = (dispatch: any) => {
  return {
    downloadDoc: (path: any) =>
      dispatch(DocumentsActions.getDownloadDocDataRequest(path)),
    setDocumentsState: (key: any, value: any) =>
      dispatch(DocumentsActions.setDocumentsState(key, value)),
  };
};

const ViewReceiptDrawer = (props: any) => {
  const { documents, handleClose, open } = props;
  const { downloadDoc, setDocumentsState } = actionDispatch(useDispatch());
  const { breakpoints } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("md"));
  const isDocLoading = useSelector((state: any) => state?.documents?.isLoading);
  const showPDFPreview = useSelector(
    (state: any) => state?.documents?.showPDFPreview
  );
  const docError = useSelector((state: any) => state?.documents?.error);
  const [showPdf, setShowPdf] = useState(false);

  const documentBaseString = useSelector(
    (state: any) => state?.documents?.downloadDocData
  );

  const handleViewClick = async (path: any) => {
    await downloadDoc(path);
    setShowPdf(true);
  };

  const dataURItoBlob = (dataURI: any) => {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  };

  useEffect(() => {
    if (showPDFPreview && showPdf) {
      const blob = dataURItoBlob(documentBaseString);
      const url = URL.createObjectURL(blob);
      window.open(url);
      setDocumentsState("showPDFPreview", false);
      setShowPdf(false);
    }
  }, [showPDFPreview]);

  const CustomListItem = styled(ListItem)`
    & .MuiListItemText-primary {
      font-size: 1.3rem;
      font-family: "Jost-Regular";
    }
    & .MuiTypography-body1 {
      width: 85%;
    }
    @media only screen and (max-width: 768px) {
      & .MuiListItemText-primary {
        font-size: 1rem;
      }
    }
  `;

  const CustomMuiList = styled(List)`
    & .MuiListItem-root {
      padding: 15px;
      border-radius: 5px;
      box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: 768px) {
      & .MuiListItem-root {
        padding: 5px;
      }
    }
  `;
  const ref = useClickOutside(handleClose, ["mouseup", "mousedown"]);
  return (
    <>
      {open && <Overlay opacity={0.2} color="#000" blur={5} />}
      <Drawer
        open={open}
        anchor={"right"}
        PaperProps={{
          ref: ref,
          sx: {
            width: fullScreen ? "100%" : breakpoints.values.sm,
          },
        }}
      >
        <Stack>
          <Typography variant={"body1"} fontFamily={"Jost-Medium"}>
            Document
          </Typography>
          <IconButton onClick={handleClose}>
            <CancelIcon color="error" />
          </IconButton>
        </Stack>
        {isDocLoading ? (
          <Loader />
        ) : (
          <CustomMuiList>
            {documents?.map((doc: any) => (
              <CustomListItem
                sx={{
                  boxShadow: "0 11px 27px 0 rgba(0, 0, 0, 0.06)",
                  margin: "1rem 0",
                }}
                key={doc?.id}
                secondaryAction={
                  <MuiStyledButton
                    className={styles["view-btn"]}
                    variant="text"
                    color="inherit"
                    onClick={() => handleViewClick(doc?.path)}
                  >
                    View
                  </MuiStyledButton>
                }
              >
                <ListItemAvatar>
                  <Avatar src={MediaAssets.ic_docs} variant="rounded" />
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "1rem" }}
                  primary={doc?.name}
                />
              </CustomListItem>
            ))}
          </CustomMuiList>
        )}
      </Drawer>
    </>
  );
};

export default ViewReceiptDrawer;
