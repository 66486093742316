import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  Paper,
  useMediaQuery,
  Theme,
  Chip,
  Grid,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useLocation, useNavigate } from "react-router-dom";
import { PortfolioDetailStyleWrapper } from "./PortfolioDetail.style";
import {
  ButtonArrowIcon,
  MuiLoadingButton,
  MuiStyledButton,
} from "components/mui-buttons/MuiButtons";
import { useEffect, useMemo, useState } from "react";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import { Carousel } from "@mantine/carousel";
import ReferralSection from "pages/home/referral-section/ReferralSection";
import MyWatchList from "./portfolio-sub-components/MyWatchList";
import PanToolIcon from "@mui/icons-material/PanTool";
import PromiseSection from "./portfolio-sub-components/PromiseSection";
import MasonryGridSection from "./portfolio-sub-components/MasonryGridSection";
import PriceTrendsGraph from "./portfolio-sub-components/PriceTrendsGraph";
import ActionItem from "components/action-item/ActionItem";
import { useDispatch, useSelector } from "react-redux";
import { getPortfolioAction } from "redux-container/portfolio-redux/PortfolioRedux";
import InvestmentDetailAccordian from "./portfolio-sub-components/InvestmentDetailAccordian";
import ProjectFAQ from "./portfolio-sub-components/PortfolioFAQ";
import PromotionCard from "pages/home/promotion-card/PromotionCard";
import { RouteConfigurations } from "routes/RouteConfigurations";
import Loader from "components/loader/Loader";
import MyServicesDialog from "pages/profile/my-services-dialog/MyServicesDialog";
import { TroubleSigninAction } from "redux-container/login/trouble-signingin-redux/TroubleSigninginRedux";
import MuiActionStatusDialog from "components/mui-dialogs/MuiActionStatusDialog";
import { caseTypeEnum } from "enumerations/CaseTypeEnums";
import SeeAllDocumentDrawer from "./portfolio-sub-components/SeeAllDocumentDrawer";
import { DocumentsActions } from "redux-container/documents/DocumentsRedux";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { numDifferentiation } from "utils/helperFunctions";
// import moment from "moment";
import { customDateFormat } from "utils/DateUtils";
import ImageIcon from "@mui/icons-material/Image";

const actionDispatch = (dispatch: any) => {
  return {
    getCustomerDocument: async (params: any) =>
      dispatch(DocumentsActions.getDownloadDocDataRequest(params)),
    getPortfolioDetailsRequest: (Ids: any) =>
      dispatch(getPortfolioAction.getPortfolioDetailsRequest(Ids)),
    setPortfolioState: (key: any, value: any) =>
      dispatch(getPortfolioAction.setPortfolioState(key, value)),
    setTroubleSigninState: (key: any, value: any) =>
      dispatch(TroubleSigninAction.setTroubleSigninState(key, value)),
    addTroubleSigninRequest: (data: any) =>
      dispatch(TroubleSigninAction.addTroubleSigninRequest(data)),
    setDocumentsState: (key: any, value: any) =>
      dispatch(DocumentsActions.setDocumentsState(key, value)),
  };
};

const PortfolioDetails = () => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const isBookingComplete = state?.projects?.investment?.isBookingComplete;
  const {
    setDocumentsState,
    getPortfolioDetailsRequest,
    addTroubleSigninRequest,
    setTroubleSigninState,
    setPortfolioState,
    getCustomerDocument,
  } = actionDispatch(useDispatch());
  const {
    investmentDetail,
    pageData,
    isLoading,
    isResponseDialogOpen,
    isDrawerOpen,
    isDocLoading,
    showPDFPreview,
    documentBaseString,
  } = useSelector((state: any) => ({
    isLoading: state?.portfolio?.isLoading,
    investmentDetail: state?.portfolio?.investmentDetail,
    pageData: state?.pageManagement?.pageData?.page,
    isDrawerOpen: state?.portfolio?.isDrawerOpen,
    isResponseDialogOpen: state?.troubleSignin?.isResponseDialogOpen,
    isDocLoading: state?.documents?.isLoading,
    showPDFPreview: state?.documents?.showPDFPreview,
    documentBaseString: state?.documents?.downloadDocData,
  }));

  // const [currentTime, setCurrentTime] = useState<any>(
  //   moment().format("HH:mm:ss")
  // );
  // const targetTime = moment({
  //   ...state.projects?.project?.fomoContent?.targetTime,
  // });
  // const timeBetween = moment.duration(targetTime.diff(currentTime));
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const { hours, minutes, seconds } =
  //       state.projects?.project?.fomoContent?.targetTime;
  //     const toNumber = { hours: +hours, minutes: +minutes, seconds: +seconds };
  //     setCurrentTime(
  //       moment({
  //         ...state.projects?.project?.fomoContent?.targetTime,
  //       })
  //     );
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);
  const [open, setOpen] = useState(false);
  const [showPdf, setShowPdf] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDrawerOpen = () => {
    setPortfolioState("isDrawerOpen", true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDrawerClose = () => {
    setPortfolioState("isDrawerOpen", false);
  };

  useEffect(() => {
    let cancelEffect = true;
    if (cancelEffect) {
      getPortfolioDetailsRequest({
        investmentId: state?.projects?.investment?.id,
        projectId: state?.projects?.project?.id,
      });
    }
    return () => {
      cancelEffect = false;
    };
  }, []);

  const handleNavigateToTimeline = () => {
    navigate(RouteConfigurations.projectTimeline, {
      state: { projectId: investmentDetail?.projectInformation?.id },
    });
  };

  const handleNavigateToInvestDetails = () => {
    navigate(RouteConfigurations.investmentDetails, {
      state: {
        projectId: state?.projects?.project?.id,
      },
    });
  };
  const handleClickSeeAllMediaGallery = () => {
    navigate(RouteConfigurations.mediaGallery, {
      state: {
        galleryData:
          investmentDetail?.projectInformation
            ?.latestMediaGalleryOrProjectContent[0],
      },
    });
  };

  const handleNavigateToBookingJourney = () => {
    navigate(RouteConfigurations.bookingJourney, {
      state: {
        investment: investmentDetail?.investmentInformation?.id,
        primaryOwner: investmentDetail?.investmentInformation?.owners,
        crmInventoryName:
          investmentDetail?.investmentInformation?.crmInventory?.name,
        project: investmentDetail?.projectInformation,
        isBookingComplete: isBookingComplete,
      },
    });
  };

  const handleDialogOpen = () => {
    const payload = {
      description: "My number is blocked!",
      countryCode: "+91",
      email: "test@gmail.com",
      phoneNumber: "9515432099",
      caseType: caseTypeEnum.PROMISE,
      issueType: "2003",
    };
    addTroubleSigninRequest(payload);
    setTroubleSigninState("isResponseDialogOpen", true);
  };

  const handleDialogClose = () => {
    setTroubleSigninState("isResponseDialogOpen", false);
  };

  function dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  const handleViewClick = async (pathName?: string) => {
    await getCustomerDocument(pathName);
    setShowPdf(true);
  };

  const handlePromiseNavigation = () => {
    navigate(RouteConfigurations.promise);
  };
  useEffect(() => {
    const blob = dataURItoBlob(documentBaseString);
    const url = URL.createObjectURL(blob);

    if (showPDFPreview && showPdf) {
      setTimeout(() => {
        window.open(url);
        setDocumentsState("showPDFPreview", false);
        setShowPdf(false);
      }, 500);
    } else if (showPdf && !documentBaseString) {
      setDocumentsState("error", `Couldn't Fetch Data. Try Again!`);
    }
  }, [showPDFPreview]);

  const slicedSimilarInvestmentData = useMemo(() => {
    if (!!state.projects?.project?.numberOfSimilarInvestmentsToShow) {
      return investmentDetail?.projectInformation?.similarInvestments.slice(
        0,
        state.projects?.project?.numberOfSimilarInvestmentsToShow
      );
    } else {
      return investmentDetail?.projectInformation?.similarInvestments;
    }
  }, [
    investmentDetail?.projectInformation?.similarInvestments,
    state.projects?.project?.numberOfSimilarInvestmentsToShow,
  ]);

  const filteredPaymentScheduled =
    investmentDetail?.investmentInformation?.paymentSchedules.filter(
      (
        payment: typeof investmentDetail.investmentInformation.paymentSchedules[0]
      ) =>
        !payment?.isPaymentDone &&
        payment?.targetDate &&
        new Date(payment?.targetDate) < new Date()
    );

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PortfolioDetailStyleWrapper>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={12} sm={12} md={7} lg={9} xl={9}>
            <Stack alignItems={"flex-start"} justifyContent="flex-start">
              {isSmallScreen ? null : (
                <IconButton
                  color="inherit"
                  edge={"start"}
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeftIcon />
                </IconButton>
              )}
              <List
                sx={{
                  width: "100%",
                  maxWidth: isSmallScreen ? 440 : "100%",
                  padding: 0,
                }}
              >
                <ListItem
                  disableGutters={isSmallScreen}
                  sx={{ columnGap: "0.5rem", paddingTop: 0 }}
                  alignItems="flex-start"
                >
                  <ListItemAvatar>
                    <Avatar
                      src={state.projects?.project?.projectIcon?.value?.url}
                      className={"project-icon"}
                      sx={{
                        width: "65px",
                        height: "65px",
                        img: {
                          objectFit: "cover",
                        },
                      }}
                      variant="rounded"
                    >
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ margin: 0 }}
                    primary={
                      <Typography
                        fontFamily={"Jost-SemiBold"}
                        variant="h6"
                        color="rgba(0, 0, 0, 0.72)"
                      >
                        {state.projects?.project?.launchName}
                        {isBookingComplete && (
                          <Chip
                            className="estimate-badge"
                            icon={
                              state.projects?.investment?.projectIea?.includes(
                                "---"
                              ) ? undefined : (
                                <KeyboardDoubleArrowUpIcon fontSize="small" />
                              )
                            }
                            label={state.projects?.investment?.projectIea}
                          />
                        )}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body1" color="rgba(0, 0, 0, 0.72)">
                        {`${state.projects?.project?.address?.city}, ${state.projects?.project?.address?.state}`}
                        <br />
                        {state.projects?.project?.fomoContent
                          ?.isNoOfViewsActive && (
                          <Chip
                            className="view-count-chip"
                            icon={<RemoveRedEyeOutlinedIcon fontSize="small" />}
                            label={numDifferentiation(
                              state.projects?.project?.fomoContent?.noOfViews,
                              true
                            )}
                          />
                        )}
                        {/* {state.projects?.project?.fomoContent
                      ?.isTargetTimeActive && (
                      <Chip
                        className="timer-chip"
                        icon={<AccessTimeIcon fontSize="small" />}
                        label={`${timeBetween.hours()}:${timeBetween.minutes()}:${timeBetween.seconds()} Hrs Left`}
                      />
                    )} */}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Stack>
          </Grid>
          <Grid
            mt={2.5}
            textAlign={"end"}
            item
            xs={12}
            sm={12}
            md={5}
            lg={3}
            xl={3}
          >
            <MuiStyledButton
              onClick={handleNavigateToBookingJourney}
              variant="text"
              size="small"
              color="inherit"
              endIcon={<ButtonArrowIcon />}
              className={"view-booking-journey-btn"}
            >
              View Booking Journey
            </MuiStyledButton>
          </Grid>
        </Grid>
        <InvestmentDetailAccordian
          isSmallScreen={isSmallScreen}
          investmentDetail={investmentDetail}
          navigateto={handleNavigateToBookingJourney}
        />
        <Typography my={"2rem"} variant="body1" fontFamily={"Jost-Light"}>
          {investmentDetail?.projectInformation?.fullDescription}
        </Typography>
        <Stack
          width={isSmallScreen ? "100%" : "80%"}
          mx={"auto"}
          justifyContent={"center"}
          columnGap={"1rem"}
          mb={"3rem"}
        >
          <MuiStyledButton
            className="large-outlined-button"
            sx={{ fontFamily: "Jost-Medium" }}
            fullWidth
            onClick={handleNavigateToInvestDetails}
          >
            See Project Details
          </MuiStyledButton>
          <MuiStyledButton
            onClick={handleNavigateToTimeline}
            className="large-outlined-button"
            sx={{ fontFamily: "Jost-Medium" }}
            fullWidth
          >
            View Timeline
          </MuiStyledButton>
        </Stack>
        {filteredPaymentScheduled && !!filteredPaymentScheduled.length && (
          <>
            <Stack mb={isSmallScreen ? "0.5rem" : "2rem"}>
              <Stack paddingLeft={isSmallScreen ? "0" : "1.8rem"}>
                <IconButton sx={{ rotate: "y 180deg" }} color="inherit">
                  <PanToolIcon />
                </IconButton>
                <Typography variant="h5" fontFamily={"Jost-Medium"}>
                  The following items need your attention
                </Typography>
              </Stack>
              <MuiStyledButton
                variant="text"
                endIcon={<ButtonArrowIcon />}
                onClick={handleNavigateToBookingJourney}
              >
                SEE ALL
              </MuiStyledButton>
            </Stack>
            <MantineCarousel
              align={"center"}
              slideSize={"100%"}
              slideGap={"sm"}
              includeGapInSize={false}
              containermargin={"1rem 0.4rem 4rem"}
              controlsOffset={0}
              withIndicators
              sx={{
                ".mantine-Carousel-controls": {
                  top: "calc(50% - 50px)",
                },
              }}
            >
              {filteredPaymentScheduled.map((payment: Record<string, any>) => (
                <Carousel.Slide key={payment?.id}>
                  <ActionItem
                    seealloncard={false}
                    actionstatus={payment?.cardTitle}
                    title={payment?.paymentMilestone}
                    description={`Your payment towards ${
                      state.projects?.project?.launchName
                    } is due on ${customDateFormat(
                      payment?.targetDate
                    )}. Please pay on time to maintain healthy relation.`}
                  />
                </Carousel.Slide>
              ))}
            </MantineCarousel>
          </>
        )}
        {pageData?.isFacilityManagementActive && (
          <PromotionCard
            cardurl={pageData?.facilityManagement?.value?.url}
            oncardclick={handleClickOpen}
          />
        )}

        <MyServicesDialog open={open} handleclose={handleClose} />
        {investmentDetail?.investmentInformation?.projectDocuments !== null &&
          !!investmentDetail?.investmentInformation?.projectDocuments
            .length && (
            <>
              <Stack my={isSmallScreen ? "1rem" : "2rem"}>
                <Typography variant="h5" fontFamily={"Jost-Medium"}>
                  Document
                </Typography>
                <MuiStyledButton
                  variant="text"
                  endIcon={<ButtonArrowIcon />}
                  onClick={handleDrawerOpen}
                >
                  SEE ALL
                </MuiStyledButton>
              </Stack>
              <SeeAllDocumentDrawer
                open={isDrawerOpen}
                onClose={handleDrawerClose}
                documents={
                  investmentDetail?.investmentInformation?.projectDocuments
                }
              />
              {investmentDetail?.investmentInformation?.projectDocuments
                ?.slice(0, 2)
                .map((doc: Record<string, any>) => (
                  <Paper className="document-item" key={doc.id}>
                    <Typography variant={isSmallScreen ? "body2" : "h6"}>
                      {doc?.name}
                    </Typography>
                    <MuiLoadingButton
                      loading={isDocLoading}
                      loadingPosition="end"
                      onClick={() => handleViewClick(doc?.path)}
                      variant="text"
                      color={"primary"}
                    >
                      View
                    </MuiLoadingButton>
                  </Paper>
                ))}
            </>
          )}
        {investmentDetail?.projectInformation
          ?.latestMediaGalleryOrProjectContent[0]?.isImagesActive &&
          !!investmentDetail?.projectInformation?.latestMediaGalleryOrProjectContent[0]?.images?.filter(
            (item: any) => item?.status === "1001"
          ).length && (
            <MasonryGridSection
              galleryItems={
                investmentDetail?.projectInformation
                  ?.latestMediaGalleryOrProjectContent
              }
              onClickSeeAll={handleClickSeeAllMediaGallery}
            />
          )}
        {investmentDetail?.projectPromises?.data &&
          !!investmentDetail?.projectPromises?.data?.length && (
            <PromiseSection
              promiseTitle={
                state.projects?.project?.otherSectionHeadings?.promises
                  ?.sectionHeading
              }
              projectPromises={investmentDetail?.projectPromises?.data}
              onseeallclick={handlePromiseNavigation}
            />
          )}
        <Stack justifyContent={"center"}>
          <MuiLoadingButton onClick={handleDialogOpen}>
            Want to know more about Promises? Click here
          </MuiLoadingButton>
        </Stack>
        <MuiActionStatusDialog
          maxWidth={"sm"}
          issuccess={true}
          isconfirmdialog={true}
          responsemessage={"Request Sent"}
          message={
            "A relationship manager will get back to you to discuss more about it."
          }
          open={isResponseDialogOpen}
          handleclose={handleDialogClose}
        />
        <PriceTrendsGraph
          generalInfoEscalationGraph={
            state?.projects?.project?.generalInfoEscalationGraph
          }
        />
        <ReferralSection />
        {investmentDetail?.projectInformation?.projectContentsAndFaqs &&
          !!investmentDetail?.projectInformation?.projectContentsAndFaqs
            ?.length && (
            <ProjectFAQ
              isSmallScreen={isSmallScreen}
              projectFAQs={
                investmentDetail?.projectInformation?.projectContentsAndFaqs
              }
              project={investmentDetail?.projectInformation}
            />
          )}
        <Stack my={"2rem"} justifyContent={"center"}>
          <MuiLoadingButton onClick={() => navigate(RouteConfigurations.chat)}>
            Have any questions? Ask Here
          </MuiLoadingButton>
        </Stack>
      </PortfolioDetailStyleWrapper>
      <MyWatchList
        sectionTitle={"Similar Investments"}
        watchListData={slicedSimilarInvestmentData}
      />
    </>
  );
};

export default PortfolioDetails;
