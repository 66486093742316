import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, IconButton } from "@mui/material";
import TourismAround from "./tourism-around/TourismAround";
import OpportunityBanner from "./opportunity-banner/OpportunityBanner";
import ExpectedGrowth from "./expected-growth/ExpectedGrowth";
import UpcomingInfra from "./upcoming-infra/UpcomingInfra";
import CurrentInfra from "./current-infra/CurrentInfra";
import { useNavigate } from "react-router-dom";
import AboutProject from "./about-project/AboutProject";
import { useSelector } from "react-redux";
import ProjectAmenities from "pages/investment-details/project-amenities/ProjectAmenities";
import PageFooter from "./page-footer/PageFooter";
import styles from "./InvestmentOpportunityDoc.module.css";
import { RouteConfigurations } from "routes/RouteConfigurations";

const InvestmentOpportunityDoc = () => {
  const navigate = useNavigate();

  const { investmentDetails } = useSelector((state: any) => ({
    investmentDetails: state?.investmentDetails?.investmentDetails,
  }));

  const handleOnClickApplyNow = () => {
    navigate(RouteConfigurations.landSkuList, {
      state: {
        projectId: investmentDetails?.projectContent?.id,
        launchName: investmentDetails?.projectContent?.launchName,
      },
    });
  };

  return (
    <div>
      <IconButton edge={"start"} onClick={() => navigate(-1)}>
        <ChevronLeftIcon />
      </IconButton>
      <Box padding={"0 1.5rem"}>
        {investmentDetails?.projectContent?.opportunityDoc
          ?.isBannerImageActive && <OpportunityBanner />}
        {investmentDetails?.projectContent?.opportunityDoc
          ?.isEscalationGraphActive && <ExpectedGrowth />}
        {investmentDetails?.projectContent?.opportunityDoc
          ?.isCurrentInfraStoryActive && <CurrentInfra />}
        {investmentDetails?.projectContent?.opportunityDoc
          ?.isUpcomingInfraStoryActive && <UpcomingInfra />}
        {investmentDetails?.projectContent?.opportunityDoc
          ?.isTourismAroundActive && <TourismAround />}
        {investmentDetails?.projectContent?.opportunityDoc
          ?.isAboutProjectsActive && <AboutProject />}
        {investmentDetails?.projectContent?.opportunityDoc
          ?.isProjectAminitiesActive && (
          <ProjectAmenities showAllAmenities={true} />
        )}
        {investmentDetails?.projectContent?.opportunityDoc
          ?.isPageFooterActive && <PageFooter />}
        <div className={styles["apply-now-brn-holder"]}>
          <Button onClick={handleOnClickApplyNow} className="btn btn-dark">
            Apply Now
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default InvestmentOpportunityDoc;
