import {
  Box,
  Dialog,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styles from "./MediaViewModal.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { Overlay } from "@mantine/core";

const MediaViewModal = (props: any) => {
  const { open, contentType = "img", mediaName, mediaUrl, handleClose } = props;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      {open && <Overlay opacity={0.2} color="#000" blur={5} />}
      <Dialog
        hideBackdrop
        open={open}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflowY: "hidden",
            overflowX: "hidden",
            paddingTop: "80px",
          },
        }}
        className={styles["background-transparent"]}
        fullScreen={isMobileScreen ? true : false}
        maxWidth={!isMobileScreen && "md"}
        onClose={handleClose}
      >
        <Box sx={{ paddingBottom: "80px" }}>
          <Stack
            justifyContent={"start"}
            spacing={3}
            mb={3}
            ml={isMobileScreen ? 3 : 2}
            sx={{ minHeight: "70px" }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon className={styles["white-color"]} color={"inherit"} />
            </IconButton>

            <Box>
              <Typography className={styles["white-color"]}>
                {mediaName}
              </Typography>
            </Box>
          </Stack>
          {contentType === "video" ? (
            <iframe
              allow="fullscreen"
              className={styles["dialog-video"]}
              src={mediaUrl}
            />
          ) : (
            <img className={styles["dialog-image"]} src={mediaUrl} />
          )}
        </Box>
      </Dialog>
    </div>
  );
};

export default MediaViewModal;
