import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Grid, Stack, useMediaQuery } from "@mui/material";
import styles from "./ProjectsSearch.module.css";
import { amountFormatter } from "utils/AmountFormatter";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import EastIcon from "@mui/icons-material/East";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useNavigate } from "react-router-dom";

interface IProjectDetailedCard {
  launchName: string;
  city: string;
  state: string;
  mediaUrl: string;
  appreciation: any;
  startingPrice: string | number;
  startingArea: string | number;
  description: string;
  id: string | number;
  isSoldout?: boolean;
}

export default function ProjectDetailedCard(props: IProjectDetailedCard) {
  const {
    launchName,
    city,
    state,
    mediaUrl,
    appreciation,
    startingPrice,
    startingArea,
    description,
    id,
    isSoldout,
  } = props;

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const address = () => {
    if (city && state) {
      if ((city + ", " + state).length > 25) {
        return (city + ", " + state).slice(0, 22) + "...";
      } else {
        return city + ", " + state;
      }
    } else if (city && !state) {
      return city;
    } else if (!city && state) {
      return state;
    }
    return "";
  };

  const navigate = useNavigate();

  const handleNavigateToDetailedPage = () => {
    navigate(RouteConfigurations.investmentDetails, {
      state: { projectId: id },
    });
  };
  return (
    <Box
      mb={2}
      sx={{ margin: isSoldout ? "2 0" : 0 }}
      className={isSoldout ? styles["card-main-container"] : ""}
    >
      <Box
        mt={isSoldout && !isSmallerScreen ? 1 : 0}
        style={
          isSoldout
            ? {
                backgroundColor: "rgba(92, 89, 89, 0.7)",
                borderRadius: "4px",
                padding: "0px 3px",
              }
            : {}
        }
        className={!isSoldout ? styles["card-main-container"] : ""}
      >
        <Box sx={{ display: "flex", borderRadius: "10px" }}>
          <Box
            sx={{
              padding: isSoldout ? "4px 0px 7px 0px" : "0 0 7px 0",
              backgroundColor: isSoldout ? "" : "#f4f4f6",
            }}
            className={styles["card-image-container"]}
            onClick={handleNavigateToDetailedPage}
          >
            <CardMedia
              component="img"
              className={
                isSoldout
                  ? `${styles["card-media-mui"]} ${styles["media-opacity"]}`
                  : styles["card-media-mui"]
              }
              image={mediaUrl}
              alt=" "
            />

            {mediaUrl && (
              <Grid container className={styles["image-titles"]}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Stack flexDirection={"column"}>
                    {startingPrice ? (
                      <Typography className={styles["typography-white"]}>
                        ₹{amountFormatter(startingPrice)}
                      </Typography>
                    ) : (
                      "₹0"
                    )}

                    <Typography className={styles["typography-white"]}>
                      Onwards
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Stack flexDirection={"column"}>
                    <Typography className={styles["typography-white"]}>
                      {startingArea} Sqft
                    </Typography>

                    <Typography className={styles["typography-white"]}>
                      Onwards
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Box>
          <Box
            onClick={handleNavigateToDetailedPage}
            sx={{ marginRight: isSoldout ? "5px" : "", cursor: "pointer" }}
            className={styles["card-details-content-container"]}
          >
            <Box>
              <div
                className={`${styles["title-subtitle-detail-holder"]} ${styles["margin-left"]}`}
              >
                <div
                  className={
                    isSoldout
                      ? styles["title-subtitle-holder-soldout"]
                      : styles["title-subtitle-holder"]
                  }
                >
                  <h2 className={styles["line-ellipsis"]}>{launchName}</h2>
                  {(city + state).length > 25 && !isSmallerScreen ? (
                    <span className={styles["line-ellipsis"]}>{address()}</span>
                  ) : isSmallerScreen ? (
                    <span className={styles["line-ellipsis"]}>
                      <Stack flexDirection={"column"} alignItems={"flex-start"}>
                        <span className={styles["line-ellipsis"]}>{city}</span>
                        <span className={styles["line-ellipsis"]}>{state}</span>
                      </Stack>
                    </span>
                  ) : (
                    <span className={styles["line-ellipsis"]}>
                      {city}
                      {city && state && ", "} {state}
                    </span>
                  )}
                </div>

                <div>
                  {appreciation !== undefined && appreciation !== null && (
                    <Stack>
                      <KeyboardDoubleArrowUpIcon
                        className={styles["card-easticon"]}
                        color={"success"}
                      />
                      <Typography
                        color={"#44b75d"}
                        className={styles["percentage"]}
                      >
                        {appreciation}%
                      </Typography>
                    </Stack>
                  )}
                  <div style={{ textAlign: "end" }}>
                    <EastIcon
                      sx={{ color: isSoldout ? "white" : "" }}
                      className={styles["card-easticon"]}
                      onClick={handleNavigateToDetailedPage}
                    />
                  </div>
                </div>
              </div>
            </Box>
            <Box
              className={
                isSoldout
                  ? styles["description-content-wrapper-soldout"]
                  : styles["description-content-wrapper"]
              }
            >
              <div>
                <Typography
                  color={isSoldout ? "white" : "inherit"}
                  className={`${styles["description-text"]} ${styles["description-text-ellipsis-2"]}`}
                >
                  {description}
                </Typography>
              </div>
            </Box>
          </Box>
        </Box>
        <div style={{ textAlign: "end" }}>
          {!isSoldout && (
            <MuiStyledButton
              endIcon={<EastIcon />}
              variant="text"
              className={styles["apply-now-btn"]}
              onClick={() => {
                navigate(RouteConfigurations.landSkuList, {
                  state: { projectId: id, launchName },
                });
              }}
            >
              Apply Now
            </MuiStyledButton>
          )}
        </div>
      </Box>
      <Box mt={1.3} sx={{ textAlign: "end" }}>
        {isSoldout && (
          <Box className={styles["sold-out-text-wrapper"]}>
            <Typography className={styles["sold-out-text"]}>
              Sold Out
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
