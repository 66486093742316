import MediaAssets from "assets";
import styles from "./Chat.module.css";
import {
  Button,
  Grid,
  Box,
  useMediaQuery,
  useTheme,
  styled,
  Drawer,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ChatAction } from "redux-container/chat-redux/ChatRedux";
import { Fragment, useEffect, useState } from "react";
import ChatContactItem from "./ChatContactItem";
import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";
import ChatDisplay from "./chat-display/ChatDisplay";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";
import { dateConstants, formatDateTime } from "utils/DateFormatter";
import Loader from "components/loader/Loader";

const Chat = () => {
  const ChatContentWrapper = styled(Box)`
    & ::-webkit-scrollbar {
      display: none;
    }
  `;
  const actionDispatch = (dispatch: any) => {
    return {
      getChat: () => {
        dispatch(ChatAction.getChatRequest());
      },
      getChatData: (data: any) => {
        dispatch(ChatAction.getChatDataRequest(data));
      },
      setChatDataState: (key: any, value: any) => {
        dispatch(ChatAction.setChatDataState(key, value));
      },
    };
  };

  const { getChat, getChatData, setChatDataState } = actionDispatch(
    useDispatch()
  );

  const chats = useSelector((state: any) => state.chat.chat);
  const isLoading = useSelector((state: any) => state.chat.isLoading);
  const openChatDisplayDrawer = useSelector(
    (state: any) => state?.chat?.openDisplayDrawer
  );
  const error = useSelector((state: any) => state?.chat?.error);
  const lastUpdatedDate = useSelector(
    (state: any) => state?.chat?.lastUpdatedDate
  );
  const isChatPageLoading = useSelector(
    (state: any) => state?.chat?.isChatPageLoading
  );
  const getInitialChatDisplayData = useSelector(
    (state: any) => state?.chat?.getInitialChatDisplayData
  );

  const chatDisplayDetailsState = useSelector(
    (state: any) => state?.chat?.chatDisplayDetails
  );

  const handleCloseSnackBar = () => {
    setChatDataState("error", null);
  };

  const onCellClick = (
    topicId: string,
    isInvested: boolean,
    shouldSetState: boolean,
    launchName?: string,
    imageUrl?: string,
    crmId?: any,
    projectContentId?: any,
    bookingData?: any
  ): void => {
    const requestData = {
      topicId: topicId,
      isInvested: isInvested,
    };
    getChatData(requestData);

    if (shouldSetState) {
      setChatDataState("chatDisplayDetails", {
        name: launchName,
        imageUrl: imageUrl,
        topicId: topicId,
        isInvested: isInvested,
        crmId: crmId,
        projectContentId: projectContentId,
        booking: bookingData,
      });
    }
    setChatDataState("openDisplayDrawer", true);
    // setOpenChatDisplayDrawer(true)
  };

  const callGetChatData = () => {
    getChat();
  };

  useEffect(() => {
    callGetChatData();
    return () => {
      setChatDataState("shouldScroll", false);
    };
  }, []);

  useEffect(() => {
    if (chats?.length > 0) {
      if (!chatDisplayDetailsState) {
        const requestData = {
          topicId: chats[0].topicId,
          isInvested: chats[0]?.isInvested ? chats[0]?.isInvested : true,
        };
        getChatData(requestData);
        // if (getInitialChatDisplayData) {
        setChatDataState("chatDisplayDetails", {
          name: chats[0]?.name,
          imageUrl:
            chats[0]?.booking?.crmLaunchPhase?.projectContent
              ?.projectCoverImages?.chatListViewPageMedia?.value?.url,
          topicId: chats[0].topicId,
          isInvested: chats[0]?.isInvested,
          crmId: chats[0]?.booking?.crmLaunchPhase?.crmProjectId,
        });
        // setChatDataState("getInitialChatDisplayData", false);
        // }
      } else {
        const requestData = {
          topicId: chatDisplayDetailsState?.topicId
            ? chatDisplayDetailsState?.topicId
            : chats[0]?.topicId,
          isInvested: chatDisplayDetailsState?.isInvested
            ? chatDisplayDetailsState?.isInvested
            : true,
        };
        getChatData(requestData);
        setChatDataState("shouldScroll", true);
      }
    }
  }, [chats?.length]);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const ChatDisplayDrawer = () => {
    return (
      <Drawer
        variant={"persistent"}
        open={openChatDisplayDrawer}
        anchor={"right"}
        PaperProps={{
          sx: {
            width: "100%",
            padding: "0 2rem",
          },
        }}
      >
        <ChatContentWrapper>
          <ChatDisplay callGetChatData={onCellClick} />
        </ChatContentWrapper>
      </Drawer>
    );
  };

  const handleOnClickRefresh = () => {
    setChatDataState("shouldScroll", false);
    getChat();
  };

  return (
    <Fragment>
      {isChatPageLoading && !openChatDisplayDrawer ? (
        <Loader />
      ) : (
        <ChatContentWrapper>
          <MuiSnackbar
            shouldOpen={error !== null ? true : false}
            message={error}
            isSuccess={false}
            closeHandler={handleCloseSnackBar}
          />
          <div className={styles["chat-container"]}>
            <p>Welcome to HoABL App, We are happy to have you.</p>

            <div className={styles["header"]}>
              <div className={styles["chat-card-detail"]}>
                <h2>Chats ({chats.length})</h2>
              </div>
              <div className={styles["date-and-time"]}>
                <span className={styles["header-update"]}>
                  Last Updated on:{" "}
                </span>
                <span className={styles["header-date"]}>
                  {formatDateTime(
                    lastUpdatedDate,
                    dateConstants.timeFormathhmmssA
                  )}{" "}
                  {formatDateTime(
                    lastUpdatedDate,
                    dateConstants["dateFormatDDthmm'YY"]
                  )}
                </span>
                <Box onClick={handleOnClickRefresh}>
                  <span className={styles["chat-refresh"]}>
                    <img
                      className={styles["chat-refresh-image"]}
                      src={MediaAssets.ic_chat_refresh}
                    />
                  </span>
                </Box>
              </div>
            </div>

            <Grid container spacing={3}>
              <Grid
                item
                className={styles["chat-list-holder"]}
                xs={12}
                sm={12}
                md={12}
                lg={5}
                xl={4}
              >
                {Array.from(chats).map((chat: any, index: any): any => {
                  return (
                    <Box mb={1.5}>
                      <ChatContactItem
                        key={index}
                        chatData={chat}
                        onCellClick={onCellClick}
                      />
                    </Box>
                  );
                })}
              </Grid>

              {isLargeScreen ? (
                <ChatDisplayDrawer />
              ) : (
                <Grid item xs={12} md={12} lg={7} xl={8}>
                  <ChatDisplay callGetChatData={onCellClick} />
                </Grid>
              )}
            </Grid>
          </div>
        </ChatContentWrapper>
      )}
    </Fragment>
  );
};

export default Chat;
