import { useSelector } from "react-redux";
import styles from "./OpportunityBanner.module.css";

const OpportunityBanner = () => {
  const { investmentDetails } = useSelector((state: any) => ({
    investmentDetails: state?.investmentDetails?.investmentDetails,
  }));

  return (
    <div className={styles["banner"]}>
      <div className={styles["banner-heading-holder"]}>
        <h1 className={styles["banner-heading"]}>
          {
            investmentDetails?.projectContent?.opportunityDoc?.sectionHeading
              ?.heading
          }
        </h1>
        <p className={styles["banner-subheading"]}>
          {
            investmentDetails?.projectContent?.opportunityDoc?.sectionHeading
              ?.subHeading
          }
        </p>
      </div>
      <img
        src={
          investmentDetails?.projectContent?.opportunityDoc?.bannerImage?.value
            ?.url
        }
        alt=""
      />
      <div className={styles["banner-overlay"]}></div>
    </div>
  );
};

export default OpportunityBanner;
