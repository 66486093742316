import { ButtonArrowIcon, MuiStyledButton } from "components/mui-buttons/MuiButtons";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { Grid, Theme, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import styles from "./HomePromises.module.css";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import { Carousel } from "@mantine/carousel";
import PromiseCard from "pages/promises/promise-card/PromiseCard";
import { getPageAction } from "redux-container/page-management-redux/PageManagementRedux";
import { numOfItems } from "utils/helperFunctions";
import { useMemoizedValue } from "app/hooks/useMemoizedValue";

const actionDispatch = (dispatch: any) => {
  return {
    getPagesRequest: (pageType: string) =>
      dispatch(getPageAction.getPagesRequest(pageType)),
    setPageState: (key: string, value: any) =>
      dispatch(getPageAction.setPageState(key, value)),
  };
};

const HomePromises = () => {
  const navigate = useNavigate();
  const { setPageState } = actionDispatch(useDispatch());
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { pageData, promiseSection } = useSelector((state: any) => ({
    pageData: state?.pageManagement?.pageData?.homePagesOrPromises,
    promiseSection: state?.pageManagement?.pageData?.page,
  }));

  const handlePromiseDrawer = (promise: typeof pageData[0]) => () => {
    setPageState("promiseDetails", promise);
    setPageState("isDialogOpen", true);
  };

  const slicedPromisesData = useMemoizedValue(
    pageData,
    promiseSection?.totalPromisesOnHomeScreen
  );

  return (
    <Fragment>
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <div className={styles["left-split"]}>
            <div>
              <h1>{promiseSection?.promisesHeading}</h1>
              {!isSmallScreen && <p>Peace of land assured by promises</p>}
            </div>
            <MuiStyledButton
              endIcon={<ButtonArrowIcon />}
              className={styles["see-all-promise"]}
              variant={isSmallScreen ? "text" : "contained"}
              onClick={() => navigate(RouteConfigurations.promise)}
            >
              SEE ALL
            </MuiStyledButton>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          {slicedPromisesData && !!slicedPromisesData.length && (
            <MantineCarousel
              includeGapInSize
              containermargin={"1rem 0"}
              slideSize={numOfItems(2.9)}
              height={isSmallScreen ? 300 : 340}
              slidesToScroll={slicedPromisesData.length <= 3 ? 1 : 3}
              breakpoints={[
                { maxWidth: "lg", slideSize: numOfItems(3) },
                { maxWidth: "md", slideSize: numOfItems(3) },
                { maxWidth: "sm", slideSize: numOfItems(3) },
                { maxWidth: "xs", slideSize: numOfItems(2), slideGap: "xs" },
              ]}
            >
              {slicedPromisesData.map((promise: typeof pageData[0]) => {
                return (
                  <Carousel.Slide key={promise?.id}>
                    <PromiseCard
                      cardheight={300}
                      name={promise?.name}
                      description={promise?.shortDescription}
                      iconsrc={promise?.displayMedia?.value?.url}
                      handlecardclick={handlePromiseDrawer(promise)}
                    />
                  </Carousel.Slide>
                );
              })}
            </MantineCarousel>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default HomePromises;
