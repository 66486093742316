import MediaAssets from "assets";
import { useEffect } from "react";
import styles from "./Promises.module.css";
import { getPageAction } from "redux-container/page-management-redux/PageManagementRedux";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/Loader";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import { Carousel } from "@mantine/carousel";
import PromiseCard from "./promise-card/PromiseCard";
import { numOfItems } from "utils/helperFunctions";
import { Avatar, Theme, Typography, useMediaQuery } from "@mui/material";
import { PageTypeEnum } from "enumerations/PageTypeEnum";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";

const actionDispatch = (dispatch: any) => {
  return {
    getPromiseRequest: (pageType: string) =>
      dispatch(getPageAction.getPromiseRequest(pageType)),
    setPageState: (key: string, value: any) =>
      dispatch(getPageAction.setPageState(key, value)),
  };
};

const Promises = () => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { getPromiseRequest, setPageState } = actionDispatch(useDispatch());
  const { pageData, promiseData, isLoading, error } = useSelector(
    (state: any) => ({
      pageData: state?.pageManagement?.promiseData?.page,
      isLoading: state?.pageManagement?.isLoading,
      error: state?.pageManagement?.error,
      promiseData: state?.pageManagement?.promiseData?.homePagesOrPromises,
    })
  );

  const handlePromiseDrawer = (promise: typeof promiseData[0]) => () => {
    setPageState("promiseDetails", promise);
    setPageState("isDialogOpen", true);
  };

  useEffect(() => {
    let cancelEffect = true;
    if (cancelEffect) {
      getPromiseRequest(PageTypeEnum.Promises);
    }
    return () => {
      cancelEffect = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Avatar
            className={styles["promise-full-banner"]}
            src={
              isSmallScreen
                ? MediaAssets.ic_promise_banner_small
                : MediaAssets.ic_promise_banner
            }
            alt="ic_promise_banner"
          />
          <div className={styles["about-promise-holder"]}>
            <h2>{pageData?.promiseSection?.aboutPromises?.sectionHeading}</h2>
            <p>{pageData?.promiseSection?.aboutPromises?.subDescription}</p>
          </div>
          {promiseData && !!promiseData.length && (
            <div className={styles["promises-carousel-holder"]}>
              <h2>{pageData?.promiseSection?.promiseSectionHeading}</h2>

              <MantineCarousel
                containermargin={0}
                breakpoints={[
                  { maxWidth: "xl", slideSize: numOfItems(4.5) },
                  { maxWidth: "lg", slideSize: numOfItems(3.4) },
                  { maxWidth: "md", slideSize: numOfItems(2.3) },
                  { maxWidth: "sm", slideSize: numOfItems(2) },
                  { maxWidth: "xs", slideSize: numOfItems(2) },
                ]}
                height={isSmallScreen ? 280 : 360}
                slideSize={numOfItems(5)}
              >
                {promiseData?.map((promise: typeof promiseData[0]) => {
                  return (
                    <Carousel.Slide>
                      <PromiseCard
                        isarrowrequire={true}
                        name={promise?.name}
                        iconsrc={promise?.displayMedia?.value?.url}
                        description={promise?.shortDescription}
                        handlecardclick={handlePromiseDrawer(promise)}
                      />
                    </Carousel.Slide>
                  );
                })}
              </MantineCarousel>
            </div>
          )}
          {pageData?.promiseSection?.aboutPromises?.isDisclaimerActive && (
            <Typography
              variant={isSmallScreen ? "body2" : "body1"}
              className={styles["disclaimer-text"]}
            >
              {pageData?.promiseSection?.disclaimer}
            </Typography>
          )}
        </>
      )}

      <MuiSnackbar
        shouldOpen={error !== null ? true : false}
        message={error}
        isSuccess={false}
        closeHandler={() => setPageState("error", null)}
      />
    </section>
  );
};

export default Promises;
