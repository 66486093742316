import { Box, Stack, Typography } from '@mui/material'
import React from 'react';
import styles from './UserChatCard.module.css';
import CheckIcon from '@mui/icons-material/Check';

const UserChatCard = ({message}: any) => {
  return (
    <Stack justifyContent={'end'} textAlign={'end'} >
    <Stack className={styles["user-chat-card"]}>
      <Typography className={styles["user-chat-message"]}>
        {message}
      </Typography>
    <Box className={styles["check-icon-container"]}>
      <CheckIcon className={styles["check-icon"]} fontSize={'inherit'} />
    </Box>
    </Stack>
    </Stack>
  )
}

export default UserChatCard