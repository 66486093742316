import { useState, useEffect } from "react";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import { helpCenterMenus } from "../ProfileConstants";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useDispatch, useSelector } from "react-redux";
import { ProfileAction } from "redux-container/profile/ProfileRedux";
import { Constants } from "constants/Constants";
import ProfileMenuItem from "../profile-menu-item/ProfileMenuItem";
import EastIcon from "@mui/icons-material/East";
import ShareFeedback from "../share-feedback/ShareFeedback";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import PrivacyPolicy from "pages/privacy-policy/PrivacyPolicy";
import MediaAssets from "assets";
import styles from "./HelpCenter.module.css";

const actionDispatch = (dispatch: any) => {
  return {
    getProfileDetails: () => dispatch(ProfileAction.getProfileDetailsRequest()),
  };
};

const HelpCenter = () => {
  const { getProfileDetails } = actionDispatch(useDispatch());
  const [openShareFeedbackDialog, setOpenShareFeedbackDialog] = useState(false);
  const [privacyPolicyDialog, setPrivacyPolicyDialog] = useState(false);
  const navigate = useNavigate();
  const isPrivacyPolicyActive = useSelector(
    (state: any) =>
      state?.profile?.profileDetails?.pageManagement?.data?.page?.isTermsActive
  );

  const handleOpenDialog = () => {
    setOpenShareFeedbackDialog(true);
  };

  const handleDialogClose = () => {
    setOpenShareFeedbackDialog(false);
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getProfileDetails();
    handleClosePrivacyDialog();
    setOpenShareFeedbackDialog(false);
  }, []);

  const filterActiveItems = () => {
    const values = helpCenterMenus.map((item: any) => {
      if (item?.title === "Terms & Conditions & Privacy Policy") {
        if (isPrivacyPolicyActive === true) {
          return item;
        }
      } else {
        return item;
      }
    });
    return values;
  };

  const handleClosePrivacyDialog = () => {
    setPrivacyPolicyDialog(false);
  };

  return (
    <div>
      <Stack
        className={styles["typography-section-heading"]}
        mb={3}
        spacing={2}
        justifyContent={"start"}
      >
        <IconButton>
          <KeyboardArrowLeftIcon
            className={styles["left-arrow"]}
            onClick={handleNavigateBack}
          />
        </IconButton>
        <Typography className={styles["typography-section-heading"]}>
          Help Center
        </Typography>
      </Stack>
      <ShareFeedback
        open={openShareFeedbackDialog}
        handleClose={handleDialogClose}
        setOpenShareFeedbackDialog={setOpenShareFeedbackDialog}
      />
      {filterActiveItems()?.map((menu: any, index: any) => {
        return (
          <Box
            key={index}
            onClick={() => {
              if (index === 3) {
                handleOpenDialog();
              } else if (index === 1) {
                setPrivacyPolicyDialog(true);
              }
            }}
          >
            {menu && (
              <ProfileMenuItem
                title={menu.title}
                subtitle={menu.subtitle}
                icon={menu.icon}
                route={menu.route}
                currentIndex={index}
                maxIndex={helpCenterMenus?.length}
              />
            )}
          </Box>
        );
      })}

      <div className={styles["chat-us-holder"]}>
        <p className={styles["want-to-connect"]}>Want to connect?</p>
        <MuiStyledButton
          endIcon={<EastIcon />}
          startIcon={
            <img className={styles["chat-image"]} src={MediaAssets?.ic_chat} />
          }
          variant="contained"
          autoFocus
          disableElevation
          className={styles["chat-btn"]}
          onClick={() => navigate(RouteConfigurations.chat)}
        >
          Chat with us
        </MuiStyledButton>
        <p className={styles["or-text"]}>Or</p>

        <Stack
          sx={{ marginBottom: "70px" }}
          spacing={1}
          className={styles["justify-right-items"]}
        >
          <Typography className={styles["typography-gray"]}>
            Email us:
          </Typography>
          <Typography
            className={styles["email"]}
            fontWeight={500}
            onClick={() =>
              (window.location.href = `mailto:${Constants.HOABL_HELP_CENTER_MAIL}`)
            }
          >
            {Constants.HOABL_HELP_CENTER_MAIL}
          </Typography>
        </Stack>

        <PrivacyPolicy
          open={privacyPolicyDialog}
          handleClose={handleClosePrivacyDialog}
        />
      </div>
    </div>
  );
};

export default HelpCenter;
