import { Fragment } from "react";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import EastIcon from "@mui/icons-material/East";
import MuiCard from "components/mui-card/MuiCard";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import { Carousel } from "@mantine/carousel";
import { IconButton, Stack, Typography } from "@mui/material";
import { IPortfolioComponentProps } from "../Portfolio";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { numOfItems, useHandleCardNavigation } from "utils/helperFunctions";
import WatchListDrawer from "./WatchListDrawer";
import { useDispatch } from "react-redux";
import { getWatchListAction } from "redux-container/watch-list/watchListRedux";

const MyWatchList = (props: IPortfolioComponentProps): any => {
  const dispatch = useDispatch();
  const { watchListData, sectionTitle = "My Watchlist" } = props;
  const { handleNavigation } = useHandleCardNavigation();
  const handleDrawerOpen = (watchListData: any[]) => () => {
    dispatch(
      getWatchListAction.setWatchListState("watchListData", watchListData)
    );
    dispatch(getWatchListAction.setWatchListState("isDialogOpen", true));
  };

  return (
    watchListData &&
    !!watchListData.length && (
      <Fragment>
        <Stack mb={"2rem"}>
          <Typography variant="h5" fontFamily={"Jost-Medium"}>
            {sectionTitle}
          </Typography>
          <MuiStyledButton
            endIcon={<EastIcon />}
            variant="text"
            color="primary"
            onClick={handleDrawerOpen(watchListData)}
          >
            SEE ALL
          </MuiStyledButton>
        </Stack>
        <MantineCarousel
          containermargin={"1rem 0.1rem"}
          controlsOffset={0}
          includeGapInSize={false}
          slideSize={numOfItems(4)}
          breakpoints={[
            { maxWidth: "xl", slideSize: numOfItems(3.3) },
            { maxWidth: "lg", slideSize: numOfItems(2.3) },
            { maxWidth: "md", slideSize: numOfItems(2) },
            { maxWidth: "sm", slideSize: numOfItems(2) },
            { maxWidth: "xs", slideSize: numOfItems(1.2) },
          ]}
          slidesToScroll={1}
        >
          {watchListData?.map((list: typeof watchListData[0]) => (
            <Carousel.Slide key={list?.project ? list.project?.id : list?.id}>
              <MuiCard
                sx={{
                  height: "100%",
                }}
                src={
                  list?.project
                    ? list.project?.projectCoverImages?.homePageMedia?.value
                        ?.url
                    : list?.projectCoverImages?.homePageMedia?.value?.url
                }
                customheight={240}
                detail={list?.project ? list.project : list}
                buttonwitharrow={true}
                onbuttonclick={() =>
                  handleNavigation(
                    RouteConfigurations.landSkuList,
                    list?.project ? list.project?.id : list?.id,
                    list?.project ? list.project?.launchName : list?.launchName
                  )
                }
                oncontentclick={() =>
                  handleNavigation(
                    RouteConfigurations.investmentDetails,
                    list?.project ? list.project?.id : list?.id
                  )
                }
              >
                <Stack>
                  <Typography
                    variant="body2"
                    color={"grey.A400"}
                    fontFamily={"Jost-Light"}
                  >
                    {list?.project
                      ? list?.project?.shortDescription
                      : list?.shortDescription}
                  </Typography>
                  <IconButton color="inherit">
                    <EastIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </MuiCard>
            </Carousel.Slide>
          ))}
        </MantineCarousel>
        <WatchListDrawer
          watchListData={watchListData}
          sectionTitle={sectionTitle}
        />
      </Fragment>
    )
  );
};

export default MyWatchList;
