import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../../../utils/ValidationUtils";
import { MuiLoadingButton } from "../../../../components/mui-buttons/MuiButtons";
import { TroubleSigninAction } from "redux-container/login/trouble-signingin-redux/TroubleSigninginRedux";
import {
  troubleSigninPayload,
  TroubleSigninValidate,
} from "../LoginInitialValues";
import MuiFormDialog from "components/mui-dialogs/MuiFormDialog";
import TroubleSigningForm from "pages/authentication/TroubleSigningForm";
import MuiActionStatusDialog from "components/mui-dialogs/MuiActionStatusDialog";
import MuiTextField from "../../../../components/mui-textfield/MuiTextfield";
import newUserStyles from "./NewUserNameInfo.module.css";
import NewUserNameInfoActions from "../../../../redux-container/login/new-user-name-info/NewUserNameInfoRedux";
import VerifyOtpActions from "../../../../redux-container/login/verify-otp/VerifyOtpRedux";
import GenerateOtpActions from "../../../../redux-container/login/generate-otp/GenerateOtpRedux";
import useForm from "app/hooks/useForm";
import styles from "../Login.module.css";
import NumberListDialog from "components/number-list-dialog/NumberListDialog";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const actionDispatch = (dispatch: any) => {
  return {
    submitUserName: (data: any) =>
      dispatch(NewUserNameInfoActions.newUserNameInfoRequest(data)),
    setVerifyOtpState: (key: any, data: any) =>
      dispatch(VerifyOtpActions.setVerifyOtpState(key, data)),
    setGenerateOtpState: (key: any, data: any) =>
      dispatch(GenerateOtpActions.setGenerateOtpState(key, data)),
    addTroubleSigninRequest: (data: any) =>
      dispatch(TroubleSigninAction.addTroubleSigninRequest(data)),
    setTroubleSigninState: (key: any, value: any) =>
      dispatch(TroubleSigninAction.setTroubleSigninState(key, value)),
  };
};

const NewUserNameInfo = ({ fullScreen }: any) => {
  const {
    submitUserName,
    setVerifyOtpState,
    setGenerateOtpState,
    addTroubleSigninRequest,
    setTroubleSigninState,
  } = actionDispatch(useDispatch());
  const {
    isResponseDialogOpen,
    isDialogOpen,
    errorMessage,
    isLoading,
    isSuccess,
    message,
    mobileNumber,
    isCountryCodeDialogOpen,
    isSetUserNameLoading,
  } = useSelector((state: any) => ({
    isResponseDialogOpen: state?.troubleSignin?.isResponseDialogOpen,
    isDialogOpen: state?.troubleSignin?.isDialogOpen,
    isLoading: state?.troubleSignin?.isLoading,
    message: state?.troubleSignin?.message,
    errorMessage: state?.troubleSignin?.error,
    isSuccess: state?.troubleSignin?.isSuccess,
    mobileNumber: state?.generateOtp.mobileNumber,
    isCountryCodeDialogOpen: state?.troubleSignin?.isCountryCodeDialogOpen,
    isSetUserNameLoading: state?.newUserNameInfo?.isLoading,
  }));
  const { handleSubmit, valid, resetForm, setFieldValue, ...formData } =
    useForm({
      initialValues: troubleSigninPayload,
      validate: TroubleSigninValidate,
    });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleChangeFirstName = (event: any) => {
    // No leading whitespace, special characters and numbers will be accepted for firstName and lastName
    setFirstName(
      event.target.value
        .toString()
        .trimStart()
        .replace(/[^A-Za-z ]/gi, "")
    );
  };

  const handleChangeLastName = (event: any) => {
    setLastName(
      event.target.value
        .toString()
        .trimStart()
        .replace(/[^A-Za-z ]/gi, "")
    );
  };

  const handleSubmitAndContinue = () => {
    const requestBody = {
      firstName: firstName,
      lastName: lastName,
    };
    submitUserName(requestBody);
  };

  const handleFormDialogOpen = () => {
    setTroubleSigninState("isDialogOpen", true);
    setFieldValue("phoneNumber", mobileNumber);
  };

  const handleFormDialogClose = () => {
    setTroubleSigninState("isResponseDialogOpen", false);
    resetForm(troubleSigninPayload);
    setTroubleSigninState("isDialogOpen", false);
  };
  const openCountryCodeDialog = () => {
    setTroubleSigninState("isCountryCodeDialogOpen", true);
  };

  const onFormSubmit = (values: typeof troubleSigninPayload) => {
    addTroubleSigninRequest(values);
    setTroubleSigninState("isResponseDialogOpen", true);
    setTroubleSigninState("isDialogOpen", false);
  };

  const handleNavigateBack = () => {
    setVerifyOtpState("shouldShowNewUserName", false);
    setVerifyOtpState("isOtpVerified", false); // added this to hide snackbar of verify otp screen
    setGenerateOtpState("isOtpGenerated", false);
  };

  return (
    <div className={styles["form-holder"]}>
      <div>
        <h2 className={styles["form-heading"]}>
          <IconButton onClick={handleNavigateBack}>
            <KeyboardArrowLeftIcon />
          </IconButton>
          Please enter your name.
        </h2>

        <MuiTextField
          inputProps={{
            maxLength: 15,
          }}
          required
          fullWidth
          label="First Name"
          placeholder="Type here"
          name="firstName"
          value={firstName}
          // autoFocus
          // focused
          onChange={handleChangeFirstName}
          className="margin-top-55"
        />

        <MuiTextField
          inputProps={{
            maxLength: 15,
          }}
          fullWidth
          label="Last Name"
          placeholder="Type here"
          name="lastName"
          value={lastName}
          onChange={handleChangeLastName}
          className={styles["form-textfield"]}
        />

        <div className={newUserStyles["submit-btn-holder"]}>
          <MuiLoadingButton
            loading={isSetUserNameLoading}
            disabled={isEmpty(firstName) ? true : false}
            variant="contained"
            onClick={handleSubmitAndContinue}
            autoFocus
            // size="large"
            disableElevation
            className={isEmpty(firstName) ? "btn btn-disabled" : "btn btn-dark"}
          >
            Submit and Continue
          </MuiLoadingButton>
        </div>
      </div>

      <div className={styles["trouble-signing-in-holder"]}>
        <button
          className={styles["trouble-signing-in"]}
          onClick={handleFormDialogOpen}
        >
          Trouble Signing in?
        </button>
      </div>

      <MuiFormDialog
        fullScreen={fullScreen}
        maxWidth={"lg"}
        formheading="Trouble Signing In?"
        open={isDialogOpen}
        handleclose={handleFormDialogClose}
        handleformsubmit={handleSubmit(onFormSubmit)}
        isformlayout={true}
        formbuttondisabled={!valid}
        children={
          <TroubleSigningForm
            formProps={{ setFieldValue, ...formData }}
            handleAdormentClick={openCountryCodeDialog}
          />
        }
      />
      <NumberListDialog
        open={isCountryCodeDialogOpen}
        onClick={() => setTroubleSigninState("isCountryCodeDialogOpen", false)}
      />
      {!isLoading && (
        <MuiActionStatusDialog
          maxWidth={"sm"}
          issuccess={isSuccess}
          isconfirmdialog={false}
          responsemessage={isSuccess ? message : errorMessage}
          message={"We will contact you shortly."}
          open={isResponseDialogOpen}
          handleclose={handleFormDialogClose}
        />
      )}
    </div>
  );
};

export default NewUserNameInfo;
