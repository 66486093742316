import { Box, Grid, Stack } from "@mui/material";
import MediaAssets from "assets";
import moment from "moment";
import { useDispatch } from "react-redux";
import { ChatAction } from "redux-container/chat-redux/ChatRedux";
import { updated } from "utils/DateUtils";
import styles from "./Chat.module.css";

interface IChatProps {
  chatData: any;
  onCellClick: any;
}

const actionDispatch = (dispatch: any) => {
  return {
    setChatDataState: (key: any, value: any) => {
      dispatch(ChatAction.setChatDataState(key, value));
    },
  };
};

const ChatContactItem = ({ chatData, onCellClick }: IChatProps) => {
  const { setChatDataState } = actionDispatch(useDispatch());

  return (
    <div
      key={chatData.index}
      className={styles["chat-contact-item"]}
      onClick={() => {
        onCellClick(
          chatData?.topicId,
          chatData?.isInvested,
          true,
          chatData?.name,
          chatData?.booking?.crmLaunchPhase?.projectContent?.projectCoverImages
            ?.chatListViewPageMedia?.value?.url,
          chatData?.booking?.crmLaunchPhase?.crmProjectId, // crmId
          chatData?.booking?.crmLaunchPhase?.projectContent?.id, // project content id
          chatData?.booking // booking object
        );
        setChatDataState("isAllowTyping", false);
        setChatDataState("shouldScroll", true);
      }}
    >
      <img
        src={
          chatData?.booking?.crmLaunchPhase?.projectContent?.projectCoverImages
            ?.chatListViewPageMedia?.value?.url
        }
        alt="Cover Image"
      />
      <div className={styles["chat-content-holder"]}>
        <Grid container spacing={2}>
          <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
            <h3 className={styles['line-ellipsis']}>{chatData?.name}</h3>
          </Grid>
          <Grid
            className={styles['updated-at-section']}
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
          >
            {chatData?.lastMessage?.message && (
            <p>{updated(chatData?.lastMessage?.updatedAt)}</p>
            )}
          </Grid>
        </Grid>
        <p className={styles['line-ellipsis']}>{chatData?.lastMessage?.message}</p>
      </div>
    </div>
  );
};

export default ChatContactItem;
