import {
  Box,
  Grid,
  Typography,
  CardActionArea,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MuiCard from "components/mui-card/MuiCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { InsightAction } from "redux-container/insights-redux/InsightsRedux";
import { handleUrlLink, splitOutPara } from "utils/helperFunctions";
import InsightView from "./InsightView";
import PlayCircleTwoToneIcon from "@mui/icons-material/PlayCircleTwoTone";
import PageHeader from "components/page-header/PageHeader";
import { MediaTypeEnum } from "enumerations/MediaTypeEnum";
import { useMemoizedValue } from "app/hooks/useMemoizedValue";

const actionDispatch = (dispatch: any) => {
  return {
    getInsightsRequest: () => dispatch(InsightAction.getInsightsRequest()),
    setInsightsState: (key: any, data: any) =>
      dispatch(InsightAction.setInsightsState(key, data)),
  };
};

const Insights = () => {
  const { getInsightsRequest, setInsightsState } = actionDispatch(
    useDispatch()
  );
  const { insightsData, pageData } = useSelector((state: any) => ({
    insightsData: state?.insights?.insightsData,
    pageData: state?.pageManagement?.pageData?.page,
  }));

  const { breakpoints } = useTheme();
  const isSmallerScreen = useMediaQuery(breakpoints.down("sm"));

  const slicedInsightsData = useMemoizedValue(
    insightsData,
    pageData?.totalInsightsOnListView
  );

  const handleDialogOpen = (insightIndex: number, insightsData: any) => {
    setInsightsState("isDialogOpen", true);
    setInsightsState("insightDetailIndex", insightIndex);
    setInsightsState("insightsData", insightsData);
  };

  useEffect(() => {
    let cancelEffect = true;
    if (cancelEffect) {
      getInsightsRequest();
    }
    return () => {
      cancelEffect = false;
    };
  }, []);

  return (
    <Box>
      <PageHeader
        title={pageData?.insightsHeading}
        subtitle={pageData?.insightsSubHeading}
      />
      <Grid container spacing={3}>
        {slicedInsightsData?.map(
          (insight: typeof insightsData[0], insightIndex: number) => (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={insight.id}>
              <CardActionArea
                onClick={() =>
                  handleDialogOpen(insightIndex, slicedInsightsData)
                }
              >
                <MuiCard
                  sx={{
                    height: "100%",
                    minHeight: "540px",
                  }}
                  customheight={300}
                  cardtitle={insight?.displayTitle}
                  titleTypographyProps={{
                    variant: "body2",
                    fontFamily: "Jost-Medium",
                    minHeight: isSmallerScreen ? 0 : "45px",
                  }}
                  action={
                    insight?.insightsMedia[0]?.media?.value?.mediaType ===
                      MediaTypeEnum.Video && (
                      <PlayCircleTwoToneIcon
                        sx={{
                          color: "common.white",
                          position: "absolute",
                          left: "8%",
                          bottom: "40%",
                        }}
                        fontSize={"large"}
                      />
                    )
                  }
                  src={handleUrlLink(insight?.insightsMedia[0]?.media?.value)}
                >
                  <Typography
                    variant="body2"
                    color={"grey.A400"}
                    fontFamily={"Jost-Light"}
                    dangerouslySetInnerHTML={splitOutPara(
                      insight?.insightsMedia[0]?.description
                    )}
                  ></Typography>
                </MuiCard>
              </CardActionArea>
            </Grid>
          )
        )}
      </Grid>
      <InsightView />
    </Box>
  );
};

export default Insights;
