import MediaAssets from "assets";
import { Constants } from "constants/Constants";
import styles from "./DownloadApp.module.css";
import Grid from "@mui/material/Grid";
import { Avatar, useMediaQuery } from "@mui/material";

const DownloadApp = () => {
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  const openGooglePlayStore = () => {
    window.open(Constants.GOOGLE_PLAY_STORE_URL);
  };

  const openAppleAppStore = () => {
    window.open(Constants.APPLE_APP_STORE_URL);
  };

  return (
    <div className={styles["download-app-section"]}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={styles["heading-holder"]}
        >
          <div className={styles["stores-icon-holder"]}>
            <h1 className={styles["download-heading"]}>
              Download the app today
            </h1>
            <div>
              <img
                src={MediaAssets.ic_playstore_download}
                alt="Download app from Google Play Store"
                className={styles["playstore-icon"]}
                onClick={openGooglePlayStore}
              />
              <img
                src={MediaAssets.ic_appstore_download}
                alt="Download app from Apple App Store"
                className={styles["playstore-icon"]}
                onClick={openAppleAppStore}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Avatar
            src={MediaAssets.ic_mobile_app}
            sx={{
              width: "50%",
              height: "50%",
              margin: "auto",
              marginTop: isSmallScreen ? "30px" : "auto",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DownloadApp;
