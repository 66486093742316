import {
  Box,
  Container,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import styles from "./ProjectTimeline.module.css";
import ProjectTimelineCard from "./project-timeline-card/ProjectTimelineCard";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ImageViewDialog from "pages/profile/my-account/image-view-dialog/ImageViewDialog";
import { getProjectContentsAction } from "redux-container/project-contents/ProjectContents";
import Loader from "components/loader/Loader";
import { formatDateTime, dateConstants } from "utils/DateFormatter";
import MyServicesDialog from "pages/profile/my-services-dialog/MyServicesDialog";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";

const actionDispatch = (dispatch: any) => {
  return {
    getProjectContentById: (id: any) =>
      dispatch(getProjectContentsAction.getProjectContentByIdRequest(id)),
    getTimelineMedia: (data: any) =>
      dispatch(
        getProjectContentsAction.getMediaByCategoryAndProjectIdRequest(data)
      ),
    setProjectContentState: (key: any, value: any) =>
      dispatch(getProjectContentsAction.setProjectContentsState(key, value)),
  };
};

const ProjectTimeline = () => {
  const projectContentById = useSelector(
    (state: any) => state?.projectContent?.projectContentById
  );

  const projectTimelines = projectContentById?.projectContent?.projectTimelines;
  const projectCompletionDate =
    projectContentById?.projectContent?.projectCompletionDate;

  const isLoading = useSelector(
    (state: any) => state?.projectContent?.isLoading
  );
  const error = useSelector((state: any) => state?.projectContent?.error);

  const { getProjectContentById, getTimelineMedia, setProjectContentState } =
    actionDispatch(useDispatch());
  const location: any = useLocation();

  const navigate = useNavigate();
  const handleNavigateBack = () => {
    navigate(-1);
  };

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [dialogImageTitle, setDialogImageTitle] = useState("");
  const [dialogImageUrl, setDialogImageUrl] = useState("");
  const isFacilityVisible = useSelector(
    (state: any) => state?.pagemanagement?.pageData?.isFacilityVisible
  );
  const [openMyServiceDialog, setOpenMyServiceDialog] = useState(false);
  const [openImageDialogCheck, SetIsOpenImageDialog] = useState(false);

  useEffect(() => {
    getProjectContentById(location?.state?.projectId);
  }, [location?.state?.projectId]);

  const handleViewDetailsClick = (title: any, url: any) => {
    setDialogImageTitle(title);
    setDialogImageUrl(url);
    setOpenImageDialog(true);
  };

  const handleMediaForTimeline = async (category: any, index: any) => {
    const getMediaByCategory =
      projectContentById?.projectContent?.latestMediaGalleryOrProjectContent[0]?.images?.filter(
        (item: any) => item.category === category
      );
    setDialogImageTitle(getMediaByCategory[index]?.name);
    setDialogImageUrl(getMediaByCategory[index]?.mediaContent?.value?.url);
    SetIsOpenImageDialog(true);
  };

  const handleCloseMyServiceDialog = () => {
    setOpenMyServiceDialog(false);
    SetIsOpenImageDialog(false);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
    setDialogImageTitle("");
    setDialogImageUrl("");
  };

  useEffect(() => {
    if (openImageDialogCheck && dialogImageUrl) {
      setOpenImageDialog(true);
    }
  }, [dialogImageUrl, openImageDialogCheck]);

  const handleCloseSnackBar = () => {
    setProjectContentState("error", null);
  };

  return (
    <Fragment>
      <ImageViewDialog
        open={openImageDialog}
        title={dialogImageTitle}
        handleClose={handleCloseImageDialog}
        imageUrl={dialogImageUrl}
        contentType={"img"}
      />
      <MuiSnackbar
        shouldOpen={error !== null ? true : false}
        message={error}
        isSuccess={false}
        closeHandler={handleCloseSnackBar}
      />
      <Box>
        <Stack justifyContent={"start"} spacing={1}>
          <IconButton onClick={handleNavigateBack}>
            <KeyboardArrowLeftIcon />
          </IconButton>

          <Typography className={styles["typography-main-heading"]}>
            Project Timeline
          </Typography>
        </Stack>

        {isLoading ? (
          <Loader />
        ) : (
          <Box mb={4} className={styles["section-container"]}>
            <Stack>
              <Stack
                ml={isSmallerScreen ? 3 : 0}
                flexDirection={"column"}
                alignItems={"start"}
              >
                <Typography className={styles["typography-main-heading"]}>
                  {projectContentById?.projectContent?.launchName}
                </Typography>
                <Typography className={styles["typography-text-2"]}>
                  {projectContentById?.projectContent?.address?.city}
                  {projectContentById?.projectContent?.address?.city &&
                    projectContentById?.projectContent?.address?.state &&
                    ","}{" "}
                  {projectContentById?.projectContent?.address?.state}
                </Typography>
              </Stack>

              <Stack flexDirection={"column"}>
                <Typography className={styles["typography-text-1"]}>
                  Project Completion
                </Typography>
                {projectTimelines?.length > 0 && projectCompletionDate ? (
                  <Typography className={styles["typography-main-heading-2"]}>
                    {formatDateTime(
                      projectCompletionDate,
                      dateConstants.dateFormatMMYY
                    )}
                  </Typography>
                ) : (
                  <Typography className={styles["typography-main-heading-2"]}>
                    -
                  </Typography>
                )}
              </Stack>
            </Stack>

            {projectTimelines?.map((item: any, index: number) => {
              let getSectionDetails = () => {
                if (index !== 0 || index !== projectTimelines?.length - 1) {
                  const data = item?.timeLines?.filter(
                    (timeline: any) => timeline?.values?.percentage < 100
                  );
                  return data;
                }
                return;
              };
              let isAllMilestoneCompleted =
                getSectionDetails()?.length > 0 ? true : false;
              return (
                <Box mt={2} className={styles["timeline-details-section"]}>
                  <ProjectTimelineCard
                    title={item?.timeLineSectionHeading}
                    showTimelineConnector={
                      item?.timeLines?.filter(
                        (item: any) => item?.isSectionActive === true
                      )?.length > 0
                        ? true
                        : false
                    }
                    isHeading={true}
                    isSectionCompleted={
                      index === 0 || index === projectTimelines?.length - 1
                        ? true
                        : !isAllMilestoneCompleted
                    }
                    showHeaderCircle={
                      index === 0 || index === projectTimelines?.length - 1
                        ? false
                        : isAllMilestoneCompleted
                    }
                    // isConnectorDisabled={}
                  />
                  {item?.timeLines?.map(
                    (timeline: any, timelineIndex: number) => (
                      <div>
                        {timeline?.isSectionActive && (
                          <ProjectTimelineCard
                            title={timeline?.values?.displayName}
                            showViewDetails={
                              timeline?.values?.isCtaActive &&
                              timeline?.values?.percentage === 100
                                ? true
                                : false
                            }
                            handleClickViewDetails={() => {
                              if (timeline?.values?.medias?.value?.url) {
                                handleViewDetailsClick(
                                  timeline?.values?.imageTitle,
                                  timeline?.values?.medias?.value?.url
                                );
                              } else {
                                handleMediaForTimeline(
                                  timeline?.category,
                                  timelineIndex
                                );
                              }
                            }}
                            showTimelineConnector={
                              item?.timeLines[timelineIndex + 1]
                                ?.isSectionActive
                                ? true
                                : false
                            }
                            timelineStatusPercentage={
                              timeline?.values?.percentage
                            }
                            isSectionDisabled={
                              timeline?.values?.percentage === 0 ? true : false
                            }
                            isConnectorDisabled={
                              item?.timeLines[timelineIndex + 1]?.values
                                ?.percentage === 0
                                ? true
                                : false
                            }
                            isSectionCompleted={
                              item?.timeLines?.length === timelineIndex + 1 &&
                              isFacilityVisible
                                ? true
                                : timeline?.values?.percentage === 100
                                ? true
                                : false
                            }
                            toolTipDetails={timeline?.values?.toolTipDetails}
                            reraDetails={
                              index === 0 &&
                              timelineIndex === 0 &&
                              projectContentById?.projectContent?.reraDetails
                                ?.reraNumbers?.length > 0 &&
                              projectContentById?.projectContent?.reraDetails
                                ?.reraNumbers
                            }
                            showFacilityManagementBtn={
                              index === projectTimelines?.length - 1
                                ? true
                                : false
                            }
                            disbaledFMBtn={!isFacilityVisible}
                            handleClickFMBtn={() => {
                              setOpenMyServiceDialog(true);
                            }}
                          />
                        )}
                      </div>
                    )
                  )}
                </Box>
              );
            })}
          </Box>
        )}
        <MyServicesDialog
          open={openMyServiceDialog}
          handleclose={handleCloseMyServiceDialog}
        />
      </Box>
    </Fragment>
  );
};

export default ProjectTimeline;
