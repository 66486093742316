import { Dialog, DialogActions, DialogTitle, styled } from "@mui/material";
import { IDialogCustomProps } from "./MuiFormDialog";

export const FormDialog = styled(Dialog)<IDialogCustomProps>`
  & .MuiDialog-paper {
    padding: ${({ isformlayout }) =>
      isformlayout ? " 0.3rem 0.5rem 2rem" : " 0.3rem 0.5rem 1rem"};
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const FormDialogTitle = styled<any>(DialogTitle)`
  && {
    display: flex;
    justify-content: ${(props) =>
      props.formheading ? "space-between" : "flex-end"};
    font-family: "Jost-semiBold";
    font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  }
`;

export const FormDialogAction = styled(DialogActions)`
  justify-content: center;
  margin-top: 1rem;
  & .MuiLoadingButton-root {
    max-width: ${({ theme }) => theme.typography.pxToRem(150)};
    &.Mui-disabled {
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;
