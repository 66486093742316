import { Box, Checkbox, Grid, IconButton, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./SecurityTips.module.css";
import MediaAssets from "../../../../assets";
import { SecurityTipsAction } from "../../../../redux-container/profile/SecurityTipsRedux";
import { splitOutPara } from "utils/helperFunctions";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { PageTypeEnum } from "enumerations/PageTypeEnum";
import { Stack } from "@mui/system";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const actionDispatch = (dispatch: any) => {
  // const { open, handleClose, setOpensecurityTipsDialog } = props;
  return {
    getSecurityTips: (pageType: any) => {
      dispatch(SecurityTipsAction.getSecurityTipsRequest(pageType));
    },
  };
};

const SecurityTipsItem = (props: any) => {
  const { getSecurityTips } = actionDispatch(useDispatch());

  const securityTips = useSelector((state: any) => state?.securityTips);
  const isDialogOpen = useSelector(
    (state: any) => state?.securityTips?.isDialogOpen
  );
  const isLoading = useSelector((state: any) => state?.securityTips?.isLoading);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getSecurityTips(PageTypeEnum.Profile);
  }, []);

  return (
    <div>
      <div className={styles["main-container-for-card"]}>
        <div className={styles["heading-div"]}>
          <Stack spacing={1.5} justifyContent={'start'}>
            {isSmallScreen && (
              <KeyboardArrowLeftIcon
                className={styles["left-arrow"]}
                onClick={props.onClickOnCloseIcon}
              />
            )}
          <Typography className={styles["securitytips-heading"]}>Security Tips</Typography>
          </Stack>
          {!isSmallScreen && (
          <IconButton aria-label="close">
            <CloseRoundedIcon onClick={props.onClickOnCloseIcon} />
          </IconButton>
          )}
        </div>

        <Box mb={2}>
          <Typography className={`${styles['display-name-heading']} ${styles['line-ellipsis']}`}>
            {securityTips?.securityTips?.data?.page?.securityTips?.sectionHeading}
          </Typography>
        </Box>

        <div className={styles["content-div-in-security-tips"]}>
          {securityTips?.securityTips?.data?.page?.securityTips?.detailedInformation?.map(
            (content: any) => {
              return (
                <>
                  <div className={styles["img-div"]}>
                    <img
                      className={styles["imgg"]}
                      src={content?.media?.value?.url}
                      alt=""
                    />
                  </div>
                  
                  <Box mt={3}>
                    <Typography
                    className={styles["description-text"]}
                      dangerouslySetInnerHTML={{
                        __html: content?.description,
                      }}
                    ></Typography>
                  </Box>
                </>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default SecurityTipsItem;
