import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import { Carousel } from "@mantine/carousel";
import { useDispatch, useSelector } from "react-redux";
import { NewLaunchActions } from "redux-container/investments/new-launch/NewLaunchRedux";
import {
  IconButton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { numOfItems, useHandleCardNavigation } from "utils/helperFunctions";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useEffect } from "react";
import MuiCard from "components/mui-card/MuiCard";
import EastIcon from "@mui/icons-material/East";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import CollectionTwoDrawer from "./CollectionTwoDrawer";
import styles from "./CollectionTwo.module.css";

const actionDispatch = (dispatch: any) => {
  return {
    setNewLaunchState: (key: any, value: any) =>
      dispatch(NewLaunchActions.setNewLaunchState(key, value)),
  };
};

const CollectionTwo = () => {
  const { handleNavigation } = useHandleCardNavigation();

  const { setNewLaunchState } = actionDispatch(useDispatch());

  const { pageData, collections } = useSelector((state: any) => ({
    pageData: state?.newLaunch?.newLaunchData?.page,
    collections:
      state?.newLaunch?.newLaunchData?.pageManagementsOrCollectionTwoModels,
  }));

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    return () => {
      setNewLaunchState("isTrendingProjectDialogOpen", false);
    };
  }, []);

  return (
    <section className={styles["collection-two-holder"]}>
      <div className={styles["section-heading-holder"]}>
        <div>
          <h1>{pageData?.collectionTwo?.heading}</h1>
          <span>{pageData?.collectionTwo?.subHeading}</span>
        </div>
        <MuiStyledButton
          endIcon={<EastIcon />}
          variant="text"
          color="primary"
          onClick={() => setNewLaunchState("isTrendingProjectDialogOpen", true)}
        >
          SEE ALL
        </MuiStyledButton>
      </div>

      {Boolean(collections.length) && (
        <MantineCarousel
          slidesToScroll={1}
          controlsOffset={0}
          slideSize={numOfItems(4)}
          breakpoints={[
            { maxWidth: "xl", slideSize: numOfItems(3.3) },
            { maxWidth: "lg", slideSize: numOfItems(2.3) },
            { maxWidth: "md", slideSize: numOfItems(2) },
            { maxWidth: "sm", slideSize: numOfItems(2) },
            { maxWidth: "xs", slideSize: numOfItems(1.2) },
          ]}
        >
          {collections
            ?.slice(0, pageData?.collectionTwo?.totalProjectContentsToDisplay)
            .map((detail: typeof collections[0], detailIndex: number) => (
              <Carousel.Slide key={detailIndex}>
                <MuiCard
                  sx={{
                    height: "100%",
                  }}
                  key={detailIndex + 1}
                  src={detail?.projectCoverImages?.homePageMedia?.value?.url}
                  buttonwitharrow={true}
                  customheight={"25vh"}
                  detail={detail}
                  onbuttonclick={() =>
                    handleNavigation(
                      RouteConfigurations.landSkuList,
                      detail?.id,
                      detail?.launchName
                    )
                  }
                  oncontentclick={() =>
                    handleNavigation(
                      RouteConfigurations.investmentDetails,
                      detail?.id
                    )
                  }
                >
                  <Stack columnGap={isSmallScreen ? "0.5rem" : "1rem"}>
                    <Typography
                      variant={isSmallScreen ? "body2" : "body1"}
                      color={"grey.A400"}
                      fontFamily={"Jost-Light"}
                    >
                      {detail?.shortDescription}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        handleNavigation(detail?.id, detail?.launchName)
                      }
                      color="inherit"
                    >
                      <EastIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                </MuiCard>
              </Carousel.Slide>
            ))}
        </MantineCarousel>
      )}

      <CollectionTwoDrawer />
    </section>
  );
};

export default CollectionTwo;
