import {
  Divider,
  Grid,
  IconButton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  ButtonArrowIcon,
  MuiLoadingButton,
} from "components/mui-buttons/MuiButtons";
import { DiscoverAllInvestmentStyleWrapper } from "./DiscoverAllInvestement.style";
import MuiCard from "components/mui-card/MuiCard";
import { useSelector } from "react-redux";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import { Carousel } from "@mantine/carousel";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useNavigate } from "react-router-dom";
import { useHandleCardNavigation, numOfItems } from "utils/helperFunctions";
import { useMemoizedValue } from "app/hooks/useMemoizedValue";

const DiscoverAllInvestment = () => {
  const navigate = useNavigate();
  const { handleNavigation } = useHandleCardNavigation();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { pageData, totalInvestmentToShow } = useSelector((state: any) => ({
    pageData: state?.pageManagement?.pageData?.pageManagementsOrNewInvestments,
    totalInvestmentToShow:
      state?.pageManagement?.pageData?.page?.totalProjectsOnHomeScreen,
  }));

  const slicedPageData = useMemoizedValue(pageData, totalInvestmentToShow);

  const handleInvestmentNavigation = () =>
    navigate(RouteConfigurations.investments);

  const sectionTitle = (
    <Typography
      variant="h4"
      fontFamily={{ xs: "Sea-Side" }}
      className="newGenerationLand-text"
      component={isSmallScreen ? Divider : "div"}
    >
      New Generation Land
      <span
        style={{
          fontFamily: "Jost-Medium",
          fontSize: "16px",
          position: "absolute",
          marginTop: -4,
          paddingLeft: 5,
        }}
      >
        &#174;
      </span>
    </Typography>
  );

  return (
    <DiscoverAllInvestmentStyleWrapper>
      {isSmallScreen && sectionTitle}
      <Grid
        container
        alignItems={"flex-end"}
        spacing={{ sm: 0, xs: 0, md: 0, lg: 3, xl: 4 }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          lg={5}
          xl={5}
          textAlign={isSmallScreen ? "center" : "left"}
          order={isSmallScreen ? 1 : 0}
        >
          {!isSmallScreen && (
            <>
              <Typography variant="h6">presenting</Typography>
              {sectionTitle}
              <Typography variant="body1">
                We ensure a completely secure experience for our members by
                keeping track of every single nuance of the ownership journey to
                put your mind at ease.
              </Typography>
            </>
          )}
          <MuiLoadingButton
            className={"investment-click-button"}
            onClick={handleInvestmentNavigation}
          >
            Discover All Investments
          </MuiLoadingButton>
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={7} xl={7}>
          {pageData && Boolean(pageData.length) && (
            <MantineCarousel
              containScroll="trimSnaps"
              slideSize={numOfItems(1.93)}
              breakpoints={[
                { maxWidth: "lg", slideSize: numOfItems(1.3) },
                { maxWidth: "md", slideSize: numOfItems(1.3) },
                { maxWidth: "sm", slideSize: numOfItems(1.3) },
                { maxWidth: "xs", slideSize: numOfItems(1.3) },
              ]}
              slidesToScroll={2}
              controlsOffset={0}
            >
              {slicedPageData &&
                slicedPageData.map(
                  (detail: typeof pageData[0]) =>
                    detail && (
                      <Carousel.Slide key={detail?.id}>
                        <MuiCard
                          sx={{
                            height: "100%",
                            cursor: "pointer",
                          }}
                          src={
                            detail?.projectCoverImages?.homePageMedia?.value
                              ?.url
                          }
                          buttonwitharrow={true}
                          customheight={"25vh"}
                          detail={detail}
                          oncardClick={() =>
                            handleNavigation(
                              RouteConfigurations.investmentDetails,
                              detail?.id
                            )
                          }
                          onbuttonclick={() =>
                            handleNavigation(
                              RouteConfigurations.landSkuList,
                              detail?.id,
                              detail?.launchName
                            )
                          }
                          oncontentclick={() =>
                            handleNavigation(
                              RouteConfigurations.investmentDetails,
                              detail?.id
                            )
                          }
                        >
                          <Stack columnGap={isSmallScreen ? "0.5rem" : "1rem"}>
                            <Typography
                              variant={"body2"}
                              color={"grey.A400"}
                              fontFamily={"Jost-Light"}
                            >
                              {detail?.shortDescription}
                            </Typography>
                            <IconButton color="inherit">
                              <ButtonArrowIcon fontSize="small" />
                            </IconButton>
                          </Stack>
                        </MuiCard>
                      </Carousel.Slide>
                    )
                )}
            </MantineCarousel>
          )}
        </Grid>
      </Grid>
    </DiscoverAllInvestmentStyleWrapper>
  );
};

export default DiscoverAllInvestment;
