import { ChangeEvent, ReactNode, FC } from "react";
import {
  BaseTextFieldProps,
  Box,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";

export interface ICustomTexfieldProps extends BaseTextFieldProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  InputProps?: { [key: string]: ReactNode };
  value?: any;
  defaultValue?: any;
  error?: boolean;
}

const TextFieldStyledWrapper = styled(Box)<TextFieldProps>`
  & .MuiInputBase-input:not(::placeholder) {
    color: ${({ theme, error }) =>
      error ? theme.palette.error.main : theme.palette.common.black};
  }
  & .MuiOutlinedInput-root {
    padding-left: 0.5rem;
    & .MuiInputBase-inputAdornedEnd {
      color: ${({ theme }) => theme.palette.common.black};
    }
  }
  & .MuiFormHelperText-root {
    margin-left: 0px;
    margin-right: 5px;
  }
  & .MuiFormHelperText-root.Mui-error {
    text-align: end;
    color: ${({ theme }) => theme.palette.error.main};
  }
  & .MuiAlert-message {
    font-size: 0.875rem;
  }
`;

const MuiTextField: FC<ICustomTexfieldProps> = (props) => {
  const { children, value = "", inputProps, defaultValue = "", error } = props;

  return (
    <TextFieldStyledWrapper error={error}>
      <TextField {...props}>{children}</TextField>
      <Typography
        component={"p"}
        textAlign={"end"}
        color={"text.disabled"}
        variant="caption"
        lineHeight={"1.5em"}
      >
        {inputProps?.maxLength > 15
          ? `${value?.length || defaultValue?.length}/${
              inputProps?.maxLength
            } Characters`
          : ""}
      </Typography>
    </TextFieldStyledWrapper>
  );
};

export default MuiTextField;
