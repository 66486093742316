import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Fragment, useState } from "react";
import ImageIcon from "@mui/icons-material/Image";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/mui-accordian/MuiAccordian";
import EstimationCard from "./PercentageEstimateCard";
import {
  MuiLoadingButton,
  MuiStyledButton,
} from "components/mui-buttons/MuiButtons";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useNavigate } from "react-router-dom";
import LineChart from "components/line-chart/LineChart";
import { IPortfolioComponentProps } from "../Portfolio";
import { numDifferentiation } from "utils/helperFunctions";
import MediaAssets from "assets";

const EastArrow = () => (
  <Avatar
    src={MediaAssets.ic_button_next_arrow_black}
    sx={{ width: 25, height: 25 }}
  />
);

const InvestmentCarouselSection = (props: IPortfolioComponentProps) => {
  const { projectName, projectData, InvestmentStatus, summarydetails } = props;
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<string | false>("");

  const handleChange =
    (panel: string) =>
    (__event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Grid id={projectName} container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="h5" pb={isSmallScreen ? "0" : "0.5rem"}>
          {`${projectName} Investments`}
        </Typography>
      </Grid>
      {/* {projectData && !!projectData.length ? ( */}
      {projectData?.map(
        (projects: typeof projectData[0], projectIndex: number) => (
          <Fragment key={projects?.id}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Accordion
                expanded={expanded === `panel-${projectIndex}`}
                onChange={handleChange(`panel-${projectIndex}`)}
              >
                <AccordionSummary>
                  <Grid
                    container
                    pl={1}
                    bgcolor={"common.white"}
                    borderRadius={"8px"}
                  >
                    <Grid item xs={6} sm={5} md={4} lg={3} xl={2.5}>
                      <List disablePadding>
                        <ListItem disablePadding>
                          <ListItemAvatar
                            sx={{ minWidth: isSmallScreen ? "45px" : "56px" }}
                          >
                            <Avatar
                              src={projects?.project?.projectIcon?.value?.url}
                              sx={{
                                img: {
                                  objectFit: "cover",
                                },
                              }}
                              variant="rounded"
                            >
                              <ImageIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            sx={{ marginBottom: 0 }}
                            secondaryTypographyProps={{
                              color: "#918fa3",
                              variant: isSmallScreen ? "caption" : "body2",
                            }}
                            primaryTypographyProps={{
                              variant: isSmallScreen ? "body2" : "body1",
                            }}
                            primary={projects?.project?.launchName}
                            secondary={`${projects?.project?.address?.city}, ${projects?.project?.address?.state}`}
                          />
                        </ListItem>
                      </List>
                      <Typography
                        sx={{ wordBreak: "break-word" }}
                        variant="body2"
                      >
                        {projects?.investment?.inventoryBucket}
                      </Typography>
                      <Typography variant="body1" color={"primary.main"}>
                        {projects?.investment?.crmInventory?.name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={7}
                      md={8}
                      lg={9}
                      xl={9.5}
                      padding={"1rem 0 0"}
                      bgcolor={"#f8f8f8"}
                    >
                      <LineChart
                        graphheight={50}
                        customizableGraphData={
                          projects?.project?.generalInfoEscalationGraph
                        }
                        isTextDisabled={false}
                      />
                      <Stack
                        p={isSmallScreen ? "0.5rem" : "0rem 1rem"}
                        textAlign={"center"}
                      >
                        <Box>
                          <Typography
                            variant={isSmallScreen ? "caption" : "body1"}
                            component={"p"}
                            fontFamily={"Jost-Medium"}
                            lineHeight={1}
                          >
                            ₹
                            {numDifferentiation(
                              projects?.investment?.amountInvested,
                              false,
                              true
                            )}
                          </Typography>
                          <Typography
                            variant={isSmallScreen ? "caption" : "body1"}
                            component={"p"}
                            fontFamily={"Jost-Light"}
                            lineHeight={1}
                          >
                            {projects?.investment?.amountInvested >= 100000
                              ? "Lakhs"
                              : projects?.investment?.amountInvested >= 10000000
                              ? "Cr"
                              : "K"}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant={isSmallScreen ? "caption" : "body1"}
                            component={"p"}
                            fontFamily={"Jost-Medium"}
                            lineHeight={1}
                          >
                            {projects?.investment?.crmInventory?.areaSqFt?.toLocaleString()}
                          </Typography>
                          <Typography
                            variant={isSmallScreen ? "caption" : "body1"}
                            component={"p"}
                            fontFamily={"Jost-Light"}
                            lineHeight={1}
                          >
                            Sqft
                          </Typography>
                        </Box>
                        {projectName === InvestmentStatus.completed ? (
                          <Box>
                            <Typography
                              color={"primary.main"}
                              fontFamily={"Jost-Medium"}
                              lineHeight={1}
                              variant={isSmallScreen ? "caption" : "body1"}
                              component={"p"}
                            >
                              {summarydetails?.iea}
                            </Typography>
                            <Typography
                              fontFamily={"Jost-Light"}
                              lineHeight={1}
                              variant={isSmallScreen ? "caption" : "body1"}
                              component={"p"}
                            >
                              OEA
                            </Typography>
                          </Box>
                        ) : (
                          <Box>
                            <Typography
                              color={"error.dark"}
                              fontFamily={"Jost-Medium"}
                              lineHeight={1}
                              variant={isSmallScreen ? "caption" : "body1"}
                              component={"p"}
                            >
                              {projects?.investment?.actionItemCount}
                            </Typography>
                            <Typography
                              color={"error.dark"}
                              fontFamily={"Jost-Light"}
                              variant={isSmallScreen ? "caption" : "body1"}
                              component={"p"}
                              lineHeight={1}
                            >
                              Actions
                            </Typography>
                          </Box>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      borderRight={isSmallScreen ? "none" : "1px solid #bbbccd"}
                      paddingTop={"1rem"}
                    >
                      <LineChart
                        customizableGraphData={
                          projects?.project?.generalInfoEscalationGraph
                        }
                        enableAnnotation={
                          projects?.investment?.referenceIndex && true
                        }
                        annotationValue={projects?.investment?.referenceIndex}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      display={"flex"}
                      direction={"column"}
                      justifyContent={"space-between"}
                      padding={"0.5rem"}
                    >
                      <Box>
                        {projects?.investment?.projectIea && (
                          <EstimationCard
                            cardtype="OEA"
                            maintext="Owner Estimated Appreciation (OEA)"
                            subtext="OEA is the estimated appreciation from the time of entry in the investment."
                            percentage={projects?.investment?.projectIea}
                          />
                        )}
                        <EstimationCard
                          cardtype="Estimated"
                          maintext="Estimated Appreciation (EA)"
                          subtext="EA is the estimated appreciation from the time of entry in the investment."
                          percentage={`${
                            projects?.project?.generalInfoEscalationGraph
                              ?.estimatedAppreciation ?? "0"
                          }%`}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Stack justifyContent={"flex-end"}>
                <MuiStyledButton
                  variant="text"
                  size="small"
                  color="inherit"
                  endIcon={<EastArrow />}
                  onClick={() =>
                    navigate(RouteConfigurations.portfolioDetails, {
                      state: { projects },
                    })
                  }
                >
                  Manage Investment
                </MuiStyledButton>
              </Stack>
            </Grid>
          </Fragment>
        )
      )}
      {/*  ) : (
         <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
           <EstimationCard
             isinvestdetail={true}
             cardtype="Invest Now"
             subtext="You are almost there.Complete all your pending payments and enjoy the benefits of NGL investments."
             oncardclick={handleCardClick}
           />
         </Grid> */}
    </Grid>
  );
};

export default InvestmentCarouselSection;
