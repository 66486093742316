import { Fragment, useEffect, useState } from "react";
import { Carousel } from "@mantine/carousel";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Stack, Theme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { InvestmentDetailsActions } from "redux-container/investments/investment-details/InvestmentDetailsRedux";
import styles from "./InvestmentOverview.module.css";
import MediaAssets from "assets";
import Grid from "@mui/material/Grid";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import Button from "@mui/material/Button";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareDialog from "pages/home/share-dialog/ShareDialog";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";
import { numDifferentiation } from "utils/helperFunctions";
import InfoIconComponent from "components/info-icon-component/InfoIconComponent";

const actionDispatch = (dispatch: any) => {
  return {
    deleteWatchlist: (id: number) =>
      dispatch(InvestmentDetailsActions.deleteWatchlistRequest(id)),
    addToWatchlist: (data: any) =>
      dispatch(InvestmentDetailsActions.addToWatchlistRequest(data)),
    setInvestmentDetailsState: (key: any, value: any) =>
      dispatch(InvestmentDetailsActions.setInvestmentDetailsState(key, value)),
  };
};

const InvestmentOverview = () => {
  const { setInvestmentDetailsState, addToWatchlist, deleteWatchlist } =
    actionDispatch(useDispatch());
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  // For Investment details media carousle we have to concat projectCoverImages.newInvestmentPageMedia with mediaGalleryOrProjectContent.images and mediaGalleryOrProjectContent.360images

  // FIX this , since project cover image is not concat properly
  const { investmentDetails, medias, watchlists, isWatchlisted, message } =
    useSelector((state: any) => ({
      investmentDetails: state?.investmentDetails?.investmentDetails,
      medias:
        state?.investmentDetails?.investmentDetails?.projectContent?.mediaGalleryOrProjectContent[0]?.images?.concat(
          state?.investmentDetails?.investmentDetails?.projectContent
            ?.mediaGalleryOrProjectContent[0]?.threeSixtyImages,
          state?.investmentDetails?.investmentDetails?.projectContent
            ?.projectCoverImages?.newInvestmentPageMedia?.value?.url
        ),
      watchlists: state?.investmentDetails?.watchlists,
      isWatchlisted: state?.investmentDetails?.isWatchlisted,
      message: state?.investmentDetails?.message,
    }));

  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  const [isAddedToWatchlist, setIsWatchlisted] = useState(false);

  const addWatchlist = () => {
    const requestBody = {
      projectId: investmentDetails.projectContent.id,
    };
    addToWatchlist(requestBody);
  };

  const removeFromWatchlist = () => {
    deleteWatchlist(investmentDetails.projectContent.id);
  };

  const onClickShareIcon = () => {
    setShareDialogOpen(true);
  };

  const onCloseShareDialog = () => {
    setShareDialogOpen(false);
  };

  const handleCloseSnackbar = () => {
    setInvestmentDetailsState("message", null);
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const handleClickApplyNow = () => {
    navigate(RouteConfigurations.landSkuList, {
      state: {
        projectId: investmentDetails?.projectContent?.id,
        launchName: investmentDetails?.projectContent?.launchName,
      },
    });
  };

  useEffect(() => {
    if (watchlists) {
      for (let watchlist of watchlists) {
        if (
          investmentDetails?.projectContent?.id ===
          watchlist.watchlist.projectContentId
        ) {
          setIsWatchlisted(true);
        } else {
          setIsWatchlisted(false);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (isWatchlisted === false) {
      setIsWatchlisted(false);
    }
    if (isWatchlisted === true) {
      setIsWatchlisted(true);
    }
  }, [isWatchlisted]);

  return (
    <Fragment>
      <div className={styles["new-launch-holder"]}>
        <div className={styles["name-location-holder"]}>
          <IconButton
            onClick={handleNavigateBack}
            className={styles["left-icon"]}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <img
            src={
              investmentDetails?.projectContent?.projectCoverImages
                ?.newInvestmentPageMedia?.value?.url
            }
            alt=""
          />
          <div>
            <h2>
              {investmentDetails?.projectContent?.launchName}
              <span>
                <img
                  className={styles["upward-icon"]}
                  src={MediaAssets.ic_upwards_arrow}
                  alt=""
                />
                {`${investmentDetails?.projectContent?.generalInfoEscalationGraph?.estimatedAppreciation}%`}
              </span>
            </h2>
            <span>{`${investmentDetails?.projectContent?.address?.city}, ${investmentDetails?.projectContent?.address?.state}`}</span>
          </div>
        </div>

        <div>
          {isAddedToWatchlist ? (
            <IconButton
              onClick={removeFromWatchlist}
              className={styles["favorite-icon"]}
            >
              <FavoriteIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={addWatchlist}
              className={styles["favorite-icon"]}
            >
              <FavoriteBorderIcon />
            </IconButton>
          )}

          <IconButton onClick={onClickShareIcon} title="Share Link">
            <ShareIcon />
          </IconButton>
        </div>
      </div>

      <section className={styles["new-launch-item"]}>
        <div className={styles["carousel-holder"]}>
          {medias && medias !== null && medias.length > 0 && (
            <MantineCarousel
              slideSize="40%"
              draggable={true}
              slidesToScroll={1}
              align={"start"}
              slideGap={"xl"}
              breakpoints={[
                { maxWidth: "md", slideSize: "50%" },
                { maxWidth: "sm", slideSize: "100%" },
              ]}
            >
              {medias?.map((media: any) => {
                return (
                  <Carousel.Slide key={media?.mediaContent?.key}>
                    <img
                      src={
                        media?.thumbnail
                          ? media?.thumbnail
                          : media?.mediaContent?.value?.url
                          ? media?.mediaContent?.value?.url
                          : !!media && media
                      }
                      alt=""
                    />
                  </Carousel.Slide>
                );
              })}
            </MantineCarousel>
          )}
        </div>
        <div className={styles["details-holder"]}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <p className={styles["full-desc-text"]}>
                {investmentDetails?.projectContent?.fullDescription}
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              className={styles["view-details-btn-holder"]}
            >
              <div className={styles["amount-area-holder"]}>
                <div>
                  <p>
                    ₹
                    {numDifferentiation(
                      investmentDetails?.projectContent?.priceStartingFrom
                    )}
                  </p>
                  <span>Onwards</span>
                </div>
                <div>
                  <p>
                    {investmentDetails?.projectContent?.areaStartingFrom} Sqft
                  </p>
                  <span>Onwards</span>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        {investmentDetails?.projectContent?.reraDetails?.isReraDetailsActive ? (
          <div className={styles["rera-holder"]}>
            <Stack
              justifyContent={"center"}
              direction={isSmallScreen ? "column" : "row"}
              columnGap={"1rem"}
            >
              {investmentDetails?.projectContent?.reraDetails?.reraNumbers?.map(
                (reraNo: any) => {
                  return (
                    <p className={styles["rera-numbers"]} key={reraNo}>
                      {reraNo}
                    </p>
                  );
                }
              )}
            </Stack>
            <InfoIconComponent
              infoContent={
                investmentDetails?.projectContent?.reraDetails
                  ?.companyNameAndAddress
              }
              placement="bottom-start"
              fontSize={isSmallScreen ? "1.125rem" : "1.5rem"}
            />
          </div>
        ) : null}

        <div className={styles["apply-now-btn-holder"]}>
          {investmentDetails?.projectContent?.isSoldOut === false ? (
            <Button
              variant="contained"
              className={styles["apply-now-btn"]}
              onClick={handleClickApplyNow}
            >
              Apply Now
            </Button>
          ) : (
            <Button variant="contained" className={"btn btn-gray"}>
              Sold Out
            </Button>
          )}
        </div>
      </section>

      {isShareDialogOpen && (
        <ShareDialog onShareDialogClose={onCloseShareDialog} />
      )}

      <MuiSnackbar
        shouldOpen={message !== null ? true : false}
        message={message}
        isSuccess={true}
        closeHandler={handleCloseSnackbar}
      />
    </Fragment>
  );
};

export default InvestmentOverview;
