import {
  MuiLoadingButton,
  MuiStyledButton,
} from "components/mui-buttons/MuiButtons";
import { Fragment, useEffect } from "react";
import { Carousel } from "@mantine/carousel";
import { NewLaunchActions } from "redux-container/investments/new-launch/NewLaunchRedux";
import { useDispatch, useSelector } from "react-redux";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useNavigate } from "react-router-dom";
import { sortArray } from "utils/ArrayUtils";
import { IconButton } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import styles from "./NewLaunch.module.css";
import MediaAssets from "assets";
import Grid from "@mui/material/Grid";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import Button from "@mui/material/Button";
import NewLaunchesDrawer from "./NewLaunchesDrawer";
import { numDifferentiation } from "utils/helperFunctions";

const actionDispatch = (dispatch: any) => {
  return {
    setNewLaunchState: (key: any, value: any) =>
      dispatch(NewLaunchActions.setNewLaunchState(key, value)),
  };
};

const NewLaunch = () => {
  const navigate = useNavigate();

  const { setNewLaunchState } = actionDispatch(useDispatch());

  const { investmentData, pageData, newLaunches, newInvestmentsWithPriority } =
    useSelector((state: any) => ({
      investmentData:
        state?.newLaunch?.newLaunchData?.pageManagementsOrNewInvestments,
      pageData: state?.newLaunch?.newLaunchData?.page?.newInvestments,
      newInvestmentsWithPriority:
        state?.newLaunch?.newLaunchData?.page?.newInvestmentsWithPriority,
      newLaunches: state?.newLaunch?.newLaunchData,
    }));

  const galleries = useSelector((state: any) => state?.newLaunch?.galleries);
  const medias = galleries?.mediaGalleries?.images.concat(
    galleries?.mediaGalleries?.threeSixtyImages
  );

  if (newInvestmentsWithPriority) {
    for (let newInvest of newInvestmentsWithPriority) {
      for (let project of investmentData) {
        if (newInvest && newInvest.id && project && project.id) {
          if (newInvest?.id === project?.id) {
            project.priority = newInvest?.priority;
          }
        }
      }
    }
  }

  const investment =
    investmentData?.length > 0 ? sortArray(investmentData, "priority")[0] : [];

  useEffect(() => {
    return () => {
      setNewLaunchState("isSeeAllNewLaunchDialogOpen", false);
    };
  }, []);

  const navigateToInvestmentDetails = () => {
    navigate(RouteConfigurations.investmentDetails, {
      state: { projectId: investment?.id },
    });
  };

  const navigateToLandSkus = () => {
    navigate(RouteConfigurations.landSkuList, {
      state: { projectId: investment?.id, launchName: investment?.launchName },
    });
  };

  return (
    <Fragment>
      <div className={styles["section-heading-holder"]}>
        <div>
          <h1>{pageData?.displayName}</h1>
          <span>{pageData?.subHeading}</span>
        </div>
        <MuiStyledButton
          endIcon={<EastIcon />}
          variant="text"
          color="primary"
          onClick={() => setNewLaunchState("isSeeAllNewLaunchDialogOpen", true)}
        >
          SEE ALL
        </MuiStyledButton>
      </div>

      <section className={styles["new-launch-item"]}>
        <div className={styles["new-launch-holder"]}>
          <div className={styles["name-location-holder"]}>
            <img
              src={
                investment?.projectCoverImages?.newInvestmentPageMedia?.value
                  ?.url
              }
              alt=""
            />
            <div>
              <h2>
                {investment?.launchName}
                <img
                  className={styles["upward-icon"]}
                  src={MediaAssets.ic_upwards_arrow}
                  alt=""
                />
                <span>
                  {`${investment?.generalInfoEscalationGraph?.estimatedAppreciation}%`}
                </span>
              </h2>
              <span>{`${investment?.address?.city}, ${investment?.address?.state}`}</span>
            </div>
          </div>

          <div className={styles["amount-area-holder"]}>
            <div>
              <p>₹{numDifferentiation(investment?.priceStartingFrom)}</p>
              <span>Onwards</span>
            </div>
            <div>
              <p>{investment?.areaStartingFrom} Sqft</p>
              <span>Onwards</span>
            </div>
          </div>
        </div>

        {medias && medias.length > 0 && (
          <div className={styles["carousel-holder"]}>
            <MantineCarousel
              slideSize="40%"
              draggable={true}
              slidesToScroll={1}
              align={"start"}
              slideGap={"xl"}
              breakpoints={[
                { maxWidth: "md", slideSize: "50%" },
                { maxWidth: "sm", slideSize: "100%" },
              ]}
            >
              {medias?.map((media: any, index: number) => {
                return (
                  <Carousel.Slide key={index + 1}>
                    <img src={media?.thumbnail} alt="" />
                  </Carousel.Slide>
                );
              })}
            </MantineCarousel>
          </div>
        )}

        <div
          className={styles["details-holder"]}
          onClick={navigateToInvestmentDetails}
        >
          <Grid container spacing={2}>
            <Grid item xs={10} sm={8} md={8} lg={9.5} xl={9}>
              <p>{investment?.shortDescription}</p>
            </Grid>

            <Grid
              item
              xs={2}
              sm={4}
              md={4}
              lg={2.5}
              xl={3}
              className={styles["view-details-btn-holder"]}
            >
              <Button
                variant="outlined"
                endIcon={<EastIcon />}
                className={styles["view-details-btn"]}
                // onClick={navigateToInvestmentDetails}
              >
                View Details
              </Button>
              <IconButton
                className={styles["view-detail-icon"]}
                // onClick={navigateToInvestmentDetails}
              >
                <EastIcon fontSize="small" htmlColor="#000000" />
              </IconButton>
            </Grid>
          </Grid>
        </div>

        {investment?.fomoContent?.isDaysActive ? (
          <div className={styles["views-holder"]}>
            <p>
              <span>{investment?.fomoContent?.noOfViews} People </span>
              saw this project in {investment?.fomoContent?.days} days
            </p>
            <Button
              variant="contained"
              className="btn btn-dark"
              onClick={navigateToLandSkus}
            >
              Apply Now
            </Button>
          </div>
        ) : (
          <div className={styles["apply-btn-holder"]}>
            <Button variant="contained" onClick={navigateToLandSkus}>
              Apply Now
            </Button>
          </div>
        )}

        <NewLaunchesDrawer investments={newLaunches} />
      </section>
    </Fragment>
  );
};

export default NewLaunch;
