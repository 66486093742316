import { ChangeEvent, FocusEvent, MouseEvent, useState } from "react";

interface useFormType {
  initialValues: Record<string, unknown>;
  validate?: (values: any) => Record<string, unknown>;
}

const isEmpty = (obj: Record<string, unknown>) => Object.keys(obj).length === 0;

const useForm = ({ initialValues, validate }: useFormType) => {
  const [values, setValues] = useState(initialValues);
  const [touched, setTouched] = useState<Record<string, any>>({});
  const [fieldArray, setFieldArray] = useState<any[]>([initialValues]);

  const addFormField = (obj: Record<string, any>) => {
    setFieldArray((currentFields) => [...currentFields, obj]);
  };

  const removeFormField = (index: number) => {
    setFieldArray((currentFields) => [
      ...currentFields.filter((field, fieldIndex) => fieldIndex !== index),
    ]);
  };

  const handleDynamicFieldChange =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setFieldArray((currentFields) =>
        fieldArray.map((field, fieldIndex) =>
          fieldIndex === index ? setFieldValue(name, value) : field
        )
      );
    };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { type, name } = event.target;
    const getValue = () => {
      if (type === "checkbox") {
        return event.target.checked;
      }
      return event.target.value as string;
    };

    const value = getValue();
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const resetForm = (initialObject: any) => {
    setValues({ ...initialObject });
  };

  const setFieldValue = (fieldName: string, fieldValue: string | null) => {
    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: fieldValue !== null ? fieldValue : values[fieldName],
    }));
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
  };

  const errors = validate ? validate(values) : {};
  const valid = isEmpty(errors);

  const handleSubmit = (
    onSubmit: (values: any, event: MouseEvent<HTMLButtonElement>) => void
  ) => {
    return (event: MouseEvent<HTMLButtonElement>) => {
      if (event && typeof event.preventDefault === "function") {
        event.preventDefault();
      }
      if (valid) {
        onSubmit(values, event);
      } else {
        for (const key in values) {
          setTouched((prevTouched) => ({ ...prevTouched, [key]: true }));
        }
      }
    };
  };

  return {
    values,
    errors,
    valid,
    touched,
    fieldArray,
    resetForm,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    addFormField,
    removeFormField,
    handleDynamicFieldChange,
  };
};

export default useForm;
