import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchActions } from "redux-container/search/SearchRedux";
// import ProjectSeachDetails from "../investments/collections/collection-one/CollectionOneItem";
// import ProjectSeachDetails from "./projects/ProjectsSearch";
import ProjectSearchDetails from "./projects/ProjectDetailedCard";
import {
  Grid,
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import Documents from "./documents/DocumentsSearch";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "components/mui-accordion/AccordionComponent";
import styles from "./Search.module.css";
import UpdateItem from "pages/updates/update-item/UpdateItem";
import PromiseCard from "pages/promises/promise-card/PromiseCard";
import { getPageAction } from "redux-container/page-management-redux/PageManagementRedux";
import InsightsItem from "pages/home/insights-homescreen/InsightsItem";
import LatestUpdatesViewDialog from "../updates/update-details/UpdateDetails";
import { DocumentsActions } from "redux-container/documents/DocumentsRedux";
import Loader from "components/loader/Loader";
import { Navigate, useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";
import InsightView from "pages/insights/InsightView";
import { InsightAction } from "redux-container/insights-redux/InsightsRedux";
import MuiCard from "components/mui-card/MuiCard";
import { splitOutPara } from "utils/helperFunctions";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const actionDispatch = (dispatch: any) => {
  return {
    setPageState: (key: string, value: any) =>
      dispatch(getPageAction.setPageState(key, value)),
    downloadDoc: (path: any) =>
      dispatch(DocumentsActions.getDownloadDocDataRequest(path)),
    docSetState: (key: any, value: any) =>
      dispatch(DocumentsActions.setDocumentsState(key, value)),
    setSearchState: (key: any, value: any) =>
      dispatch(SearchActions.setSearchState(key, value)),
    setInsightsState: (key: any, data: any) =>
      dispatch(InsightAction.setInsightsState(key, data)),
  };
};

const Search = () => {
  const {
    setPageState,
    downloadDoc,
    docSetState,
    setSearchState,
    setInsightsState,
  } = actionDispatch(useDispatch());

  const documentsSearchData = useSelector(
    (state: any) => state?.search?.customerSearchData
  );
  const adminSearchData = useSelector(
    (state: any) => state?.search?.adminSearchData
  );
  const isSearchLoading = useSelector((state: any) => state?.search?.isLoading);
  const [expanded, setExpanded] = useState<any>(false);
  const [openUpdatesDetails, setOpenUpdatesDetails] = useState<boolean>(false);
  const [updatesStoriesIndex, setUpdatesStoriesIndex] = useState(0);
  const isDocLoading = useSelector((state: any) => state?.documents?.isLoading);
  const [baseUrlString, setBaseUrlString] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const documentBaseString = useSelector(
    (state: any) => state?.documents?.downloadDocData
  );
  const docError = useSelector((state: any) => state?.documents?.error);
  const searchError = useSelector((state: any) => state?.search?.error);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handlePromiseDrawer = (promise: any) => () => {
    setPageState("promiseDetails", promise);
    setPageState("isDialogOpen", true);
  };

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const handleDocumentViewClick = async (path: any) => {
    await downloadDoc(path);

    function dataURItoBlob(dataURI: any) {
      const byteString = window.atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: "application/pdf" });
      return blob;
    }

    if (!isDocLoading && documentBaseString) {
      const blob = dataURItoBlob(documentBaseString);
      const url = URL.createObjectURL(blob);
      setBaseUrlString(url);
      setShowPdf(true);
    }
  };

  useEffect(() => {
    if (
      !isDocLoading &&
      baseUrlString &&
      documentBaseString !== null &&
      showPdf
    ) {
      window.open(baseUrlString);
      setShowPdf(false);
    } else if (showPdf && !documentBaseString && !baseUrlString) {
      docSetState("error", `Couldn't Fetch Data. Try Again!`);
    }
  }, [baseUrlString, isDocLoading, showPdf]);

  const handleSnackBarclose = () => {
    setSearchState("error", null);
    docSetState("error", null);
  };

  const handleInsightsDialogOpen = (
    insightIndex: number,
    insightsData: any
  ) => {
    setInsightsState("isDialogOpen", true);
    setInsightsState("insightDetailIndex", insightIndex);
    setInsightsState("insightsData", insightsData);
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <MuiSnackbar
        shouldOpen={docError || searchError ? true : false}
        message={docError ? docError : searchError ? searchError : ""}
        isSuccess={false}
        closeHandler={handleSnackBarclose}
      />
      {isSearchLoading ? (
        <Loader />
      ) : (
        <div>
          {!adminSearchData?.projectContentData?.length && (
            <IconButton
              onClick={handleNavigateBack}
              className={styles["left-icon"]}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
          )}
          {adminSearchData?.projectContentData?.length > 0 && (
            <Box className={styles["section-spacing"]}>
              <Stack mb={2} justifyContent={"start"} spacing={1}>
                <IconButton
                  onClick={handleNavigateBack}
                  className={styles["left-icon"]}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
                <Typography className={styles["typography-title"]}>
                  Projects
                </Typography>
              </Stack>
              <Grid container spacing={isMobileScreen ? 1 : 2}>
                {adminSearchData?.projectContentData?.map(
                  (project: any, index: number) => (
                    <Fragment key={index}>
                      {!isMobileScreen && index < 4 && (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          sm={12}
                          md={11}
                          lg={6}
                          xl={6}
                        >
                          <ProjectSearchDetails
                            launchName={project?.launchName}
                            city={project?.address?.city}
                            state={project?.address?.state}
                            mediaUrl={
                              project?.projectCoverImages?.homePageMedia?.value
                                ?.url
                            }
                            appreciation={
                              project?.generalInfoEscalationGraph
                                ?.estimatedAppreciation
                            }
                            startingPrice={project?.priceStartingFrom}
                            startingArea={project?.areaStartingFrom}
                            description={project?.shortDescription}
                            id={project?.id}
                            isSoldout={project?.isSoldOut}
                          />
                        </Grid>
                      )}
                      {isMobileScreen && index < 3 && (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          sm={12}
                          md={11}
                          lg={6}
                          xl={6}
                        >
                          <ProjectSearchDetails
                            launchName={project?.launchName}
                            city={project?.address?.city}
                            state={project?.address?.state}
                            mediaUrl={
                              project?.projectCoverImages?.homePageMedia?.value
                                ?.url
                            }
                            appreciation={
                              project?.generalInfoEscalationGraph
                                ?.estimatedAppreciation
                            }
                            startingPrice={project?.priceStartingFrom}
                            startingArea={project?.areaStartingFrom}
                            description={project?.shortDescription}
                            id={project?.id}
                            isSoldout={project?.isSoldOut}
                          />
                        </Grid>
                      )}
                    </Fragment>
                  )
                )}
              </Grid>
            </Box>
          )}

          {documentsSearchData?.length > 0 && (
            <Box className={styles["section-spacing"]}>
              <Stack>
                <Typography className={styles["typography-title"]}>
                  Documents
                </Typography>
              </Stack>

              {isDocLoading ? (
                <Loader />
              ) : (
                documentsSearchData?.map((item: any, index: any) => (
                  <Fragment key={index}>
                    {index < 3 && (
                      <Documents
                        key={index}
                        title={item?.name}
                        handleViewClick={() =>
                          handleDocumentViewClick(item?.path)
                        }
                        isViewDisabled={!item?.path}
                      />
                    )}
                  </Fragment>
                ))
              )}
            </Box>
          )}

          {adminSearchData?.marketingUpdateData?.length > 0 && (
            <Box className={styles["section-spacing"]}>
              <Stack mb={3}>
                <Typography className={styles["typography-title"]}>
                  Latest Updates
                </Typography>
              </Stack>
              <Grid container spacing={3}>
                {adminSearchData?.marketingUpdateData?.map(
                  (update: any, index: number) => (
                    <>
                      {index < 4 && (
                        <Grid item xs={12} md={3} lg={3} xl={3}>
                          <Box
                            onClick={() => {
                              setUpdatesStoriesIndex(index);
                              setOpenUpdatesDetails(true);
                            }}
                          >
                            <MuiCard
                              sx={
                                isMobileScreen
                                  ? { height: "320px", cursor: "pointer" }
                                  : {
                                      height: "360px",
                                      cursor: "pointer",
                                      zIndex: 10,
                                    }
                              }
                              key={index + 1}
                              src={update?.detailedInfo[0]?.media?.value?.url}
                            >
                              <Box
                                className={
                                  styles["mui-card-description-details-section"]
                                }
                              >
                                <Typography
                                  className={`${styles["typography-card-title"]} ${styles["line-ellipsis"]}`}
                                >
                                  {update?.displayTitle}
                                </Typography>
                                <Typography
                                  className={`${styles["typography-card-sub-title"]} ${styles["line-ellipsis"]}`}
                                >
                                  {update?.subTitle}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color={"grey.A400"}
                                  fontFamily={"Jost-Light"}
                                  textAlign={"center"}
                                  mt={
                                    update?.detailedInfo[0]?.media?.value?.url
                                      ? 1
                                      : isMobileScreen
                                      ? 1
                                      : 2.5
                                  }
                                  className={
                                    update?.detailedInfo[0]?.media?.value?.url
                                      ? styles["description-text"]
                                      : styles["description-text-ellipsis-4"]
                                  }
                                  dangerouslySetInnerHTML={
                                    update?.detailedInfo[0]?.media?.value?.url
                                      ? splitOutPara(
                                          update?.detailedInfo[0]?.description
                                        )
                                      : splitOutPara(
                                          update?.detailedInfo[0]?.description,
                                          180
                                        )
                                  }
                                ></Typography>
                              </Box>
                            </MuiCard>
                          </Box>
                        </Grid>
                      )}
                    </>
                  )
                )}
              </Grid>
            </Box>
          )}

          <LatestUpdatesViewDialog
            storyIndex={updatesStoriesIndex}
            storiesData={adminSearchData?.marketingUpdateData}
            open={openUpdatesDetails}
            handleclose={() => setOpenUpdatesDetails(false)}
          />

          {adminSearchData?.insightsData?.length > 0 && (
            <Box className={styles["section-spacing"]}>
              <Stack mb={3}>
                <Typography className={styles["typography-title"]}>
                  Insights
                </Typography>
              </Stack>
              <Grid container spacing={3}>
                {adminSearchData?.insightsData?.map(
                  (insight: any, index: number) => (
                    <Fragment key={index}>
                      {isMediumScreen && index < 3 && (
                        <Grid
                          onClick={() =>
                            handleInsightsDialogOpen(
                              index,
                              adminSearchData?.insightsData
                            )
                          }
                          key={index}
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                        >
                          <InsightsItem insight={insight} />
                        </Grid>
                      )}

                      {!isMediumScreen && index < 4 && (
                        <Grid
                          onClick={() =>
                            handleInsightsDialogOpen(
                              index,
                              adminSearchData?.insightsData
                            )
                          }
                          key={index}
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                        >
                          <InsightsItem insight={insight} />
                        </Grid>
                      )}
                    </Fragment>
                  )
                )}
              </Grid>
            </Box>
          )}

          <InsightView />

          {/* {adminSearchData?.promisesData?.length > 0 && (
            <Box className={styles["section-spacing"]}>
              <Stack mb={3}>
                <Typography className={styles["typography-title"]}>Promises</Typography>
              </Stack>

              <Grid container spacing={3}>
                {adminSearchData?.promisesData?.map(
                  (promise: any, index: number) => (
                    <Fragment key={index}>
                      {index < 4 && (
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      <PromiseCard
                        key={promise?.id}
                        name={promise?.name}
                        description={promise?.shortDescription}
                        iconsrc={promise.displayMedia?.value?.url}
                        handlecardclick={handlePromiseDrawer(promise)}
                      />
                    </Grid>
                    )}
                    </Fragment>
                  )
                )}
              </Grid>
            </Box>
          )} */}

          {adminSearchData?.faqData?.length > 0 && (
            <Box className={styles["section-spacing"]}>
              <Stack>
                <Typography className={styles["typography-title"]}>
                  FAQs
                </Typography>
              </Stack>

              {adminSearchData?.faqData?.map((item: any, index: any) => (
                <Fragment key={index}>
                  {index < 4 && (
                    <Accordion
                      key={index}
                      expanded={expanded === `panel${item?.id}`}
                      onChange={handleChange(`panel${item.id}`)}
                    >
                      <AccordionSummary
                        aria-controls={`panel${item?.id}-content`}
                        id={`panel${item?.id}-header`}
                        color={"red"}
                      >
                        <Typography
                          className={styles["accordion-summary-text"]}
                        >
                          {item?.faqQuestion?.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        className={styles["accordion-details-text"]}
                      >
                        {item?.faqAnswer?.answer}
                      </AccordionDetails>
                    </Accordion>
                  )}
                </Fragment>
              ))}
            </Box>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default Search;
