import {
  Drawer,
  Stack,
  IconButton,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import {
  MuiLoadingButton,
  MuiStyledButton,
} from "components/mui-buttons/MuiButtons";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import MediaAssets from "assets";
import MuiFormDialog from "components/mui-dialogs/MuiFormDialog";
import { useState } from "react";
import TermsAndCondition from "pages/authentication/TermsAndCondition";
import { useDispatch, useSelector } from "react-redux";
import { getPageAction } from "redux-container/page-management-redux/PageManagementRedux";
import { useClickOutside } from "@mantine/hooks";

const Briefcase = () => (
  <Avatar src={MediaAssets.ic_briefcase} sx={{ width: 19, height: 19 }} />
);

const actionDispatch = (dispatch: any) => {
  return {
    setPageState: (key: string, value: any) =>
      dispatch(getPageAction.setPageState(key, value)),
  };
};

const MuiPageSectionDrawer = () => {
  const { setPageState } = actionDispatch(useDispatch());
  const { promiseDetails, isDialogOpen } = useSelector((state: any) => ({
    promiseDetails: state?.pageManagement?.promiseDetails,
    isDialogOpen: state?.pageManagement?.isDialogOpen,
  }));
  const navigate = useNavigate();
  const { breakpoints } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("md"));
  const [popupState, setPopupState] = useState({
    TnCDialogOpen: false,
  });

  const handleDialogOpen = () => {
    setPopupState((prevState) => {
      return { ...prevState, TnCDialogOpen: true };
    });
  };

  const handleDialogClose = () => {
    setPopupState((prevState) => {
      return {
        ...prevState,
        TnCDialogOpen: false,
      };
    });
  };

  const handleDrawerClose = () => {
    setPageState("isDialogOpen", false);
  };

  const handlePortfolioClick = () => {
    setPageState("isDialogOpen", false);
    navigate(RouteConfigurations.portfolio);
  };

  // const ref = useClickOutside(handleDrawerClose, ["mouseup"]);
  const ref = useClickOutside(
    () => setPageState("isDialogOpen", false),
    ["mouseup", "mousedown"]
  );
  return (
    <Drawer
      anchor={"right"}
      open={isDialogOpen}
      PaperProps={{
        ref: ref,
        sx: {
          width: fullScreen ? "100%" : breakpoints.values.sm,
        },
      }}
      onClose={handleDrawerClose}
    >
      <Stack justifyContent={"flex-end"}>
        <IconButton onClick={handleDrawerClose}>
          <CloseRoundedIcon />
        </IconButton>
      </Stack>
      <Stack justifyContent={"flex-start"} columnGap={"1rem"}>
        <Avatar
          src={promiseDetails?.displayMedia?.value?.url}
          sx={{ width: 40, height: 40 }}
        />
        <Typography variant="h5">{promiseDetails?.name}</Typography>
      </Stack>
      <Typography variant="body1" my={"2rem"}>
        {promiseDetails?.shortDescription}
      </Typography>
      <List>
        {promiseDetails?.description.map((value: string, descIndex: number) => (
          <ListItem disableGutters key={descIndex + 1}>
            <ListItemAvatar>
              <Avatar
                component={Paper}
                variant="rounded"
                sx={{ bgcolor: "primary.light" }}
              >
                <CheckCircleRoundedIcon color="primary" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={value} />
          </ListItem>
        ))}
      </List>
      {promiseDetails?.isHowToApplyActive && (
        <Paper square sx={{ padding: "1rem", backgroundColor: "grey.100" }}>
          <Typography lineHeight={3} variant="body1">
            {promiseDetails?.howToApply.title}
          </Typography>
          <Typography variant="body2">
            {promiseDetails?.howToApply.description}
          </Typography>
        </Paper>
      )}
      <Stack direction={"column"} justifyContent={"center"} my={"2rem"}>
        <MuiLoadingButton
          startIcon={<Briefcase />}
          onClick={handlePortfolioClick}
        >
          Portfolio
        </MuiLoadingButton>
        {promiseDetails?.isTermsAndConditionsActive && (
          <MuiStyledButton
            variant="text"
            color="inherit"
            onClick={handleDialogOpen}
            sx={{ textDecoration: "underline" }}
          >
            {promiseDetails?.termsAndConditions.displayName}
          </MuiStyledButton>
        )}
      </Stack>
      <MuiFormDialog
        PaperProps={{ elevation: 0 }}
        fullScreen={fullScreen}
        maxWidth={"lg"}
        formheading={promiseDetails?.termsAndConditions.displayName}
        open={popupState.TnCDialogOpen}
        handleclose={handleDialogClose}
        children={
          <TermsAndCondition
            pageData={promiseDetails}
            shouldDisplayTitle={false}
          />
        }
      />
    </Drawer>
  );
};

export default MuiPageSectionDrawer;
