import styles from "./LocationInfrastructure.module.css";
import Radio from "@mui/material/Radio";

interface ILocationInfrastructureItemProps {
  infra: any;
  onInfraItemClick?: (infra: any) => void;
  selectedInfraLatitude?: any;
  selectedInfraName?: any;
  kilometerString?: any;
  locationDuration?: any;
}

const LocationInfrastructureItem = ({
  infra,
  onInfraItemClick,
  selectedInfraLatitude,
  selectedInfraName,
  kilometerString,
  locationDuration,
}: ILocationInfrastructureItemProps) => {
  const displayTime = (hours: any, minutes: any) => {
    if (hours === "00") {
      return `${minutes} Mins`;
    } else if (minutes === "00") {
      return `${hours} Hr`;
    } else {
      return `${hours} Hr ${minutes} Min`;
    }
  };

  const onItemClick = () => {
    onInfraItemClick && onInfraItemClick(infra);
  };

  const UnselectedInfraItem = () => (
    <div className={styles["location-infra-card"]}>
      <img src={infra?.icon?.value?.url} alt="" />
      <div>
        <h3>{infra?.name}</h3>
        <div>
          <p>{locationDuration?.text}</p>
          {kilometerString && <p>{kilometerString?.value}</p>}
        </div>
      </div>
    </div>
  );

  const SelectedInfraItem = () => (
    <div
      className={`${styles["location-infra-card"]} ${styles["selected-location-infra-card"]}
      }`}
    >
      <img src={infra?.icon?.value?.url} alt="" />
      <div>
        <h3>{infra?.name}</h3>
        <div>
          {/* <p>{displayTime(infra?.hours, infra?.minutes)}</p> */}
          <p>{locationDuration?.text}</p>
          {kilometerString && <p>{kilometerString?.value}</p>}
        </div>
      </div>
    </div>
  );

  return (
    <Radio
      sx={{ width: "100%" }}
      disableTouchRipple={true}
      disableFocusRipple={true}
      disableRipple={true}
      checkedIcon={<SelectedInfraItem />}
      icon={<UnselectedInfraItem />}
      checked={
        selectedInfraLatitude + selectedInfraName ===
        infra.latitude + infra?.name
      }
      value={infra.latitude}
      onChange={onItemClick}
      className={styles["infra-radio"]}
    />
  );
};

export default LocationInfrastructureItem;
