import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import { DataPointTypes } from "./DataPointTypes";
import styles from "./LineChart.module.css";
import { Paper, useMediaQuery, useTheme } from "@mui/material";
import annotationPlugin from "chartjs-plugin-annotation";
export interface ILineChart {
  labels?: string[];
  graphData?: number[];
  xAxisLabel?: string;
  yAxisLabel?: string;
  isCustomizedLabels?: boolean;
  isTextDisabled?: boolean;
  customizableGraphData?: any;
  graphheight?: number | string;
  showWithContainer?: boolean;
  enableAnnotation?: boolean;
  annotationValue?: number;
}

const LineChart = (props: ILineChart) => {
  Chart.register(...registerables);
  Chart.register(annotationPlugin);

  const theme = useTheme();
  const ismobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    labels,
    graphData,
    xAxisLabel,
    yAxisLabel,
    isCustomizedLabels = true,
    isTextDisabled = true,
    customizableGraphData,
    graphheight = ismobileScreen ? 280 : 525,
    showWithContainer = false,
    enableAnnotation = false,
    annotationValue = 1,
  } = props;

  const customizedGraphValues = customizableGraphData?.dataPoints?.points?.map(
    (item: any) => Number(item?.value)
  );

  const customizedLabels = () => {
    let customLabels: any = [];
    switch (customizableGraphData?.dataPoints?.dataPointType) {
      case DataPointTypes.Monthly:
        customizableGraphData?.dataPoints?.points?.map((item: any) => {
          customLabels.push(
            item?.month.slice(0, 3) + "-" + item?.year?.slice(-2)
          );
        });
        return customLabels;

      case DataPointTypes.Yearly:
        let yearly = customizableGraphData?.dataPoints?.points?.map(
          (item: any) => item?.year
        );
        customLabels = yearly;
        return customLabels;

      case DataPointTypes.HalfYearly:
        customizableGraphData?.dataPoints?.points?.map((item: any) => {
          customLabels.push(
            item?.halfYear?.slice(0, 2) + "-" + item?.year?.slice(-2)
          );
        });
        return customLabels;

      case DataPointTypes.Quaterly:
        customizableGraphData?.dataPoints?.points?.map((item: any) => {
          customLabels.push(
            item?.quater?.slice(0, 2) + "-" + item?.year?.slice(-2)
          );
        });
        return customLabels;

      default:
        return [];
    }
  };

  const data = {
    labels: isCustomizedLabels ? customizedLabels() : labels,
    datasets: [
      {
        label: "Investment Graph",
        lineTension: 0.35,
        data: isCustomizedLabels ? customizedGraphValues : graphData,
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "#3db457",
        pointRadius: 0,
        pointHoverRadius: 0,
        gridLines: false,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          display: isTextDisabled ? true : false,
          font: {
            family: "Jost-Regular",
          },
        },
        grid: {
          display: false,
        },
        title: {
          display: isTextDisabled,
          text: isCustomizedLabels
            ? customizableGraphData?.yAxisDisplayName
            : xAxisLabel,
          color: "#9192a0",
          padding: {
            top: ismobileScreen ? 10 : 20,
          },
          font: {
            size: window.screen.width < 800 ? 14 : 18,
            weight: "bold",
            family: "Jost-Medium",
          },
        },
      },
      y: {
        ticks: {
          display: isTextDisabled ? true : false,
          font: {
            family: "Jost-Regular",
          },
        },
        grid: {
          display: false,
        },
        title: {
          display: isTextDisabled,
          text: isCustomizedLabels
            ? customizableGraphData?.xAxisDisplayName
            : yAxisLabel,
          color: "#9192a0",
          padding: {
            bottom: !ismobileScreen && 20,
            top: ismobileScreen ? 0 : 30,
          },
          font: {
            size: window.screen.width < 800 ? 14 : 18,
            weight: "bold",
            family: "Jost-Medium",
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      elements: {
        point: {
          radius: 3,
        },
        arrow: {
          end: true,
        },
      },
      annotation: {
        annotations: enableAnnotation
          ? [
              {
                id: "a-line-1",
                type: "line" as const,
                mode: "vertical",
                scaleID: "x",
                borderDash: [10, 6],
                value: annotationValue,
                borderColor: "#676ac0",
                borderWidth: 1.5,
                label: {
                  enabled: true,
                  content: "Your Investment",
                  color: "#676ac0",
                  position: "start",
                },
              },
            ]
          : null,
      },
    },
  };

  return (
    <div
      className={
        showWithContainer
          ? `${styles["main-graph-content"]} ${styles["box-shadow"]}`
          : styles["main-graph-content"]
      }
    >
      <Line height={graphheight} width={80} data={data} options={options} />
    </div>
  );
};

export default LineChart;
