import {
  Avatar,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  Theme,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { FC } from "react";
import MediaAssets from "assets";

interface IPromiseCardProps {
  name?: string;
  iconsrc?: string;
  description?: string;
  cardheight?: number;
  handlecardclick?: () => void;
  isarrowrequire?: boolean;
}

const PromiseCardStyleWrapper = styled("div")<IPromiseCardProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 10px;
  background: url(${MediaAssets.ic_promise_card_bg}),
    ${({ theme }) => theme.palette.gradient?.lightBlueShades} top center / cover;
  min-height: ${({ cardheight }) => `calc(${cardheight}px + 20px)`};
  padding: 1.5rem;
  cursor: pointer;
  & .promise-icon {
    margin: 1rem 0 1.5rem;
    height: 50px;
    width: 100px;
  }
  & .promise-name-section {
    width: 100%;
    column-gap: 0.5rem;
    & .promise-name {
      color: ${({ theme }) => theme.palette.primary.dark};
      font-family: "Jost-Bold";
    }
  }
  & .promise-description {
    max-width: 80%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 0 1.5rem 1.5rem;
    min-height: ${({ cardheight }) => `calc(${cardheight}px - 60px)`};
    .promise-icon {
      height: 40px;
      & img {
        object-position: left;
      }
    }
  }
`;

const PromiseCard: FC<IPromiseCardProps> = (props) => {
  const {
    name = "Card Name",
    iconsrc = "Icon Src",
    description = "Some description",
    isarrowrequire = false,
    handlecardclick,
    cardheight = 320,
  } = props;
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <PromiseCardStyleWrapper onClick={handlecardclick} cardheight={cardheight}>
      <Avatar variant="rounded" className="promise-icon" src={iconsrc} />
      <Stack
        className="promise-name-section"
        m={isSmallScreen ? "1rem 0 0.5rem" : "0 0 1rem"}
      >
        <Typography
          variant={isSmallScreen ? "body1" : "h6"}
          className={"promise-name"}
        >
          {name}
        </Typography>
        {isarrowrequire && <EastIcon fontSize="small" />}
      </Stack>
      <Typography
        variant={isSmallScreen ? "body2" : "body1"}
        className={"promise-description"}
      >
        {description}
      </Typography>
    </PromiseCardStyleWrapper>
  );
};

export default PromiseCard;
