import { Theme, Typography, useMediaQuery } from "@mui/material";
import styles from "./PortfolioInformation.module.css";

interface IPortfolioInformationItemProps {
  title: string;
  value: string;
}

const PortfolioInformationItem = ({
  title,
  value,
}: IPortfolioInformationItemProps) => {
  const isMediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("md", "xl")
  );

  return (
    <div className={styles["portfolio-item"]}>
      <Typography
        variant={isMediumScreen || isLargeScreen ? "body2" : "h6"}
        fontFamily={"Jost-Bold"}
        color={"common.white"}
      >
        {value}
      </Typography>
      <Typography variant="body2" color={"common.white"}>
        {title}
      </Typography>
    </div>
  );
};

export default PortfolioInformationItem;
