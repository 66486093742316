import { Box, styled, Typography } from "@mui/material";

const TermsAndCondditionStyleWrapper = styled(Box)`
  & .para-content {
    color: ${({ theme }) => theme.palette.grey.A400};
    padding-bottom: 0.5rem;
    font-family: "Jost-Light";
  }
  .sub-header {
    padding: 1rem 0;
    font-family: "Jost-Regular";
  }
`;

const TermsAndCondition = ({ pageData, shouldDisplayTitle = true }: any) => {
  const { termsAndConditions } = pageData;
  return (
    <TermsAndCondditionStyleWrapper>
      {shouldDisplayTitle && (
        <Typography className="sub-header" variant={"body1"} py={"1rem"}>
          {termsAndConditions.displayName}
        </Typography>
      )}
      <Typography
        className="para-content"
        variant={"body2"}
        dangerouslySetInnerHTML={{ __html: termsAndConditions.description }}
      ></Typography>
    </TermsAndCondditionStyleWrapper>
  );
};

export default TermsAndCondition;
