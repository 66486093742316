export enum chatEnum {
    FINAL_MESSAGE = 100,
    ABOUT_HOABL = 102,
    PROMISE = 103,
    INVESTMENTS = 104,
    OTHERS = 105,
    PROJECT = 106,
    SALES = 107,
    START_TYPING = 108,
    REDIRECT_ABOUT_HOABL = 109,
    REDIRECT_INVESTMENTS = 110,
    REDIRECT_PROJECT = 111,
    REDIRECT_OTHERS = 112,
    REDIRECT_PROMISE = 113,
    REDIRECT_FAQ = 114,
    PORTFOLIO = 115,
    BOOKING_STATUS = 116,
    DEVELOPMENT_STATUS = 117,
    PAYMENT = 118,
    REDIRECT_PORTFOLIO = 119,
    REDIRECT_BOOKING_JOURNEY = 120,
    REDIRECT_PROJECT_TIMELINE = 121,
}

export enum ChatOrigins {
    UserOrigin = 1,
    CMSOrigin = 2,
    CRMOrigin = 3
}