import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import styles from "./OpportunityDoc.module.css";

const OpportunityDoc = () => {
  const navigate = useNavigate();

  const { investmentDetails } = useSelector((state: any) => ({
    investmentDetails: state?.investmentDetails?.investmentDetails,
  }));

  return (
    <div className={styles["opportunity-doc-holder"]}>
      <img
        onClick={() => navigate(RouteConfigurations.investmentOpportunityDoc)}
        src={
          investmentDetails?.projectContent?.opportunityDoc?.whyToInvestMedia
            ?.value?.url
        }
        alt=""
        className={styles["image-banner"]}
      />
    </div>
  );
};

export default OpportunityDoc;
