import {
  Avatar,
  Box,
  CardMedia,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styles from "./ImageCard.module.css";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Fragment, useState } from "react";
import { handleUrlLink } from "utils/helperFunctions";
import ImageViewDialog from "../../profile/my-account/image-view-dialog/ImageViewDialog";
import MediaViewCarousel from "../media-view-carousel/MediaViewCarousel";

const ImageCardComponent = (props: any) => {
  const {
    mediaUrl,
    title,
    contentType = "img",
    mediaContentData,
    initialIndex,
    isVideoCard = false,
  } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [showDetailedDialog, setShowDetailedDialog] = useState(false);

  return (
    <Fragment>
      <MediaViewCarousel
        open={showDetailedDialog}
        title={title}
        handleClose={() => setShowDetailedDialog(false)}
        imageUrl={
          contentType === "video"
            ? mediaUrl?.url
            : handleUrlLink(mediaUrl, true)
        }
        contentType={contentType}
        mediaContent={mediaContentData}
        initialIndex={initialIndex}
      />
      <Box
        className={styles["container"]}
        onClick={() => setShowDetailedDialog(true)}
      >
        <CardMedia
          component="img"
          className={
            contentType === "video"
              ? styles["card-media-mui-thumbnail"]
              : styles["card-media-mui"]
          }
          image={handleUrlLink(mediaUrl, true)}
          alt={title ? title : mediaUrl}
        />

        {mediaUrl && (
          <>
            {contentType === "video" && (
              <Box
                className={
                  isVideoCard
                    ? styles["card-video-icon-content-videoPlay"]
                    : styles["card-video-icon-content"]
                }
              >
                <PlayCircleOutlineIcon
                  fontSize={isSmallScreen ? "small" : "large"}
                  className={
                    isVideoCard
                      ? styles["video-play-icon-lg"]
                      : styles["video-play-icon"]
                  }
                  color={"inherit"}
                />
              </Box>
            )}
            {!isVideoCard && (
              <Box className={styles["card-title"]}>
                <Typography
                  className={`${styles["typography-white"]} ${styles["line-ellipsis"]}`}
                >
                  {title}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </Fragment>
  );
};

export default ImageCardComponent;
