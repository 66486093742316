import { Box } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPageAction } from "redux-container/page-management-redux/PageManagementRedux";
import { getUserAction } from "redux-container/user-management/UserManagementRedux";
import DiscoverAllInvestment from "./discover-all-investment/DiscoverAllInvestment";
import DownloadApp from "./download-app/DownloadApp";
import HomePromises from "./home-promises/HomePromises";
import InsightsHomeScreen from "./insights-homescreen/InsightsHomeScreen";
import LatestUpdates from "./latest-updates/LatestUpdates";
import PortfolioInformation from "./portfolio-information/PortfolioInformation";
import PromotionCard from "./promotion-card/PromotionCard";
import ReferralSection from "./referral-section/ReferralSection";
import Testimonials from "./testimonials/Testimonials";
import ActionItemSection from "./action-item-section/ActionItemSection";
import styles from "./Home.module.css";
import Loader from "components/loader/Loader";
import { getPortfolioAction } from "redux-container/portfolio-redux/PortfolioRedux";
import MyServicesDialog from "pages/profile/my-services-dialog/MyServicesDialog";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useHandleCardNavigation } from "utils/helperFunctions";
import { InsightAction } from "redux-container/insights-redux/InsightsRedux";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";
import { ContactTypeEnum } from "enumerations/ContactTypeEnum";
import { ProfileAction } from "redux-container/profile/ProfileRedux";

const actionDispatch = (dispatch: any) => {
  return {
    getPagesRequest: (pageType: string) =>
      dispatch(getPageAction.getPagesRequest(pageType)),
    getPortfolioPreviewRequest: () =>
      dispatch(getPortfolioAction.getPortfolioPreviewRequest()),
    getActionItemsRequest: () =>
      dispatch(getUserAction.getActionItemsRequest()),
    getInsightsRequest: () => dispatch(InsightAction.getInsightsRequest()),
    setPageState: (key: string, value: any) =>
      dispatch(getPageAction.setPageState(key, value)),
    getProfileDetails: () => dispatch(ProfileAction.getProfileDetailsRequest()),
  };
};

const Home = () => {
  const {
    getPortfolioPreviewRequest,
    getActionItemsRequest,
    getInsightsRequest,
    setPageState,
    getProfileDetails,
  } = actionDispatch(useDispatch());
  const { pageData, actionItemData, portfolioData, isLoading, error } =
    useSelector((state: any) => ({
      pageData: state?.pageManagement?.pageData,
      actionItemData: state?.userManagement?.userData,
      portfolioData: state?.portfolio?.portfolioData,
      isLoading: state?.pageManagement?.isLoading,
      error: state?.pageManagement?.error,
    }));
  const { handleNavigation } = useHandleCardNavigation();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let cancelEffect = true;
    if (cancelEffect) {
      getPortfolioPreviewRequest();
      getActionItemsRequest();
      getInsightsRequest();
      getProfileDetails();
    }
    return () => {
      cancelEffect = false;
    };
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <Box
        className={[
          styles.sectionwrapper,
          !pageData?.isFacilityVisible ? styles.rmvbottomradius : [],
        ].join(" ")}
      >
        {portfolioData?.investmentCount > 0 && <PortfolioInformation />}
        {pageData?.page?.isNewInvestmentsActive &&
          !!pageData?.pageManagementsOrNewInvestments?.length && (
            <DiscoverAllInvestment />
          )}
        {actionItemData !== null && actionItemData.length > 0 && (
          <ActionItemSection />
        )}
        <DownloadApp />

        {pageData?.page?.isLatestUpdatesActive && <LatestUpdates />}
        {pageData?.page?.isPromisesActive && <HomePromises />}
      </Box>
      {pageData?.isFacilityVisible && (
        <PromotionCard
          cardurl={pageData?.page?.facilityManagement?.value?.url}
          oncardclick={handleClickOpen}
        />
      )}
      {(pageData?.page?.isPromotionAndOfferActive &&
        pageData?.contactType === ContactTypeEnum.PreLead) ||
      pageData?.contactType === ContactTypeEnum.Customer ? (
        <PromotionCard
          cardurl={pageData?.page?.promotionAndOffersMedia?.value?.url}
          oncardclick={() =>
            handleNavigation(
              RouteConfigurations.investmentDetails,
              pageData?.page?.promotionAndOffersProjectContentId
            )
          }
        />
      ) : null}
      <Box
        className={[
          styles.sectionwrapper,
          pageData?.contactType === ContactTypeEnum.PreLead ||
          pageData?.contactType === ContactTypeEnum.Customer
            ? []
            : styles.rmvtopradius,
        ].join(" ")}
      >
        {pageData?.page?.isInsightsActive && <InsightsHomeScreen />}
        {pageData?.page?.isTestimonialsActive && <Testimonials />}
        <ReferralSection />
      </Box>
      <MyServicesDialog open={open} handleclose={handleClose} />
      <MuiSnackbar
        shouldOpen={error !== null ? true : false}
        message={error}
        isSuccess={false}
        closeHandler={() => setPageState("error", null)}
      />
    </Fragment>
  );
};

export default Home;
