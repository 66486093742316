import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getSecurityTipsSuccess: ["response"],
  getSecurityTipsFailure: ["error"],
  getSecurityTipsRequest: ["pageType"],

  setSecurityTipsState: ["key", "value"],
});

export const SecurityTipsTypes = Types;

export const SecurityTipsAction = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  securityTips: [],
  isDialogOpen: true,
};

export const getSecurityTipsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getSecurityTipsSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      securityTips: response,
      isLoading: false,
    };
  }
};

export const getSecurityTipsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setSecurityTipsState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SECURITY_TIPS_REQUEST]: getSecurityTipsRequest,
  [Types.GET_SECURITY_TIPS_SUCCESS]: getSecurityTipsSuccess,
  [Types.GET_SECURITY_TIPS_FAILURE]: getSecurityTipsFailure,

  [Types.SET_SECURITY_TIPS_STATE]: setSecurityTipsState,
});
