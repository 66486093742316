import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  CircularProgress,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { EditProfileActions } from "../../../redux-container/profile/EditProfileRedux";
import { ProfileAction } from "../../../redux-container/profile/ProfileRedux";
import { SelectChangeEvent } from "@mui/material/Select";
import { GenderTypeEnumUtils } from "enumerations/GenderTypeEnum";
import { isEmailValid } from "utils/ValidationUtils";
import MediaAssets from "../../../assets";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/material/IconButton";
import MuiTextField from "components/mui-textfield/MuiTextfield";
import styles from "./EditProfile.module.css";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";
import Loader from "components/loader/Loader";
import { useClickOutside } from "@mantine/hooks";
import { Overlay } from "@mantine/core";

const actionDispatch = (dispatch: any) => {
  return {
    getProfileDetails: () => dispatch(ProfileAction.getProfileDetailsRequest()),
    getCountries: () => dispatch(EditProfileActions.getCountriesRequest()),
    getStates: (countryCode: any) =>
      dispatch(EditProfileActions.getStatesRequest(countryCode)),
    editProfile: (data: any) =>
      dispatch(EditProfileActions.editProfileRequest(data)),
    getCities: (countryCode: any, stateIsoCode: any) =>
      dispatch(EditProfileActions.getCitiesRequest(countryCode, stateIsoCode)),
    updateProfilePicture: (data: any) =>
      dispatch(EditProfileActions.updateProfilePictureRequest(data)),
    deleteProfilePicture: (data: any) =>
      dispatch(EditProfileActions.deleteProfilePictureRequest(data)),
    setEditProfileState: (key: any, data: any) =>
      dispatch(EditProfileActions.setEditProfileState(key, data)),
  };
};

const EditProfile = () => {
  const genders = GenderTypeEnumUtils.getGenderTypeEnums();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const {
    getProfileDetails,
    getCountries,
    getStates,
    getCities,
    editProfile,
    updateProfilePicture,
    deleteProfilePicture,
    setEditProfileState,
  } = actionDispatch(useDispatch());

  const [profilePicture, setProfilePicture] = useState("");
  const [profilePictureCopy, setProfilePictureCopy] = useState<any>(null);
  const [profileDisplayName, setProfileDisplayName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState(null);
  const [selectedGender, setSelectedGender] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [completeAddress, setCompleteAddress] = useState("");
  const [locality, setLocality] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [pincode, setPincode] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [shouldFetchAddress, setShouldFetchAddress] = useState(false);

  const {
    isEditProfileDialogOpen,
    countries,
    cities,
    states,
    profileDetails,
    isProfileUpdateSuccess,
    isProfilePicLoading,
    message,
    error,
    isProfileLoading,
    isLoading,
  } = useSelector((state: any) => ({
    isEditProfileDialogOpen: state?.editProfile?.isEditProfileDialogOpen,
    countries: state?.editProfile?.countries,
    cities: state?.editProfile?.cities,
    states: state?.editProfile?.states,
    isProfileUpdateSuccess: state?.editProfile?.isProfileUpdateSuccess,
    isProfilePicLoading: state?.editProfile?.isProfilePicLoading,
    message: state?.editProfile?.message,
    error: state?.editProfile?.error,
    isLoading: state?.editProfile?.isLoading,
    profileDetails: state?.profile?.profileDetails,
    isProfileLoading: state?.profile?.isLoading,
  }));

  useEffect(() => {
    setProfileDetails();
  }, []);

  useEffect(() => {
    resetErrors();
  }, [isEditProfileDialogOpen]);

  const resetErrors = () => {
    setEmailError("");
  };

  const setProfileDetails = async () => {
    let countryName;
    countryName = await countries.find((country: any) => {
      if (
        country?.name === profileDetails?.country ? profileDetails?.country : ""
      ) {
        return country;
      }
    });

    setProfilePicture(
      profileDetails?.profilePictureUrl ? profileDetails?.profilePictureUrl : ""
    );
    setFirstName(profileDetails?.firstName ? profileDetails?.firstName : "");
    setLastName(profileDetails?.lastName ? profileDetails?.lastName : "");
    setPhoneNumber(
      profileDetails?.phoneNumber ? profileDetails?.phoneNumber : ""
    );
    setEmail(profileDetails?.email ? profileDetails?.email : "");
    setDob(profileDetails?.dateOfBirth ? profileDetails?.dateOfBirth : null);
    setSelectedGender(profileDetails?.gender ? profileDetails?.gender : "");
    setHouseNumber(
      profileDetails?.houseNumber ? profileDetails?.houseNumber : ""
    );
    setCompleteAddress(
      profileDetails?.streetAddress ? profileDetails?.streetAddress : ""
    );
    setLocality(profileDetails?.locality ? profileDetails?.locality : "");
    setSelectedCity(profileDetails?.city ? profileDetails?.city : "");
    setSelectedState(profileDetails?.state ? profileDetails?.state : "");
    setSelectedCountry(profileDetails?.country ? profileDetails?.country : "");
    setSelectedCountryCode(profileDetails?.country ? countryName?.isoCode : "");
    setPincode(profileDetails?.pincode ? profileDetails?.pincode : "");
    setShouldFetchAddress(true);
  };

  const toggleDrawer = () => {
    setEditProfileState("error", null);
    setEditProfileState("isProfileUpdateSuccess", false);
    setEditProfileState("isEditProfileDialogOpen", false);
  };

  const uploadProfilePicture = (event: any) => {
    setProfilePicture(URL.createObjectURL(event.target.files[0]));
    setProfilePictureCopy(event.target.files[0]);
  };

  const saveProfilePicture = () => {
    if (profilePictureCopy) {
      const formData = new FormData();
      formData.append("file", profilePictureCopy);
      formData.append("fileName", profilePictureCopy.name);
      updateProfilePicture(formData);
    }
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleDeleteProfilePicture = () => {
    setConfirmDialogOpen(true);
  };

  const removeProfilePicture = () => {
    if (profilePicture) {
      setProfilePicture("");
      setProfilePictureCopy(null);
      const splitProfilePic = profilePicture.split("/");
      const imageToDelete = splitProfilePic[splitProfilePic.length - 1];
      deleteProfilePicture(imageToDelete);
      setConfirmDialogOpen(false);
    }
  };

  const validateEmail = () => {
    if (!isEmailValid(email)) {
      setEmailError("Please enter a valid email");
      return false;
    }
  };

  const handleCountryChange = (event: SelectChangeEvent) => {
    setSelectedCountry(event.target.value as string);

    let countryName;
    countryName = countries.find((country: any) => {
      if (country.name === event.target.value) {
        return country;
      }
    });
    setSelectedCountryCode(countryName.isoCode);
    getStates(countryName.isoCode);
  };

  const handleStateChange = (event: SelectChangeEvent) => {
    setSelectedState(event.target.value as string);
    let stateName;
    stateName = states.find((state: any) => {
      if (state.name === event.target.value) {
        return state;
      }
    });
    getCities(selectedCountryCode, stateName.isoCode);
  };

  const handleCityChange = (event: SelectChangeEvent) => {
    setSelectedCity(event.target.value as string);
  };

  const handleSave = () => {
    if (email.length > 0) {
      const isValid = validateEmail();
      if (isValid === false) {
        return;
      }
    }

    const requestBody = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      dateOfBirth: dob,
      gender: selectedGender,
      houseNumber: houseNumber,
      streetAddress: completeAddress,
      locality: locality,
      pincode: pincode,
      city: selectedCity,
      state: selectedState,
      country: selectedCountry,
    };
    const { dateOfBirth, ...restBodyReq } = requestBody;
    saveProfilePicture();
    if (!!dateOfBirth) {
      editProfile(requestBody);
    } else {
      editProfile({ ...restBodyReq });
    }
    resetErrors();
  };

  const handleCloseFlashMessage = () => {
    setEditProfileState("isProfileUpdateSuccess", false);
    toggleDrawer();
  };

  const ref = useClickOutside(toggleDrawer, ["mouseup", "mousedown"]);

  const isNotificationDialogOpen = useSelector(
    (state: any) => state?.notifications?.isDialogOpen
  );

  const getProfilePicture = () => {
    if (profilePicture === "" || profilePicture === null) {
      if (
        profileDetails?.firstName !== "" &&
        profileDetails?.firstName !== null &&
        profileDetails?.lastName !== "" &&
        profileDetails?.lastName !== null
      ) {
        return `${profileDetails?.firstName
          ?.toUpperCase()
          ?.charAt(0)}${profileDetails?.lastName?.toUpperCase()?.charAt(0)}`;
      } else {
        if (
          profileDetails?.firstName !== null &&
          profileDetails?.firstName.length > 0
        ) {
          return `${profileDetails?.firstName
            ?.toUpperCase()
            ?.charAt(0)}${profileDetails?.firstName?.toLowerCase()?.charAt(1)}`;
        }
      }
    }
  };

  return (
    <>
      {isEditProfileDialogOpen && (
        <Overlay onClick={toggleDrawer} opacity={0.2} color="#000" blur={5} />
      )}
      <Drawer
        anchor={"right"}
        open={!isNotificationDialogOpen && isEditProfileDialogOpen}
        onClose={toggleDrawer}
        PaperProps={{
          // ref: ref,
          sx: {
            width: isMediumScreen ? "100%" : "50%",
            marginTop: "0px",
          },
        }}
      >
        {isProfileLoading ? (
          <Loader />
        ) : (
          <>
            <div className={styles["heading-holder"]}>
              <h2>Edit Profile</h2>
              <IconButton aria-label="close" onClick={toggleDrawer}>
                <CloseRoundedIcon />
              </IconButton>
            </div>

            <h2 className={styles["profile-picture-heading"]}>
              Profile Picture
            </h2>

            <Grid
              container
              // spacing={2}
              className={styles["profile-details-holder"]}
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className={styles["profile-pic-holder"]}>
                  {isProfilePicLoading ? (
                    <CircularProgress
                      color="inherit"
                      className={styles["profile-pic-loader"]}
                    />
                  ) : (
                    <Avatar
                      variant="circular"
                      className={styles["profile-image"]}
                      src={profilePicture}
                    >
                      {getProfilePicture()}
                    </Avatar>
                  )}
                  <div className={styles["profile-pic-actions"]}>
                    <Button
                      variant="text"
                      component="label"
                      onChange={uploadProfilePicture}
                    >
                      <img src={MediaAssets.ic_upload} alt="" />
                      Upload New Picture
                      <input hidden accept="image/*" type="file" />
                    </Button>

                    <Button
                      variant="text"
                      onClick={handleDeleteProfilePicture}
                      disabled={profilePicture ? false : true}
                    >
                      <img src={MediaAssets.ic_remove} alt="" />
                      Remove Picture
                    </Button>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <h2 className={styles["personal-details-heading"]}>
                  Personal Details
                </h2>

                <div className={styles["personal-details-holder"]}>
                  <div>
                    <span>Name</span>
                    <h3>{`${firstName} ${lastName}`}</h3>
                  </div>
                  <div>
                    <span>Phone Number</span>
                    <h3>+91 {phoneNumber}</h3>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <MuiTextField
                  fullWidth
                  label="Email Address"
                  placeholder="Type here"
                  name="emailAddress"
                  className={styles["form-textfield"]}
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value.toString());
                  }}
                  error={emailError.length > 0 ? true : false}
                  helperText={emailError}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth"
                    value={dob}
                    inputFormat="DD/MM/YYYY"
                    maxDate={new Date()}
                    onChange={(newValue: any) => {
                      setDob(newValue);
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <FormControl fullWidth>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    value={selectedGender}
                    label="Gender"
                    onChange={(event) =>
                      setSelectedGender(event.target.value as string)
                    }
                  >
                    {genders && genders.length > 0
                      ? genders.map((gender: any) => {
                          return (
                            <MenuItem key={gender.value} value={gender.value}>
                              {gender.label}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <h2 className={styles["communication-address-heading"]}>
              Communication Address Details
            </h2>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <MuiTextField
                  fullWidth
                  label="Floor and House Number"
                  placeholder="Type here"
                  name="houseNumber"
                  value={houseNumber}
                  onChange={(event) =>
                    setHouseNumber(event.target.value.toString())
                  }
                  className={styles["form-textfield"]}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <MuiTextField
                  fullWidth
                  label="Enter Locality"
                  placeholder="Type here"
                  name="locality"
                  value={locality}
                  onChange={(event) =>
                    setLocality(event.target.value.toString())
                  }
                  className={styles["form-textfield"]}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <MuiTextField
                  fullWidth
                  label="Enter complete street address"
                  placeholder="Type here"
                  name="completeAddress"
                  value={completeAddress}
                  onChange={(event) =>
                    setCompleteAddress(event.target.value.toString())
                  }
                  className={styles["form-textfield"]}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    value={selectedCountry}
                    label="Country"
                    onChange={handleCountryChange}
                  >
                    {countries && countries.length > 0
                      ? countries.map((country: any) => {
                          return (
                            <MenuItem
                              key={country.isoCode}
                              value={country.name}
                            >
                              {country.name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl fullWidth>
                  <InputLabel>State</InputLabel>
                  <Select
                    disabled={
                      selectedCountry === "" && selectedCountry !== null
                        ? true
                        : false
                    }
                    value={selectedState}
                    label="State"
                    onChange={handleStateChange}
                  >
                    {states && states.length
                      ? states.map((state: any) => {
                          return (
                            <MenuItem key={state.isoCode} value={state.name}>
                              {state.name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl fullWidth>
                  <InputLabel>City</InputLabel>
                  <Select
                    disabled={
                      (selectedCountry === "" && selectedCountry !== null) ||
                      (selectedState === "" && selectedState !== null)
                        ? true
                        : false
                    }
                    value={selectedCity}
                    label="City"
                    onChange={handleCityChange}
                  >
                    {cities && cities.length > 0
                      ? cities.map((city: any) => {
                          return (
                            <MenuItem key={city} value={city}>
                              {city}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <MuiTextField
                  fullWidth
                  label="Pincode"
                  placeholder="Type here"
                  name="pincode"
                  value={pincode}
                  onChange={(event) =>
                    setPincode(event.target.value.toString())
                  }
                  className={styles["form-textfield"]}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              onClick={handleSave}
              disableElevation
              sx={{
                width: "200px",
                marginRight: "auto",
                marginLeft: "auto",
                marginBottom: "20px",
                textTransform: "none",
              }}
              className={"btn btn-dark margin-top-55"}
            >
              Save and Update
            </Button>

            <ConfirmationDialog
              shouldOpen={isConfirmDialogOpen}
              content="Are you sure you want to remove picture?"
              okText="Yes"
              cancelBtnText="No"
              cancelHandler={handleCloseConfirmDialog}
              okHandler={removeProfilePicture}
            />

            <MuiSnackbar
              shouldOpen={isProfileUpdateSuccess}
              message={message}
              isSuccess={isProfileUpdateSuccess}
              closeHandler={() => handleCloseFlashMessage()}
            />

            <MuiSnackbar
              shouldOpen={error !== null ? true : false}
              message={error}
              isSuccess={false}
              closeHandler={() => setEditProfileState("error", null)}
            />
          </>
        )}
      </Drawer>
    </>
  );
};

export default EditProfile;
