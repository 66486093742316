import React from "react";
import { Fragment, useEffect, useState } from "react";
import {
  Drawer,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styles from "../../new-launch/NewLaunch.module.css";
import { useDispatch, useSelector } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { NewLaunchActions } from "redux-container/investments/new-launch/NewLaunchRedux";
import ProjectSearchDetails from "../../../search/projects/ProjectDetailedCard";
import { useClickOutside } from "@mantine/hooks";
import { Overlay } from "@mantine/core";

const actionDispatch = (dispatch: any) => {
  return {
    setNewLaunchState: (key: any, value: any) =>
      dispatch(NewLaunchActions.setNewLaunchState(key, value)),
  };
};

const CollectionTwoDrawer = (investments: any) => {
  const { setNewLaunchState } = actionDispatch(useDispatch());
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { isTrendingProjectDialogOpen, collectionTwoDetails, pageData } =
    useSelector((state: any) => ({
      isTrendingProjectDialogOpen:
        state?.newLaunch?.isTrendingProjectDialogOpen,
      collectionTwoDetails:
        state?.newLaunch?.newLaunchData?.pageManagementsOrCollectionTwoModels,
      pageData: state?.newLaunch?.newLaunchData?.page,
    }));

  const toggleDrawer = () => {
    setNewLaunchState("isTrendingProjectDialogOpen", false);
  };
  const ref = useClickOutside(toggleDrawer, ["mouseup", "mousedown"]);

  const handleClose = () => {
    setNewLaunchState("isTrendingProjectDialogOpen", false);
  };

  return (
    <>
      {isTrendingProjectDialogOpen && (
        <Overlay onClick={handleClose} opacity={0.2} color="#000" blur={5} />
      )}
      <Drawer
        anchor={"right"}
        open={isTrendingProjectDialogOpen}
        onClose={handleClose}
        PaperProps={{
          ref: ref,
          style: isMediumScreen
            ? {
                width: "100%",
                marginTop: "0px",
                padding: "20px 20px 120px 20px",
              }
            : {
                width: "50%",
                // marginTop: "125px",
                padding: "20px 20px 120px 20px",
              },
        }}
      >
        <div className={styles["heading-holder"]}>
          <h2>{pageData?.collectionTwo?.heading}</h2>
          <IconButton aria-label="close" onClick={toggleDrawer}>
            <CloseRoundedIcon />
          </IconButton>
        </div>

        <Fragment>
          {collectionTwoDetails &&
            collectionTwoDetails.length &&
            collectionTwoDetails.map((project: any, index: any) => {
              return (
                <ProjectSearchDetails
                  key={index}
                  launchName={project?.launchName}
                  city={project?.address?.city}
                  state={project?.address?.state}
                  mediaUrl={
                    project?.projectCoverImages?.homePageMedia?.value?.url
                  }
                  appreciation={
                    project?.generalInfoEscalationGraph?.estimatedAppreciation
                  }
                  startingPrice={project?.priceStartingFrom}
                  startingArea={project?.areaStartingFrom}
                  description={project?.shortDescription}
                  id={project?.id}
                  isSoldout={project?.isSoldOut}
                />
              );
            })}
        </Fragment>
      </Drawer>
    </>
  );
};

export default CollectionTwoDrawer;
