import { useNavigate } from "react-router-dom";
import { css } from "@mui/material";
import { MediaTypeEnum } from "enumerations/MediaTypeEnum";

const stripHtml = (html: string) => {
  // Create a new div element
  let temporalDivElement = document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html?.trim();
  // Retrieve the text property of the element (cross-browser support)
  return (
    temporalDivElement.textContent?.trimStart() ||
    temporalDivElement.innerText.trimStart() ||
    ""
  );
};

const numDifferentiation = (
  value: number | undefined,
  fullSufixes: boolean = false,
  noSufixes: boolean = false
) => {
  let amount: any = value;
  if (amount >= 10000000) {
    fullSufixes
      ? (amount = (amount / 10000000).toFixed(2) + " Crores")
      : noSufixes
      ? (amount = (amount / 10000000).toFixed(2))
      : (amount = (amount / 10000000).toFixed() + " Cr");
  } else if (amount >= 100000) {
    fullSufixes
      ? (amount = (amount / 100000).toFixed(2) + " Lakhs")
      : noSufixes
      ? (amount = (amount / 100000).toFixed(2))
      : (amount = (amount / 100000).toFixed() + " L");
  } else if (amount >= 10000) {
    amount = amount.toLocaleString();
  }
  return amount;
};

const splitOutPara = (str: string, count?: any) => {
  let splitCount = count ? count : 90;
  const para = stripHtml(str);
  const buttonStyles = css`
    cursor: pointer;
    color: #676ac0;
    font-family: Jost-Medium;
  `;
  return {
    __html:
      para.split(" ").join("").length > 25
        ? `${str.substring(
            0,
            splitCount
          )}...<span style="${buttonStyles.styles.trim()}">READ MORE</span>`
        : str,
  };
};

const numOfItems = (item: number = 2) => {
  return `${Math.floor(100 / item).toFixed(3)}%`;
};

const useHandleCardNavigation = () => {
  const navigate = useNavigate();

  const handleNavigation = (route: string, id: number, launchName?: string) => {
    return navigate(route, {
      state: {
        projectId: id,
        launchName,
      },
    });
  };
  return { handleNavigation };
};

const handleUrlLink = (media: any, isUpperCase?: any) => {
  if (media?.mediaType === MediaTypeEnum.Video) {
    const url = media?.url.replace("https://www.youtube.com/embed/", "");
    return `https://img.youtube.com/vi/${url}/hqdefault.jpg`;
  } else if (
    isUpperCase &&
    media?.mediaType?.toUpperCase() === MediaTypeEnum.Video
  ) {
    const url = media?.url.replace("https://www.youtube.com/embed/", "");
    return `https://img.youtube.com/vi/${url}/hqdefault.jpg`;
  } else {
    return media?.url;
  }
};

export {
  splitOutPara,
  numDifferentiation,
  numOfItems,
  handleUrlLink,
  useHandleCardNavigation,
};
