import { Carousel, CarouselProps } from "@mantine/carousel";
import { useTheme, useMediaQuery } from "@mui/material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
interface ICustomCarouselProps extends CarouselProps {
  containermargin?: React.CSSProperties["margin"];
}
const MantineCarousel = (props: ICustomCarouselProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {
    containermargin,
    children,
    controlSize = 60,
    align = "start",
    slideGap = "xl",
    controlsOffset = "xs",
    dragFree = true,
    ...restCarouselProps
  } = props;

  const CarouselStyles = {
    container: {
      margin: containermargin || "1rem 0.2rem",
    },
    control: {
      opacity: 1,
      borderColor: theme.palette.grey[100],
      "&[data-inactive]": {
        opacity: 0,
        cursor: "default",
      },
    },
    indicator: {
      width: 10,
      height: 10,
      transition: "width 250ms ease",
      backgroundColor: theme.palette.primary.main,
      "&[data-active]": {
        width: 30,
      },
    },
  };

  return (
    <Carousel
      dragFree={dragFree}
      nextControlIcon={<ArrowForwardIosRoundedIcon />}
      previousControlIcon={<ArrowBackIosRoundedIcon />}
      containScroll="trimSnaps"
      styles={CarouselStyles}
      controlsOffset={controlsOffset}
      align={align}
      slideGap={slideGap}
      withControls={isSmallScreen ? false : true}
      controlSize={controlSize}
      {...restCarouselProps}
    >
      {children}
    </Carousel>
  );
};

export default MantineCarousel;
