import { Fragment, useEffect, useMemo, useState } from "react";
import { NotificationAction } from "../../redux-container/notifications/NotificationsRedux";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Badge,
  BadgeProps,
  Box,
  Grid,
  styled,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { SearchActions } from "redux-container/search/SearchRedux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { Constants } from "constants/Constants";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MediaAssets from "../../assets";
import styles from "./Header.module.css";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import Search from "@mui/icons-material/Search";
import Notifications from "pages/notifications/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import Marquee from "react-fast-marquee";
import { getPageAction } from "redux-container/page-management-redux/PageManagementRedux";
import { PageTypeEnum } from "enumerations/PageTypeEnum";
import { ChatAction } from "redux-container/chat-redux/ChatRedux";

const actionDispatch = (dispatch: any) => {
  return {
    setNotificationState: (key: any, value: any) => {
      dispatch(NotificationAction.setNotificationState(key, value));
    },
    getCustomerSearchData: (data: any) =>
      dispatch(SearchActions.getCustomerSearchDataRequest(data)),
    getAdminSearchData: (data: any) =>
      dispatch(SearchActions.getAdminSearchDataRequest(data)),
    getPagesRequest: (pageType: string) =>
      dispatch(getPageAction.getPagesRequest(pageType)),
    setChatDataState: (key: any, value: any) =>
      dispatch(ChatAction.setChatDataState(key, value)),
  };
};

let headerMenus = [
  {
    id: 1,
    pathName: "HOABL",
    path: RouteConfigurations.home,
    activeIcon: MediaAssets.ic_home_active,
    inactiveIcon: MediaAssets.ic_home_inactive,
  },
  {
    id: 2,
    pathName: "Invest",
    path: RouteConfigurations.investments,
    activeIcon: MediaAssets.ic_investments_active,
    inactiveIcon: MediaAssets.ic_investments_inactive,
  },
  {
    id: 3,
    pathName: "Portfolio",
    path: RouteConfigurations.portfolio,
    activeIcon: MediaAssets.ic_portfolio_active,
    inactiveIcon: MediaAssets.ic_portfolio_inactive,
  },
  {
    id: 4,
    pathName: "Promises",
    path: RouteConfigurations.promise,
    activeIcon: MediaAssets.ic_promise_active,
    inactiveIcon: MediaAssets.ic_promise_inactive,
  },
  {
    id: 5,
    pathName: "My Services",
    path: RouteConfigurations.myService,
    activeIcon: MediaAssets.ic_my_service_active,
    inactiveIcon: MediaAssets.ic_my_service_inactive,
  },
  {
    id: 6,
    pathName: "Profile",
    path: RouteConfigurations.profile,
    activeIcon: MediaAssets.ic_profile_active,
    inactiveIcon: MediaAssets.ic_profile_inactive,
  },
];

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    "& .MuiBadge-badge": {
      right: 8,
      top: 6,
      border: `1px solid #676ac0`,
      height: "9px",
      width: "9px",
    },
  }));
  const {
    getPagesRequest,
    setNotificationState,
    getAdminSearchData,
    getCustomerSearchData,
    setChatDataState,
  } = actionDispatch(useDispatch());

  const [hasFocus, setFocus] = useState(false);
  const [searchKey, setSearchKey] = useState<string>("");
  const [focusCount, setFocusCount] = useState(0);
  const [debouceValue, setDebounceValue] = useState<any>();

  const { masthead, isFacilityVisible } = useSelector((state: any) => ({
    masthead: state?.pageManagement?.pageData?.page?.mastheadSection,
    isFacilityVisible: state?.pageManagement?.pageData?.isFacilityVisible,
  }));
  const documentsSearchData = useSelector(
    (state: any) => state?.search?.customerSearchData
  );
  const adminSearchData = useSelector(
    (state: any) => state?.search?.adminSearchData
  );

  const notificationsData = useSelector(
    (state: any) => state?.notifications?.notifications
  );

  const [shouldShowMasthead, setShouldShowMathead] = useState<boolean>(false);

  useEffect(() => {
    let cancelEffect = true;
    if (cancelEffect) {
      getPagesRequest(PageTypeEnum.HoABL);
    }

    if (isSmallScreen) {
      setTimeout(() => setShouldShowMathead(true), 500);
    } else {
      setShouldShowMathead(true);
    }
    return () => {
      cancelEffect = false;
    };
  }, []);

  useEffect(() => {
    if (masthead) {
      if (masthead?.totalSqftOfLandTransacted?.shouldDisplay) {
        setShouldShowMathead(true);
      } else if (masthead?.totalAmoutOfLandTransacted?.shouldDisplay) {
        setShouldShowMathead(true);
      } else if (masthead?.grossWeightedAvgAppreciation?.shouldDisplay) {
        setShouldShowMathead(true);
      } else if (masthead?.totalNumberOfUsersWhoBoughtTheLand?.shouldDisplay) {
        setShouldShowMathead(true);
      } else {
        setShouldShowMathead(false);
      }
    }
  }, [
    masthead?.totalSqftOfLandTransacted?.shouldDisplay,
    masthead?.totalAmoutOfLandTransacted?.shouldDisplay,
    masthead?.grossWeightedAvgAppreciation?.shouldDisplay,
    masthead?.totalNumberOfUsersWhoBoughtTheLand?.shouldDisplay,
  ]);

  useEffect(() => {
    if (hasFocus) {
      navigate(RouteConfigurations.search);
    }
  }, [hasFocus, focusCount]);

  useEffect(() => {
    if (pathname !== RouteConfigurations.search) {
      handleClearSearch();
    }
  }, [pathname]);

  useEffect(() => {
    const params = {
      searchKey: searchKey,
    };
    getAdminSearchData(params);
    getCustomerSearchData(params);
  }, [debouceValue]);

  const checkSearchDataExists = () => {
    if (
      documentsSearchData?.length ||
      adminSearchData?.faqData?.length ||
      adminSearchData?.insightsData?.length ||
      adminSearchData?.projectContentData?.length ||
      adminSearchData?.marketingUpdateData?.length ||
      adminSearchData?.promisesData?.length
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (
      // searchKey &&
      documentsSearchData?.length === 0 &&
      adminSearchData?.faqData?.length === 0 &&
      adminSearchData?.insightsData?.length === 0 &&
      adminSearchData?.projectContentData?.length === 0 &&
      adminSearchData?.marketingUpdateData?.length === 0 &&
      adminSearchData?.promisesData?.length === 0
    ) {
      navigate(RouteConfigurations?.investments);
    }

    if (
      searchKey &&
      pathname !== RouteConfigurations?.search &&
      hasFocus &&
      checkSearchDataExists()
    ) {
      navigate(RouteConfigurations?.search);
    }
  }, [
    adminSearchData?.faqData,
    adminSearchData?.insightsData,
    adminSearchData?.projectContentData,
    adminSearchData?.marketingUpdateData,
    adminSearchData?.promisesData,
    searchKey,
    documentsSearchData,
  ]);

  // checking should display value in masthead object
  const checkMasthead = () => {
    let activeValue = [];
    if (masthead) {
      Object?.keys(masthead)?.map((mastheadKey: any) => {
        if (masthead[mastheadKey]?.shouldDisplay) {
          activeValue.push(masthead[mastheadKey]?.shouldDisplay);
        }
      });
    }
    // setShouldShowMathead(truectiveValue?.length);
  };

  const navigateToHomeScreen = () => {
    navigate(RouteConfigurations.home);
  };

  const navigateToAboutUs = () => {
    navigate(RouteConfigurations.aboutUs);
  };

  const openGooglePlayStore = () => {
    window.open(Constants.GOOGLE_PLAY_STORE_URL);
  };

  const openAppleAppStore = () => {
    window.open(Constants.APPLE_APP_STORE_URL);
  };

  const toggleDrawer = () => {
    setNotificationState("isDialogOpen", true);
  };

  const handleSearchInput = (e: any) => {
    setSearchKey(e.target.value);
    setTimeout(() => {
      setDebounceValue(e.target.value);
    }, 500);
  };

  const handleClearSearch = () => {
    setSearchKey("");
    setDebounceValue(null);
  };

  const checkForUnReadNotification = () => {
    let isUnReadMessageAvailable: boolean = false;

    for (let i = 0; i < notificationsData?.length; i++) {
      if (notificationsData[i]?.readStatus === false) {
        isUnReadMessageAvailable = true;
        break;
      }
    }
    return isUnReadMessageAvailable;
  };

  const handleClickChatIcon = () => {
    setChatDataState("getInitialChatDisplayData", true);
  };

  const filteredMenu = useMemo(() => {
    if (isFacilityVisible) {
      return headerMenus.filter((menus) => {
        return menus.pathName !== headerMenus[3].pathName;
      });
    } else {
      return headerMenus.filter((menus) => {
        return menus.pathName !== headerMenus[4].pathName;
      });
    }
  }, [isFacilityVisible]);

  return (
    <AppBar className={styles["header-nav"]}>
      <img
        src={MediaAssets.ic_logo_white}
        alt="The House of Abhinandan Lodha - New Generation Land"
        className={styles["logo"]}
        onClick={navigateToHomeScreen}
      />

      <div className={styles["second-div"]}>
        <div className={styles["masthead-app-store-holder"]}>
          <Box
            flexBasis={{ sm: "60%", md: "70%", lg: "78%", xl: "83%" }}
            className={styles[shouldShowMasthead ? "masthead" : ""]}
            onClick={navigateToAboutUs}
          >
            <img
              src={MediaAssets.ic_logo_white}
              alt="The House of Abhinandan Lodha - New Generation Land"
              className={styles["logo-mobile"]}
            />
            {shouldShowMasthead && (
              <Marquee
                speed={30}
                pauseOnHover={true}
                gradient={false}
                className={styles["marquee-wrapper"]}
                gradientWidth="10px"
              >
                {masthead?.totalSqftOfLandTransacted?.shouldDisplay && (
                  <div>
                    <span className={styles["heading"]}>
                      {masthead?.totalSqftOfLandTransacted?.displayName}
                    </span>
                    <h2 className={styles["value"]}>
                      {masthead?.totalSqftOfLandTransacted?.value}
                    </h2>
                  </div>
                )}

                {masthead?.totalAmoutOfLandTransacted?.shouldDisplay && (
                  <div>
                    <span className={styles["heading"]}>
                      {masthead?.totalAmoutOfLandTransacted?.displayName}
                    </span>
                    <h2 className={styles["value"]}>
                      {masthead?.totalAmoutOfLandTransacted?.value}
                    </h2>
                  </div>
                )}

                {masthead?.grossWeightedAvgAppreciation?.shouldDisplay && (
                  <div>
                    <span className={styles["heading"]}>
                      {masthead?.grossWeightedAvgAppreciation?.displayName}
                    </span>
                    <h2 className={styles["value"]}>
                      {masthead?.grossWeightedAvgAppreciation?.value}
                    </h2>
                  </div>
                )}

                {masthead?.totalNumberOfUsersWhoBoughtTheLand
                  ?.shouldDisplay && (
                  <div>
                    <span className={styles["heading"]}>
                      {
                        masthead?.totalNumberOfUsersWhoBoughtTheLand
                          ?.displayName
                      }
                    </span>
                    <h2 className={styles["value"]}>
                      {masthead?.totalNumberOfUsersWhoBoughtTheLand?.value}
                    </h2>
                  </div>
                )}
              </Marquee>
            )}
          </Box>

          <Box
            flexBasis={{ sm: "35%", md: "28%", lg: "20%", xl: "18%" }}
            className={styles["row"]}
          >
            <span className={styles["download-app-heading"]}>Download App</span>
            <div
              title="Download from Play Store"
              className={styles["icon-btn"]}
              aria-label="delete"
              onClick={openGooglePlayStore}
            >
              <img
                src={MediaAssets.ic_playstore}
                alt="Google Play Store"
                className={styles["store-icons"]}
              />
            </div>
            <div
              title="Download from App Store"
              className={styles["icon-btn"]}
              aria-label="delete"
              onClick={openAppleAppStore}
            >
              <img
                src={MediaAssets.ic_appstore}
                alt="Apple App Store"
                className={styles["store-icons"]}
              />
            </div>
          </Box>
        </div>

        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Grid item xs={7} sm={7} md={3} lg={3.5} xl={3.5} mr={"1rem"}>
            <OutlinedInput
              fullWidth
              placeholder="Search"
              className={styles["search-field"]}
              size={"small"}
              onFocus={() => {
                setFocus(true);
                setFocusCount(focusCount + 1);
              }}
              onClick={() => setFocus(true)}
              onChange={handleSearchInput}
              sx={{ marginRight: 2 }}
              value={searchKey}
              startAdornment={
                <IconButton
                  aria-label="search"
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <Search className={styles["search-icon"]} />
                </IconButton>
              }
              endAdornment={
                searchKey && (
                  <IconButton
                    aria-label="close"
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <CloseIcon onClick={handleClearSearch} />
                  </IconButton>
                )
              }
            />
          </Grid>
          {!isSmallScreen &&
            filteredMenu.map((menu) => (
              <Grid item sm={1} md={1.5} lg={1.4} xl={1} key={menu.id}>
                <NavLink
                  to={menu.path}
                  className={({ isActive }) =>
                    isActive || pathname === menu.path
                      ? styles["menu-item-active"]
                      : styles["menu-item"]
                  }
                  children={({ isActive }) => (
                    <Fragment>
                      <img
                        className={styles["nav-menu-icons"]}
                        src={isActive ? menu.activeIcon : menu.inactiveIcon}
                        alt="nav-menu-icons"
                      />
                      {menu.pathName}
                    </Fragment>
                  )}
                />
              </Grid>
            ))}
          <Grid
            item
            xs={2}
            sm={2}
            md={0.3}
            lg={0.5}
            xl={0.3}
            className={styles["nav-icons-holder"]}
            onClick={toggleDrawer}
            sx={{ cursor: "pointer" }}
          >
            <Box sx={{ zIndex: -1 }}>
              <NavLink
                to={pathname} // To stay in the current route & open notification drawer. If "" is given, it'll go to Home.
                children={({ isActive }) => (
                  <StyledBadge
                    color="error"
                    variant="dot"
                    invisible={!checkForUnReadNotification()}
                  >
                    <NotificationsIcon className={styles["nav-icons-active"]} />
                  </StyledBadge>
                )}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={0.3}
            lg={0.5}
            xl={0.3}
            className={styles["nav-icons-holder"]}
          >
            <NavLink
              to={RouteConfigurations.chat}
              children={({ isActive }) => (
                <MessageIcon
                  onClick={handleClickChatIcon}
                  className={styles["nav-icons-active"]}
                />
              )}
            />
          </Grid>
        </Grid>
      </div>

      {/* {<Notifications />} */}
    </AppBar>
  );
};

export default Header;
