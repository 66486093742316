import Grid from "@mui/material/Grid";
import PortfolioInformationItem from "./PortfolioInformationItem";
import styles from "./PortfolioInformation.module.css";
import { useSelector } from "react-redux";
import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useNavigate } from "react-router-dom";
import { Divider, Theme, Typography, useMediaQuery } from "@mui/material";
import { numDifferentiation } from "utils/helperFunctions";

const PortfolioInformation = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { portfolioData } = useSelector((state: any) => ({
    portfolioData: state?.portfolio?.portfolioData,
  }));

  return (
    <>
      {isSmallScreen && (
        <Typography mt={"1.5rem"} variant="body1" component={Divider}>
          Portfolio
        </Typography>
      )}
      <div className={styles["portfolio-section"]}>
        <div className={styles["portfolio-info-holder"]}>
          <div className={styles["portfolio-left-split"]}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <PortfolioInformationItem
                  title="Total Investments"
                  value={portfolioData?.investmentCount}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <PortfolioInformationItem
                  title="Amount Invested"
                  value={`₹ ${numDifferentiation(
                    portfolioData?.amountInvested,
                    true
                  )}*`}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <PortfolioInformationItem
                  title="Total Area Sq Ft"
                  value={portfolioData?.totalAreaSqFt}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <PortfolioInformationItem
                  title="Amount Pending"
                  value={`₹ ${numDifferentiation(
                    portfolioData?.amountPending,
                    true
                  )}`}
                />
              </Grid>
            </Grid>
          </div>

          <div className={styles["portfolio-right-split"]}>
            <div className={styles["heading-holder"]}>
              <h3>Your Portfolio</h3>
              <p>WE WILL SHOW YOU THE RIGHT WAY TO GENERATE WEALTH.</p>
            </div>

            <MuiLoadingButton
              className={styles["view-portfolio-btn"]}
              onClick={() => navigate(RouteConfigurations.portfolio)}
            >
              View My Portfolio
            </MuiLoadingButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioInformation;
