import styles from "./InvestmentMap.module.css";
import { useSelector } from "react-redux";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";

const InvestmentMap = () => {
  const navigate = useNavigate();

  const { investmentDetails } = useSelector((state: any) => ({
    investmentDetails: state?.investmentDetails?.investmentDetails,
  }));

  const openMap = () => {
    navigate(RouteConfigurations.investmentLocation);
  };

  return (
    <div className={styles["map-holder"]}>
      <img
        src={investmentDetails?.projectContent?.address?.mapMedia?.value?.url}
        alt=""
        className={styles["map"]}
      />
      <MuiStyledButton
        variant="contained"
        onClick={openMap}
        disableElevation
        className={`${styles["open-map-btn"]} btn btn-dark`}
      >
        View On Map
      </MuiStyledButton>
    </div>
  );
};

export default InvestmentMap;
