import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../BookingJourney.module.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import CloseIcon from "@mui/icons-material/Close";
import { DocumentsActions } from "redux-container/documents/DocumentsRedux";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/Loader";
import viewAllStyles from "./ViewAllPayments.module.css";
import CancelIcon from "@mui/icons-material/Cancel";
import { Overlay } from "@mantine/core";
import { dateConstants, formatDateTime } from "utils/DateFormatter";

const actionDispatch = (dispatch: any) => {
  return {
    downloadDoc: (path: any) =>
      dispatch(DocumentsActions.getDownloadDocDataRequest(path)),
    setDocumentsState: (key: any, value: any) =>
      dispatch(DocumentsActions.setDocumentsState(key, value)),
  };
};

const ViewAllPayments = (props: any) => {
  const { downloadDoc, setDocumentsState } = actionDispatch(useDispatch());

  const {
    setShowAllPaymentReceipts,
    paymentReceipts,
    paymentHistory,
    shouldOpen,
  } = props;
  const [showAllReceipts, setShowAllReceipts] = useState(false);
  const documentBaseString = useSelector(
    (state: any) => state?.documents?.downloadDocData
  );
  const isDocLoading = useSelector((state: any) => state?.documents?.isLoading);
  const [showPdf, setShowPdf] = useState(false);
  const showPDFPreview = useSelector(
    (state: any) => state?.documents?.showPDFPreview
  );

  useEffect(() => {
    function dataURItoBlob(dataURI: any) {
      const byteString = window.atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: "application/pdf" });
      return blob;
    }

    const blob = dataURItoBlob(documentBaseString);
    const url = URL.createObjectURL(blob);

    if (showPDFPreview && showPdf) {
      window.open(url);
      setDocumentsState("showPDFPreview", false);
      setShowPdf(false);
    } else if (showPdf && !documentBaseString) {
      setDocumentsState("error", `Couldn't Fetch Data. Try Again!`);
    }
  }, [showPDFPreview]);

  useEffect(() => {
    setShowAllReceipts(false);
  }, []);

  const handleNavigateBack = () => {
    setShowAllPaymentReceipts(false);
  };

  const handleCloseDialog = () => {
    setShowAllReceipts(false);
  };

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleViewRecieptClick = async (path: any) => {
    await downloadDoc(path);
    setShowPdf(true);
  };

  const commaSeparatedAmount = (amount: number) => {
    return amount.toLocaleString();
  };

  const { breakpoints } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("md"));

  return (
    <Box mt={1}>
      {shouldOpen && (
        <Overlay
          onClick={handleNavigateBack}
          opacity={0.2}
          color="#000"
          blur={5}
        />
      )}
      <Drawer
        open={shouldOpen}
        anchor={"right"}
        onClose={handleNavigateBack}
        PaperProps={{
          sx: {
            width: fullScreen ? "100%" : breakpoints.values.sm,
            padding: fullScreen ? "10px 15px" : "10px 15px 10px 15px",
          },
        }}
      >
        <Stack>
          <Typography
            ml={!fullScreen ? 1.5 : 0}
            className={styles["section-heading-name"]}
          >
            All Receipts
          </Typography>

          <IconButton style={{ marginTop: "5px" }} onClick={handleNavigateBack}>
            <CancelIcon color="error" />
          </IconButton>
        </Stack>

        <Stack>
          <MuiStyledButton
            onClick={() => setShowAllReceipts(true)}
            disabled={paymentReceipts?.length > 0 ? false : true}
            sx={{ textDecoration: "underline", marginBottom: -2 }}
            variant="text"
            color="primary"
            className={viewAllStyles["view-btn"]}
          >
            Download Receipts
          </MuiStyledButton>
        </Stack>

        {showAllReceipts && (
          <Drawer
            anchor={"right"}
            open={showAllReceipts}
            onClose={handleCloseDialog}
            PaperProps={{
              sx: {
                width: "100%",
                padding: "20px 15px",
              },
            }}
          >
            <div>
              <Box>
                <Stack
                  spacing={2}
                  justifyContent={"space-between"}
                  pl={isSmallerScreen ? 1 : 2}
                  pr={isSmallerScreen ? 1 : 2}
                >
                  <Typography className={styles["section-heading-name"]}>
                    Documents
                  </Typography>

                  <IconButton onClick={handleCloseDialog} color="error">
                    <CancelIcon className={styles["left-arrow"]} />
                  </IconButton>
                </Stack>
              </Box>
              {isDocLoading ? (
                <Loader />
              ) : (
                <Box mt={3}>
                  {paymentReceipts?.map((item: any, index: number) => (
                    <Paper
                      key={index}
                      sx={
                        isSmallerScreen
                          ? { padding: "5px 15px", marginBottom: "10px" }
                          : { padding: "5px 25px", marginBottom: "10px" }
                      }
                    >
                      <Stack>
                        <Typography
                          className={viewAllStyles["typograpghy-text"]}
                        >
                          {item?.name}
                        </Typography>
                        <MuiStyledButton
                          onClick={() => {
                            handleViewRecieptClick(item?.path);
                          }}
                          disabled={item?.path ? false : true}
                          sx={{
                            textDecoration: "underline",
                            marginTop: "0.5rem",
                          }}
                          variant="text"
                          color="primary"
                          className={styles["view-btn"]}
                        >
                          View
                        </MuiStyledButton>
                      </Stack>
                    </Paper>
                  ))}
                </Box>
              )}
            </div>
          </Drawer>
        )}

        <Box mt={3}>
          {paymentHistory?.map((item: any, index: number) => (
            <div key={index}>
              <Box
                className={styles["payment-card"]}
                sx={
                  isSmallerScreen
                    ? { padding: "5px 0px", marginBottom: "3px" }
                    : { padding: "5px", marginBottom: "5px" }
                }
              >
                <Stack>
                  <Stack
                    spacing={2}
                    flexDirection={"column"}
                    alignItems={"baseline"}
                  >
                    <Typography className={viewAllStyles["typography-medium"]}>
                      Total Amount Paid:
                    </Typography>
                    <Typography
                      className={viewAllStyles["typography-semiBold-theme"]}
                    >
                      {item?.paidAmount
                        ? "₹ " + commaSeparatedAmount(item?.paidAmount)
                        : " -"}
                    </Typography>
                  </Stack>

                  <Typography
                    className={viewAllStyles["typography-text-italic"]}
                  >
                    {formatDateTime(
                      item?.updatedAt,
                      dateConstants.dateFormatDDthmmYY
                    )}
                  </Typography>
                </Stack>
              </Box>
              <Divider />
            </div>
          ))}
          <Box className={viewAllStyles["all-reciepts-disclaimer"]}>
            <Typography
              className={viewAllStyles["all-reciepts-disclaimer-text"]}
              variant="body2"
            >
              *The receipts shown might be combined across plot pages if bulk
              payments were made for multiple plots.
            </Typography>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default ViewAllPayments;
