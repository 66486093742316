import { Fragment } from "react";
import { Stack, Typography, Chip, Paper } from "@mui/material";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import LineChart from "components/line-chart/LineChart";

const PriceTrendsGraph = ({ generalInfoEscalationGraph }: any) => {
  return (
    <Fragment>
      <Stack my={"2rem"}>
        <Typography variant="h5" fontFamily={"Jost-Medium"}>
          {generalInfoEscalationGraph?.title}
        </Typography>
        <Typography variant={"body1"} color={"#918fa3"}>
          {"(Rise in last 1 Year)"}
          <Chip
            className="estimate-badge"
            icon={<KeyboardDoubleArrowUpIcon fontSize="small" />}
            label={`${generalInfoEscalationGraph?.estimatedAppreciation}%`}
          />
        </Typography>
      </Stack>
      <Paper
        elevation={0}
        sx={{
          boxShadow: "0 10px 25px 0 rgba(0, 0, 0, 0.1)",
          minHeight: "35vh",
        }}
      >
        <LineChart customizableGraphData={generalInfoEscalationGraph} />
        <Typography
          variant={"body1"}
          color={"primary.dark"}
          py={"1rem"}
          textAlign={"center"}
        >
          NOTE: OEA will start showing once the investment is completely done.
        </Typography>
      </Paper>
    </Fragment>
  );
};

export default PriceTrendsGraph;
