import styles from "./AllTestimonials.module.css";

const TestimonialItem = (testimonial: any) => {
  const displayFullName = (testimonial: any) => {
    let fullName = "";
    if (testimonial.firstName !== null && testimonial.lastName !== null) {
      fullName = `${testimonial.firstName} ${testimonial.lastName}`;
    } else {
      fullName = `${testimonial.firstName}`;
    }
    return fullName;
  };

  return (
    <div className={styles["testimonial-card"]}>
      <h2>{displayFullName(testimonial?.testimonial)}</h2>
      <div>{`${testimonial?.testimonial?.designation} ${testimonial?.testimonial?.companyName}`}</div>
      <p>{testimonial?.testimonial?.testimonialContent}</p>
    </div>
  );
};

export default TestimonialItem;
