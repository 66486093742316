import React from "react";
import { Typography, Stack, styled, Switch } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import styles from "./MenuItem.module.css";
import MuiSwitch from "components/mui-switch/MuiSwitch";
import { useNavigate } from "react-router-dom";

const MenuItems = (props: any) => {
  const {
    title,
    subTitle,
    showSwitch = true,
    route,
    disableTopBorder = false,
    switchValue,
    handleSwitchChange,
    handleClick,
  } = props;

  const navigate = useNavigate();

  return (
    <div
      className={
        disableTopBorder
          ? styles["menu-container-no-border"]
          : styles["menu-container"]
      }
      onClick={!showSwitch && handleClick}
    >
      <Stack sx={{ cursor: !showSwitch ? "pointer" : "" }}>
        <Stack
          flexDirection={"column"}
          alignItems={"start"}
          alignContent={"center"}
          justifyContent={"start"}
        >
          <Typography variant="body2" className={styles["menu-item-title"]}>
            {title}
          </Typography>
          <Typography
            variant="caption"
            className={styles["menu-item-sub-title"]}
          >
            {subTitle}
          </Typography>
        </Stack>

        {showSwitch ? (
          <MuiSwitch
            value={switchValue}
            checked={switchValue}
            onChange={handleSwitchChange}
          />
        ) : (
          <EastIcon className={styles["east-icon"]} />
        )}
      </Stack>
    </div>
  );
};

export default MenuItems;
