import { Fragment, useEffect, useMemo, useState } from "react";
import { UpdatesActions } from "redux-container/updates/UpdatesRedux";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import PageHeader from "components/page-header/PageHeader";
import UpdateItem from "./update-item/UpdateItem";
import Loader from "components/loader/Loader";
import LatestUpdatesViewDialog from "./update-details/UpdateDetails";
import { useMemoizedValue } from "app/hooks/useMemoizedValue";

const actionDispatch = (dispatch: any) => {
  return {
    getUpdates: () => dispatch(UpdatesActions.getUpdatesRequest()),
  };
};

const Updates = () => {
  const { getUpdates } = actionDispatch(useDispatch());
  const { isLoading, latestUpdatesList, pageData, totalUpdatesToShow } =
    useSelector((state: any) => ({
      pageData: state?.pageManagement?.pageData?.page,
      latestUpdatesList: state?.updates?.updates,
      isLoading: state?.updates?.isLoading,
      totalUpdatesToShow:
        state?.pageManagement?.pageData?.page?.totalUpdatesOnListView,
    }));

  const slicedPageData = useMemoizedValue(
    latestUpdatesList,
    totalUpdatesToShow
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [storiesIndex, setStoriesIndex] = useState();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    getUpdates();
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <PageHeader
            title={pageData?.latestUpdates?.heading}
            subtitle={pageData?.latestUpdates?.subHeading}
          />

          <LatestUpdatesViewDialog
            storyIndex={storiesIndex}
            storiesData={slicedPageData}
            open={openDialog}
            handleclose={() => setOpenDialog(false)}
          />

          <Grid mb={5} container spacing={2}>
            {slicedPageData &&
              slicedPageData
                ?.slice(0, totalUpdatesToShow)
                ?.map((update: any, index: any) => {
                  return (
                    <Grid
                      key={update.id}
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                    >
                      <UpdateItem
                        update={update}
                        index={index}
                        onClick={() => {
                          setOpenDialog(true);
                          setStoriesIndex(index);
                        }}
                      />
                    </Grid>
                  );
                })}
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Updates;
