import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import MediaAssets from "assets";
import styles from "./NewUserNameInfo.module.css";
import NewUserNameActions from "../../../../redux-container/login/new-user-name-info/NewUserNameInfoRedux";
import GenerateOtpActions from "../../../../redux-container/login/generate-otp/GenerateOtpRedux";
import VerifyOtpActions from "../../../../redux-container/login/verify-otp/VerifyOtpRedux";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
interface IRegistrationSuccessDialogProps {
  shouldOpen: boolean;
}

const actionDispatch = (dispatch: any) => {
  return {
    setNewUserNameInfoState: (key: any, value: any) =>
      dispatch(NewUserNameActions.setNewUserNameInfoState(key, value)),
    setGenerateOtpState: (key: any, data: any) =>
      dispatch(GenerateOtpActions.setGenerateOtpState(key, data)),
    setVerifyOtpState: (key: any, data: any) =>
      dispatch(VerifyOtpActions.setVerifyOtpState(key, data)),
  };
};

const RegistrationSuccessDialog = ({
  shouldOpen,
}: IRegistrationSuccessDialogProps) => {
  const { setNewUserNameInfoState, setGenerateOtpState, setVerifyOtpState } =
    actionDispatch(useDispatch());

  const handleContinue = () => {
    setGenerateOtpState("isOtpGenerated", true);
    setVerifyOtpState("shouldShowNewUserName", true);
    setNewUserNameInfoState("isRegistrationSuccessDialogOpen", false);
    setNewUserNameInfoState("isLoggedIn", true);
    setVerifyOtpState("isOtpVerified", false);
  };

  const displayFullName = () => {
    const firstName = getItemFromLocalStorage(Constants.FIRSTNAME);
    const lastName = getItemFromLocalStorage(Constants.LASTNAME);
    return `${firstName} ${lastName}`;
  };

  return (
    <Dialog maxWidth={"md"} disableEscapeKeyDown={true} open={shouldOpen}>
      <DialogContent>
        <div className={styles["registration-heading-holder"]}>
          <img src={MediaAssets.ic_party_left} alt="" />
          <h2>Registration Complete</h2>
          <img src={MediaAssets.ic_party_right} alt="" />
        </div>

        <div className={styles["registration-banner"]}>
          <img src={MediaAssets.ic_logo_gold} alt="" />
          <div>
            <p>Dear {displayFullName()},</p>
            <p>Welcome to our family</p>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <MuiLoadingButton
          variant="contained"
          onClick={handleContinue}
          autoFocus
          disableElevation
          className={"btn btn-dark"}
          sx={{
            fontFamily: "Jost-Bold",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "20px",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          Continue
        </MuiLoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RegistrationSuccessDialog;
