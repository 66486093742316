import moment from "moment";

const date1 = () => {
  const date = moment();
  return date.format("DD HH mm ss");
};

export const updated = (updatedat: any): any => {
  const timeofreceive = moment(updatedat);
  const date = moment();
  const seconds = date.diff(timeofreceive, "seconds");
  const minutes = date.diff(timeofreceive, "minute");
  const hours = date.diff(timeofreceive, "hours");
  const days = date.diff(timeofreceive, "days");

  if (days != 0) {
    const difference = days;
    return difference + (difference === 1 ? "d" : "d");
  } else if (hours != 0) {
    const difference = hours;
    return difference + (difference === 1 ? "h" : "h");
  } else if (minutes != 0) {
    const difference = minutes;
    return difference + (difference === 1 ? "m" : "m");
  } else if (seconds <= 59) {
    // check this logic should be < 59
    return "Just Now";
  } else if (seconds != 0) {
    const difference = seconds;
    return difference + " seconds";
  }
};

const customDateFormat = (date: Date, format: string = "DD MMM YYYY") =>
  moment(date || new Date()).format(format);
export default date1;
export { customDateFormat };
