import styles from "./InvestmentPromotions.module.css";
import { useSelector } from "react-redux";

const InvestmentPromotions = () => {
  const { investmentDetails } = useSelector((state: any) => ({
    investmentDetails: state?.investmentDetails?.investmentDetails,
  }));

  return (
    <div className={styles["promotions-holder"]}>
      <img
        src={investmentDetails?.projectContent?.offersAndPromotions?.value?.url}
        alt=""
        className={styles["image-banner"]}
      />
    </div>
  );
};

export default InvestmentPromotions;
