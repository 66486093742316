import { Fragment, useEffect } from "react";
import LastFewPlots from "./last-few-plots/LastFewPlots";
import NewLaunch from "./new-launch/NewLaunch";
import Promotions from "./promotions/Promotions";
import { NewLaunchActions } from "redux-container/investments/new-launch/NewLaunchRedux";
import { useDispatch, useSelector } from "react-redux";
import { PageTypeEnum } from "enumerations/PageTypeEnum";
import Loader from "components/loader/Loader";
import CollectionTwo from "./collections/collection-two/CollectionTwo";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";

const actionDispatch = (dispatch: any) => {
  return {
    getPagesRequest: (pageType: string) =>
      dispatch(NewLaunchActions.getNewLaunchRequest(pageType)),
    setNewLaunchState: (key: any, value: any) =>
      dispatch(NewLaunchActions.setNewLaunchState(key, value)),
    getMediaGallery: (id: any) =>
      dispatch(NewLaunchActions.getMediaGalleryRequest(id)),
    getAllInvestments: () =>
      dispatch(NewLaunchActions.getAllInvestmentsRequest()),
  };
};

const Investments = () => {
  const {
    setNewLaunchState,
    getPagesRequest,
    getMediaGallery,
    getAllInvestments,
  } = actionDispatch(useDispatch());

  const isLoading = useSelector((state: any) => state?.newLaunch?.isLoading);

  const { pageData, investmentData, newInvestmentsWithPriority, error } =
    useSelector((state: any) => ({
      error: state?.newLaunch?.error,
      pageData: state?.newLaunch?.newLaunchData?.page,
      investmentData:
        state?.newLaunch?.newLaunchData?.pageManagementsOrNewInvestments,
      newInvestmentsWithPriority:
        state?.newLaunch?.newLaunchData?.page?.newInvestmentsWithPriority,
    }));

  let sortedInvestments: any = [];

  useEffect(() => {
    let cancelEffect = true;
    if (cancelEffect) {
      getPagesRequest(PageTypeEnum.Investments);
      getAllInvestments();

      if (newInvestmentsWithPriority) {
        for (let newInvest of newInvestmentsWithPriority) {
          for (let project of investmentData) {
            if (newInvest && newInvest.id && project && project.id) {
              if (newInvest?.id === project?.id) {
                project.priority = newInvest.priority;
                sortedInvestments.push(project);
              }
            }
          }
        }
      }

      if (sortedInvestments && sortedInvestments.length > 0) {
        getMediaGallery(sortedInvestments[0]?.id);
      }
    }
    return () => {
      cancelEffect = false;
    };
  }, []);

  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {pageData !== null && <NewLaunch />}
          {pageData?.isPromotionAndOfferActive &&
            pageData?.isPromotionAndOfferActive === true && <Promotions />}
          {pageData?.isCollectionOneActive &&
            pageData?.isCollectionOneActive === true && <LastFewPlots />}
          {pageData?.isCollectionTwoActive &&
            pageData?.isCollectionTwoActive === true && <CollectionTwo />}
        </div>
      )}

      <MuiSnackbar
        shouldOpen={error !== null ? true : false}
        message={error}
        isSuccess={false}
        closeHandler={() => setNewLaunchState("error", null)}
      />
    </Fragment>
  );
};

export default Investments;
