import styles from "./Notifications.module.css";
import date, { updated } from "../../utils/DateUtils";

interface INotificationProps {
  notificationData: any;
  updateNotificationStatus: (
    notificationId: number,
    targetPage: number
  ) => void;
}

const NotificationItem = ({
  notificationData,
  updateNotificationStatus,
}: INotificationProps) => {
  const notification = notificationData;

  const updateStatus = () => {
    updateNotificationStatus(
      notification.id,
      notification.notification.targetPage
    );
  };

  return (
    <div
      className={
        notification.readStatus
          ? `${styles["read-notification-card"]}`
          : `${styles["unread-notification-card"]}`
      }
      onClick={updateStatus}
    >
      <div className={styles["left-split"]}>
        <div
          className={styles["notifcation-title-holder"]}
          style={
            notification?.notification?.notificationDescription?.media?.value
              ?.url
              ? { width: "80%" }
              : { width: "100%" }
          }
        >
          {notification.readStatus === false ? (
            <div className={styles["new-notification-badge"]}>
              <span>New</span>
            </div>
          ) : null}
          <h2
            className={
              notification?.readStatus === false
                ? styles["notifcation-unread-title"]
                : styles["notifcation-read-title"]
            }
          >
            {notification?.notification?.notificationDescription?.title}
          </h2>
          <span
            className={
              notification?.readStatus === false
                ? styles["notification-received-time"]
                : styles["notification-read-time"]
            }
          >
            {updated(notification?.notification?.updatedAt)}
          </span>
        </div>

        <div>
          <p
            className={
              notification.readStatus === false
                ? styles["notification-content"]
                : styles["notification-read-content"]
            }
          >
            {notification?.notification?.notificationDescription?.body}
          </p>
        </div>
      </div>

      {notification?.notification?.notificationDescription?.media?.value
        ?.url ? (
        <div className={styles["right-split"]}>
          <img
            className={styles["notification-icon"]}
            src={
              notification?.notification?.notificationDescription?.media?.value
                ?.url
            }
            alt=""
          />
        </div>
      ) : null}
    </div>
  );
};

export default NotificationItem;
