import { Overlay } from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";
import { Drawer } from "@mui/material";
import { Fragment } from "react";
import Securitytips from "./SecurityTipsItem";

const SecurityTips = (props: any) => {
  const ref = useClickOutside(props.onClickOnCloseIcon, [
    "mouseup",
    "mousedown",
  ]);
  return (
    <>
      {props.drawer && (
        <Overlay
          onClick={props.onClickOnCloseIcon}
          opacity={0.2}
          color="#000"
          blur={5}
        />
      )}
      <Drawer
        anchor={"right"}
        open={props.drawer}
        PaperProps={{
          ref: ref,
          sx: {
            width: {
              md: "50%",
              sm: "100%",
            },
            padding: "20px 15px 120px 15px",
          },
        }}
      >
        <Fragment>
          <Securitytips onClickOnCloseIcon={props.onClickOnCloseIcon} />
        </Fragment>
      </Drawer>
    </>
  );
};

export default SecurityTips;
