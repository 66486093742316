import styles from "./Testimonials.module.css";

const TestimonialItem = (testimonialData: any) => {
  const testimonial = testimonialData.testimonial;
  const displayFullName = (testimonial: any) => {
    let fullName = "";
    if (testimonial.firstName !== null && testimonial.lastName !== null) {
      fullName = `${testimonial.firstName} ${testimonial.lastName}`;
    } else {
      fullName = `${testimonial.firstName}`;
    }
    return fullName;
  };

  return (
    <div className={styles["testimonial-card"]}>
      <h2>{displayFullName(testimonial)}</h2>
      <div>{`${testimonial.designation} ${testimonial.companyName}`}</div>
      <p>{testimonial.testimonialContent}</p>
    </div>
  );
};

export default TestimonialItem;
