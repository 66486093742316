import React, { Fragment, useState, useEffect } from "react";
import Search from "@mui/icons-material/Search";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  Grid,
  IconButton,
  Stack,
  Typography,
  InputAdornment,
  Box,
  useMediaQuery,
} from "@mui/material";
import styles from "./FaqSection.module.css";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import { Carousel } from "@mantine/carousel";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "components/mui-accordion/AccordionComponent";
import { ProfileAction } from "redux-container/profile/ProfileRedux";
import { useDispatch, useSelector } from "react-redux";
import MuiTextField from "components/mui-textfield/MuiTextfield";
import { KeyCodeTypeEnum } from "enumerations/KeyCodeTypeEnum";
import ClearIcon from "@mui/icons-material/Clear";
import { FaqConstants } from "./FaqsConstants";
import Loader from "components/loader/Loader";
import { styled, useTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { getPortfolioAction } from "redux-container/portfolio-redux/PortfolioRedux";
const actionDispatch = (dispatch: any) => {
  return {
    getCategories: (data: any) =>
      dispatch(ProfileAction.getAllCategoriesRequest(data)),
    getFaqsList: (data: any) =>
      dispatch(ProfileAction.getFaqsListRequest(data)),
    getProjectFaq: (id: any) =>
      dispatch(getPortfolioAction.getProjectFaqsRequest(id)),
  };
};

const FaqSection = () => {
  const { getCategories, getFaqsList, getProjectFaq } = actionDispatch(
    useDispatch()
  );
  const location: any = useLocation();
  const [expanded, setExpanded] = useState<any>(
    location?.state?.faqId ? `panel${location?.state?.faqId}` : false
  );
  const [faqSearchKey, setFaqSearchKey] = useState("");
  const [onKeyPressSearchValue, setOnKeyPressSearchValue] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const faqsData = useSelector((state: any) => state?.profile?.faqsData);
  const isCategoriesLoading = useSelector(
    (state: any) => state?.profile?.isLoading
  );
  const projectFaqsContent = useSelector(
    (state: any) => state?.portfolio?.projectFaqs
  );
  const [projectFaqsData, setProjectFaqsData] = useState<any>([]);
  const isprojectFaqsLoading = useSelector(
    (state: any) => state?.portfolio?.isLoading
  );

  const CategoryDetails = useSelector(
    (state: any) => state?.profile?.categories
  );
  const categoryList = location?.state?.projectId
    ? projectFaqsData
    : CategoryDetails;

  const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body1,
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: "60px",
    borderRadius: "10px",
  }));

  useEffect(() => {
    setProjectFaqsData(projectFaqsContent);
  }, [location?.state?.projectId, isprojectFaqsLoading, isCategoriesLoading]);

  useEffect(() => {
    if (location?.state?.projectId) {
      getProjectFaq(location?.state?.projectId);
    } else {
      const getCategoryParams = {
        categoryType: FaqConstants.General_Category,
      };
      getCategories(getCategoryParams);
    }
  }, [location?.state?.projectId, location?.state?.projectId]);

  useEffect(() => {
    if (location?.state?.projectId) {
      let filterProjectFaqs = () => {
        let finalFilteredProjectFaqs: any = [];
        projectFaqsContent?.map((category: any) => {
          if (
            category?.name
              ?.toLowerCase()
              ?.includes(onKeyPressSearchValue.toLowerCase())
          ) {
            finalFilteredProjectFaqs.push(category);
          } else {
            category?.faqs?.map((faq: any) => {
              if (
                faq?.faqQuestion?.question
                  ?.toLowerCase()
                  ?.includes(onKeyPressSearchValue.toLowerCase()) ||
                faq?.faqAnswer?.answer
                  ?.toLowerCase()
                  ?.includes(onKeyPressSearchValue.toLowerCase())
              ) {
                finalFilteredProjectFaqs.push(category);
              }
            });
          }
        });
        setProjectFaqsData(finalFilteredProjectFaqs);
      };
      filterProjectFaqs();
    } else {
      const searchParams = {
        typeOfFAQ: FaqConstants.General_FAQs,
        searchKey: onKeyPressSearchValue,
      };

      const categoryParams = {
        typeOfFAQ: FaqConstants.General_FAQs,
        category: onKeyPressSearchValue,
      };

      // Filtering Unique Category Names
      const getCategoryName = categoryList?.map((item: any) => item?.name);
      const uniqueArray = getCategoryName?.filter((item: any, pos: any) => {
        return getCategoryName?.indexOf(item) == pos;
      });

      const categorySearch = uniqueArray?.find((element: any) => {
        if (
          element?.toUpperCase()?.includes(onKeyPressSearchValue?.toUpperCase())
        ) {
          return true;
        }
      });

      if (categorySearch) {
        getFaqsList(categoryParams);
      } else {
        getFaqsList(searchParams);
      }
    }
  }, [onKeyPressSearchValue, location?.state?.projectId]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleOnChangeSearchKey = (e: any) => {
    setFaqSearchKey(e.target.value);
  };

  const handleOnEnterKeyPress = (event: any) => {
    if (event.code === KeyCodeTypeEnum.EnterKey) {
      setOnKeyPressSearchValue(faqSearchKey);
    } else if (isMediumScreen) {
      setOnKeyPressSearchValue(faqSearchKey);
    }
  };

  const searchKeyPress = () => {
    setOnKeyPressSearchValue(faqSearchKey);
  };

  const clearSearchKey = () => {
    setFaqSearchKey("");
    setOnKeyPressSearchValue("");
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  let previousFaqIndexNameValue = "";

  const scrollToElement: any = (elementId: string) => {
    const documentObject: any = document;
    const targetedElement: any =
      documentObject?.getElementById(elementId).offsetTop;
    window.scroll(0, targetedElement - 50);
  };

  return (
    <Box mb={6}>
      <section className={styles["faq-section"]}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item sm={6} lg={6} md={6} xl={6} xs={6}>
            <Stack
              spacing={1}
              justifyContent={"start"}
              alignItems={"center"}
              textAlign={"start"}
            >
              <KeyboardArrowLeftIcon
                onClick={handleNavigateBack}
                fontSize={"medium"}
                className={styles["left-arrow"]}
              />
              <Typography className={styles["section-heading-name"]}>
                {location?.state?.projectId
                  ? location?.state?.launchName
                  : "FAQs"}
              </Typography>
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            lg={4}
            md={4}
            xl={3}
            justifyContent={"center"}
            textAlign={"center"}
            className={styles["search-box-content"]}
          >
            <MuiTextField
              className={styles["search-field"]}
              size={isMobileScreen ? "small" : "medium"}
              placeholder="Search FAQs"
              value={faqSearchKey}
              onChange={handleOnChangeSearchKey}
              onKeyPress={handleOnEnterKeyPress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="search"
                      onClick={searchKeyPress}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    {faqSearchKey.length > 0 ? (
                      <IconButton
                        aria-label="cancel"
                        onClick={clearSearchKey}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        <ClearIcon />
                      </IconButton>
                    ) : (
                      <div></div>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        {isCategoriesLoading || isprojectFaqsLoading ? (
          <Loader />
        ) : (
          <>
            {categoryList?.length > 0 && (
              <div className={styles["categories-section"]}>
                <Typography className={styles["category-heading"]}>
                  Categories
                </Typography>

                <div className={styles["carousel-content"]}>
                  <MantineCarousel
                    slideSize="10%"
                    slidesToScroll={1}
                    align={"start"}
                    slideGap={"xl"}
                    draggable
                    controlSize={40}
                  >
                    <div style={{ marginLeft: "20px" }}></div>
                    {categoryList
                      ?.filter((element: any) => element?.faqs?.length > 0)
                      ?.map((item: any, categoryIndex: any) => {
                        return (
                          <Carousel.Slide key={categoryIndex}>
                            <Box
                              className={styles["carousel-box"]}
                              onClick={() => scrollToElement(item?.name)}
                            >
                              <Item className={styles["carousel-item"]}>
                                {item?.name}
                              </Item>
                            </Box>
                          </Carousel.Slide>
                        );
                      })}
                  </MantineCarousel>
                </div>
              </div>
            )}

            {location?.state?.projectId && projectFaqsData?.length > 0 ? (
              <div>
                {projectFaqsData?.map((item: any, projectFaqIndex: number) => (
                  <div
                    key={projectFaqIndex}
                    className={styles["category-details-item"]}
                  >
                    <Typography className={styles["category-heading"]}>
                      {item?.name}
                    </Typography>
                    {item?.faqs?.map((faq: any, faqIndex: number) => (
                      <Accordion
                        key={faqIndex}
                        expanded={expanded === `panel${faq?.id}`}
                        onChange={handleChange(`panel${faq.id}`)}
                      >
                        <AccordionSummary
                          aria-controls={`panel${faq?.id}-content`}
                          id={`panel${faq?.id}-header`}
                        >
                          <Typography
                            className={styles["accordion-summary-text"]}
                            variant="body1"
                          >
                            {faq?.faqQuestion?.question}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          className={styles["accordion-details-text"]}
                        >
                          {faq?.faqAnswer?.answer}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                ))}
              </div>
            ) : !location?.state?.projectId && faqsData?.length > 0 ? (
              <div>
                {faqsData?.map((item: any, index: any) => {
                  let currentValue = item?.category?.name;
                  let showDetails = false;
                  if (currentValue !== previousFaqIndexNameValue) {
                    showDetails = true;
                    previousFaqIndexNameValue = currentValue;
                  } else {
                    showDetails = false;
                  }
                  return (
                    <div className={styles["category-details-item"]}>
                      {showDetails && (
                        <Typography
                          id={currentValue}
                          className={styles["category-heading"]}
                        >
                          {currentValue}
                        </Typography>
                      )}

                      <Accordion
                        key={index}
                        expanded={expanded === `panel${item?.id}`}
                        onChange={handleChange(`panel${item.id}`)}
                      >
                        <AccordionSummary
                          aria-controls={`panel${item?.id}-content`}
                          id={`panel${item?.id}-header`}
                        >
                          <Typography
                            className={styles["accordion-summary-text"]}
                            variant="body1"
                          >
                            {item?.faqQuestion?.question}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          className={styles["accordion-details-text"]}
                        >
                          {item?.faqAnswer?.answer}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
              </div>
            ) : (
              <Box mt={5} textAlign={"center"}>
                <Typography>No FAQs Found</Typography>
              </Box>
            )}
          </>
        )}
      </section>
    </Box>
  );
};

export default FaqSection;
