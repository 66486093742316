import { useMemo } from "react";

export const useMemoizedValue = (array: any[], totalCount: number) => {
  const slicedData = useMemo(() => {
    if (!!totalCount) {
      return array?.slice(0, totalCount);
    } else {
      return array;
    }
  }, [array, totalCount]);
  return [...slicedData];
};
