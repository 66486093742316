import { Stack, Typography, Paper, IconButton } from "@mui/material";
import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";
import { Fragment } from "react";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";

const ProjectFAQ = ({ projectFAQs, project, isSmallScreen }: any) => {
  const navigate = useNavigate();

  const handleNavigateToFaqs = (faqId?: any) => {
    navigate(RouteConfigurations.faqs, {
      state: { projectId: project?.id, launchName: project?.launchName, faqId },
    });
  };

  return (
    <Fragment>
      <Stack my={"2rem"}>
        <Typography variant={"h5"} fontFamily={"Jost-Medium"}>
          Frequently asked Questions
        </Typography>
        <MuiLoadingButton
          onClick={() => handleNavigateToFaqs()}
          variant="text"
          size="small"
        >
          SEE ALL
        </MuiLoadingButton>
      </Stack>
      {projectFAQs?.map((faqs: Record<string, any>) => (
        <Paper className="document-item" key={faqs?.faqId}>
          <Typography variant={isSmallScreen ? "body2" : "h6"}>
            {faqs?.frequentlyAskedQuestion?.faqQuestion?.question}
          </Typography>
          <IconButton
            color="inherit"
            size={isSmallScreen ? "small" : "large"}
            onClick={() => handleNavigateToFaqs(faqs?.faqId)}
          >
            <EastIcon />
          </IconButton>
        </Paper>
      ))}
    </Fragment>
  );
};

export default ProjectFAQ;
