import { styled, css } from "@mui/material";
import { cardPropsType } from "./MuiCard";

export const CardStyleWrapper = styled("div")<cardPropsType>`
  height: 100%;
  .card-root {
    ${({ buttonwitharrow }) =>
      buttonwitharrow &&
      css`
        display: grid;
      `}
    position: relative;
    background-color: ${({ theme, detail }) =>
      detail?.isSoldOut ? "rgba(0, 0, 0, 0.7)" : theme.palette.common.white};
    box-shadow: ${({ disableshadow }) =>
      disableshadow ? "none" : "0 7px 17px 0 rgba(30, 30, 33, 0.08)"};
    padding: 0.5rem;
    & .card-media-style {
      border-radius: 15px;
      border: none;
    }
    & .card-action-section {
      padding: 0;
      justify-content: center;
    }
    & .MuiCardContent-root {
      ${({ buttonwitharrow }) =>
        buttonwitharrow &&
        css`
          padding: 0;
        `}
      & .MuiCardActionArea-root {
        & .MuiTypography-root,
        .MuiIconButton-root {
          ${({ theme, detail }) =>
            detail?.isSoldOut &&
            css`
              color: ${theme.palette.common.white};
            `}
        }
      }
      & .MuiCardActionArea-focusHighlight {
        background: transparent;
      }
      & .child-content p,
      .MuiIconButton-root svg path {
        color: ${({ theme, detail }) =>
          detail?.isSoldOut
            ? theme.palette.common.white
            : theme.palette.common.black};
        fill: ${({ theme, detail }) =>
          detail?.isSoldOut
            ? theme.palette.common.white
            : theme.palette.common.black};
      }
    }
    & .MuiCardHeader-root {
      padding: 0.5rem;
      & .MuiCardHeader-content {
        & .MuiCardHeader-title {
          display: -webkit-box;
          max-width: 100%;
          /* -webkit-line-clamp: 1; */
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      ${({ buttonwitharrow }) =>
        buttonwitharrow &&
        css`
          position: absolute;
          top: 1rem;
          left: 0rem;
          width: 100%;
        `}
    }
    ${({ theme }) => theme.breakpoints.down("sm")} {
      padding: 0.3rem;
    }
  }
`;

export const OverlayContentStyleWrapper = styled("div")<cardPropsType>`
  & .overlay-container {
    display: grid;
    height: 30vh;
    align-items: flex-end;
    border-radius: 10px;
    background: url(${({ bgimgsrc }) => bgimgsrc}) center / cover no-repeat;
    opacity: ${({ detail }) => (detail?.isSoldOut ? 0.3 : 1)};
    & .address-detail-section {
      backdrop-filter: blur(12.3px);
      background-color: rgba(0, 0, 0, 0.36);
      & .line-ellipsis {
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    & .price-detail-section {
      padding: 0.5rem 1rem;
      backdrop-filter: blur(12.3px);
      background-color: rgba(0, 0, 0, 0.36);
      ${({ theme }) => theme.breakpoints.down("sm")} {
        padding: 1rem 0.5rem;
      }
    }
    & .estimate-badge {
      border-radius: 20px 0 0 20px;
      background-color: rgba(0, 0, 0, 0.36);
      & .MuiChip-icon,
      .MuiChip-label {
        padding-left: 5px;
        color: ${({ theme }) => theme.palette.success.light};
      }
      & .MuiChip-deleteIcon:hover {
        color: ${({ theme }) => theme.palette.common.white};
      }
    }
    & .chip-section {
      align-self: flex-start;
      & .timer-chip {
        visibility: hidden;
        margin: 0.5rem 0 0 0.5rem;
      }
      & .view-count-chip {
        margin: 0.5rem 0.5rem 0 0;
      }
      & .view-count-chip,
      .timer-chip {
        min-width: 15%;
        backdrop-filter: blur(15.8px);
        background-color: rgba(255, 255, 255, 0.29);
        border-radius: ${({ theme }) => theme.shape.borderRadius}px;
        & .MuiChip-icon {
          color: ${({ theme }) => theme.palette.common.white};
        }
      }
    }
  }
`;
