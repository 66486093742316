const sortArray = (items: [any], sortKey: any) => {
  const filteredItems = items.filter((item) => {
    if (item !== null) {
      return item;
    }
  });

  return filteredItems.sort((a, b) => a[sortKey] - b[sortKey]);
};

export { sortArray };
