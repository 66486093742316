import { Avatar, Grid, Typography } from "@mui/material";
import MediaAssets from "assets";
import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";

const NoPortfolio = () => {
  const navigate = useNavigate();
  const IvestmentIcon = () => (
    <Avatar
      sx={{ width: 15, height: 15 }}
      src={MediaAssets.ic_investments_active}
    />
  );
  return (
    <Grid container textAlign={"center"} justifyContent={"center"}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Avatar
          src={MediaAssets.ic_no_project}
          sx={{ width: "100%", height: "50vh" }}
        />
        <Typography variant="h5" fontFamily={"Jost-Light"}>
          Add your first Project
        </Typography>
        <Typography variant="body2" margin={"1rem 0 2rem"}>
          You can manage your invested project at the tip of your finger.
        </Typography>
        <MuiLoadingButton
          startIcon={<IvestmentIcon />}
          onClick={() => navigate(RouteConfigurations.investments)}
        >
          Explore New Investment Projects
        </MuiLoadingButton>
      </Grid>
    </Grid>
  );
};

export default NoPortfolio;
