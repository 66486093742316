import { Carousel, Embla, useAnimationOffsetEffect } from "@mantine/carousel";
import {
  Typography,
  useTheme,
  useMediaQuery,
  Dialog,
  IconButton,
} from "@mui/material";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import MuiCard from "components/mui-card/MuiCard";
import { useDispatch, useSelector } from "react-redux";
import { InsightAction } from "redux-container/insights-redux/InsightsRedux";
import { CloseIcon } from "pages/home/share-dialog/ShareDialog";
import { useState } from "react";

const actionDispatch = (dispatch: any) => {
  return {
    setInsightsState: (key: any, data: any) =>
      dispatch(InsightAction.setInsightsState(key, data)),
  };
};

const InsightView = () => {
  const TRANSITION_DURATION = 200;
  const { setInsightsState } = actionDispatch(useDispatch());
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isXlargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const { insightDetailIndex, isDialogOpen, insightsData } = useSelector(
    (state: any) => ({
      isDialogOpen: state?.insights?.isDialogOpen,
      insightsData: state?.insights?.insightsData,
      insightDetailIndex: state?.insights?.insightDetailIndex,
    })
  );
  const [embla, setEmbla] = useState<Embla | null>(null);
  useAnimationOffsetEffect(embla, TRANSITION_DURATION);

  return (
    <Dialog
      hideBackdrop
      PaperProps={{
        sx: {
          backgroundColor: isSmallScreen ? "common.white" : "transparent",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        },
        elevation: 0,
        square: true,
      }}
      maxWidth={"md"}
      fullWidth
      fullScreen={isSmallScreen}
      open={insightsData !== null && insightsData?.length && isDialogOpen}
      onClose={() => setInsightsState("isDialogOpen", false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      transitionDuration={TRANSITION_DURATION}
    >
      <MantineCarousel
        align={"end"}
        containermargin={0}
        controlsOffset={0}
        slideGap={0}
        getEmblaApi={setEmbla}
        dragFree={false}
        initialSlide={insightDetailIndex}
      >
        {insightsData?.map((insight: typeof insightsData[0]) => (
          <Carousel.Slide key={insight.id}>
            <MuiCard
              sx={{
                "&&&": {
                  width: isSmallScreen ? "auto" : "85%",
                  margin: "auto",
                  height: "100%",
                  borderRadius: 0,
                  padding: "0.5rem 2rem",
                },
              }}
              disableshadow={true}
              customheight={isXlargeScreen ? 300 : 200}
              cardtitle={insight?.displayTitle}
              titleTypographyProps={{
                variant: "body1",
                fontFamily: "Jost-Medium",
              }}
              src={insight?.insightsMedia[0]?.media?.value?.url}
              componenttype={
                insight?.insightsMedia[0]?.media?.value?.mediaType === "VIDEO"
                  ? "iframe"
                  : "img"
              }
              action={
                <IconButton
                  sx={{ marginTop: isXlargeScreen ? "0.5rem" : "auto" }}
                  onClick={() => setInsightsState("isDialogOpen", false)}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              <Typography
                component={"div"}
                variant="caption"
                color={"grey.A400"}
                fontFamily={"Jost-Light"}
                dangerouslySetInnerHTML={{
                  __html: insight?.insightsMedia[0]?.description,
                }}
              ></Typography>
            </MuiCard>
          </Carousel.Slide>
        ))}
      </MantineCarousel>
    </Dialog>
  );
};

export default InsightView;
