import { useEffect, useState } from "react";
import {
  Grid,
  Stack,
  IconButton,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  useJsApiLoader,
  GoogleMap,
  MarkerF,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { Constants } from "constants/Constants";
import { useLocation, useNavigate } from "react-router-dom";
import LocationInfrastructureItem from "pages/investment-details/location-infrastructure/LocationInfrastructureItem";
import styles from "./InvestmentLocationMap.module.css";
import MediaAssets from "assets";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

type GeoLocation = {
  lat: number;
  lng: number;
  icon?: any;
};

const InvestmentLocationMap = () => {
  const navigate = useNavigate();

  const { address, investmentDetails, crmProject } = useSelector(
    (state: any) => ({
      address:
        state?.investmentDetails?.investmentDetails?.projectContent?.address,
      investmentDetails: state?.investmentDetails?.investmentDetails,
      crmProject:
        state?.investmentDetails?.investmentDetails?.projectContent?.crmProject,
    })
  );

  const [investmentLocation, setInvestmentLocation] = useState<GeoLocation>();
  const [infraLocation, setInfraLocation] = useState<GeoLocation>();
  const [directions, setDirectionsResponse] = useState(null);
  const [selectedLatitude, setSelectedLatitude] = useState("");
  const [selectedInfraName, setSelectedInfraName] = useState("");
  const [markers, setMarkers] = useState<GeoLocation[]>([]);
  const [kilometerArray, setKilometerArray] = useState<any>([]);
  const [locationDuration, setLocationDuration] = useState<any>([]);

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const location: any = useLocation();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: Constants.GOOGLE_MAP_KEY,
  });

  const mapContainerStyle = {
    width: "100%",
    height: "500px",
  };

  const origin = {
    lat: 19.021651,
    lng: 73.36292915,
  };

  const destination = {
    lat: 19.028657,
    lng: 73.318184,
  };

  useEffect(() => {
    let updatedValue = {};
    updatedValue = {
      lat: Number(crmProject?.lattitude),
      lng: Number(crmProject?.longitude),
    };
    setInvestmentLocation((investmentLocation: any) => ({
      ...investmentLocation,
      ...updatedValue,
    }));
  }, []);

  useEffect(() => {
    if (infraLocation !== undefined) {
      changeDirection();
    }
  }, [infraLocation]);

  useEffect(() => {
    if (directions !== null) {
      setMarkers([]);
      if (infraLocation) {
        setMarkers((current) => [
          ...current,
          {
            lat: Number(crmProject?.lattitude),
            lng: Number(crmProject?.longitude),
            icon: MediaAssets.ic_source_location,
          },
        ]);
        setMarkers((current) => [
          ...current,
          {
            lat: infraLocation?.lat,
            lng: infraLocation?.lng,
            icon: MediaAssets.ic_destination_location,
          },
        ]);
      }
    }
  }, [directions]);

  const calculateDistance = () => {
    const distanceMatrixService: any = new google.maps.DistanceMatrixService();
    let kilometerList: any = [];
    let locationDurationList: any = [];
    let currentCoordinate = {
      lat: Number(investmentLocation?.lat),
      lng: Number(investmentLocation?.lng),
    };

    investmentDetails?.projectContent?.locationInfrastructure?.values.map(
      (item: any, itemIndex: any) => {
        let destinationCoordinate = {
          lat: Number(item?.latitude),
          lng: Number(item?.longitude),
        };

        let matrixOptions: any = {
          origins: [currentCoordinate],
          destinations: [destinationCoordinate],
          travelMode: "DRIVING",
        };

        distanceMatrixService
          .getDistanceMatrix(matrixOptions)
          .then((response: any) => {
            kilometerList.push({
              id: itemIndex + 1, // setting index as id to have unique elements.
              value: response?.rows[0]?.elements[0]?.distance?.text,
            });
            locationDurationList.push({
              id: itemIndex + 1,
              text: response?.rows[0]?.elements[0]?.duration?.text?.includes(
                "hours"
              )
                ? response?.rows[0]?.elements[0]?.duration?.text?.replace(
                    "hours",
                    "hrs"
                  )
                : response?.rows[0]?.elements[0]?.duration?.text,
            });
            if (!!response?.rows[0]?.elements[0]?.distance?.text) {
              setKilometerArray(kilometerList);
            }
            if (!!response?.rows[0]?.elements[0]?.duration?.text) {
              setLocationDuration(locationDurationList);
            }
            return;
          });
      }
    );
  };

  useEffect(() => {
    if (investmentLocation && isLoaded) {
      calculateDistance(); // this function will collect all the km string by calling google map distance matrix method.
    }
    if (location?.state?.selectedInfra && investmentLocation && isLoaded) {
      onInfraItemClick(location?.state?.selectedInfra);
    }
  }, [investmentLocation, isLoaded]);

  const onInfraItemClick = (infra: any) => {
    setSelectedLatitude(infra.latitude);
    setSelectedInfraName(infra.name);
    setDirectionsResponse(null);
    if (infra) {
      setInfraLocation(undefined);

      let updatedValue = {};
      updatedValue = {
        lat: Number(infra?.latitude),
        lng: Number(infra?.longitude),
      };
      setInfraLocation((infraLocation: any) => ({
        ...infraLocation,
        ...updatedValue,
      }));
    }
  };

  const changeDirection = () => {
    setDirectionsResponse(null);
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: investmentLocation ? investmentLocation : origin,
        destination: infraLocation ? infraLocation : destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result: any, status: any) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirectionsResponse(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  if (!isLoaded) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <Grid container justifyContent={"space-between"}>
        <Grid
          item
          xs={11.9}
          sm={11.9}
          md={6.9}
          lg={8.4}
          xl={8.9}
          style={{ paddingTop: 0 }}
        >
          <div className={styles["map-holder"]}>
            <div style={{ position: "absolute", top: 7, left: 10, zIndex: 1 }}>
              <IconButton
                onClick={handleNavigateBack}
                className={styles["left-icon"]}
              >
                <KeyboardArrowLeftIcon
                  sx={{ backgroundColor: "white" }}
                  fontSize={isSmallerScreen ? "medium" : "large"}
                />
              </IconButton>
            </div>

            <Box
              style={{
                borderRadius: "10px",
                maxWidth: "100%",
                maxHeight: "100%",
                overflow: "hidden",
              }}
            >
              <GoogleMap
                center={investmentLocation ? investmentLocation : undefined}
                zoom={18}
                mapContainerStyle={mapContainerStyle}
                options={{
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                  keyboardShortcuts: false,
                }}
              >
                {investmentLocation && directions === null ? (
                  <MarkerF position={investmentLocation} visible={true} />
                ) : null}

                {markers.map((marker) => (
                  <MarkerF
                    position={{
                      lat: Number(marker.lat),
                      lng: Number(marker.lng),
                    }}
                    visible={true}
                    icon={marker?.icon}
                  />
                ))}

                {directions && (
                  <DirectionsRenderer
                    directions={directions}
                    options={{
                      suppressMarkers: true,
                      polylineOptions: {
                        strokeOpacity: 1,
                        strokeColor: "#676ac0",
                      },
                    }}
                  />
                )}
              </GoogleMap>
            </Box>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={3.5}
          xl={3}
          className={styles["location-holder"]}
        >
          <h2>Around this Project</h2>
          <div className={styles["around-project-content"]}>
            {investmentDetails?.projectContent?.locationInfrastructure?.values?.map(
              (infra: any, index: any) => {
                return (
                  <Stack spacing={2} direction="column" key={index}>
                    <LocationInfrastructureItem
                      infra={infra}
                      onInfraItemClick={onInfraItemClick}
                      selectedInfraLatitude={selectedLatitude}
                      selectedInfraName={selectedInfraName}
                      locationDuration={
                        locationDuration?.filter(
                          (element: any) => element?.id === index + 1
                        )[0]
                      }
                      kilometerString={
                        kilometerArray?.filter(
                          (element: any) => element?.id === index + 1
                        )[0]
                      }
                    />
                  </Stack>
                );
              }
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default InvestmentLocationMap;
