import { Box, Stack } from "@mui/material";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import React, { Fragment, useState, useRef } from "react";
import { useSelector } from "react-redux";
import styles from "./TourismAround.module.css";
import TourismAroundItem from "./TourismAroundItem";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const TourismAround = () => {
  const { tourismAround } = useSelector((state: any) => ({
    tourismAround:
      state?.investmentDetails?.investmentDetails?.projectContent
        ?.opportunityDoc?.tourismAround,
  }));

  const [shouldShowLess, setShouldShowLess] = useState<boolean>(true);

  const handleShowViewMore = () => {
    let countToShow = shouldShowLess ? 2 : tourismAround?.stories?.length;
    return countToShow;
  };

  const ViewButtonContent = (status: boolean) => {
    return(
      <Stack>
        {status ? "VIEW MORE" : "VIEW LESS"}
        <ExpandLessIcon className={status ? styles["collapse-icon"] : ""} />
      </Stack>
    )
  }

  return (
    <div>
      <h2 className={styles["section-heading"]}>
        {tourismAround !== null && tourismAround?.heading !== null
          ? tourismAround.heading
          : "Tourism Around"}
      </h2>
      <div>
        {tourismAround !== null &&
          tourismAround?.stories !== null &&
          tourismAround?.stories.length &&
          tourismAround?.stories
            ?.slice(0, handleShowViewMore())
            ?.map(
              (
                detail: typeof tourismAround.stories[0],
                detailIndex: number
              ) => <TourismAroundItem key={detailIndex} tourism={detail} />
            )}
      </div>
      {tourismAround?.stories?.length > 2 && (
        <Box style={{ textAlign: 'end'}}>
          <MuiStyledButton
            variant="text"
            color="primary"
            className={styles["view-btn"]}
            onClick={() => setShouldShowLess(!shouldShowLess)}
          >
            {ViewButtonContent(shouldShowLess)}
          </MuiStyledButton>
        </Box>
      )}
    </div>
  );
};

export default TourismAround;
