import {
  Drawer,
  Stack,
  IconButton,
  useMediaQuery,
  useTheme,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getWatchListAction } from "redux-container/watch-list/watchListRedux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ProjectSearchDetails from "pages/search/projects/ProjectDetailedCard";
import { useClickOutside } from "@mantine/hooks";
import { Overlay } from "@mantine/core";

const WatchListDrawer = ({ watchListData, sectionTitle }: any) => {
  const dispatch = useDispatch();
  const ref = useClickOutside(
    () => dispatch(getWatchListAction.setWatchListState("isDialogOpen", false)),
    ["mouseup", "mousedown"]
  );
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down("sm"));
  const { isDialogOpen } = useSelector((state: any) => ({
    isDialogOpen: state?.watchList?.isDialogOpen,
  }));

  return (
    <>
      {isDialogOpen && <Overlay opacity={0.2} color="#000" blur={5} />}
      <Drawer
        anchor={"right"}
        open={isDialogOpen}
        PaperProps={{
          ref: ref,
          sx: {
            width: isSmallScreen ? "100%" : breakpoints.values.sm,
          },
        }}
        onClose={() =>
          dispatch(getWatchListAction.setWatchListState("isDialogOpen", false))
        }
      >
        <Stack>
          <Typography variant="h5" fontFamily={"Jost-Medium"} mb={"2rem"}>
            {sectionTitle}
          </Typography>
          <IconButton
            onClick={() =>
              dispatch(
                getWatchListAction.setWatchListState("isDialogOpen", false)
              )
            }
          >
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
        {watchListData?.map((project: any, index: any) => {
          return (
            <ProjectSearchDetails
              key={project?.project ? project?.project?.id : project?.id}
              launchName={
                project?.project
                  ? project?.project?.launchName
                  : project?.launchName
              }
              city={
                project?.project
                  ? project?.project?.address?.city
                  : project?.address?.city
              }
              state={
                project?.project
                  ? project?.project?.address?.state
                  : project?.address?.state
              }
              mediaUrl={
                project?.project
                  ? project?.project?.projectCoverImages?.homePageMedia?.value
                      ?.url
                  : project?.projectCoverImages?.homePageMedia?.value?.url
              }
              appreciation={
                project?.project
                  ? project?.project?.generalInfoEscalationGraph
                      ?.estimatedAppreciation
                  : project?.generalInfoEscalationGraph?.estimatedAppreciation
              }
              startingPrice={
                project?.project
                  ? project?.project?.priceStartingFrom
                  : project?.priceStartingFrom
              }
              startingArea={
                project?.project
                  ? project?.project?.areaStartingFrom
                  : project?.areaStartingFrom
              }
              description={
                project?.project
                  ? project?.project?.shortDescription
                  : project?.shortDescription
              }
              id={project?.project ? project?.project?.id : project?.id}
              isSoldout={
                project?.project
                  ? project?.project?.isSoldOut
                  : project?.isSoldOut
              }
            />
          );
        })}
      </Drawer>
    </>
  );
};

export default WatchListDrawer;
