
export const documentTypeEnum = {
    PAN_CARD: 200100,
    ADDRESS_PROOF: 200101,
    AFS: 200103,
    POWER_OF_ATTORNEY: 200104,
    DEED_OF_CONVEYANCE: 200105,
    "712": 200106,
    ALLOTMENT: 200107,
    CUSTOMER_GUIDELINES: 200108,
    UNVERIFIED_PAN_CARD: 200109,
    UNVERIFIED_ADDRESS_PROOF: 200110
}

export const documentCategoryEnum = {
    KYC: 100100,
    BOOKING: 100102,
    PAYMENT: 100103,
    PROJECT: 100104
}