export const RouteConfigurations = {
  login: "/login",
  home: "/home",
  promise: "/promise",
  myService: "/my-service",
  promiseDetails: "details",
  portfolio: "/portfolio",
  portfolioDetails: "/portfolio/portfolio-details",
  investments: "/investments",
  investmentDetails: "/investments/investment-details",
  investmentOpportunityDoc: "/investments/investment-opportunity-doc",
  investmentLocation: "/investments/investment-location",
  landSkuList: "/investments/land-sku-list",
  profile: "/profile",
  testimonials: "/home/testimonials",
  updates: "/home/updates",
  insights: "/home/insights",
  chat: "/chat",
  investmentSummaryMap:
    "/investments/investment-details/investment-summary-map",
  myAccount: "/profile/my-account",
  securityAndSettings: "/profile/security-and-settings",
  helpCenter: "/profile/help-center",
  facilityManagement: "/facility-management",
  faqs: "/faqs",
  profileFaqs: "/profile-faqs",
  pageNotFound404: "*",
  aboutUs: "/profile/about-us",
  paymentHistory: "/payment-history",
  documentDetails: "/document-details",
  search: "/search",
  projectTimeline: "/project-timeline",
  mediaGallery: "/media-gallery",
  bookingJourney: "/booking-journey",
};
