import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { DocumentsActions } from "redux-container/documents/DocumentsRedux";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";

export function* getDownloadedDocData(api: any, action: any): any {
  try {
    const response = yield call(api.downloadDoc, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(DocumentsActions.getDownloadDocDataSuccess(response.data));
    } else {
      yield put(DocumentsActions.getDownloadDocDataFailure(response.data));
    }
  } catch (error) {
    yield put(DocumentsActions.getDownloadDocDataFailure(error));
  }
};

export function* docUpload(api: any, action: any): any {
  try {
    const response = yield call(api.docUpload, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(DocumentsActions.docUploadSuccess(response.data));
    } else {
      yield put(DocumentsActions.docUploadFailure(response.data));
    }
  } catch (error) {
    yield put(DocumentsActions.docUploadFailure(error));
  }
}

