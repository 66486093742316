import {
  Avatar,
  Divider,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PageHeader from "components/page-header/PageHeader";
import { Fragment, useEffect, useMemo } from "react";
import InvestmentCarouselSection from "./portfolio-sub-components/InvestmentCarouselSection";
import InvestmentDetailCards from "./portfolio-sub-components/InvestmentDetailCards";
import NoDetailCard from "./portfolio-sub-components/NoDetailCard";
import ReferralSection from "pages/home/referral-section/ReferralSection";
import MyWatchList from "./portfolio-sub-components/MyWatchList";
import { useDispatch, useSelector } from "react-redux";
import { getWatchListAction } from "redux-container/watch-list/watchListRedux";
import { getPortfolioAction } from "redux-container/portfolio-redux/PortfolioRedux";
import Loader from "components/loader/Loader";
import NoPortfolio from "./NoPortfolio";
import { useHandleCardNavigation } from "utils/helperFunctions";
import { RouteConfigurations } from "routes/RouteConfigurations";
import EstimationCard from "./portfolio-sub-components/PercentageEstimateCard";
import { useNavigate } from "react-router-dom";

export interface IPortfolioComponentProps {
  [key: string]: any;
}

enum InvestmentStatus {
  completed = "Completed",
  ongoing = "Ongoing",
}

const actionDispatch = (dispatch: any) => {
  return {
    getInvestmentSummaryRequest: () =>
      dispatch(getPortfolioAction.getInvestmentSummaryRequest()),
    getWatchListRequest: () =>
      dispatch(getWatchListAction.getWatchListRequest()),
  };
};

const Portfolio = () => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { getInvestmentSummaryRequest, getWatchListRequest } = actionDispatch(
    useDispatch()
  );
  const navigate = useNavigate();

  const { handleNavigation } = useHandleCardNavigation();
  const { investmentSummary, watchListData, isLoading } = useSelector(
    (state: any) => ({
      isLoading: state?.portfolio?.isLoading,
      investmentSummary: state?.portfolio?.investmentSummary,
      watchListData: state?.watchList?.watchListData,
    })
  );

  const filterProject = () => {
    const completedProject = investmentSummary?.projects.filter(
      (item: typeof investmentSummary.projects[0]) =>
        item.investment?.isBookingComplete
    );
    const ongoingProject = investmentSummary?.projects.filter(
      (item: typeof investmentSummary.projects[0]) =>
        !item.investment?.isBookingComplete
    );
    return { completedProject, ongoingProject };
  };

  const filteredProjects = useMemo(
    () => filterProject(),
    [investmentSummary?.projects]
  );

  const handleCardClick = (projectName: InvestmentStatus) => {
    if (projectName === InvestmentStatus.completed) {
      navigate(RouteConfigurations.bookingJourney, {
        state: {
          investment: filteredProjects?.ongoingProject[0]?.investment?.id,
          project:
            filteredProjects?.ongoingProject[0]?.project?.crmLaunchPhase
              ?.launchName,
          crmInventoryName:
            filteredProjects?.ongoingProject[0]?.investment?.crmInventory?.name,
          isBookingComplete:
            filteredProjects?.ongoingProject[0]?.investment?.isBookingComplete,
        },
      });
    } else {
      navigate(RouteConfigurations.investments);
    }
  };

  useEffect(() => {
    let cancelEffect = true;
    if (cancelEffect) {
      getInvestmentSummaryRequest();
      getWatchListRequest();
    }
    return () => {
      cancelEffect = false;
    };
  }, []);

  const scrollToElement: any = (elementId: string) => {
    const documentObject: any = document;
    const targetedElement: any =
      documentObject?.getElementById(elementId).offsetTop;
    window.scroll(0, targetedElement - 50);
  };
  
  return isLoading ? (
    <Loader />
  ) : investmentSummary?.isInvestor ? (
    <Fragment>
      <PageHeader
        title={"My Portfolio"}
        subtitle={"Manage your invested project at the tip your finger."}
        shouldbackbuttonvisible={false}
      />
      <Grid
        container
        rowGap={isSmallScreen ? "2rem" : "3rem"}
        justifyContent={"space-around"}
      >
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Typography variant="h5" pb={"1rem"}>
            {"Summary - Completed Investments"}
          </Typography>
          {!!investmentSummary?.summary?.completed?.count ? (
            <InvestmentDetailCards
              investment={investmentSummary?.summary?.completed}
              iea={investmentSummary?.summary?.iea}
              onClick={() => scrollToElement(InvestmentStatus.completed)}
            />
          ) : (
            <NoDetailCard
              projectType={"Completed"}
              projectDetails={filteredProjects?.ongoingProject[0]}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Typography variant="h5" pb={"1rem"}>
            {"Summary - Ongoing Investments"}
          </Typography>
          {!!investmentSummary?.summary?.ongoing?.count ? (
            <InvestmentDetailCards
              investment={investmentSummary?.summary?.ongoing}
              onClick={() => scrollToElement(InvestmentStatus.ongoing)}
            />
          ) : (
            <NoDetailCard projectType={"Ongoing"} />
          )}
        </Grid>
        {!!investmentSummary?.summary?.completed?.count && (
          <>
            <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
              <InvestmentCarouselSection
                projectName={"Completed"}
                investmentDetails={filteredProjects?.ongoingProject[0]}
                projectData={filteredProjects?.completedProject}
                summarydetails={investmentSummary?.summary}
                InvestmentStatus={InvestmentStatus}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
          <InvestmentCarouselSection
            projectName={"Ongoing"}
            InvestmentStatus={InvestmentStatus}
            projectData={filteredProjects?.ongoingProject}
            summarydetails={investmentSummary?.summary}
          />
        </Grid>
        {investmentSummary?.summary?.completed?.count ? null : (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={11} xl={11}>
              <Typography
                variant="h5"
                padding={isSmallScreen ? "0" : "1rem 0 1.5rem"}
              >
                {`Completed Investments`}
              </Typography>
              <EstimationCard
                isinvestdetail={true}
                cardtype="Invest Now"
                subtext="You are almost there.Complete all your pending payments and enjoy the benefits of NGL investments."
                oncardclick={() =>
                  navigate(RouteConfigurations.portfolioDetails, {
                    state: {
                      projects: { ...filteredProjects.ongoingProject[0] },
                    },
                  })
                }
              />
            </Grid>
          </>
        )}
        {!!investmentSummary?.summary?.ongoing?.count ? (
          !investmentSummary?.summary?.completed?.count ? null : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider />
            </Grid>
          )
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={11} xl={11} mt={"-1rem"}>
            <EstimationCard
              isinvestdetail={true}
              cardtype="Invest Now"
              subtext="You are almost there.Complete all your pending payments and enjoy the benefits of NGL investments."
              oncardclick={() => handleCardClick(InvestmentStatus.ongoing)}
            />
          </Grid>
        )}
        {investmentSummary?.pageManagement?.data?.page
          ?.isPromotionAndOfferActive ? (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Avatar
              src={
                investmentSummary?.pageManagement?.data?.page
                  ?.promotionAndOffersMedia?.value?.url
              }
              sx={{ width: "100%", height: "100%", cursor: "pointer" }}
              onClick={() =>
                handleNavigation(
                  RouteConfigurations.investmentDetails,
                  investmentSummary?.pageManagement?.data?.page
                    ?.promotionAndOffersProjectContentId
                )
              }
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
          <MyWatchList watchListData={watchListData} />
        </Grid>
        <Grid item xs={12} sm={12} md={11} lg={12} xl={12}>
          <ReferralSection />
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <NoPortfolio />
  );
};

export default Portfolio;
