import { Divider, Grid, Typography } from "@mui/material";
import MediaAssets from "assets";
import { useSelector } from "react-redux";
import styles from "./AboutProject.module.css";

const AboutProject = () => {
  const { aboutProjects } = useSelector((state: any) => ({
    aboutProjects:
      state?.investmentDetails?.investmentDetails?.projectContent
        ?.opportunityDoc?.aboutProjects,
  }));

  return (
    <div className={styles["about-project-holder"]}>
      <Grid
        container
        alignItems={"center"}
        className={styles["section-heading"]}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            variant="h5"
            className="newGenerationLand-text"
            component={Divider}
            color={"primary.main"}
          >
            {aboutProjects?.heading !== null
              ? aboutProjects?.heading
              : "About Project"}
          </Typography>
        </Grid>
      </Grid>

      <div className={styles["about-project-card"]}>
        <img src={aboutProjects?.media?.value?.url} alt="" />
        <p dangerouslySetInnerHTML={{ __html: aboutProjects?.description }}></p>
      </div>
    </div>
  );
};

export default AboutProject;
