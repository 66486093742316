import { Dialog, DialogContent, DialogProps, Typography } from "@mui/material";
import { setTroubleSigninState } from "redux-container/login/trouble-signingin-redux/TroubleSigninginRedux";

const NumberListDialog = (props: DialogProps) => {
  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      disableEscapeKeyDown={true}
      sx={{ textAlign: "left" }}
      onClick={() => setTroubleSigninState("isCountryCodeDialogOpen", false)}
      {...props}
    >
      <DialogContent>
        <Typography sx={{ cursor: "pointer" }}>+91 | IND</Typography>
      </DialogContent>
    </Dialog>
  );
};

export default NumberListDialog;
