import Grid from "@mui/material/Grid";
import LineChart from "components/line-chart/LineChart";
import { useSelector } from "react-redux";
import styles from "./ExpectedGrowth.module.css";

const ExpectedGrowth = () => {
  const { graphDetails } = useSelector((state: any) => ({
    graphDetails:
      state?.investmentDetails?.investmentDetails?.projectContent
        ?.opportunityDoc?.escalationGraph,
  }));

  return (
    <div className={styles["expected-growth-holder"]}>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}
          className={styles["expected-growth-info"]}
        >
          <h2>{graphDetails.title ? graphDetails.title : "Expected Growth"}</h2>
          <p>
            We ensure a completely secure experience for our members by keeping
            track of every single nuance of the ownership journey to put your
            mind at ease.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <LineChart
            graphheight={350}
            showWithContainer={true}
            customizableGraphData={graphDetails}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ExpectedGrowth;
