import styles from "./CurrentInfra.module.css";

const CurrentInfraItem = (infra: any) => {
  return (
    <div className={styles["current-infra-card"]}>
      <div className={styles["image-title-holder"]}>
        <img src={infra?.infra?.media?.value?.url} alt="" />
        <div>
          <p>{infra?.infra?.title}</p>
        </div>
      </div>
      <p dangerouslySetInnerHTML={{ __html: infra?.infra?.description }}></p>
    </div>
  );
};

export default CurrentInfraItem;
