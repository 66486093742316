import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import styles from "./BookingJourneySection.module.css";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import MediaAssets from "assets";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import {
  MuiLoadingButton,
  MuiStyledButton,
} from "components/mui-buttons/MuiButtons";
import { RouteConfigurations } from "routes/RouteConfigurations";
import MyServicesDialog from "pages/profile/my-services-dialog/MyServicesDialog";

interface IJourneyBlocksInterface {
  isMilestoneSuccess?: boolean;
  milestoneName?: string;
  applicationDate?: string;
  milestoneSubName?: string;
  showPaymentDetails?: boolean;
  showPaymentDetailsText?: string;
  viewText?: any;
  onViewClick?: any;
  isDividerRequired?: boolean;
  children?: any;
  childrenDivider?: boolean;
  isDisabled?: any;
  isViewReceiptDisabled?: boolean;
  showFMBtn?: boolean;
  isFMBtnDisabled?: boolean;
}

const JourneyBlocks = (props: IJourneyBlocksInterface) => {
  const {
    isMilestoneSuccess,
    milestoneName,
    applicationDate,
    milestoneSubName,
    showPaymentDetails,
    showPaymentDetailsText,
    viewText,
    onViewClick,
    isDividerRequired,
    children,
    childrenDivider,
    isDisabled,
    isViewReceiptDisabled,
    showFMBtn,
    isFMBtnDisabled,
  } = props;

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [openFMDialog, setOpenFMDialog] = useState(false);

  const handleShowFMDialog = () => {
    setOpenFMDialog(true);
  };

  const handleCloseFMDialog = () => {
    setOpenFMDialog(false);
  };
  return (
    <Fragment>
      <MyServicesDialog open={openFMDialog} handleclose={handleCloseFMDialog} />
      <Stack spacing={2} ml={0.2} mt={1} mb={1}>
        <Stack
          justifyContent={"start"}
          alignItems={"start"}
          flexDirection={isSmallerScreen ? "column" : "row"}
          spacing={isSmallerScreen ? 5 : 2}
        >
          <Stack alignItems={"start"} spacing={isSmallerScreen ? 1 : 2}>
            <IconButton>
              {isMilestoneSuccess ? (
                <CircleRoundedIcon
                  className={`${styles["small-icon"]} ${styles["circle-outlined"]}`}
                  color="inherit"
                />
              ) : (
                <CircleTwoToneIcon className={styles["small-icon"]} />
              )}
            </IconButton>

            <Typography
              className={
                isDisabled
                  ? `${styles["disabled-text"]} ${styles["typography-sub-title"]} ${styles["line-ellipsis-5"]}`
                  : `${styles["typography-sub-title"]} ${styles["line-ellipsis-5"]}`
              }
            >
              {milestoneName}
            </Typography>

            {applicationDate && (
              <Typography
                className={`${styles["application-date"]} ${styles["typography-sub-title"]} ${styles["line-ellipsis-5"]}`}
              >
                {applicationDate}
              </Typography>
            )}
          </Stack>

          {milestoneSubName && (
            <Typography
              className={`${styles["typography-sub-title"]} ${styles["line-ellipsis-5"]}`}
            >
              {milestoneSubName}
            </Typography>
          )}

          {showPaymentDetails && (
            <Stack spacing={1}>
              <img
                src={
                  isMilestoneSuccess
                    ? MediaAssets.ic_rupee_coin
                    : MediaAssets.ic_rupee_disabled
                }
                className={styles["rupee-coin"]}
              />
              <Typography
                className={`${styles["typography-sub-title"]} ${styles["line-ellipsis-5"]}`}
              >
                {showPaymentDetailsText}
              </Typography>
            </Stack>
          )}
        </Stack>

        <Box className={styles["view-btn-wrapper"]}>
          {viewText && (
            <MuiStyledButton
              onClick={!isDisabled && !isViewReceiptDisabled && onViewClick}
              disabled={isDisabled || isViewReceiptDisabled}
              sx={{ textDecoration: "underline", marginTop: "0.5rem" }}
              variant="text"
              color="primary"
              className={styles["view-btn"]}
            >
              {viewText}
            </MuiStyledButton>
          )}
        </Box>
        {showFMBtn && (
          <MuiLoadingButton
            className={styles["click-here-btn"]}
            onClick={handleShowFMDialog}
            disabled={isFMBtnDisabled}
          >
            Download The App
          </MuiLoadingButton>
        )}
      </Stack>
      <Box style={{ marginLeft: "30px" }}>{children}</Box>
    </Fragment>
  );
};

export default JourneyBlocks;
