import ApiService from "services/ApiService";
import { takeEvery } from "redux-saga/effects";
import { TroubleSigninTypes } from "redux-container/login/trouble-signingin-redux/TroubleSigninginRedux";
import { addTroubleSignin } from "./login/trouble-signin-saga/TroubleSigninSaga";

import { referYourFriendSaga } from "./refer-your-friend-saga/ReferYourFriendSaga";
import { ReferYourFriendTypes } from "redux-container/refer-your-friend/ReferYourFriendRedux";

import { GenerateOtpTypes } from "../redux-container/login/generate-otp/GenerateOtpRedux";
import { generateOtp } from "../sagas/login/generate-otp/GenerateOtpSaga";

import { VerifyOtpTypes } from "../redux-container/login/verify-otp/VerifyOtpRedux";
import { verifyOtp } from "../sagas/login/verify-otp/VerifyOtpSaga";

import { NewUserNameInfoTypes } from "../redux-container/login/new-user-name-info/NewUserNameInfoRedux";
import { setNewUserName } from "../sagas/login/new-user-name-info/NewUserNameInfoSaga";
import {
  getProfilePageData,
  pageManagementSaga,
  promisePageSaga,
} from "./page-saga/PageSaga";
import { getPageTypes } from "redux-container/page-management-redux/PageManagementRedux";
import { InsightsTypes } from "redux-container/insights-redux/InsightsRedux";
import { insightSaga } from "./insight-saga/insightSaga";

import { TestimonialsTypes } from "../redux-container/testimonials/TestimonialsRedux";
import { getTestimonials } from "../sagas/testimonials/TestimonialSaga";

import { UpdatesTypes } from "../redux-container/updates/UpdatesRedux";
import { getUpdates } from "../sagas/updates/UpdatesSaga";

import { NotificationTypes } from "../redux-container/notifications/NotificationsRedux";
import {
  getNotifications,
  updateNotificationStatus,
} from "../sagas/notifications/NotificationsSaga";
import { ProfileTypes } from "redux-container/profile/ProfileRedux";
import {
  getCategories,
  getFaqsList,
  getProfileDetails,
  editProfileUserSettings,
  getMyAccountDetails,
  logout,
  logoutFromAllDevices,
  shareFeedback,
} from "sagas/profile/ProfileSaga";

import {
  getProjectContentsList,
  getProjectContentById,
  getMediaByCategoryAndProjectId,
} from "./project-content/ProjectContentSaga";
import { getProjectContentsTypes } from "redux-container/project-contents/ProjectContents";

import { NewLaunchTypes } from "../redux-container/investments/new-launch/NewLaunchRedux";
import {
  getAllInvestments,
  getInvestmentDetails,
  getMediaGallery,
  getNewLaunch,
  getWatchLists,
  deleteWatchList,
  addToWatchlist,
} from "../sagas/investments/InvestmentsSaga";
import { getSecurityTips } from "./profile/SecurityTipsSaga";
import { SecurityTipsTypes } from "redux-container/profile/SecurityTipsRedux";
import { getActionItemSaga } from "./user-saga/UserSaga";
import { getUserTypes } from "redux-container/user-management/UserManagementRedux";

import { DocumentsTypes } from "redux-container/documents/DocumentsRedux";
import { getDownloadedDocData, docUpload } from "./documents/DocumentsSaga";
import { ChatTypes } from "redux-container/chat-redux/ChatRedux";
import {
  getChat,
  getChatData,
  initiateChat,
  postChatData,
} from "./chat/ChatSaga";
import { EditProfileTypes } from "../redux-container/profile/EditProfileRedux";
import {
  deleteProfilePicture,
  getCities,
  getCountries,
  getStates,
  editProfile,
  updateProfilePicture,
} from "./profile/EditProfileSaga";
import { PrivacyPolicyTypes } from "redux-container/privacy-policy/PrivacyPolicy";
import { getPrivacyPolicySaga } from "./privacy-policy-saga/PrivacyPolicysaga";
import { SearchTypes } from "redux-container/search/SearchRedux";
import { getCustomerSearchData, getAdminSearchData } from "./search/SearchSaga";
import { getWatchListTypes } from "redux-container/watch-list/watchListRedux";
import { getWatchListSaga } from "./watch-list-saga/WatchListSaga";
import { getPortfolioTypes } from "redux-container/portfolio-redux/PortfolioRedux";
import {
  getPortfolioDetailsSaga,
  getPortfolioPreview,
  getPortfolioSummarySaga,
  getBookingJourneyData,
  getProjectFaqsContent,
} from "./portfolio-saga/PortfolioSaga";

import { InvestmentDetailsTypes } from "../redux-container/investments/investment-details/InvestmentDetailsRedux";
import { LandSkuListTypes } from "redux-container/investments/land-sku-list/LandSkuListRedux";
import {
  getLandSkuListSaga,
  postAddInventorySaga,
  postVideoCallSaga,
} from "./investments/LandSkuListSaga";

const api = ApiService.create();

export default function* root() {
  yield takeEvery(GenerateOtpTypes.GENERATE_OTP_REQUEST, generateOtp, api);
  yield takeEvery(VerifyOtpTypes.VERIFY_OTP_REQUEST, verifyOtp, api);
  yield takeEvery(
    NewUserNameInfoTypes.NEW_USER_NAME_INFO_REQUEST,
    setNewUserName,
    api
  );
  yield takeEvery(
    TroubleSigninTypes.ADD_TROUBLE_SIGNIN_REQUEST,
    addTroubleSignin,
    api
  );
  yield takeEvery(
    getPortfolioTypes.GET_PORTFOLIO_PREVIEW_REQUEST,
    getPortfolioPreview,
    api
  );
  yield takeEvery(
    getPortfolioTypes.GET_INVESTMENT_SUMMARY_REQUEST,
    getPortfolioSummarySaga,
    api
  );
  yield takeEvery(
    getPortfolioTypes.GET_PORTFOLIO_DETAILS_REQUEST,
    getPortfolioDetailsSaga,
    api
  );
  yield takeEvery(
    getPortfolioTypes.GET_BOOKING_JOURNEY_REQUEST,
    getBookingJourneyData,
    api
  );
  yield takeEvery(
    getPortfolioTypes.GET_PROJECT_FAQS_REQUEST,
    getProjectFaqsContent,
    api
  );
  yield takeEvery(getPageTypes.GET_PAGES_REQUEST, pageManagementSaga, api);
  yield takeEvery(
    getPageTypes.GET_PROFILE_PAGE_REQUEST,
    getProfilePageData,
    api
  );
  yield takeEvery(getPageTypes.GET_PROMISE_REQUEST, promisePageSaga, api);
  yield takeEvery(
    ReferYourFriendTypes.REFER_YOUR_FRIEND_REQUEST,
    referYourFriendSaga,
    api
  );
  yield takeEvery(InsightsTypes.GET_INSIGHTS_REQUEST, insightSaga, api);

  yield takeEvery(
    TestimonialsTypes.GET_TESTIMONIALS_REQUEST,
    getTestimonials,
    api
  );

  yield takeEvery(UpdatesTypes.GET_UPDATES_REQUEST, getUpdates, api);

  yield takeEvery(
    NotificationTypes.GET_NOTIFICATIONS_REQUEST,
    getNotifications,
    api
  );

  yield takeEvery(
    NotificationTypes.UPDATE_NOTIFICATION_STATUS_REQUEST,
    updateNotificationStatus,
    api
  );

  yield takeEvery(ProfileTypes.GET_ALL_CATEGORIES_REQUEST, getCategories, api);

  yield takeEvery(ProfileTypes.GET_FAQS_LIST_REQUEST, getFaqsList, api);

  yield takeEvery(
    getProjectContentsTypes.GET_PROJECT_CONTENTS_REQUEST,
    getProjectContentsList,
    api
  );

  yield takeEvery(
    getProjectContentsTypes.GET_PROJECT_CONTENT_BY_ID_REQUEST,
    getProjectContentById,
    api
  );

  yield takeEvery(
    getProjectContentsTypes.GET_MEDIA_BY_CATEGORY_AND_PROJECT_ID_REQUEST,
    getMediaByCategoryAndProjectId,
    api
  );

  yield takeEvery(
    ProfileTypes.GET_PROFILE_DETAILS_REQUEST,
    getProfileDetails,
    api
  );

  yield takeEvery(
    ProfileTypes.EDIT_PROFILE_USER_SETTINGS_REQUEST,
    editProfileUserSettings,
    api
  );

  yield takeEvery(
    ProfileTypes.GET_MY_ACCOUNT_DETAILS_REQUEST,
    getMyAccountDetails,
    api
  );

  yield takeEvery(ProfileTypes.LOGOUT_REQUEST, logout, api);
  yield takeEvery(ProfileTypes.LOGOUT_ALL_REQUEST, logoutFromAllDevices, api);

  yield takeEvery(ProfileTypes.SHARE_FEEDBACK_REQUEST, shareFeedback, api);

  yield takeEvery(NewLaunchTypes.GET_NEW_LAUNCH_REQUEST, getNewLaunch, api);
  yield takeEvery(
    NewLaunchTypes.GET_MEDIA_GALLERY_REQUEST,
    getMediaGallery,
    api
  );
  yield takeEvery(
    NewLaunchTypes.GET_ALL_INVESTMENTS_REQUEST,
    getAllInvestments,
    api
  );

  yield takeEvery(
    SecurityTipsTypes.GET_SECURITY_TIPS_REQUEST,
    getSecurityTips,
    api
  );

  yield takeEvery(
    DocumentsTypes.GET_DOWNLOAD_DOC_DATA_REQUEST,
    getDownloadedDocData,
    api
  );

  yield takeEvery(DocumentsTypes.DOC_UPLOAD_REQUEST, docUpload, api);
  yield takeEvery(
    getWatchListTypes.GET_WATCH_LIST_REQUEST,
    getWatchListSaga,
    api
  );
  yield takeEvery(
    getUserTypes.GET_ACTION_ITEMS_REQUEST,
    getActionItemSaga,
    api
  );

  yield takeEvery(ChatTypes.GET_CHAT_REQUEST, getChat, api);
  yield takeEvery(ChatTypes.GET_CHAT_DATA_REQUEST, getChatData, api);
  yield takeEvery(ChatTypes.CHAT_INITIATE_REQUEST, initiateChat, api);
  yield takeEvery(ChatTypes.CHAT_POST_DATA_REQUEST, postChatData, api);
  yield takeEvery(
    SearchTypes.GET_ADMIN_SEARCH_DATA_REQUEST,
    getAdminSearchData,
    api
  );

  yield takeEvery(
    SearchTypes.GET_CUSTOMER_SEARCH_DATA_REQUEST,
    getCustomerSearchData,
    api
  );

  // Edit Profile Sagas
  yield takeEvery(EditProfileTypes.EDIT_PROFILE_REQUEST, editProfile, api);
  yield takeEvery(EditProfileTypes.GET_COUNTRIES_REQUEST, getCountries, api);
  yield takeEvery(EditProfileTypes.GET_STATES_REQUEST, getStates, api);
  yield takeEvery(EditProfileTypes.GET_CITIES_REQUEST, getCities, api);
  yield takeEvery(
    EditProfileTypes.UPDATE_PROFILE_PICTURE_REQUEST,
    updateProfilePicture,
    api
  );
  yield takeEvery(
    EditProfileTypes.DELETE_PROFILE_PICTURE_REQUEST,
    deleteProfilePicture,
    api
  );
  yield takeEvery(
    PrivacyPolicyTypes.GET_PRIVACY_POLICY_REQUEST,
    getPrivacyPolicySaga,
    api
  );

  // Investment Sagas
  yield takeEvery(
    InvestmentDetailsTypes.GET_INVESTMENT_DETAILS_REQUEST,
    getInvestmentDetails,
    api
  );
  yield takeEvery(
    InvestmentDetailsTypes.GET_ALL_WATCHLIST_REQUEST,
    getWatchLists,
    api
  );
  yield takeEvery(
    InvestmentDetailsTypes.DELETE_WATCHLIST_REQUEST,
    deleteWatchList,
    api
  );
  yield takeEvery(
    InvestmentDetailsTypes.ADD_TO_WATCHLIST_REQUEST,
    addToWatchlist,
    api
  );
  yield takeEvery(
    PrivacyPolicyTypes.GET_PRIVACY_POLICY_REQUEST,
    getPrivacyPolicySaga,
    api
  );
  yield takeEvery(
    LandSkuListTypes.GET_LAND_SKU_LIST_REQUEST,
    getLandSkuListSaga,
    api
  );

  yield takeEvery(
    LandSkuListTypes.POST_VIDEO_CALL_REQUEST,
    postVideoCallSaga,
    api
  );

  yield takeEvery(
    LandSkuListTypes.POST_ADD_INVENTORY_REQUEST,
    postAddInventorySaga,
    api
  );
}
