import { Grid, styled } from "@mui/material";

export const ReferralSectionStyleWrapper = styled(Grid)`
  align-items: center;
  justify-content: center;
  & .joy-share-text {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: "Sea-Side";
    padding: 0.5rem 0 1rem;
  }
  & .box-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    & .box-item {
      background-color: ${({ theme }) => theme.palette.grey[100]};
      padding: 1rem 2rem;
      &:first-of-type {
        flex-basis: 75%;
        border-radius: 7px 0 0 7px;
        font: normal 1.3rem "Jost-Regular";
        color: ${({ theme }) => theme.palette.grey[200]};
      }
      &:not(:first-of-type) {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 0.5rem;
        border-radius: 0 7px 7px 0;
        flex-basis: 25%;
        font: normal 1.3rem "Jost-Medium";
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.common.white};
      }
    }
    ${({ theme }) => theme.breakpoints.down("sm")} {
      .box-item {
        padding: 0.5rem;
        &:first-of-type,
        &:not(:first-of-type),
        svg {
          column-gap: 0.3rem;
          font-size: 0.8rem;
        }
      }
    }
  }
`;
