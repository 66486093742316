import { Button, Grid } from "@mui/material";
import MediaAssets from "assets";
import { numDifferentiation } from "utils/helperFunctions";
import styles from "./LandSku.module.css";

interface ILandSkuProps {
  sku: any;
  applyNowSku: (sku: any) => void;
}

const LandSkuItem = ({ sku, applyNowSku }: ILandSkuProps) => {
  const applyNow = () => {
    applyNowSku(sku);
  };

  return (
    <div
      className={`${styles["land-sku-item"]} ${
        sku?.isSoldOut && !sku?.isApplied
          ? styles["sold-out-card"]
          : styles["apply-card"]
      }`}
    >
      <Grid container spacing={2}>
        <Grid item xs={9} sm={9} md={10} lg={10} xl={10}>
          <div className={styles["name-area-holder"]}>
            <h3
              className={`${
                sku?.isSoldOut && !sku?.isApplied
                  ? styles["sold-out-name-text"]
                  : styles["apply-now-name-text"]
              }`}
            >
              {sku?.name}
            </h3>

            <span
              className={`${
                sku?.isSoldOut && !sku?.isApplied
                  ? styles["sold-out-area-text"]
                  : styles["apply-now-area-text"]
              }`}
            >
              {`${sku?.areaRange?.from} - ${sku?.areaRange?.to} Sqft`}
            </span>
          </div>

          <div className={styles["amount-holder"]}>
            <span
              className={`${styles["amount"]} ${
                sku?.isSoldOut && !sku?.isApplied
                  ? styles["sold-out-amount"]
                  : styles["apply-now-amount"]
              }`}
            >{`₹${numDifferentiation(
              sku?.priceRange?.from
            )} - ₹${numDifferentiation(sku?.priceRange?.to)}`}</span>
          </div>

          <div>
            <p
              className={`${styles["description"]} ${
                sku?.isSoldOut && !sku?.isApplied
                  ? styles["sold-out-description"]
                  : styles["apply-now-description"]
              }`}
            >
              {sku?.shortDescription}
            </p>
          </div>
        </Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={2}
          lg={2}
          xl={2}
          className={styles["btn-holder"]}
        >
          {/* 
            //   Sold Out Logic
            if applied (true ) = show applied
            if applied ( false ) & is sold out ( true ) = show soldout
            if applied ( false ) & is sold out ( false ) = show apply now
          */}
          {Boolean(sku?.isApplied) ? (
            <div>
              <img src={MediaAssets.ic_green_tick} alt="" />
              <span>Applied</span>
            </div>
          ) : null}

          {Boolean(sku?.isSoldOut) ? (
            <Button
              className="btn btn-gray"
              sx={{ cursor: "not-allowed" }}
              disableElevation
            >
              Sold Out
            </Button>
          ) : null}

          {!Boolean(sku?.isApplied) && !Boolean(sku?.isSoldOut) ? (
            <Button className="btn btn-dark" onClick={applyNow}>
              Apply Now
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default LandSkuItem;
