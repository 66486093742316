import { useEffect } from "react";
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Styles from "./PrivacyPolicy.module.css";
import { useDispatch, useSelector } from "react-redux";
import { PrivacyPolicyAction } from "../../redux-container/privacy-policy/PrivacyPolicy";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Overlay } from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";

const actionDispatch = (dispatch: any) => {
  return {
    getPrivacyPolicy: (pageType: any) => {
      dispatch(PrivacyPolicyAction.getPrivacyPolicyRequest(pageType));
    },
  };
};

const PrivacyPolicy = (props: any) => {
  const { handleClose } = props;
  const { getPrivacyPolicy } = actionDispatch(useDispatch());
  const ref = useClickOutside(props.setOpenPrivacyPolicyDrawer, [
    "mouseup",
    "mousedown",
  ]);
  const privacyPolicy = useSelector(
    (state: any) => state.privacyPolicy.privacyPolicy.termsAndConditions
  );
  // const isDialogOpen = useSelector(
  //     (state: any) => state.privacyPolicy.isDialogOpen
  // );
  const isLoading = useSelector((state: any) => state.privacyPolicy.isLoading);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getPrivacyPolicy("HOABL");
  }, []);

  const dangerouslySetInnerHTML = { __html: privacyPolicy?.description };

  return (
    <>
      {props.open && (
        <Overlay
          onClick={props.handleClose}
          opacity={0.2}
          color="#000"
          blur={5}
        />
      )}
      <Drawer
        anchor={"right"}
        open={props.open}
        onClose={props.handleClose}
        PaperProps={{
          ref: ref,
          sx: {
            width: {
              md: "50%",
              sm: "100%",
            },
            padding: "20px 15px",
          },
        }}
      >
        <Grid container>
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack spacing={1.5} justifyContent={"start"}>
              {isSmallScreen && (
                <IconButton onClick={handleClose}>
                  <ChevronLeftIcon color={"inherit"} />
                </IconButton>
              )}
              <h2 className={Styles["section-heading"]}>
                Terms & Conditions & Privacy Policy
              </h2>
            </Stack>
            {!isSmallScreen && (
              <IconButton>
                <CloseIcon onClick={handleClose} />
              </IconButton>
            )}
          </Grid>
          <>
            <Box mt={1.5}>
              <Typography
                className={Styles["typography-sub-heading"]}
                style={{ paddingTop: "10px" }}
              >
                {privacyPolicy?.displayName}
              </Typography>
            </Box>
            <Box mt={2}>
              <p
                className={Styles["typography-description"]}
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
              ></p>
            </Box>
          </>
        </Grid>
      </Drawer>
    </>
  );
};

export default PrivacyPolicy;
