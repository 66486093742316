import {
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styles from "./ProjectTimelineCard.module.css";
import CustomTimelineConnector from "./CustomTimelineConnector";
import CircleIcon from "@mui/icons-material/Circle";
import InfoIconComponent from "components/info-icon-component/InfoIconComponent";
import {
  MuiLoadingButton,
  MuiStyledButton,
} from "components/mui-buttons/MuiButtons";
import MediaAssets from "assets";

const ProjectTimelineCard = (props: any) => {
  const {
    title,
    isHeading,
    showViewDetails,
    handleClickViewDetails,
    isSectionCompleted,
    isSectionDisabled,
    showTimelineConnector,
    isConnectorDisabled = false,
    timelineStatusPercentage,
    toolTipDetails,
    reraDetails,
    showFacilityManagementBtn,
    disbaledFMBtn,
    handleClickFMBtn,
    showHeaderCircle,
  } = props;

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Stack>
        <Stack spacing={2} justifyContent={"start"}>
          <IconButton style={isHeading ? {} : { marginLeft: "2px" }}>
            {showHeaderCircle ? (
              <img
                src={MediaAssets.ic_green_circle}
                className={
                  isHeading
                    ? styles["success-circle-lg"]
                    : styles["success-circle-sm"]
                }
              />
            ) : isSectionCompleted ? (
              <img
                src={MediaAssets.ic_ok_success}
                className={
                  isHeading
                    ? styles["completed-icon-lg"]
                    : styles["completed-icon-sm"]
                }
              />
            ) : isHeading && !isSectionCompleted ? (
              <CircleIcon
                style={{ marginLeft: "-3px", fontSize: "2rem" }}
                color={"disabled"}
              />
            ) : isSectionDisabled ? (
              <CircleIcon
                fontSize={isHeading ? "medium" : "small"}
                color={"disabled"}
              />
            ) : (
              <img
                src={MediaAssets.ic_green_circle}
                className={
                  isHeading
                    ? styles["success-circle-lg"]
                    : styles["success-circle-sm"]
                }
              />
            )}
          </IconButton>
          {isHeading ? (
            <Typography className={styles["typography-heading"]}>
              {title}
            </Typography>
          ) : (
            <div>
              {timelineStatusPercentage === 100 ||
              timelineStatusPercentage === 0 ? (
                <Stack spacing={2}>
                  <Typography
                    className={
                      timelineStatusPercentage === 0
                        ? styles["typography-text-disabled"]
                        : styles["typography-text"]
                    }
                  >
                    {title}
                  </Typography>
                  <InfoIconComponent infoContent={toolTipDetails} />
                </Stack>
              ) : (
                <Stack
                  spacing={1}
                  className={styles["status-details-container"]}
                >
                  <Stack spacing={2}>
                    <div className={styles["status-details-text"]}>
                      <Typography
                        className={`${styles["typography-text"]} ${styles["line-ellipsis"]}`}
                      >
                        {title}
                      </Typography>
                    </div>
                    <InfoIconComponent infoContent={toolTipDetails} />
                  </Stack>
                  {!isSmallerScreen && (
                    <div className={styles["status-gap-space"]}></div>
                  )}
                  <Box className={styles["status-details"]}>
                    <Typography className={styles["status-number-value"]}>
                      {timelineStatusPercentage}%
                    </Typography>
                  </Box>
                </Stack>
              )}
            </div>
          )}
        </Stack>

        {showViewDetails ? (
          <div>
            <MuiStyledButton
              className={styles["typography-view"]}
              variant="text"
              color="inherit"
              onClick={handleClickViewDetails}
            >
              View Details
            </MuiStyledButton>
          </div>
        ) : showFacilityManagementBtn ? (
          <div>
            <MuiLoadingButton
              className={styles["download-app-btn"]}
              // variant="text"
              color="primary"
              onClick={handleClickFMBtn}
              disabled={disbaledFMBtn}
            >
              Download The App
            </MuiLoadingButton>
          </div>
        ) : (
          ""
        )}
      </Stack>
      {showTimelineConnector && (
        <CustomTimelineConnector isDisabled={isConnectorDisabled} />
      )}

      {reraDetails && (
        <Box ml={5}>
          <Grid container spacing={1}>
            {reraDetails?.map((item: any, index: number) => (
              <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                <Typography className={styles["typography-text"]}>
                  {item}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default ProjectTimelineCard;
