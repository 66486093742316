import { Box, Container, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactNode } from "react";
import styles from "./CustomerRegistrationDetailsDialog.module.css";
import ClearIcon from "@mui/icons-material/Clear";
import { formatDateTime, dateConstants } from "utils/DateFormatter";

export interface ICustomerRegistration{
    fieldTitle : string;
    value : string | number;
  };


interface ICustomerRegistrationDetailsDialog {
  size?: any;
  shouldOpen: boolean;
  title?: string;
  content: any;
  cancelHandler: (e?: any) => any;
  cancelBtnText?: string;
}

const CustomerPaymentPendingDialog = ({
  size="md",
  shouldOpen,
  title ="Pending Amount",
  content,
  cancelHandler,
  cancelBtnText = "OKAY",
}: ICustomerRegistrationDetailsDialog) => {

  const handleClose = () => {
    cancelHandler();
  };

  return (
    <Dialog
      maxWidth={size}
      fullWidth
      open={shouldOpen}
      disableEscapeKeyDown={true}
    >
    <Stack className={styles["pending-payment-dialog-header"]} justifyContent={'space-between'}>
        <DialogTitle>
            <Typography className={`${styles["typography-heading"]}`}>
                {title}
            </Typography>
        </DialogTitle>

        <IconButton
            aria-label="cancel"
            onClick={handleClose}
            onMouseDown={(e : any) => e.preventDefault()}
            >
            <ClearIcon className={styles["clear-icon"]} />
        </IconButton>

    </Stack>

      <DialogContent  className={styles["dialog-content-section"]}>
        <Box className={styles['pending-amount-mention-section']}>
        <div>
            <Typography className={styles["typography-title-bold"]}>₹{content?.pendingAmount ? content?.pendingAmount : ' 0'}</Typography>
                <Grid container justifyContent={'center'} alignItems={'center'} spacing={1} mt={1} >
                  <Grid item>
                <Typography className={`${styles["typography-text"]} ${styles['text-dark-yellow']}`}>Milestone : </Typography>
                </Grid>

                <Grid item>
                <Typography className={`${styles["typography-text"]} ${styles["line-ellipsis"]} ${styles['text-dark-yellow']}`}>{content?.paymentMilestone ? content?.paymentMilestone : "-"}</Typography>
                </Grid>
                </Grid>

            <Stack justifyContent={'center'} spacing={1} mt={1}>
                <Typography className={`${styles['typography-title-2']} ${styles['text-dark-yellow']}`}>Due Date : </Typography>
                <Typography className={`${styles['typography-title']} ${styles['text-dark-yellow']}`}>{content?.targetDate ? formatDateTime(content?.targetDate, dateConstants.dateFormatDDmmYYYY) : "-"}</Typography>
            </Stack>
        </div>
        </Box>

        <div className={styles["dashed-divider"]}></div>

        <Box paddingX={5} mt={2}>
          <Stack justifyContent={"flex-start"} spacing={1}>
            <Typography className={styles["typography-text"]}>Total Paid Amount:</Typography>
            <Typography fontWeight={600} className={styles["typography-title"]}>₹{content?.paidAmount ? content?.paidAmount : 0}</Typography>
          </Stack>

        </Box>

      </DialogContent>

        <Divider />
      <div className={styles["dialog-actions"]}>
        <Button
            className={styles["okay-btn"]}
            variant="text"
            color="inherit"
            onClick={handleClose}
        >
            {cancelBtnText}
        </Button>
      </div>
    </Dialog>
  );
};

export default CustomerPaymentPendingDialog;
