import React from "react";
import EastIcon from "@mui/icons-material/East";
import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";
import MediaAssets from "assets";
import styles from "./Promotions.module.css";
import { useDispatch, useSelector } from "react-redux";
import CollectionOne from "../collections/collection-one/CollectionOne";
import { NewLaunchActions } from "redux-container/investments/new-launch/NewLaunchRedux";
import DiscoverInvestmentsDrawer from "./DiscoverInvestmentsDrawer";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useHandleCardNavigation } from "utils/helperFunctions";

const actionDispatch = (dispatch: any) => {
  return {
    setNewLaunchState: (key: any, value: any) =>
      dispatch(NewLaunchActions.setNewLaunchState(key, value)),
    getAllInvestments: () =>
      dispatch(NewLaunchActions.getAllInvestmentsRequest()),
  };
};

const Promotions = () => {
  const { setNewLaunchState, getAllInvestments } = actionDispatch(
    useDispatch()
  );
  const { handleNavigation } = useHandleCardNavigation();

  const { investmentData, allInvestments, pageData } = useSelector(
    (state: any) => ({
      investmentData:
        state?.newLaunch?.newLaunchData?.pageManagementsOrNewInvestments,
      pageData: state?.newLaunch?.newLaunchData?.page,
      allInvestments: state?.newLaunch?.investments,
    })
  );

  const investment = investmentData?.length > 0 ? investmentData[0] : [];

  return (
    <div className={styles["promotions-holder"]}>
      <img
        src={pageData?.promotionAndOffersMedia?.value?.url}
        alt=""
        className={styles["image-banner"]}
        onClick={() =>
          handleNavigation(
            RouteConfigurations.investmentDetails,
            pageData?.promotionAndOffersProjectContentId
          )
        }
      />

      <MuiLoadingButton
        endIcon={<EastIcon />}
        variant="contained"
        className="margin-top-40"
        sx={{ padding: "10px 40px" }}
        onClick={() => {
          setNewLaunchState("isDiscoverInvestmentsDialogOpen", true);
          // getAllInvestments();
        }}
      >
        Discover all investments
      </MuiLoadingButton>

      <DiscoverInvestmentsDrawer investments={allInvestments} />
    </div>
  );
};

export default Promotions;
