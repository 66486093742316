import React, { Fragment, useEffect, useRef, useState } from "react";
import styles from "../Chat.module.css";
import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Avatar,
  TextField,
} from "@mui/material";
import MediaAssets from "assets";
import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ChatAction } from "redux-container/chat-redux/ChatRedux";
import { useDispatch, useSelector } from "react-redux";
import UserChatCard from "../user-chat-card/UserChatCard";
import Loader from "components/loader/Loader";
import MuiTextField from "components/mui-textfield/MuiTextfield";
import chatDisplayStyles from "./ChatDisplay.module.css";
import { chatEnum } from "enumerations/ChatEnum";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useHandleCardNavigation } from "utils/helperFunctions";
import { ChatOrigins } from "enumerations/ChatEnum";
import { KeyCodeTypeEnum } from "enumerations/KeyCodeTypeEnum";

const actionDispatch = (dispatch: any) => {
  return {
    setChatDataState: (key: any, value: any) => {
      dispatch(ChatAction.setChatDataState(key, value));
    },
    chatInitiate: (data: any) => {
      dispatch(ChatAction.chatInitiateRequest(data));
    },
    postChatData: (data: any) => {
      dispatch(ChatAction.chatPostDataRequest(data));
    },
    getChat: () => {
      dispatch(ChatAction.getChatRequest());
    },
  };
};

const ChatDisplay = (props: any) => {
  const { callGetChatData } = props;
  const { setChatDataState, chatInitiate, postChatData, getChat } =
    actionDispatch(useDispatch());
  const chatDisplayDetails = useSelector(
    (state: any) => state?.chat?.chatDisplayDetails
  );

  const handleCloseDrawer = () => {
    setChatDataState("openDisplayDrawer", false);
  };
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const chatData = useSelector((state: any) => state?.chat?.chatData);
  const isLoading = useSelector((state: any) => state?.chat?.isLoading);
  const error = useSelector((state: any) => state?.chat?.error);
  const navigate = useNavigate();
  const isAllowTyping = useSelector((state: any) => state?.chat?.isAllowTyping);
  const [userTypeQuery, setUserTypeQuery] = useState("");
  const { handleNavigation } = useHandleCardNavigation();
  const [onKeyPressSearchValue, setOnKeyPressSearchValue] = useState("");

  const messagesEndRef: any = useRef(null);

  const scrollToBottom: any = () => {
    messagesEndRef.current?.scrollIntoView(true); // using ref to scroll down to the last message in chat display section
  };

  const handleInitiateChat = () => {
    const requestData = {
      topicId: chatDisplayDetails?.topicId,
      isInvested: chatDisplayDetails?.isInvested,
    };
    chatInitiate(requestData);
    callGetChatDataRequest();
    scrollToBottom();
  };

  const shouldScroll = useSelector((state: any) => state?.chat?.shouldScroll);

  useEffect(() => {
    if (shouldScroll) {
      scrollToBottom();
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
    const checkForAllowTyping = chatData?.messages?.filter(
      (item: any) =>
        item?.origin === 1 &&
        item?.conversationId === chatData?.conversation?.id &&
        chatData?.conversation?.isOpen === true &&
        item?.action === chatEnum.START_TYPING
    );
    if (checkForAllowTyping?.length > 0) {
      setChatDataState("isAllowTyping", true);
    } else {
      if (isAllowTyping) {
        setChatDataState("isAllowTyping", false);
      }
    }
  }, [chatData?.message?.length]);

  const chatJSON = useSelector(
    (state: any) => state?.chat?.chatJsonDetails?.autoChat?.chatJSON
  );

  const callPostChatDataText = (option: any, origin?: number) => {
    const requestData = {
      conversationId: chatData?.conversation?.id,
      topicId: chatDisplayDetails?.topicId,
      origin: !!origin ? origin : 1,
      message: option?.text,
      selection: option?.action,
      actionType: option?.actionType,
      options: [],
      replyTo: null,
      crmProjectId: chatDisplayDetails?.booking?.crmLaunchPhase?.crmProjectId,
    };
    !error && postChatData(requestData);
  };

  const callPostChatDataOptions = (options: any, message: any) => {
    const requestData = {
      conversationId: chatData?.conversation?.id,
      topicId: chatDisplayDetails?.topicId,
      origin: ChatOrigins.CMSOrigin,
      message: message,
      selection: null,
      actionType: null,
      options: options,
      replyTo: null,
      crmProjectId: chatDisplayDetails?.booking?.crmLaunchPhase?.crmProjectId,
    };

    setTimeout(() => {
      !error && postChatData(requestData);
    }, 200);
  };

  const callGetChatDataRequest = () => {
    setTimeout(() => {
      !error &&
        callGetChatData(
          chatDisplayDetails?.topicId,
          chatDisplayDetails?.isInvested,
          false
        );
      !error && getChat();
    }, 200);
    return callGetChatData;
  };

  const handlePostUserTypeQuery = (query: string) => {
    const requestData = {
      conversationId: chatData?.conversation?.id,
      topicId: chatDisplayDetails?.topicId,
      origin: ChatOrigins.UserOrigin,
      message: query,
      selection: null,
      actionType: null,
      options: [],
      replyTo: null,
      crmProjectId: chatDisplayDetails?.booking?.crmLaunchPhase?.crmProjectId,
    };
    !error && postChatData(requestData);
    !error && callGetChatDataRequest();
    // !error && setChatDataState('isAllowTyping', false)
  };

  const handleClickSendChatMessage = (
    options: any,
    option: any,
    message: any,
    chatData: any
  ) => {
    // callPostChatDataText(option) // first post api call for text.
    // callPostChatDataOptions(options, message) // second post api call for options

    const handleFilter = (optionData: any) => {
      let filteredData = chatJSON?.chatBody?.filter(
        (item: any) => item.linkedOption === optionData?.optionNumber
      );
      return filteredData[0];
    };

    switch (option.action) {
      case chatEnum.ABOUT_HOABL:
        callPostChatDataText(option);
        const getAutoChatJSONForAboutHoabl = handleFilter(option);
        callPostChatDataOptions(
          getAutoChatJSONForAboutHoabl?.options,
          getAutoChatJSONForAboutHoabl?.message
        );
        callGetChatDataRequest();
        return;

      case chatEnum.BOOKING_STATUS:
        callPostChatDataText(option);
        const getAutoChatJSONForBookingStatus = handleFilter(option);
        callPostChatDataOptions(
          getAutoChatJSONForBookingStatus.options,
          getAutoChatJSONForBookingStatus.message
        );
        callGetChatDataRequest();
        return;

      case chatEnum.DEVELOPMENT_STATUS:
        callPostChatDataText(option);
        const getAutoChatJSONForDevelopmentStatus = handleFilter(option);
        callPostChatDataOptions(
          getAutoChatJSONForDevelopmentStatus?.options,
          getAutoChatJSONForDevelopmentStatus?.message
        );
        callGetChatDataRequest();
        return;

      case chatEnum.FINAL_MESSAGE:
        const reqForFinalMessage = {
          ...option,
          text: chatData?.autoChat?.chatJSON?.finalMessage,
        };
        callPostChatDataText(reqForFinalMessage, 2);
        callGetChatDataRequest();
        return;

      case chatEnum.INVESTMENTS:
        callPostChatDataText(option);
        const getAutoChatJSONForInvestment = handleFilter(option);
        callPostChatDataOptions(
          getAutoChatJSONForInvestment?.options,
          getAutoChatJSONForInvestment?.message
        );
        callGetChatDataRequest();
        return;

      case chatEnum.OTHERS:
        // callPostChatDataText(option);
        // callGetChatDataRequest();

        // return navigate(RouteConfigurations.faqs);
        callPostChatDataText(option);
        const getAutoChatJSONForOthers = handleFilter(option);
        callPostChatDataOptions(
          getAutoChatJSONForOthers?.options,
          getAutoChatJSONForOthers?.message
        );
        callGetChatDataRequest();
        return;

      case chatEnum.PAYMENT:
        callPostChatDataText(option);
        const getAutoChatJSONForPayment = handleFilter(option);
        callPostChatDataOptions(
          getAutoChatJSONForPayment?.options,
          getAutoChatJSONForPayment?.message
        );
        callGetChatDataRequest();
        return;

      case chatEnum.PORTFOLIO:
        callPostChatDataText(option);
        const getAutoChatJSONForPortfolio = handleFilter(option);
        callPostChatDataOptions(
          getAutoChatJSONForPortfolio?.options,
          getAutoChatJSONForPortfolio?.message
        );
        callGetChatDataRequest();
        return;

      case chatEnum.PROJECT:
        callPostChatDataText(option);
        const getAutoChatJSONForProject = handleFilter(option);
        callPostChatDataOptions(
          getAutoChatJSONForProject?.options,
          getAutoChatJSONForProject?.message
        );
        callGetChatDataRequest();
        return;

      case chatEnum.PROMISE:
        callPostChatDataText(option);
        const getAutoChatJSONForPromise = handleFilter(option);
        callPostChatDataOptions(
          getAutoChatJSONForPromise?.options,
          getAutoChatJSONForPromise?.message
        );
        callGetChatDataRequest();
        return;

      case chatEnum.REDIRECT_ABOUT_HOABL:
        callPostChatDataText(option);
        callGetChatDataRequest();
        return navigate(RouteConfigurations.aboutUs);

      case chatEnum.REDIRECT_BOOKING_JOURNEY:
        callPostChatDataText(option);
        callGetChatDataRequest();
        return navigate(RouteConfigurations.bookingJourney, {
          state: {
            investment: chatDisplayDetails?.booking?.id,
            primaryOwner: chatDisplayDetails?.booking?.owners,
            crmInventoryName: chatDisplayDetails?.booking?.crmInventory?.name,
            project:
              chatDisplayDetails?.booking?.crmLaunchPhase?.projectContent,
            isBookingComplete:
              chatDisplayDetails?.booking?.bookingStatus === 225360010
                ? true
                : false,
          },
        });

      case chatEnum.REDIRECT_FAQ:
        callPostChatDataText(option);
        callGetChatDataRequest();
        return navigate(RouteConfigurations.faqs);

      case chatEnum.REDIRECT_INVESTMENTS:
        callPostChatDataText(option);
        callGetChatDataRequest();
        return navigate(RouteConfigurations.investments);

      case chatEnum.REDIRECT_OTHERS:
        callPostChatDataText(option);
        callGetChatDataRequest();
        return;

      case chatEnum.REDIRECT_PORTFOLIO:
        callPostChatDataText(option);
        callGetChatDataRequest();
        return navigate(RouteConfigurations.portfolio);

      case chatEnum.REDIRECT_PROJECT:
        callPostChatDataText(option);
        callGetChatDataRequest();
        handleNavigation(
          RouteConfigurations.investmentDetails,
          chatDisplayDetails?.booking?.crmLaunchPhase?.projectContent?.id
        );
        return;

      case chatEnum.REDIRECT_PROJECT_TIMELINE:
        callPostChatDataText(option);
        callGetChatDataRequest();
        return navigate(RouteConfigurations.projectTimeline, {
          state: { projectId: chatDisplayDetails?.projectContentId },
        });

      case chatEnum.REDIRECT_PROMISE:
        callPostChatDataText(option);
        callGetChatDataRequest();
        return navigate(RouteConfigurations.promise);

      case chatEnum.SALES:
        callPostChatDataText(option);
        callGetChatDataRequest();
        callPostChatDataOptions(options, message);
        return;

      case chatEnum.START_TYPING:
        callPostChatDataText(option);
        const reqForAllowTypingMessage = {
          ...option,
          text: chatData?.autoChat?.chatJSON?.allowTypingMessage,
        };
        setTimeout(() => {
          callPostChatDataText(reqForAllowTypingMessage, 2);
          callGetChatDataRequest();
        }, 200);
        return setChatDataState("isAllowTyping", true);

      default: // first post api call for text.
        // second post api call for options
        callPostChatDataText(option);
        callPostChatDataOptions(options, message);
        callGetChatDataRequest();
        break;
    }
  };

  const handleOnEnterKeyPress = (event: any) => {
    if (event.code === KeyCodeTypeEnum.EnterKey) {
      setChatDataState("shouldScroll", true);
      userTypeQuery && handlePostUserTypeQuery(userTypeQuery);
    }
  };

  return (
    <Box className={chatDisplayStyles["chat-main-display-content"]}>
      <Box
        mt={0.4}
        pt={0.5}
        ml={isLargeScreen ? -5 : 0}
        mr={isLargeScreen ? -5 : 0}
      >
        <div className={styles["chat-box-header"]}>
          <Box mb={2} className={styles["chatBox-header-logo"]}>
            {isLargeScreen && (
              <ChevronLeftIcon
                sx={{ marginLeft: "-30px" }}
                onClick={handleCloseDrawer}
              />
            )}
            <img src={chatDisplayDetails?.imageUrl} />
            <p>{chatDisplayDetails?.name}</p>
          </Box>
        </div>
      </Box>
      <Box className={styles["chat-box"]}>
        {/* {isLoading ? (
          <Loader />
        ) : ( */}
        <Box mb={isLargeScreen ? 7 : 0} className={styles["chatbody"]}>
          {chatData?.messages?.map((item: any, itemIndex: number) => (
            <div key={itemIndex}>
              {item?.origin === ChatOrigins?.CMSOrigin ||
              item?.origin === ChatOrigins?.CRMOrigin ? (
                <Box>
                  <div className={styles["chat-bubble"]}>
                    <p>{item?.message}</p>
                  </div>
                  <Box mb={3}>
                    {item?.options?.length > 0 &&
                      item?.options?.map((option: any, optionIndex: number) => (
                        <Button
                          key={optionIndex}
                          className={styles["chat-query"]}
                          disabled={
                            chatData?.conversation?.id !==
                              item?.conversationId ||
                            isAllowTyping ||
                            !chatData?.conversation?.isOpen
                          }
                          onClick={() => {
                            handleClickSendChatMessage(
                              item?.options,
                              option,
                              item?.message,
                              chatData
                            );
                            setChatDataState("shouldScroll", true);
                          }}
                        >
                          <p>{option.text}</p>
                          {optionIndex + 1 === item?.options?.length && (
                            <p ref={messagesEndRef}></p>
                          )}
                        </Button>
                      ))}
                  </Box>
                </Box>
              ) : item?.origin === ChatOrigins?.UserOrigin ? (
                <>
                  <UserChatCard message={item?.message} />
                </>
              ) : (
                ""
              )}
            </div>
          ))}
          <Box ref={messagesEndRef} mb={2}></Box>
        </Box>
        {/* )} */}
      </Box>

      {((chatData?.conversation == null && isAllowTyping === false) ||
        (chatData?.conversation?.isOpen === false &&
          isAllowTyping === false)) && ( // check for conversation object null and isOpen
        <Box className={chatDisplayStyles["start-btn-wrapper"]}>
          <div className={styles["start-chat-btn"]}>
            <MuiLoadingButton onClick={handleInitiateChat}>
              Start Chat
            </MuiLoadingButton>
          </div>
        </Box>
      )}

      {isAllowTyping && (
        <Box className={chatDisplayStyles["text-field-wrapper"]}>
          <MuiTextField
            inputProps={{
              style: {
                height: "13px",
                width: "100%",
              },
            }}
            placeholder="Start Typing..."
            className={chatDisplayStyles["text-field"]}
            value={userTypeQuery}
            onChange={(e: any) => {
              if (userTypeQuery === "") {
                setUserTypeQuery(e.target.value.trim());
              } else {
                setUserTypeQuery(e.target.value);
              }
            }}
            onKeyPress={handleOnEnterKeyPress}
            InputProps={{
              endAdornment: (
                <Box
                  onClick={(e: any) =>
                    userTypeQuery && handlePostUserTypeQuery(userTypeQuery)
                  }
                  className={
                    userTypeQuery
                      ? chatDisplayStyles["send-btn-section"]
                      : chatDisplayStyles["send-btn-section-disabled"]
                  }
                >
                  <Avatar
                    className={chatDisplayStyles["send-arrow-icon"]}
                    src={MediaAssets.ic_send_arrow}
                  />
                </Box>
              ),
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ChatDisplay;
