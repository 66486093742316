import React from "react";
import { Stack, Typography, Paper, Button, Box, Divider } from "@mui/material";
import styles from "./AccountDetailedCard.module.css";
import {
  MuiStyledButton,
} from "components/mui-buttons/MuiButtons";
import MediaUploadComponent from "components/media-upload/MediaUpload";

interface IAccountDetailsStatus {
  handleViewClick?:any;
  contentName: string;
  handleUploadClick?: any;
}

const AccountDetailsStatus = (props: IAccountDetailsStatus) => {
  const {
    handleViewClick,
    contentName,
    handleUploadClick,
  } = props;
  return (
    <div>
      <Paper
        className={`${styles["card-content"]} ${styles["content-padding-status"]}`}
      >
        <Box>
          <Typography className={styles["typography-content-name"]}>
            {contentName}
          </Typography>
        </Box>
        <Divider className={styles.divider} />
        <Stack>
          <Box>
            <div className={styles["status-section-content"]}>
              <Typography className={styles["status-text"]}>
                Status :{" "}
              </Typography>
              <Typography className={styles["status-value"]}>
                Verification Pending{" "}
              </Typography>
            </div>
          </Box>
          <Stack>
            <MuiStyledButton
              className={styles["view-btn"]}
              variant="text"
              color="inherit"
              onClick={handleViewClick}
            >
              Preview
            </MuiStyledButton>

            {/* <MuiStyledButton
              className={styles["view-btn"]}
              variant="text"
              color="inherit"
            >
              <MediaUploadComponent
                uploadTitle={"Re-Upload"}
                onChange={handleUploadClick}
              />
            </MuiStyledButton> */}
          </Stack>
        </Stack>
      </Paper>
    </div>
  );
};

export default AccountDetailsStatus;
