import { Box, Typography } from "@mui/material";
import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { IPortfolioComponentProps } from "../Portfolio";

enum projectTypes {
  COMPLETED = "Completed",
  ONGOING = "Ongoing",
}

const NoDetailCard = (props: IPortfolioComponentProps) => {
  const navigate = useNavigate();
  const { projectType, projectDetails } = props;
  const handleCardNavigation = () => {
    if (projectType === projectTypes.COMPLETED) {
      navigate(RouteConfigurations.portfolioDetails, {
        state: { projects: projectDetails },
      });
    } else {
      navigate(RouteConfigurations.investments);
    }
  };

  return (
    <Box
      padding={"1.5rem"}
      bgcolor={"grey.100"}
      borderRadius={"10px"}
      border={"1px solid #d3d3d9"}
    >
      <Typography variant="h5" color={"text.disabled"}>
        {`No ${projectType} Projects`}
      </Typography>
      <Typography py={"1rem"} variant="body1" color={"grey.900"}>
        {projectType === projectTypes.COMPLETED
          ? "You are almost there.Complete all your pending payments and enjoy the benefits of NGL investments."
          : "You have no ongoing investment. Add a new investment product to your portfolio now"}
      </Typography>
      <MuiLoadingButton onClick={handleCardNavigation}>
        {projectType === projectTypes.COMPLETED
          ? "See Details"
          : "Explore new Investments"}
      </MuiLoadingButton>
    </Box>
  );
};

export default NoDetailCard;
