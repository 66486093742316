import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { Grid, Theme, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "@mantine/carousel";
import { getPageAction } from "redux-container/page-management-redux/PageManagementRedux";
import { numOfItems } from "utils/helperFunctions";
import { useMemo } from "react";
import PromiseCard from "pages/promises/promise-card/PromiseCard";
import EastIcon from "@mui/icons-material/East";
import styles from "./ProjectPromises.module.css";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";

const actionDispatch = (dispatch: any) => {
  return {
    setPageState: (key: string, value: any) =>
      dispatch(getPageAction.setPageState(key, value)),
  };
};

const ProjectPromises = () => {
  const navigate = useNavigate();

  const { setPageState } = actionDispatch(useDispatch());
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { promises, investmentDetails, totalPromisesOnHomeScreen } =
    useSelector((state: any) => ({
      investmentDetails: state?.investmentDetails?.investmentDetails,
      promises: state?.pageManagement?.promiseData?.homePagesOrPromises,
      totalPromisesOnHomeScreen:
        state?.investmentDetails?.investmentDetails?.pageManagementContent[0]
          ?.totalPromisesOnHomeScreen,
    }));

  const handlePromiseDrawer = (promise: typeof promises[0]) => () => {
    setPageState("promiseDetails", promise);
    setPageState("isDialogOpen", true);
  };

  const slicedPageData = useMemo(() => {
    if (!!totalPromisesOnHomeScreen) {
      return promises?.slice(0, totalPromisesOnHomeScreen);
    } else {
      return promises;
    }
  }, [promises, totalPromisesOnHomeScreen]);

  return (
    <div className={styles["promise-holder"]}>
      <div className={styles["heading-holder"]}>
        <h2>
          {investmentDetails?.projectContent?.otherSectionHeadings?.promises
            ?.sectionHeading !== null
            ? investmentDetails?.projectContent?.otherSectionHeadings?.promises
                ?.sectionHeading
            : "Promises Securing these Investments"}
        </h2>
        <MuiStyledButton
          endIcon={<EastIcon />}
          variant="text"
          color="primary"
          onClick={() => navigate(RouteConfigurations.promise)}
        >
          SEE ALL
        </MuiStyledButton>
      </div>

      <Grid
        container
        spacing={2}
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          {promises && promises.length > 0 && (
            <MantineCarousel
              slideSize={numOfItems(3)}
              height={isSmallScreen ? 300 : 340}
              slidesToScroll={
                investmentDetails?.pageManagementContent[0]
                  ?.totalPromisesOnHomeScreen <= 3
                  ? 1
                  : 3
              }
              breakpoints={[
                { maxWidth: "lg", slideSize: numOfItems(3) },
                { maxWidth: "md", slideSize: numOfItems(3) },
                { maxWidth: "sm", slideSize: numOfItems(3) },
                { maxWidth: "xs", slideSize: numOfItems(2), slideGap: "xs" },
              ]}
            >
              {slicedPageData &&
                slicedPageData.map(
                  (promise: typeof promises[0], index: number) => {
                    return (
                      <Carousel.Slide key={index + 1}>
                        <PromiseCard
                          key={promise?.id}
                          name={promise?.name}
                          description={promise?.shortDescription}
                          iconsrc={promise.displayMedia?.value?.url}
                          handlecardclick={handlePromiseDrawer(promise)}
                        />
                      </Carousel.Slide>
                    );
                  }
                )}
            </MantineCarousel>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectPromises;
