import * as React from "react";
// import SwipeableViews from 'react-swipeable-views';
import { useTheme } from "@mui/material/styles";
import { Grid, Stack, styled, useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import styles from "./TabsComponent.module.css";
import ImageCard from "../image-card-component/ImageCardComponent";
import MediaAssets from "assets";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

interface ITabComponent {
  images: any;
  videos: any;
  droneShoots: any;
  threeSixtyImages: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

interface ITabsComponent {
  galleryData: any;
  tabIndex?: number;
}

export default function TabsComponent(props: ITabsComponent) {
  const { galleryData, tabIndex = 0 } = props;
  const { images, videos, droneShoots, threeSixtyImages } = galleryData;
  const theme = useTheme();
  const [value, setValue] = React.useState(tabIndex);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const CustomTabsContainer = styled(Tabs)`
    .Mui-selected {
      text-transform: capitalize;
      color: black;
      font-weight: "Jost-Medium";
      font-size: 1.1rem;
    }
    .Mui-selected {
      font-family: "Jost-semiBold";
      width: 20px;
    }
  `;

  const tabsList = [
    {
      id: 1,
      label: "Photos",
      panelDetails: galleryData?.isImagesActive
        ? images?.filter((item: any) => item?.status === "1001")
        : [],
      contentType: "image",
    },
    {
      id: 2,
      label: "Videos",
      panelDetails: galleryData?.isVideosActive
        ? videos?.filter((item: any) => item?.status === "1001")
        : [],
      contentType: "video",
    },
    {
      id: 3,
      label: "Drone Shoots",
      panelDetails: galleryData?.isDroneShootsActive
        ? droneShoots?.filter((item: any) => item?.status === "1001")
        : [],
      contentType: "video",
    },
    {
      id: 4,
      label: "360 Photos",
      panelDetails: galleryData?.isThreeSixtyImagesActive
        ? threeSixtyImages?.filter((item: any) => item?.status === "1001")
        : [],
      contentType: "image",
    },
  ];

  return (
    <Box>
      <Box>
        <CustomTabsContainer
          value={value}
          onChange={handleChange}
          textColor="inherit"
          aria-label="scrollable auto tabs"
          indicatorColor={"primary"}
          variant={isSmallScreen ? "scrollable" : "fullWidth"}
          scrollButtons={isSmallScreen ? true : false}
        >
          {tabsList.map((item: any, index: number) => (
            <Tab
              label={item.label}
              className={styles["tab-content"]}
              {...a11yProps(index)}
            />
          ))}
        </CustomTabsContainer>
        <Divider sx={{ margin: "-1px 10px 0 10px" }} />
      </Box>
      <Box className={styles["content-wrapper"]} mt={3} mb={5}>
        <Grid container spacing={1}>
          {tabsList.map((item: any, index: number) => (
            <>
              {item.id === value + 1 && (
                <>
                  {item?.panelDetails?.length > 0 &&
                    item?.panelDetails?.map(
                      (element: any, elementIndex: number) => (
                        <Grid
                          item
                          xs={item.contentType === "video" ? 12 : 6}
                          sm={item.contentType === "video" ? 12 : 6}
                          md={item.contentType === "video" ? 6 : 3}
                          lg={item.contentType === "video" ? 6 : 3}
                          xl={item.contentType === "video" ? 6 : 3}
                        >
                          <ImageCard
                            contentType={item.contentType}
                            mediaUrl={element?.mediaContent?.value}
                            title={element?.name}
                            mediaContentData={item?.panelDetails}
                            initialIndex={elementIndex}
                          />
                        </Grid>
                      )
                    )}
                  {item?.panelDetails?.length === 0 && (
                    <Box pt={2} className={styles["please-wait-content"]}>
                      <img
                        className={styles["please-wait-icon"]}
                        src={MediaAssets.ic_please_wait}
                      />
                      <Typography className={styles["please-wait-text"]}>
                        It's stunning, and its coming soon.
                      </Typography>
                      <Typography className={styles["please-wait-text"]}>
                        Please wait
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
