import { Box } from "@mui/material";
import React from "react";
import styles from "./ProjectTimelineCard.module.css";

const CustomTimelineConnector : any = (props: any) => {
  const {
    isDisabled
  } = props;
  return (
    <div>
      <Box ml={2} mb={-0.5} mt={-0.7}>
        <div className={isDisabled ? styles["timeline-circle-disabled"] : styles["timeline-circle"] }></div>
        <div className={isDisabled ? styles["timeline-circle-disabled"] : styles["timeline-circle"]}></div>
        <div className={isDisabled ? styles["timeline-circle-disabled"] : styles["timeline-circle"]}></div>
        <div className={isDisabled ? styles["timeline-circle-disabled"] : styles["timeline-circle"]}></div>
        {/* <div className={isDisabled ? styles["timeline-circle"] : styles["timeline-circle-disabled"]}></div> */}
      </Box>
    </div>
  );
};

export default CustomTimelineConnector;
