import MediaAssets from "assets";
import { RouteConfigurations } from "routes/RouteConfigurations";

export const profileMenus = [
  {
    title: "My Account",
    subtitle: "Payment History & KYC",
    icon: MediaAssets.ic_my_account,
    route: RouteConfigurations.myAccount,
  },
  {
    title: "Security & Settings",
    subtitle: "Report emergency, Location Prompt etc",
    icon: MediaAssets.ic_security_settings,
    route: RouteConfigurations.securityAndSettings,
  },
  {
    title: "Help Centre",
    subtitle: "Contact, About Us, Privacy Policy ,Chat",
    icon: MediaAssets.ic_help_center,
    route: RouteConfigurations.helpCenter,
  },
  // {
  //   title: "My Services",
  //   subtitle: "Manage your land, book, etc",
  //   icon: MediaAssets.ic_my_services,
  //   route: "",
  // },
];

export const helpCenterMenus = [
  {
    title: "Frequently Asked Questions",
    subtitle: "Read all our FAQs here",
    icon: MediaAssets.ic_faq,
    route: RouteConfigurations.faqs,
  },
  {
    title: "Terms & Conditions & Privacy Policy",
    subtitle: "Read our Terms & Conditions & Privacy Policy",
    icon: MediaAssets.ic_privacy_policy,
    // route: RouteConfigurations.securityAndSettings,
  },
  {
    title: "About Us",
    subtitle: "Read everything you want to know about us",
    icon: MediaAssets.ic_about_us,
    route: RouteConfigurations.aboutUs,
  },
  {
    title: "Share your feedback",
    subtitle: "This will help us improve the app for you",
    icon: MediaAssets.ic_feedback,
    // route: RouteConfigurations.facilityManagement,
  },
];
