import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import BookingJourneySectionComponent from "./booking-journey-section/BookingJourneySection";
import styles from "./booking-journey-section/BookingJourneySection.module.css";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import MediaAssets from "assets";
import JourneyBlock from "./booking-journey-section/JourneyBlock";
// import customerManagementActions from 'redux-container/customer-management/CustomerManagementRedux'
// import {DocumentsActions} from 'redux-container/Documents/DocumentsRedux'
import { useDispatch, useSelector } from "react-redux";
import { formatDateTime } from "utils/DateFormatter";
import { DocumentsActions } from "redux-container/documents/DocumentsRedux";

import { getPortfolioAction } from "redux-container/portfolio-redux/PortfolioRedux";
// import PDFViewDialog from 'components/pdf-view-dialog/PDFViewDialog';
import CustomerRegistrationDetailsDialog, {
  ICustomerRegistration,
} from "components/customer-registration-dialog/CustomerRegistrationDetailsDialog";
import CustomerPaymentPendingDialog from "components/customer-registration-dialog/CustomerPaymentPendingDialog";
import { dateConstants } from "utils/DateFormatter";
import { useLocation } from "react-router-dom";
import Loader from "components/loader/Loader";
import { InvestmentDetailsActions } from "redux-container/investments/investment-details/InvestmentDetailsRedux";
import CustomerPaymentCompletedDialog from "components/customer-registration-dialog/CustomerPaymentCompletedDialog";

const actionDispatch = (dispatch: any) => {
  return {
    getCustomerBookingJourney: (params: any) =>
      dispatch(getPortfolioAction.getBookingJourneyRequest(params)),
    getCustomerDocument: async (params: any) => {
      dispatch(DocumentsActions.getDownloadDocDataRequest(params));
    },
    setDocumentsState: (key: any, value: any) =>
      dispatch(DocumentsActions.setDocumentsState(key, value)),
    getInvestmentDetails: (id: number) =>
      dispatch(InvestmentDetailsActions.getInvestmentDetailsRequest(id)),
  };
};

const BookingJourney = (props: any) => {
  const {
    showAllPaymentReceipts,
    setShowAllPaymentReceipts,
    locationState,
    setPaymentHistory,
    setPaymentsReceipts,
  } = props;
  const {
    getCustomerBookingJourney,
    getCustomerDocument,
    setDocumentsState,
    getInvestmentDetails,
  } = actionDispatch(useDispatch());
  const isDocLoading = useSelector((state: any) => state?.documents?.isLoading);
  const [openCustomerRegistrationDialog, setOpenCustomerRegistrationDialog] =
    useState<boolean>(false);
  const [registrationDetailsContent, setRegistrationDetailsContent] = useState<
    ICustomerRegistration[]
  >([]);
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [openPaymentPendingDialog, setOpenPaymentPendingDialog] =
    useState(false);
  const [openPaymentCompletedDialog, setOpenPaymentCompletedDialog] =
    useState(false);
  const [paymentDialogContent, setPaymentDialogContent] = useState({});
  const [showPdf, setShowPdf] = useState(false);

  const showPDFPreview = useSelector(
    (state: any) => state?.documents?.showPDFPreview
  );
  const documentBaseString = useSelector(
    (state: any) => state?.documents?.downloadDocData
  );
  const bookingJourneyData = useSelector(
    (state: any) => state?.portfolio?.bookingJourneyData?.bookingJourney
  );

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const location: any = useLocation();

  useEffect(() => {
    const params = {
      investmentId: location?.state?.investment,
    };
    getCustomerBookingJourney(params);
    if (location?.state?.project?.id) {
      getInvestmentDetails(location?.state?.project?.id);
    }
  }, []);

  useEffect(() => {
    setPaymentsReceipts(bookingJourneyData?.paymentReceipts);
    setPaymentHistory(bookingJourneyData?.paymentHistory);
  }, [showAllPaymentReceipts]);

  useEffect(() => {
    function dataURItoBlob(dataURI: any) {
      const byteString = window.atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: "application/pdf" });
      return blob;
    }

    const blob = dataURItoBlob(documentBaseString);
    const url = URL.createObjectURL(blob);

    if (showPDFPreview && showPdf) {
      window.open(url);
      setDocumentsState("showPDFPreview", false);
      setShowPdf(false);
    } else if (showPdf && !documentBaseString) {
      setDocumentsState("error", `Couldn't Fetch Data. Try Again!`);
    }
  }, [showPDFPreview]);

  const paymentPendingList = bookingJourneyData?.payments?.filter(
    (item: any) => item?.isPaymentDone === false
  );

  const handleViewClick = async (path?: string) => {
    await getCustomerDocument(path);
    setShowPdf(true);
  };

  const handleViewRegistration = (dialogContent: any) => {
    const content: ICustomerRegistration[] = [
      {
        fieldTitle: "Registration Date: ",
        value: formatDateTime(
          dialogContent.registrationDate,
          dateConstants.dateFormatDDMMMYY
        ),
      },
      {
        fieldTitle: "Registration Number: ",
        value: dialogContent.registrationNumber,
      },
    ];
    setDialogTitle("Registration Details");
    setRegistrationDetailsContent(content);
    setOpenCustomerRegistrationDialog(true);
  };

  const handleHandOver = (dialogContent: any) => {
    const content: ICustomerRegistration[] = [
      {
        fieldTitle: "Handover Date:",
        value: formatDateTime(
          dialogContent.handoverDate,
          dateConstants.dateFormatDDMMMYY
        ),
      },
    ];
    setDialogTitle("Handover Details");
    setRegistrationDetailsContent(content);
    setOpenCustomerRegistrationDialog(true);
  };

  const handleOpenPaymentPendingDialog = (data: any) => {
    setOpenPaymentPendingDialog(true);
    setPaymentDialogContent(data);
  };

  const handleOpenCompletedPaymentDialog = (data: any) => {
    setOpenPaymentCompletedDialog(true);
    setPaymentDialogContent(data);
  };

  const projectContentDetails = useSelector(
    (state: any) => state?.investmentDetails?.investmentDetails?.projectContent
  );

  const handleViewCustomerGuildline = (path: string) => {
    window.open(path, "_blank");
  };

  return (
    <Fragment>
      <CustomerPaymentPendingDialog
        shouldOpen={openPaymentPendingDialog}
        content={paymentDialogContent}
        cancelHandler={() => setOpenPaymentPendingDialog(false)}
      />

      <CustomerPaymentCompletedDialog
        shouldOpen={openPaymentCompletedDialog}
        content={paymentDialogContent}
        cancelHandler={() => setOpenPaymentCompletedDialog(false)}
      />

      <CustomerRegistrationDetailsDialog
        shouldOpen={openCustomerRegistrationDialog}
        title={dialogTitle}
        content={registrationDetailsContent}
        cancelHandler={() => setOpenCustomerRegistrationDialog(false)}
      />
      {isDocLoading ? (
        <Loader />
      ) : (
        <Box mt={-1} pr={isSmallerScreen ? 0 : 8} pl={isSmallerScreen ? 0 : 8}>
          <BookingJourneySectionComponent
            titleText={"TRANSACTION"}
            isInvestmentCompleted={true}
            isSectionCompleted={
              bookingJourneyData?.transaction?.application?.isApplicationDone &&
              bookingJourneyData?.transaction?.allotment?.allotmentDate
            }
            isPaymentCompleted={
              bookingJourneyData?.transaction?.application?.isApplicationDone &&
              bookingJourneyData?.transaction?.allotment?.allotmentDate
                ?.allotmentLetter
            }
          >
            {bookingJourneyData?.transaction?.application && (
              <JourneyBlock
                isMilestoneSuccess={
                  bookingJourneyData?.transaction?.application
                    ?.isApplicationDone
                }
                milestoneName={"Application"}
                milestoneSubName={
                  bookingJourneyData?.transaction?.application?.milestoneName
                }
                isDividerRequired={true}
              />
            )}

            {bookingJourneyData?.transaction?.allotment && (
              <JourneyBlock
                isMilestoneSuccess={
                  bookingJourneyData?.transaction?.allotment?.allotmentDate
                    ? true
                    : false
                }
                milestoneName={"Allotment"}
                milestoneSubName={
                  bookingJourneyData?.transaction?.allotment?.allotmentDate &&
                  "Plot Alloted"
                }
                viewText={"View Allotment Letter"}
                onViewClick={() =>
                  handleViewClick(
                    bookingJourneyData?.transaction?.allotment?.allotmentLetter
                      ?.path
                  )
                }
                isViewReceiptDisabled={
                  bookingJourneyData?.transaction?.allotment?.allotmentLetter
                    ? false
                    : true
                }
              />
            )}
          </BookingJourneySectionComponent>

          <BookingJourneySectionComponent
            titleText={"DOCUMENTATION"}
            isSectionCompleted={
              (bookingJourneyData?.documentation?.POA?.isPOAAlloted &&
                bookingJourneyData?.documentation?.AFS?.isAfsVisible) ||
              (!bookingJourneyData?.documentation?.POA?.isPOAAlloted &&
                !bookingJourneyData?.documentation?.AFS?.isAfsVisible)
                ? true
                : false
            }
          >
            {bookingJourneyData?.documentation?.POA?.isPOAAlloted && (
              <JourneyBlock
                isMilestoneSuccess={
                  bookingJourneyData?.documentation?.POA?.isPOAAlloted
                }
                milestoneName={"Power Of Attorney"}
                milestoneSubName={
                  bookingJourneyData?.documentation?.POA?.isPOARequired
                    ? "POA Assigned"
                    : ""
                }
                viewText={"View POA Agreement"}
                onViewClick={() =>
                  handleViewClick(
                    bookingJourneyData?.documentation?.POA?.poaLetter?.path
                  )
                }
                isDividerRequired={
                  bookingJourneyData?.documentation?.AFS && true
                }
                isViewReceiptDisabled={
                  bookingJourneyData?.documentation?.POA?.poaLetter
                    ? false
                    : true
                }
              />
            )}

            {bookingJourneyData?.documentation?.AFS?.isAfsVisible && (
              <JourneyBlock
                isMilestoneSuccess={
                  bookingJourneyData?.documentation?.AFS?.isAfsVisible
                }
                milestoneName={"Agreement for sale"}
                milestoneSubName={
                  bookingJourneyData?.documentation?.AFS?.isAfsVisible
                    ? "Completed"
                    : ""
                }
                viewText={"View"}
                onViewClick={() =>
                  handleViewClick(
                    bookingJourneyData?.documentation?.AFS?.afsLetter?.path
                  )
                }
                isDividerRequired={
                  bookingJourneyData?.documentation?.Registration && true
                }
                isViewReceiptDisabled={
                  bookingJourneyData?.documentation?.AFS?.afsLetter
                    ? false
                    : true
                }
              />
            )}

            {bookingJourneyData?.documentation?.AFS?.isAfsVisible &&
              bookingJourneyData?.documentation?.Registration
                ?.isRegistrationScheduled && (
                <JourneyBlock
                  isMilestoneSuccess={
                    bookingJourneyData?.documentation?.Registration
                      ?.isRegistrationScheduled
                  }
                  milestoneName={"AFS Registration"}
                  milestoneSubName={
                    bookingJourneyData?.documentation?.Registration
                      ?.isRegistrationScheduled
                      ? "Completed"
                      : ""
                  }
                  viewText={"View Details"}
                  onViewClick={() =>
                    handleViewRegistration(
                      bookingJourneyData?.documentation?.Registration
                    )
                  }
                  isViewReceiptDisabled={
                    bookingJourneyData?.documentation?.Registration
                      ?.registrationDate
                      ? false
                      : true
                  }
                />
              )}
          </BookingJourneySectionComponent>

          <BookingJourneySectionComponent
            titleText={"PAYMENTS"}
            onClickViewAllReceipts={() => setShowAllPaymentReceipts(true)}
            isSectionCompleted={
              bookingJourneyData?.payments?.length > 0 &&
              paymentPendingList?.length === 0 &&
              true
            }
            isAnyPaymentCompleted={
              bookingJourneyData?.payments?.length ===
              paymentPendingList?.length
                ? false
                : true
            }
            isViewReceiptDisabled={
              bookingJourneyData?.paymentHistory?.length > 0 ? false : true
            }
            viewPaymentReciepts={
              bookingJourneyData?.payments?.length > 0 ? true : false
            }
          >
            {bookingJourneyData?.payments?.map((item: any, index: any) => (
              <JourneyBlock
                isMilestoneSuccess={item?.isPaymentDone}
                milestoneName={item?.paymentMilestone}
                showPaymentDetails={true}
                showPaymentDetailsText={
                  item?.isPaymentDone ? "Payment Completed" : "Payment Pending"
                }
                viewText={"View Details"}
                onViewClick={() => {
                  if (item?.isPaymentDone) {
                    handleOpenCompletedPaymentDialog(item);
                  } else {
                    handleOpenPaymentPendingDialog(item);
                  }
                }}
                isDividerRequired={
                  index === bookingJourneyData?.payments?.length - 1
                    ? false
                    : true
                }
              />
            ))}
          </BookingJourneySectionComponent>

          <BookingJourneySectionComponent
            titleText={"OWNERSHIP"}
            isSectionCompleted={
              bookingJourneyData?.ownership?.documents?.DOC &&
              bookingJourneyData?.ownership?.documents["712"]
            }
            isDisabled={
              bookingJourneyData?.ownership?.documents?.DOC ||
              bookingJourneyData?.ownership?.documents["712"]
                ? false
                : true
            }
          >
            <JourneyBlock
              isMilestoneSuccess={
                bookingJourneyData?.ownership?.documents?.DOC ||
                bookingJourneyData?.ownership?.documents["712"]
              }
              milestoneName={"Documents"}
              isDividerRequired={true}
              isDisabled={
                bookingJourneyData?.ownership?.documents?.DOC ||
                bookingJourneyData?.ownership?.documents["712"]
                  ? false
                  : true
              }
            >
              <JourneyBlock
                isMilestoneSuccess={
                  bookingJourneyData?.ownership?.documents?.DOC
                }
                milestoneName={"Deed of conveyance"}
                viewText={"View"}
                onViewClick={() =>
                  handleViewClick(
                    bookingJourneyData?.ownership?.documents?.DOC?.path
                  )
                }
                isDividerRequired={true}
                childrenDivider={true}
                isDisabled={
                  bookingJourneyData?.ownership?.documents?.DOC ? false : true
                }
              />

              <JourneyBlock
                isMilestoneSuccess={
                  bookingJourneyData?.ownership?.documents["712"]
                }
                milestoneName={"7/12 document"}
                viewText={"View"}
                onViewClick={() =>
                  handleViewClick(
                    bookingJourneyData?.ownership?.documents["712"]?.path
                  )
                }
                isDisabled={
                  bookingJourneyData?.ownership?.documents["712"] ? false : true
                }
              />
            </JourneyBlock>
          </BookingJourneySectionComponent>

          <BookingJourneySectionComponent
            titleText={"POSSESSION"}
            isDisabled={
              bookingJourneyData?.possession?.handover?.handoverFlag
                ? false
                : true
            }
            isSectionCompleted={
              bookingJourneyData?.possession?.handover?.handoverFlag
            }
          >
            <JourneyBlock
              isMilestoneSuccess={
                bookingJourneyData?.possession?.handover?.handoverFlag
              }
              milestoneName={"Handover"}
              isDividerRequired={true}
              isDisabled={
                bookingJourneyData?.possession?.handover?.handoverFlag
                  ? false
                  : true
              }
            >
              <JourneyBlock
                isMilestoneSuccess={
                  bookingJourneyData?.possession?.handover?.handoverFlag
                }
                milestoneName={"Handover Completed"}
                viewText={"View details"}
                onViewClick={() =>
                  handleHandOver(bookingJourneyData?.possession?.handover)
                }
                childrenDivider={true}
                isDividerRequired={true}
                isDisabled={
                  bookingJourneyData?.possession?.handover?.handoverFlag
                    ? false
                    : true
                }
              />

              <JourneyBlock
                isMilestoneSuccess={
                  bookingJourneyData?.possession?.handover?.handoverFlag &&
                  location?.state?.project?.id &&
                  !!projectContentDetails?.customerGuideLines?.value?.url
                }
                milestoneName={"Customer Guidelines"}
                viewText={"View"}
                onViewClick={() =>
                  handleViewCustomerGuildline(
                    projectContentDetails?.customerGuideLines?.value?.url
                  )
                }
                isDisabled={
                  bookingJourneyData?.possession?.handover?.handoverFlag &&
                  location?.state?.project?.id &&
                  !!projectContentDetails?.customerGuideLines?.value?.url
                    ? false
                    : true
                }
              />
            </JourneyBlock>
          </BookingJourneySectionComponent>

          <BookingJourneySectionComponent
            titleText={"Land MANAGEMENT"}
            isDisabled={
              bookingJourneyData?.facility?.isFacilityVisible ? false : true
            }
            isSectionCompleted={bookingJourneyData?.facility?.isFacilityVisible}
          >
            <JourneyBlock
              isMilestoneSuccess={
                bookingJourneyData?.facility?.isFacilityVisible
              }
              milestoneName={"Manage my Land"}
              isDisabled={
                bookingJourneyData?.facility?.isFacilityVisible ? false : true
              }
              showFMBtn={true}
              isFMBtnDisabled={
                bookingJourneyData?.facility?.isFacilityVisible ? false : true
              }
            />
          </BookingJourneySectionComponent>
        </Box>
      )}
    </Fragment>
  );
};

export default BookingJourney;
