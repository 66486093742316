import CurrentInfraItem from "./CurrentInfraItem";
import styles from "./CurrentInfra.module.css";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import { Carousel } from "@mantine/carousel";
import { numOfItems } from "utils/helperFunctions";
import { useSelector } from "react-redux";

const CurrentInfra = () => {
  const { currentInfraStory } = useSelector((state: any) => ({
    currentInfraStory:
      state?.investmentDetails?.investmentDetails?.projectContent
        ?.opportunityDoc?.currentInfraStory,
  }));

  return (
    <div>
      <h2 className={styles["section-heading"]}>
        {currentInfraStory !== null && currentInfraStory?.heading !== null
          ? currentInfraStory.heading
          : "Current Infra Story"}
      </h2>
      <div>
        {currentInfraStory !== null &&
          currentInfraStory?.stories !== null &&
          currentInfraStory?.stories.length && (
            <MantineCarousel
              slideSize={numOfItems(4)}
              controlsOffset={0}
              breakpoints={[
                { maxWidth: "md", slideSize: "50%" },
                { maxWidth: "sm", slideSize: "70%" },
              ]}
            >
              {currentInfraStory?.stories.map(
                (
                  detail: typeof currentInfraStory.stories[0],
                  detailIndex: number
                ) => (
                  <Carousel.Slide key={detailIndex}>
                    <CurrentInfraItem infra={detail} />
                  </Carousel.Slide>
                )
              )}
            </MantineCarousel>
          )}
      </div>
    </div>
  );
};

export default CurrentInfra;
