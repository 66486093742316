import React, {useState, useEffect} from "react";
import { Stack, Typography, Box, Dialog, DialogContent } from "@mui/material";
import styles from "./MyAccount.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AccountDetailedCard from "./account-detailed-card/AccountDetailedCard";
import { useDispatch, useSelector } from "react-redux";
import { DocumentsActions } from "redux-container/documents/DocumentsRedux";
import Loader from "components/loader/Loader";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";
const actionDispatch = (dispatch: any) => {
  return {
    downloadDoc: (path: any) =>
      dispatch(DocumentsActions.getDownloadDocDataRequest(path)),
    setDocumentsState: (key: any, value: any) =>
      dispatch(DocumentsActions.setDocumentsState(key, value)),
  };
};

const AllDocuments = () => {
  const { downloadDoc, setDocumentsState } = actionDispatch(useDispatch());
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const documentBaseString = useSelector(
    (state: any) => state?.documents?.downloadDocData
  );
  const isDocLoading = useSelector((state: any) => state?.documents?.isLoading);
  const location: any = useLocation();
  const [showPdf, setShowPdf] = useState(false);
  const [baseUrlString, setBaseUrlString] = useState('');
  const docError = useSelector((state: any) => state?.documents?.error);
  const showPDFPreview = useSelector((state: any) => state?.documents?.showPDFPreview);

  const documentDetails = location?.state;

  const handleViewReceipts = async (path: any) => {
    await downloadDoc(path);
    setShowPdf(true);
  };

  const dataURItoBlob = (dataURI: any) => {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  useEffect(() => {
      const blob = dataURItoBlob(documentBaseString);
      const url = URL.createObjectURL(blob);
      
    if(showPDFPreview && showPdf){
        window.open(url);
        setDocumentsState('showPDFPreview', false)
        setShowPdf(false);
    }else if(showPdf && !documentBaseString && !baseUrlString){
      setDocumentsState('error', `Couldn't Fetch Data. Try Again!`)
    }
  }, [showPDFPreview]);

  const handleSnackBarclose = () => {
    setDocumentsState('error', null)
  }
  return (
    <div>
      <MuiSnackbar
        shouldOpen={docError ? true : false}
        message={docError ? docError : ''}
        isSuccess={false}
        closeHandler={handleSnackBarclose}
      />
      <div className={styles["page-heading"]}>
        <Stack spacing={2} justifyContent={"start"}>
          <KeyboardArrowLeftIcon onClick={handleNavigateBack} />
          <Typography className={styles["section-heading-name"]}>
            Documents
          </Typography>
        </Stack>
      </div>

      {isDocLoading ? (
        <Loader />
      ) : (
        <Box mt={3} mb={3}>
          {documentDetails?.map((item: any, index: number) => (
            <AccountDetailedCard
              key={index}
              contentName={item?.name}
              handleViewClick={() => handleViewReceipts(item?.path)}
            />
          ))}
        </Box>
      )}

    </div>
  );
};

export default AllDocuments;
