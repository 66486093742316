import { Box } from "@mui/material";
import { FocusEventHandler, Fragment } from "react";

interface IMediaUpload {
  ondrop?: (event: any) => void;
  onChange: (event: any) => void;
  name?: string;
  value?: string;
  disabled?: boolean;
  loading?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  uploadTitle: string;
  accept?: string;
}

const MediaUpload = (props: IMediaUpload) => {
  const {
    ondrop,
    disabled,
    onBlur,
    name,
    onChange,
    uploadTitle,
    accept = ".jpg,.png",
    value,
  } = props;
  return (
    <Fragment>
      <Box component={"label"} htmlFor={name}>
        <input
          disabled={disabled}
          accept={accept}
          id={name}
          type="file"
          name={name}
          value={value}
          hidden
          onBlur={onBlur}
          onChange={(e) => {
            onChange(e);
          }}
          onDrop={(e: any) => {
            if (ondrop) {
              ondrop(e);
            }
          }}
        />
        <Box sx={{ cursor: "pointer" }} component={"span"}>
          {uploadTitle}
        </Box>
      </Box>
    </Fragment>
  );
};

export default MediaUpload;
