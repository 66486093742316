import { ButtonArrowIcon, MuiStyledButton } from "components/mui-buttons/MuiButtons";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import styles from "./ProjectFaq.module.css";
import EastRoundedIcon from "@mui/icons-material/EastRounded";

const ProjectFaq = () => {
  const navigate = useNavigate();

  const { investmentDetails, faqs } = useSelector((state: any) => ({
    investmentDetails: state?.investmentDetails?.investmentDetails,
    faqs: state?.investmentDetails?.investmentDetails.projectContentsAndFaqs,
  }));

  const handleNavigateToFaqs = () => {
    navigate(RouteConfigurations.faqs, {
      state: {
        projectId: investmentDetails?.projectContent?.id,
        launchName: investmentDetails?.projectContent?.launchName,
      },
    });
  };

  const navigateToChat = () => {
    navigate(RouteConfigurations.chat);
  };

  return (
    <div className="section-holder">
      <div className="section-heading-holder">
        <h1>
          {investmentDetails?.projectContent?.otherSectionHeadings?.faqSection
            .sectionHeading !== null
            ? investmentDetails?.projectContent?.otherSectionHeadings
                ?.faqSection.sectionHeading
            : "Frequently asked Questions"}
        </h1>
        <MuiStyledButton
          endIcon={<ButtonArrowIcon />}
          variant="text"
          color="primary"
          onClick={handleNavigateToFaqs}
        >
          READ ALL
        </MuiStyledButton>
      </div>

      {faqs &&
        faqs.length > 0 &&
        faqs.map((faq: any) => {
          return (
            <div className={styles["faq-card"]} onClick={handleNavigateToFaqs}>
              <p>{faq?.frequentlyAskedQuestion?.faqQuestion?.question}</p>
              <EastRoundedIcon htmlColor="#17171c" />
            </div>
          );
        })}

      <div className={styles["btn-holder"]}>
        <Button className="btn btn-dark" onClick={navigateToChat}>
          Have any questions? Ask Here
        </Button>
      </div>
    </div>
  );
};

export default ProjectFaq;
