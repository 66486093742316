import moment from "moment";

export const formatDateTime = (date: Date, format: string) => {
  // let dateIST = new Date(date).toLocaleString("en-US", {
  //   timeZone: "Asia/Kolkata",
  // });

  return moment(date).format(format);
};

export const dateConstants = {
  dateFormatMMDDYY: "MM-DD-YY",
  dateFormatDDMMMYY: "DD MMM YYYY",
  dateFormatYYYYMMDD: "YYYY-MM-DD",
  dateFormatDDmmYYYY: "DD/MM/YYYY",
  timeFormathhmmssA: "hh:mm:ss A",
  dateFormatDDthmmYY: "Do MMMM YYYY",
  ["dateFormatDDthmm'YY"]: "Do MMM' YY",
  dateFormatMMYY: "MMMM YYYY",
};
