import { styled } from "@mui/material";

export const LatestUpdatesStyleWrapper = styled("div")`
  margin: 1rem 0 5rem;
  .section-title {
    font-size: 1.875rem;
    font-family: "Jost-Medium";
    font-family: 500;
    ${({ theme }) => theme.breakpoints.down("sm")} {
      font-size: 16px;
    }
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: 1rem 0 2rem;
  }
`;
