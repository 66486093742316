import { Carousel } from "@mantine/carousel";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import { useSelector } from "react-redux";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useNavigate } from "react-router-dom";
import styles from "./VideosSection.module.css";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import EastIcon from "@mui/icons-material/East";
import { useEffect, useState } from "react";
import MediaViewModal from "components/media-view-modal/MediaViewModal";
import VideoCard from "./VideoCard";

const VideosSection = () => {
  const navigate = useNavigate();

  const { investmentDetails, medias } = useSelector((state: any) => ({
    investmentDetails: state?.investmentDetails?.investmentDetails,
    medias:
      state?.investmentDetails?.investmentDetails?.projectContent
        ?.mediaGalleryOrProjectContent[0]?.isVideosActive &&
      state?.investmentDetails?.investmentDetails?.projectContent?.mediaGalleryOrProjectContent[0]?.videos
        ?.filter((item: any) => item?.status === "1001")
        ?.concat(
          state?.investmentDetails?.investmentDetails?.projectContent
            ?.mediaGalleryOrProjectContent[0]?.isDroneShootsActive &&
            state?.investmentDetails?.investmentDetails?.projectContent?.mediaGalleryOrProjectContent[0]?.droneShoots?.filter(
              (item: any) => item?.status === "1001"
            )
        ),
  }));

  const handleClickSeeAllMediaGallery = () => {
    navigate(RouteConfigurations.mediaGallery, {
      state: {
        galleryData:
          investmentDetails?.projectContent?.mediaGalleryOrProjectContent[0],
        tabIndex: 1,
      },
    });
  };

  const [openMediaView, setOpenMediaView] = useState<boolean>(false);
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaTitle, setMediaTitle] = useState("");

  const handleCloseMediaView = () => {
    setOpenMediaView(false);
  };

  useEffect(() => {
    return () => handleCloseMediaView();
  }, []);

  return (
    <div className={styles["video-section-holder"]}>
      <div className="section-heading-holder">
        <h1>{investmentDetails?.projectContent?.mediaGallerySectionHeading}</h1>
        <MuiStyledButton
          endIcon={<EastIcon />}
          variant="text"
          color="primary"
          onClick={handleClickSeeAllMediaGallery}
        >
          SEE ALL
        </MuiStyledButton>
      </div>
      <MediaViewModal
        open={openMediaView}
        handleClose={handleCloseMediaView}
        mediaName={mediaTitle}
        mediaUrl={mediaUrl}
        contentType={"video"}
      />

      {medias && medias !== null && medias.length > 0 && (
        <MantineCarousel
          slideSize="40%"
          slidesToScroll={1}
          align={"start"}
          slideGap={"xl"}
          mx={"auto"}
          includeGapInSize={false}
          controlsOffset={0}
          breakpoints={[
            { maxWidth: "md", slideSize: "50%" },
            { maxWidth: "sm", slideSize: "70%" },
          ]}
        >
          {medias?.map((media: any, index: any) => {
            return (
              <Carousel.Slide key={index}>
                {/* <ImageCard
                  contentType={"video"}
                  mediaUrl={media?.mediaContent?.value}
                  title={media?.name}
                  mediaContentData={medias}
                  initialIndex={index}
                  isVideoCard={true}
                /> */}
                <VideoCard
                  contentType={"video"}
                  title={media?.name}
                  mediaUrl={media?.mediaContent?.value}
                  handleOnClick={() => {
                    setMediaTitle(media?.name);
                    setMediaUrl(media?.mediaContent?.value?.url);
                    setOpenMediaView(true);
                  }}
                />
              </Carousel.Slide>
            );
          })}
        </MantineCarousel>
      )}
    </div>
  );
};

export default VideosSection;
