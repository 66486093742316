import { Fragment, useState } from "react";
import {
  Stack,
  Typography,
  Box,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { Masonry } from "@mui/lab";
import {
  ButtonArrowIcon,
  MuiLoadingButton,
  MuiStyledButton,
} from "components/mui-buttons/MuiButtons";
import moment from "moment";
import ImageViewDialog from "pages/profile/my-account/image-view-dialog/ImageViewDialog";
import { handleUrlLink } from "utils/helperFunctions";

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1627308595229-7830a5c91f9f",
    title: "Snacks",
  },
  {
    img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
    title: "Mushrooms",
  },
  {
    img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
    title: "Sea star",
  },
  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: "Honey",
  },
  {
    img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    title: "Basketball",
  },
  {
    img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    title: "Basketball",
  },
  {
    img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    title: "Basketball",
  },
];

const MasonryGridSection = ({ galleryItems, onClickSeeAll }: any) => {
  // projectInformation --> latestMediaGalleryOrProjectContent

  const [showImageDialog, setShowImageDialog] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imageTitle, setImageTitle] = useState("");

  const handleOnClickShowImageDialog = (imageUrl: string, title: string) => {
    setImageUrl(imageUrl);
    setImageTitle(title);
    setShowImageDialog(true);
  };

  const handleCloseImageViewDialog = () => {
    setShowImageDialog(false);
  };

  return (
    <Fragment>
      <ImageViewDialog
        open={showImageDialog}
        title={imageTitle}
        handleClose={handleCloseImageViewDialog}
        imageUrl={imageUrl}
        contentType={"img"}
      />
      <Stack my={"2rem"} alignItems={"flex-start"}>
        <Typography variant="h5" fontFamily={"Jost-Medium"}>
          Latest Images & Videos
          <Typography
            fontFamily={"Jost-Light"}
            variant="body2"
            color={"secondary.dark"}
            lineHeight={3}
          >
            Last Updated on{" "}
            <Typography
              component={"span"}
              variant={"body2"}
              fontFamily={"Jost-Medium"}
            >
              {moment(galleryItems[0]?.updatedAt).format("Do MMM YYYY")}
            </Typography>
          </Typography>
        </Typography>
        <MuiStyledButton
          variant="text"
          endIcon={<ButtonArrowIcon />}
          onClick={onClickSeeAll}
        >
          SEE ALL
        </MuiStyledButton>
      </Stack>
      <Box
        sx={{
          width: "100%",
          height: {
            xs: "30vh",
            sm: "45vh",
            md: "45vh",
            lg: "65vh",
            xl: "65vh",
          },
          overflow: "auto",
        }}
      >
        <ImageList variant="masonry" cols={4} gap={10} sx={{ height: "100%" }}>
          {galleryItems[0]?.images
            ?.filter((image: Record<string, any>) => image?.status === "1001")
            .map((image: Record<string, any>, index: number) => (
              <ImageListItem key={index + 1}>
                <Box
                  component={"img"}
                  src={image?.mediaContent?.value?.url}
                  alt={image?.name}
                  onClick={() =>
                    handleOnClickShowImageDialog(
                      image?.mediaContent?.value?.url,
                      image?.name
                    )
                  }
                  loading="lazy"
                  sx={{
                    borderRadius: "10px",
                    display: "block",
                    width: "100%",
                    height: {
                      xs: "12vh",
                      sm: "20vh",
                      md: "20vh",
                      lg: "30vh",
                      xl: "30vh",
                    },
                  }}
                />
              </ImageListItem>
            ))}
        </ImageList>
      </Box>
    </Fragment>
  );
};

export default MasonryGridSection;
