import {
  Dialog,
  DialogContent,
  Stack,
  IconButton,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./ImageViewDialog.module.css";
import { Overlay } from "@mantine/core";
interface IImageViewDialog {
  open: boolean;
  documentString?: string;
  handleClose: () => void;
  title: string;
  imageUrl?: string;
  videoUrl?: string;
  mediaUrlObject?: any;
  contentType?: string;
}

const ImageViewDialog = (props: IImageViewDialog) => {
  const {
    open,
    documentString,
    imageUrl,
    handleClose,
    title,
    videoUrl,
    contentType = "img",
  } = props;

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const imageDetails = documentString
    ? `data:image/png;base64,${documentString}`
    : imageUrl;

  return (
    <div>
      {open && (
        <Overlay onClick={handleClose} opacity={0.2} color="#000" blur={5} />
      )}

      <Dialog
        hideBackdrop
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            marginTop: "100px",
            textAlign: "center",
          },
        }}
        className={styles["background-transparent"]}
        fullScreen={isMobileScreen ? true : false}
        maxWidth={!isMobileScreen && "md"}
      >
        <DialogTitle className={styles["dialog-tilte"]}>
          <Stack justifyContent={"start"} spacing={3}>
            <IconButton onClick={handleClose}>
              <CloseIcon className={styles["white-color"]} color={"inherit"} />
            </IconButton>

            <Typography variant="body1" className={styles["white-color"]}>
              {title}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent className={styles["dialog-content"]}>
          {contentType === "video" ? (
            <iframe
              allow="fullscreen"
              width={isMobileScreen ? "100%" : "800px"}
              height={isMobileScreen ? "55%" : "500px"}
              src={imageUrl}
            />
          ) : (
            <img className={styles["dialog-image"]} src={imageDetails} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ImageViewDialog;
