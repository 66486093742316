import {
  Box,
  Stack,
  Typography,
  Divider,
  Grid,
  Chip,
  Collapse,
  Avatar,
} from "@mui/material";
import {
  ButtonArrowIcon,
  MuiLoadingButton,
  MuiStyledButton,
} from "components/mui-buttons/MuiButtons";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import PlaceIcon from "@mui/icons-material/Place";
import { customDateFormat } from "utils/DateUtils";
import { numDifferentiation } from "utils/helperFunctions";
import { IPortfolioComponentProps } from "../Portfolio";
import { RouteConfigurations } from "routes/RouteConfigurations";
import InfoIconComponent from "components/info-icon-component/InfoIconComponent";
import MediaAssets from "assets";

const Seperator = (
  <Grid
    item
    xs={12}
    sm={12}
    md={12}
    lg={12}
    xl={12}
    borderBottom={"2px dashed #8b8773"}
    margin={"1rem 0"}
  ></Grid>
);

const InvestmentDetailAccordian = ({
  investmentDetail,
  isSmallScreen,
  navigateto,
}: IPortfolioComponentProps) => {
  const { state }: IPortfolioComponentProps = useLocation();
  const navigate = useNavigate();
  const [expand, setExpand] = useState(false);
  const isBookingComplete = state.projects?.investment?.isBookingComplete;
  const isFullForm = true;

  return (
    <Box className="investment-detail-container">
      <Stack mb={"0.5rem"}>
        <Typography
          variant={isSmallScreen ? "body2" : "body1"}
          color={"warning.A100"}
          fontFamily={"Jost-Medium"}
          paddingLeft={isSmallScreen ? "0.5rem" : "1rem"}
        >
          Investment Details
        </Typography>
        {/* removed view booking journey button from here.. and moved it to portfolioDetails.tsx as per New CR */}
      </Stack>
      <Divider variant="fullWidth" sx={{ borderColor: "#e1d7a5" }} />
      <Grid
        container
        rowGap={"1rem"}
        p={isSmallScreen ? "0.5rem" : "1rem 1.5rem"}
        mt={"0.5rem"}
        justifyContent={"space-between"}
      >
        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
          <Typography className="column-header with-icon" variant="body2">
            Investment Amount
            <InfoIconComponent
              infoContent={`Investment Amount = Basic Price + Infra Value` || 0}
              placement="top-start"
              className={"info-icon"}
            />
          </Typography>
          <Typography
            variant={isSmallScreen ? "body2" : "body1"}
            fontFamily={"Jost-Bold"}
          >
            {`₹${
              numDifferentiation(
                state?.projects?.investment?.amountInvested,
                isFullForm
              ) || 0
            }`}
          </Typography>
        </Grid>
        {isBookingComplete ? null : (
          <Grid item xs={3} sm={4} md={2} lg={1.5} xl={1.5}>
            <Typography className="column-header with-icon" variant="body2">
              {"Paid"}
              <InfoIconComponent
                infoContent={`Basic Price + Infra Value Paid`}
                placement="top-start"
                className={"info-icon"}
              />
            </Typography>
            <Typography
              variant={isSmallScreen ? "body2" : "body1"}
              fontFamily={"Jost-Bold"}
            >
              {`₹${
                numDifferentiation(
                  state?.projects?.investment?.paidAmount,
                  isFullForm
                ) || 0
              }`}
            </Typography>
          </Grid>
        )}
        <Grid item xs={isBookingComplete ? 2 : 4} sm={4} md={2} lg={2} xl={2}>
          <Typography className="column-header with-icon" variant="body2">
            {isBookingComplete ? "OEA" : "Pending"}
            {!isBookingComplete && (
              <InfoIconComponent
                infoContent={`Basic Price + Infra Value Pending` || 0}
                placement="top-start"
                className={"info-icon"}
              />
            )}
          </Typography>
          <Typography
            variant={isSmallScreen ? "body2" : "body1"}
            fontFamily={"Jost-Bold"}
          >
            {isBookingComplete ? (
              <Chip
                className="increment-badge"
                icon={
                  state?.projects?.investment?.projectIea !== "---" ? (
                    <KeyboardDoubleArrowUpIcon fontSize="small" />
                  ) : undefined
                }
                label={`${state?.projects?.investment?.projectIea}`}
              />
            ) : (
              `₹${
                numDifferentiation(
                  state?.projects?.investment?.pendingAmount,
                  isFullForm
                ) || 0
              }*`
            )}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={4} md={2} lg={2} xl={2}>
          <Typography className="column-header" variant="body2">
            {"Area"}
          </Typography>
          <Typography
            variant={isSmallScreen ? "body2" : "body1"}
            fontFamily={"Jost-Bold"}
          >
            {`${state?.projects?.investment?.crmInventory?.areaSqFt.toLocaleString()} Sqft`}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
          <Typography className="column-header" variant="body2">
            {isBookingComplete ? "Owned Since" : "Allocation Date"}
          </Typography>
          <Typography
            variant={isSmallScreen ? "body2" : "body1"}
            fontFamily={"Jost-Bold"}
          >
            {state?.projects?.investment?.allocationDate
              ? customDateFormat(state?.projects?.investment?.allocationDate)
              : "---"}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
          <Typography className="column-header" variant="body2">
            {"Possession Date"}
          </Typography>
          <Typography
            variant={isSmallScreen ? "body2" : "body1"}
            fontFamily={"Jost-Bold"}
          >
            {state?.projects?.investment?.possesionDate
              ? customDateFormat(
                  state?.projects?.investment?.possesionDate,
                  "MMM YYYY"
                )
              : "---"}
          </Typography>
        </Grid>
        {/* removed view more/less from here... as per new CR */}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            className="column-header"
            variant="body2"
            textAlign={"end"}
          >
            {"*Rounded"}
          </Typography>
        </Grid>
      </Grid>

      <Box>
        {!expand && (
          <>
            <Divider variant="fullWidth" sx={{ borderColor: "#e1d7a5" }} />
            <Stack flexDirection={"column"}>
              <MuiStyledButton
                variant="text"
                sx={{ color: "warning.A400" }}
                endIcon={<KeyboardArrowDownIcon />}
                onClick={() => setExpand(!expand)}
              >
                {"View more"}
              </MuiStyledButton>
            </Stack>
          </>
        )}
      </Box>

      <Collapse in={expand}>
        <Grid
          container
          gap={"0.5rem"}
          mt={"0.5rem"}
          p={isSmallScreen ? "1rem" : "1rem 2rem"}
          justifyContent={"space-between"}
          bgcolor={"rgba(255, 255, 255, 0.53)"}
          boxShadow={"inset 0 0 25px 0 rgba(22, 21, 14, 0.2)"}
        >
          <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} xl={5.5}>
            <Typography
              component={Stack}
              justifyContent={"flex-start"}
              alignItems={isSmallScreen ? "flex-start" : "center"}
              flexDirection={isSmallScreen ? "column-reverse" : "row"}
              columnGap={isSmallScreen ? 1 : "1rem"}
              variant="body2"
            >
              {"Land ID"}
              <Typography
                component={"span"}
                variant={isSmallScreen ? "body2" : "body1"}
                fontFamily={"Jost-Bold"}
              >
                {state?.projects?.investment?.crmInventory?.name}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} xl={5.5}>
            <Typography
              component={Stack}
              sx={{ wordBreak: "break-word" }}
              alignItems={isSmallScreen ? "flex-start" : "center"}
              flexDirection={isSmallScreen ? "column-reverse" : "row"}
              columnGap={isSmallScreen ? 1 : "1rem"}
              justifyContent={"flex-start"}
              variant="body2"
            >
              {"SKU Type"}
              <Typography
                component={"span"}
                variant={isSmallScreen ? "body2" : "body1"}
                fontFamily={"Jost-Bold"}
              >
                {state?.projects?.investment?.crmInventoryBucket?.name}
              </Typography>
            </Typography>
          </Grid>
          {Seperator}
          <Grid
            item
            xs={isBookingComplete ? 10 : 5.5}
            sm={isBookingComplete ? 10 : 5.5}
            md={3}
            lg={3}
            xl={3}
          >
            <Typography
              variant={isSmallScreen ? "body2" : "body1"}
              fontFamily={"Jost-Bold"}
            >
              {`₹${
                numDifferentiation(
                  isBookingComplete
                    ? investmentDetail?.investmentInformation?.paymentInfo
                        ?.registryCharges?.paid
                    : investmentDetail?.investmentInformation?.paymentInfo
                        ?.registryCharges?.total,
                  isFullForm
                ) || 0
              }*`}
            </Typography>
            <Typography className="column-text with-icon" variant="body2">
              {isBookingComplete
                ? "Registration Charges Paid"
                : "Est. Registry Amount"}
              <InfoIconComponent
                infoContent={
                  `₹ ${investmentDetail?.investmentInformation?.paymentInfo?.registryCharges?.total.toLocaleString()}` ||
                  0
                }
                placement="top-start"
                className={"info-icon"}
              />
            </Typography>
          </Grid>
          {!isBookingComplete ? (
            <>
              <Grid item xs={5.5} sm={5.5} md={2} lg={2} xl={2}>
                <Typography
                  variant={isSmallScreen ? "body2" : "body1"}
                  fontFamily={"Jost-Bold"}
                >
                  {`₹${
                    numDifferentiation(
                      investmentDetail?.investmentInformation?.paymentInfo
                        ?.registryCharges?.paid,
                      isFullForm
                    ) || 0
                  }*`}
                </Typography>
                <Typography className="column-text with-icon" variant="body2">
                  Registry paid
                </Typography>
              </Grid>

              <Grid item xs={5.5} sm={5.5} md={2} lg={2} xl={2}>
                <Typography
                  variant={isSmallScreen ? "body2" : "body1"}
                  fontFamily={"Jost-Bold"}
                >
                  {`₹${
                    numDifferentiation(
                      investmentDetail?.investmentInformation?.paymentInfo
                        ?.registryCharges?.pending,
                      isFullForm
                    ) || 0
                  }*`}
                </Typography>
                <Typography className="column-text with-icon" variant="body2">
                  {"Pending"}
                </Typography>
              </Grid>
            </>
          ) : null}
          {Seperator}
          <Grid item xs={5.5} sm={5.5} md={2} lg={2} xl={2}>
            <Typography
              variant={isSmallScreen ? "body2" : "body1"}
              fontFamily={"Jost-Bold"}
            >
              {`₹${
                numDifferentiation(
                  investmentDetail?.investmentInformation?.paymentInfo
                    ?.otherCharges?.total,
                  isFullForm
                ) || 0
              }*`}
            </Typography>
            <Typography className="column-text with-icon" variant="body2">
              {"Other Charges"}
              <InfoIconComponent
                infoContent={
                  `₹ ${investmentDetail?.investmentInformation?.paymentInfo?.otherCharges?.total.toLocaleString()}` ||
                  0
                }
                placement="top-start"
                className={"info-icon"}
              />
            </Typography>
          </Grid>
          <Grid item xs={5.5} sm={5.5} md={2} lg={2} xl={2}>
            <Typography
              variant={isSmallScreen ? "body2" : "body1"}
              fontFamily={"Jost-Bold"}
            >
              {`₹${
                numDifferentiation(
                  investmentDetail?.investmentInformation?.paymentInfo
                    ?.otherCharges?.paid,
                  isFullForm
                ) || 0
              }*`}
            </Typography>
            <Typography className="column-text with-icon" variant="body2">
              {"Charges Paid"}
              <InfoIconComponent
                infoContent={
                  `₹ ${investmentDetail?.investmentInformation?.paymentInfo?.otherCharges?.paid.toLocaleString()}` ||
                  0
                }
                placement="top-start"
                className={"info-icon"}
              />
            </Typography>
          </Grid>
          <Grid item xs={5.5} sm={5.5} md={2} lg={2} xl={2}>
            <Typography
              variant={isSmallScreen ? "body2" : "body1"}
              fontFamily={"Jost-Bold"}
            >
              {`₹${
                numDifferentiation(
                  investmentDetail?.investmentInformation?.paymentInfo
                    ?.otherCharges?.pending,
                  isFullForm
                ) || 0
              }*`}
            </Typography>
            <Typography className="column-text with-icon" variant="body2">
              {"Pending"}
              <InfoIconComponent
                infoContent={
                  `₹ ${investmentDetail?.investmentInformation?.paymentInfo?.otherCharges?.pending.toLocaleString()}` ||
                  0
                }
                placement="top-start"
                className={"info-icon"}
              />
            </Typography>
          </Grid>
          {Seperator}
          <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
            <Typography
              variant={isSmallScreen ? "body2" : "body1"}
              fontFamily={"Jost-Bold"}
            >
              {state.projects?.project?.address?.latitude}{" "}
              <Typography
                variant="body2"
                fontFamily={"Jost-LightItalic"}
                component={"span"}
              >
                {"Latitude"}
              </Typography>
            </Typography>
          </Grid>
          {state.projects?.project?.address?.longitude ? (
            <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
              <Typography
                variant={isSmallScreen ? "body2" : "body1"}
                fontFamily={"Jost-Bold"}
              >
                {state.projects?.project?.address?.longitude}{" "}
                <Typography
                  variant="body2"
                  fontFamily={"Jost-LightItalic"}
                  component={"span"}
                >
                  {"Longitude"}
                </Typography>
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
            <Typography
              variant={isSmallScreen ? "body2" : "body1"}
              fontFamily={"Jost-Bold"}
            >
              {state.projects?.project?.address?.altitude}{" "}
              <Typography
                variant="body2"
                fontFamily={"Jost-LightItalic"}
                component={"span"}
              >
                {"Altitude"}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <Typography
              component={Stack}
              justifyContent={isSmallScreen ? "center" : "flex-start"}
              variant={isSmallScreen ? "body2" : "body1"}
              fontFamily={"Jost-Bold"}
            >
              <PlaceIcon />
              <Typography
                sx={{ cursor: "pointer" }}
                variant="body2"
                fontFamily={"Jost-LightItalic"}
                component={"span"}
                onClick={() =>
                  navigate(RouteConfigurations.investmentSummaryMap, {
                    state: {
                      investmentLocation: state.projects?.project?.address,
                    },
                  })
                }
              >
                {"See on map"}
              </Typography>
            </Typography>
          </Grid>
          {Seperator}
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Typography variant="body2" fontFamily={"Jost-Light"}>
              {"Current Owners:"}
            </Typography>
          </Grid>
          {investmentDetail?.investmentInformation?.owners?.map(
            (name: string) => (
              <Grid item xs={5} sm={5} md={3} lg={3} xl={3} key={name}>
                <Typography variant="body2">{name}</Typography>
              </Grid>
            )
          )}
          {investmentDetail?.investmentInformation?.crmInventory?.crmReraPhase
            ?.reraNumber ? (
            <>
              {Seperator}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography variant="body2">
                  {`MAHARERA Registration No. Phase I - ${
                    investmentDetail?.investmentInformation?.crmInventory
                      ?.crmReraPhase?.reraNumber || ""
                  }`}
                </Typography>
              </Grid>
            </>
          ) : null}
        </Grid>

        <Box mt={2}>
          {expand && (
            <Stack flexDirection={"column"}>
              <MuiStyledButton
                variant="text"
                sx={{ color: "warning.A400" }}
                endIcon={<KeyboardArrowUpIcon />}
                onClick={() => setExpand(!expand)}
              >
                {"View less"}
              </MuiStyledButton>
            </Stack>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default InvestmentDetailAccordian;
