import ic_info from "./images/ic_info.png";
import ic_logo_white from "./images/ic_logo_white.png";
import ic_refer_friend from "./images/ic_refer_friend.png";
import ic_playstore from "./images/ic_playstore.png";
import ic_manage_land from "./images/ic_manage_land.png";
import ic_playstore_download from "./images/ic_playstore_download.png";
import ic_appstore_download from "./images/ic_appstore_download.png";
import ic_appstore from "./images/ic_appstore.png";
import ic_mobile_app from "./images/ic_mobile_app.png";
import ic_insight from "./images/ic_insight.png";
import ic_chat from "./images/ic_chat.png";
import ic_home_inactive from "./images/ic_home_inactive.png";
import ic_portfolio_inactive from "./images/ic_portfolio_inactive.png";
import ic_promise_inactive from "./images/ic_promise_inactive.png";
import ic_investments_inactive from "./images/ic_investments_inactive.png";
import ic_profile_inactive from "./images/ic_profile_inactive.png";
import ic_portfolio_active from "./images/ic_portfolio_active.png";
import ic_promise_active from "./images/ic_promise_active.png";
import ic_home_active from "./images/ic_home_active.png";
import ic_investments_active from "./images/ic_investments_active.png";
import ic_profile_active from "./images/ic_profile_active.png";
import ic_promise_banner from "./images/ic_full_promise_banner.png";
import ic_promise_banner_small from "./images/ic_small_promise_banner.png";
import ic_wealth from "./images/ic_wealth.png";
import ic_security from "./images/ic_security.png";
import ic_liquidity from "./images/ic_liquidity.png";
import ic_briefcase from "./images/ic_briefcase.png";
import ic_preposition_one from "./images/ic_preposition_one.png";
import ic_preposition_two from "./images/ic_preposition_two.png";
import ic_preposition_three from "./images/ic_preposition_three.png";
import ic_update from "./images/ic_update.png";
import ic_my_account from "./images/ic_my_account.png";
import ic_security_settings from "./images/ic_security_settings.png";
import ic_help_center from "./images/ic_help_center.png";
import ic_facility_management from "./images/ic_facility_management.png";
import ic_edit from "./images/ic_edit.png";
import ic_privacy_policy from "./images/ic_privacy_policy.png";
import ic_faq from "./images/ic_faq.png";
import ic_about_us from "./images/ic_about_us.png";
import ic_feedback from "./images/ic_feedback.png";
import ic_profit from "./images/ic_profit.png";
import ic_siren from "./images/ic_siren.png";
import ic_promotions from "./images/ic_promotions.png";
import ic_bg_image from "./images/ic_bg_image.png";
import ic_home_setting from "./images/ic_home_setting.png";
import ic_gmail from "./images/ic_gmail.png";
import ic_twitter from "./images/ic_twitter.png";
import ic_whatsapp from "./images/ic_whatsapp.png";
import ic_linkedin from "./images/ic_linkedin.png";
import ic_no_document from "./images/ic_no_document.png";
import ic_payment_history from "./images/ic_payment_history.png";
import ic_right_arrow from "./images/ic_right_arrow.png";
import ic_chat_icon from "./images/ic_chat_icon.png";
import ic_chat_refresh from "./images/ic_chat_refresh.png";
import ic_docs from "./images/ic_docs.png";
import ic_active_bad from "./images/ic_active_bad.png";
import ic_active_good from "./images/ic_active_good.png";
import ic_active_ok from "./images/ic_active_ok.png";
import ic_active_poor from "./images/ic_active_poor.png";
import ic_active_excellent from "./images/ic_active_excellent.png";
import ic_inactive_bad from "./images/ic_inactive_bad.png";
import ic_inactive_good from "./images/ic_inactive_good.png";
import ic_inactive_ok from "./images/ic_inactive_ok.png";
import ic_inactive_poor from "./images/ic_inactive_poor.png";
import ic_inactive_excellent from "./images/ic_inactive_excellent.png";
import ic_upload from "./images/ic_upload.png";
import ic_remove from "./images/ic_remove.png";
import ic_close from "./images/ic_close.png";
import ic_close_white from "./images/ic_close_white.png";
import ic_profile_placeholder from "./images/ic_profile_placeholder.png";
import ic_party_left from "./images/ic_party_left.png";
import ic_party_right from "./images/ic_party_right.png";
import ic_logo_gold from "./images/ic_logo_gold.png";
import ic_my_services from "./images/ic_my_services.png";
import ic_my_service_active from "./images/ic_my_service_active.png";
import ic_my_service_inactive from "./images/ic_my_service_inactive.png";
import ic_please_wait from "./images/ic_please_wait.png";
import ic_ok_success from "./images/ic_ok_success.png";
import ic_green_circle from "./images/ic_green_circle.png";
import ic_no_project from "./images/ic_no_project.png";
import ic_rupee_coin from "./images/ic_rupee_coin.png";
import ic_rupee_disabled from "./images/ic_rupee_disabled.png";
import ic_upwards_arrow from "./images/ic_upward_arrow.png";
import ic_download_icon from "./images/ic_download_icon.png";
import ic_red_close from "./images/ic_red_close.png";
import ic_green_tick from "./images/ic_green_tick.png";
import ic_send_arrow from "./images/ic_send_arrow.png";
import ic_next_arrow from "./images/ic_next_arrow.png";
import ic_source_location from "./images/ic_source_location.png";
import ic_destination_location from "./images/ic_destination_location.png";
import ic_promise_card_bg from "./images/ic_promise_card_bg.png";
import ic_button_next_arrow from "./images/ic_button-next-arrow.svg";
import ic_button_next_arrow_black from "./images/ic_next_arrow_black.png";

const MediaAssets = {
  ic_no_project,
  ic_appstore,
  ic_appstore_download,
  ic_info,
  ic_insight,
  ic_logo_white,
  ic_manage_land,
  ic_mobile_app,
  ic_playstore,
  ic_playstore_download,
  ic_refer_friend,
  ic_profile_inactive,
  ic_investments_inactive,
  ic_promise_inactive,
  ic_portfolio_inactive,
  ic_portfolio_active,
  ic_profile_active,
  ic_investments_active,
  ic_chat,
  ic_home_active,
  ic_home_inactive,
  ic_promise_banner,
  ic_liquidity,
  ic_security,
  ic_wealth,
  ic_briefcase,
  ic_preposition_one,
  ic_preposition_two,
  ic_preposition_three,
  ic_update,
  ic_my_account,
  ic_security_settings,
  ic_help_center,
  ic_facility_management,
  ic_edit,
  ic_privacy_policy,
  ic_faq,
  ic_about_us,
  ic_close_white,
  ic_feedback,
  ic_profit,
  ic_siren,
  ic_promotions,
  ic_bg_image,
  ic_home_setting,
  ic_whatsapp,
  ic_twitter,
  ic_gmail,
  ic_linkedin,
  ic_no_document,
  ic_payment_history,
  ic_right_arrow,
  ic_chat_icon,
  ic_chat_refresh,
  ic_docs,
  ic_active_bad,
  ic_active_good,
  ic_active_ok,
  ic_active_excellent,
  ic_active_poor,
  ic_inactive_bad,
  ic_inactive_good,
  ic_inactive_ok,
  ic_inactive_excellent,
  ic_inactive_poor,
  ic_close,
  ic_upload,
  ic_remove,
  ic_profile_placeholder,
  ic_party_left,
  ic_party_right,
  ic_logo_gold,
  ic_my_services,
  ic_promise_active,
  ic_please_wait,
  ic_ok_success,
  ic_green_circle,
  ic_rupee_disabled,
  ic_rupee_coin,
  ic_upwards_arrow,
  ic_download_icon,
  ic_red_close,
  ic_green_tick,
  ic_send_arrow,
  ic_promise_banner_small,
  ic_next_arrow,
  ic_my_service_active,
  ic_my_service_inactive,
  ic_source_location,
  ic_destination_location,
  ic_promise_card_bg,
  ic_button_next_arrow,
  ic_button_next_arrow_black,
};

export default MediaAssets;
