import { useNavigate } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import styles from "./ProfileMenuItem.module.css";

interface IMenuProps {
  title: string;
  subtitle: string;
  icon: string;
  route: string;
  currentIndex?: number;
  maxIndex?: number;
}

const ProfileMenuItem = ({
  title,
  subtitle,
  icon,
  route,
  currentIndex = 0,
  maxIndex = 1,
}: IMenuProps) => {
  const navigate = useNavigate();

  const handleRouteChange = () => {
    navigate(route);
  };

  return (
    <div
      className={
        maxIndex === currentIndex + 1 ? styles["menu"] : styles["index-menu"]
      }
      onClick={handleRouteChange}
    >
      <div className={styles["menu-info-holder"]}>
        <img src={icon} alt="" />
        <div className={styles["menu-info"]}>
          <h3>{title}</h3>
          <p>{subtitle}</p>
        </div>
      </div>

      <EastIcon className={styles["east-icon"]} />
    </div>
  );
};

export default ProfileMenuItem;
