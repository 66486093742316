import React, { Fragment, useState, useEffect } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  Stack,
  Typography,
  Box,
  Avatar,
  Button,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./SecuritySettings.module.css";
import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";
import MediaAssets from "assets";
import MenuItems from "./menu-items/MenuItems";
import MuiActionStatusDialog from "components/mui-dialogs/MuiActionStatusDialog";
import { ProfileAction } from "redux-container/profile/ProfileRedux";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/Loader";
import { TroubleSigninAction } from "redux-container/login/trouble-signingin-redux/TroubleSigninginRedux";
import { caseTypeEnum } from "enumerations/CaseTypeEnums";
import SecurityTips from "./security-tips/SecurityTips";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import NewUserNameInfoActions from "redux-container/login/new-user-name-info/NewUserNameInfoRedux";
import VerifyOtpActions from "redux-container/login/verify-otp/VerifyOtpRedux";
import GenerateOtpActions from "redux-container/login/generate-otp/GenerateOtpRedux";
import { RouteConfigurations } from "routes/RouteConfigurations";

const actionDispatch = (dispatch: any) => {
  return {
    getProfileDetails: () => dispatch(ProfileAction.getProfileDetailsRequest()),
    editProfileUserSettings: (params: any) =>
      dispatch(ProfileAction.editProfileUserSettingsRequest(params)),
    addTroubleSigninRequest: (data: any) =>
      dispatch(TroubleSigninAction.addTroubleSigninRequest(data)),
    setTroubleSigninState: (key: any, value: any) =>
      dispatch(TroubleSigninAction.setTroubleSigninState(key, value)),
    setProfileState: (key: any, value: any) =>
      dispatch(ProfileAction.setProfileState(key, value)),
    logoutFromAllDevices: () => dispatch(ProfileAction.logoutAllRequest()),
    logoutRequest: () => dispatch(ProfileAction.logoutRequest()),
    setGenerateOtpState: (key: any, data: any) =>
      dispatch(GenerateOtpActions.setGenerateOtpState(key, data)),
    setVerifyOtpState: (key: any, data: any) =>
      dispatch(VerifyOtpActions.setVerifyOtpState(key, data)),

    resetGenerateOtpState: () =>
      dispatch(GenerateOtpActions.resetGenerateOtpState()),
    resetVerifyOtpState: () => dispatch(VerifyOtpActions.resetVerifyOtpState()),
    resetNewUserNameInfoState: () =>
      dispatch(NewUserNameInfoActions.resetNewUserNameInfoState()),
  };
};

const SecuritySettings = () => {
  const [securityTipsDialog, setsecurityTipsDialog] = useState(false);
  const {
    getProfileDetails,
    editProfileUserSettings,
    setTroubleSigninState,
    addTroubleSigninRequest,
    setProfileState,
    logoutFromAllDevices,
    logoutRequest,
    resetGenerateOtpState,
    resetVerifyOtpState,
    resetNewUserNameInfoState,
  } = actionDispatch(useDispatch());
  const navigate = useNavigate();
  const profileData = useSelector(
    (state: any) => state?.profile?.profileDetails
  );
  const isLoading = useSelector((state: any) => state?.profile?.isLoading);
  const [isWhatsAppCommActive, setIsWhatsAppCommActive] = useState(
    profileData?.whatsappConsent ? profileData?.whatsappConsent : false
  );
  const [isPushNotifyActive, setIsPushNotifyActive] = useState(
    profileData?.showPushNotifications
      ? profileData?.showPushNotifications
      : false
  );
  const [openSecurityTip, setOpenSecurityTip] = useState(false);
  const isResponseDialogOpen = useSelector(
    (state: any) => state?.troubleSignin?.isResponseDialogOpen
  );
  const profileError = useSelector((state: any) => state?.profile?.error);

  useEffect(() => {
    getProfileDetails();
  }, [profileData?.length]);

  useEffect(() => {
    const params = {
      whatsappConsent: isWhatsAppCommActive,
      showPushNotifications: isPushNotifyActive,
    };
    editProfileUserSettings(params);
  }, [isWhatsAppCommActive, isPushNotifyActive]);

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const handleChangeWhatsAppComm = () => {
    setIsWhatsAppCommActive(!isWhatsAppCommActive);
  };

  const handleChangePushNotify = () => {
    setIsPushNotifyActive(!isPushNotifyActive);
  };

  const handleClosesecurityTipsDialog = () => {
    setsecurityTipsDialog(false);
  };

  const handleSecurityEmergencyClick = () => {
    const params = {
      description: "Report Security Emergency",
      caseType: caseTypeEnum.SECURITY_EMERGENCY,
    };
    addTroubleSigninRequest(params);

    setTroubleSigninState("isResponseDialogOpen", true);
  };

  const handleDialogClose = () => {
    setTroubleSigninState("isResponseDialogOpen", false);
  };

  const handleOpenSecurityTip = () => {
    setOpenSecurityTip(true);
    setDrawer(true);
  };
  const [drawer, setDrawer] = useState(false);
  const [showLoaderOnMount, setShowLoaderOnMount] = useState(true);
  const [openConfimationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  useEffect(() => {
    if (isLoading && showLoaderOnMount) {
      setShowLoaderOnMount(false);
    }
    setDrawer(false);
  }, [isLoading]);

  const onClickOnCloseIcon = () => {
    setDrawer(false);
  };

  const handleSnackBarclose = () => {
    setProfileState("error", null);
  };

  const isSecurityTipsActive = useSelector(
    (state: any) =>
      state?.profile?.profileDetails?.pageManagement?.data?.page
        ?.isSecurityTipsActive
  );

  const handleLogoutOtherDevices = () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseLogoutDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleLogoutFromAllDevices = () => {
    logoutFromAllDevices();
    logoutRequest();
    resetGenerateOtpState();
    resetVerifyOtpState();
    resetNewUserNameInfoState();
    localStorage.clear();
    navigate(RouteConfigurations.login);
    setOpenConfirmationDialog(false);
  };

  return (
    <Fragment>
      <MuiActionStatusDialog
        maxWidth={"sm"}
        issuccess={true}
        isconfirmdialog={true}
        responsemessage={"Your query has been submitted successfully."}
        message={"We will contact you shortly."}
        open={isResponseDialogOpen}
        handleclose={handleDialogClose}
      />

      <ConfirmationDialog
        shouldOpen={openConfimationDialog}
        content="Are you sure you want to logout from all devices?"
        okText="Yes"
        cancelBtnText="No"
        cancelHandler={handleCloseLogoutDialog}
        okHandler={handleLogoutFromAllDevices}
      />
      {isLoading && showLoaderOnMount && profileData === null ? (
        <Loader />
      ) : (
        <div className={styles["component-spacing-mobile"]}>
          <Stack
            spacing={2}
            justifyContent={"start"}
            alignItems={"center"}
            textAlign={"start"}
          >
            <KeyboardArrowLeftIcon
              onClick={handleNavigateBack}
              className={styles["pointer-cursor"]}
            />
            <Typography
              variant={"body1"}
              className={styles["section-heading-title"]}
              sx={{ fontFamily: "Jost-semiBold" }}
            >
              Security & Settings
            </Typography>
          </Stack>

          <Box mt={4}>
            <Typography
              // className={styles["font-jost-medium"]}
              paddingLeft={2}
              variant={"body1"}
              sx={{ fontFamily: "Jost-Medium" }}
            >
              Security
            </Typography>

            <div
              className={`${styles["emergency-btn-content"]} ${styles["contain-padding"]} margin-top-15px`}
            >
              <MuiLoadingButton
                className={`${styles["emergency-btn"]}`}
                startIcon={
                  <Avatar
                    className={styles["siren-icon"]}
                    src={MediaAssets.ic_siren}
                  />
                }
                onClick={handleSecurityEmergencyClick}
              >
                Report a security emergency
              </MuiLoadingButton>
            </div>
          </Box>

          <Box mt={3}>
            <MenuItems
              title={"Whatsapp Communication"}
              subTitle={" Allow or disallow communication"}
              switchValue={isWhatsAppCommActive}
              handleSwitchChange={handleChangeWhatsAppComm}
            />
            {isSecurityTipsActive && (
              <MenuItems
                title={"Read Security Tips"}
                subTitle={"Tips to keep your App usage secure"}
                showSwitch={false}
                disableTopBorder={true}
                handleClick={handleOpenSecurityTip}
              />
            )}
            <MenuItems
              title={"Log Out From Other Devices"}
              subTitle={"Manage your device access"}
              showSwitch={false}
              disableTopBorder={true}
              handleClick={handleLogoutOtherDevices}
            />
          </Box>

          <Box mt={3} mb={3}>
            <Typography
              // className={styles["font-jost-regular"]}
              paddingLeft={2}
              variant={"body1"}
              sx={{ fontFamily: "Jost-Medium" }}
            >
              Settings
            </Typography>

            <Box mt={2}>
              <MenuItems
                title={"Send Push Notifications"}
                subTitle={"Allow or disallow push notifications"}
                switchValue={isPushNotifyActive}
                handleSwitchChange={handleChangePushNotify}
              />
            </Box>
          </Box>
        </div>
      )}

      <MuiSnackbar
        shouldOpen={profileError ? true : false}
        message={profileError ? profileError : ""}
        isSuccess={false}
        closeHandler={handleSnackBarclose}
      />

      <SecurityTips drawer={drawer} onClickOnCloseIcon={onClickOnCloseIcon} />
    </Fragment>
  );
};

export default SecuritySettings;
