import {
  Box,
  createTheme,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BookingJourneyDetails from "./booking-journey/BookingJourneyDetails";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import styles from "./BookingJourney.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import ViewAllPayments from "./view-all-payment/ViewAllPayments";
import { InvestmentDetailsActions } from "redux-container/investments/investment-details/InvestmentDetailsRedux";
import { useDispatch, useSelector } from "react-redux";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";

const actionDispatch = (dispatch: any) => {
  return {
    getInvestmentDetails: (id: number) =>
      dispatch(InvestmentDetailsActions.getInvestmentDetailsRequest(id)),
    setInvestmentState: (key: any, value: any) =>
      dispatch(InvestmentDetailsActions.setInvestmentDetailsState(key, value)),
  };
};

const BookingJourney = () => {
  const [showAllPaymentReceipts, setShowAllPaymentReceipts] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [paymentReceipts, setPaymentsReceipts] = useState([]);

  const { getInvestmentDetails, setInvestmentState } = actionDispatch(
    useDispatch()
  );

  const navigate = useNavigate();
  const handleNavigateBack = () => {
    navigate(-1);
  };

  const location: any = useLocation();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (location.state?.project?.id) {
      const projectId = location.state?.project?.id;
      getInvestmentDetails(projectId);
    }
    setShowAllPaymentReceipts(false);
  }, []);

  const projectContent = useSelector(
    (state: any) => state?.investmentDetails?.investmentDetails?.projectContent
  );
  const error = useSelector((state: any) => state?.investmentDetails?.error);

  const handleCloseSnackBar = () => {
    setInvestmentState("error", null);
  };

  return (
    <>
      <ViewAllPayments
        shouldOpen={showAllPaymentReceipts}
        showAllPaymentReceipts={showAllPaymentReceipts}
        setShowAllPaymentReceipts={setShowAllPaymentReceipts}
        paymentReceipts={paymentReceipts}
        paymentHistory={paymentHistory}
      />
      <MuiSnackbar
        shouldOpen={error !== null ? true : false}
        message={error}
        isSuccess={false}
        closeHandler={handleCloseSnackBar}
      />
      <div>
        <Box mt={isSmallerScreen ? 2 : 0}>
          <Stack
            spacing={2}
            justifyContent={"start"}
            alignItems={"center"}
            textAlign={"start"}
          >
            <KeyboardArrowLeftIcon
              className={styles["left-arrow"]}
              fontSize={"medium"}
              onClick={handleNavigateBack}
            />
            <Typography className={styles["section-heading-name"]}>
              Booking Journey
            </Typography>
          </Stack>

          <Box mt={3} pl={isSmallerScreen ? 0 : 8} pr={isSmallerScreen ? 1 : 8}>
            <Stack alignItems={"baseline"}>
              <Stack
                ml={isSmallerScreen ? 1 : 0}
                flexDirection={"column"}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <Stack
                  alignItems={isSmallerScreen ? "flex-start" : "center"}
                  flexDirection={isSmallerScreen ? "column" : "row"}
                  spacing={isSmallerScreen ? 1 : 2}
                >
                  <Typography
                    className={`${styles["section-heading-name-2"]} ${styles["line-ellipsis"]}`}
                  >
                    {projectContent?.launchName
                      ? projectContent?.launchName
                      : location?.state?.project?.launchName
                      ? location?.state?.project?.launchName
                      : "-"}
                  </Typography>
                  <Typography
                    mt={
                      !isSmallerScreen &&
                      projectContent?.launchName?.length > 16
                        ? 12
                        : 0
                    }
                    ml={0}
                    className={`${styles["typography-sub-heading-3"]} ${styles["line-ellipsis"]}`}
                  >
                    {projectContent?.address?.city}
                    {projectContent?.address?.city &&
                      projectContent?.address?.state &&
                      ","}{" "}
                    {projectContent?.address?.state}
                  </Typography>
                </Stack>
                <Typography ml={1} className={styles["typography-success"]}>
                  {location.state?.isBookingComplete
                    ? "Booking Done"
                    : "Booking in progress"}
                </Typography>
              </Stack>

              <Stack
                flexDirection={"column"}
                justifyContent={"start"}
                alignItems={"end"}
              >
                <Typography
                  className={`${styles["section-heading-name-3"]} ${styles["line-ellipsis"]}`}
                >
                  {Array.isArray(location?.state?.primaryOwner)
                    ? location?.state?.primaryOwner[0]
                    : location?.state?.primaryOwner}
                </Typography>
                <Typography
                  className={`${styles["section-heading-name-3"]} ${styles["line-ellipsis"]}`}
                >
                  {location?.state?.crmInventoryName
                    ? location?.state?.crmInventoryName
                    : "-"}
                </Typography>
              </Stack>
            </Stack>

            <Divider className={styles["heading-divider"]} />
          </Box>
        </Box>

        <BookingJourneyDetails
          showAllPaymentReceipts={showAllPaymentReceipts}
          setShowAllPaymentReceipts={setShowAllPaymentReceipts}
          setPaymentsReceipts={setPaymentsReceipts}
          setPaymentHistory={setPaymentHistory}
        />

        <Box className={styles["disclaimer-section"]}>
          <Typography
            className={styles["disclaimer-text"]}
            color={"#9192a0"}
            variant={"body2"}
          >
            *Dear Customer, the booking journey status, payment receipts &
            documents might take up to 14 working days to update.
          </Typography>
        </Box>
      </div>
    </>
  );
};

export default BookingJourney;
