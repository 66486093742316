import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { ProfileAction } from "redux-container/profile/ProfileRedux";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";

export function* getCategories(api: any, action: any): any {
  try {
    const response = yield call(api.getCategories, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ProfileAction.getAllCategoriesSuccess(response.data));
    } else {
      yield put(ProfileAction.getAllCategoriesFailure(response.data));
    }
  } catch (error) {
    yield put(ProfileAction.getAllCategoriesFailure(error));
  }
}

export function* getFaqsList(api: any, action: any): any {
  try {
    const response = yield call(api.getFaqs, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ProfileAction.getFaqsListSuccess(response.data));
    } else {
      yield put(ProfileAction.getFaqsListFailure(response.data));
    }
  } catch (error) {
    yield put(ProfileAction.getFaqsListFailure(error));
  }
}

export function* getProfileDetails(api: any, action: any): any {
  try {
    const response = yield call(api.getProfileDetails, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ProfileAction.getProfileDetailsSuccess(response.data));
    } else {
      yield put(ProfileAction.getProfileDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(ProfileAction.getProfileDetailsFailure(error));
  }
}

export function* editProfileUserSettings(api: any, action: any): any {
  try {
    const response = yield call(api.editProfileUserSettings, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ProfileAction.editProfileUserSettingsSuccess(response?.data));
    } else {
      yield put(ProfileAction.editProfileUserSettingsFailure(response?.data));
    }
  } catch (error) {
    yield put(ProfileAction.editProfileUserSettingsFailure(error));
  }
}

export function* getMyAccountDetails(api: any, action: any): any {
  try {
    const response = yield call(api.getMyAccountDetails, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ProfileAction.getMyAccountDetailsSuccess(response.data));
    } else {
      yield put(ProfileAction.getMyAccountDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(ProfileAction.getMyAccountDetailsFailure(error));
  }
}

export function* logout(api: any, action: any): any {
  try {
    const response = yield call(api.logout);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ProfileAction.logoutSuccess(response.data));
    } else {
      yield put(ProfileAction.logoutFailure(response.data));
    }
  } catch (error) {
    yield put(ProfileAction.logoutFailure(error));
  }
}

export function* logoutFromAllDevices(api: any, action: any): any {
  try {
    const response = yield call(api.logoutFromAllDevices);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ProfileAction.logoutAllSuccess(response.data));
    } else {
      yield put(ProfileAction.logoutAllFailure(response.data));
    }
  } catch (error) {
    yield put(ProfileAction.logoutAllFailure(error));
  }
}

export function* shareFeedback(api: any, action: any): any {
  try {
    const response = yield call(api.shareFeedback, action.data);

    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(ProfileAction.shareFeedbackSuccess(response.data));
    } else {
      yield put(ProfileAction.shareFeedbackFailure(response.data));
    }
  } catch (error) {
    yield put(ProfileAction.shareFeedbackFailure(error));
  }
}
