import Grid from "@mui/material/Grid";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import styles from "./ProjectAmenities.module.css";
import ProjectAmenitiesItem from "./ProjectAmenitiesItem";
import EastIcon from "@mui/icons-material/East";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";

export const ViewButtonContent = (status: boolean) => {
  return (
    <Stack>
      {status ? "VIEW MORE" : "VIEW LESS"}
      <ExpandLessIcon className={status ? styles["collapse-icon"] : ""} />
    </Stack>
  );
};

interface IProjectAmenities {
  showAllAmenities: boolean;
}

const ProjectAmenities = (props: IProjectAmenities) => {
  const { showAllAmenities } = props;

  const { investmentDetails } = useSelector((state: any) => ({
    investmentDetails: state?.investmentDetails?.investmentDetails,
  }));
  const [shouldShowLess, setShouldShowLess] = useState(true);
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleShowViewMore = () => {
    let countToShow = shouldShowLess
      ? isSmallerScreen
        ? 4
        : 6
      : investmentDetails?.projectContent?.opportunityDoc?.projectAminities
          ?.length;
    return countToShow;
  };

  const handleClickSeeAllAmenities = () => {
    setShouldShowLess(false);
    navigate(RouteConfigurations.investmentOpportunityDoc, {
      state: { showAll: true },
    });
  };

  const location: any = useLocation();
  const scrollToAmenities: any = useRef(null);

  const applyScroll: any = () => {
    scrollToAmenities.current?.scrollIntoView(true);
  };

  useEffect(() => {
    if (location?.state?.showAll === true) {
      setShouldShowLess(false);
      applyScroll();
    }
  }, []);

  const navigate = useNavigate();
  // max four images to be shown if its less than four then show all in invest detail screen
  return (
    <div ref={scrollToAmenities}>
      <div className={styles["heading-holder"]}>
        <h1 className={styles["section-heading"]}>
          {investmentDetails?.projectContent?.opportunityDoc
            ?.projectAminitiesSectionHeading !== null
            ? investmentDetails?.projectContent?.opportunityDoc
                ?.projectAminitiesSectionHeading
            : "Project Amenities"}
        </h1>
        {!showAllAmenities && (
          <MuiStyledButton
            endIcon={<EastIcon />}
            variant="text"
            color="primary"
            onClick={handleClickSeeAllAmenities}
          >
            SEE ALL
          </MuiStyledButton>
        )}
      </div>

      <Grid container spacing={2}>
        {investmentDetails?.projectContent?.opportunityDoc?.projectAminities
          ?.slice(0, showAllAmenities ? handleShowViewMore() : 6)
          ?.map((amenity: any, index: any) => {
            return (
              <Grid item xs={6} sm={6} md={6} lg={4} xl={4} key={index}>
                <ProjectAmenitiesItem amenity={amenity} />
              </Grid>
            );
          })}
      </Grid>
      {investmentDetails?.projectContent?.opportunityDoc?.projectAminities
        .length > 2 &&
        showAllAmenities && (
          <Box mt={2} style={{ textAlign: "end" }}>
            <MuiStyledButton
              variant="text"
              color="primary"
              className={styles["view-btn"]}
              onClick={() => setShouldShowLess(!shouldShowLess)}
            >
              {ViewButtonContent(shouldShowLess)}
            </MuiStyledButton>
          </Box>
        )}
    </div>
  );
};

export default ProjectAmenities;
