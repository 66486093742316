import InsightsItem from "./InsightsItem";
import styles from "./InsightsHomeScreen.module.css";
import {
  ButtonArrowIcon,
  MuiStyledButton,
} from "components/mui-buttons/MuiButtons";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import { Carousel } from "@mantine/carousel";
import { numOfItems } from "utils/helperFunctions";
import InsightView from "pages/insights/InsightView";
import { InsightAction } from "redux-container/insights-redux/InsightsRedux";
import { useMemoizedValue } from "app/hooks/useMemoizedValue";

const actionDispatch = (dispatch: any) => {
  return {
    setInsightsState: (key: any, data: any) =>
      dispatch(InsightAction.setInsightsState(key, data)),
  };
};

const InsightsHomeScreen = () => {
  const { setInsightsState } = actionDispatch(useDispatch());
  const navigate = useNavigate();
  const { pageData, totalInsightsToShow } = useSelector((state: any) => ({
    pageData: state?.pageManagement?.pageData,
    totalInsightsToShow:
      state?.pageManagement?.pageData?.page?.totalInsightsOnHomeScreen,
  }));

  const slicedInsightData = useMemoizedValue(
    pageData?.pageManagementOrInsights,
    totalInsightsToShow
  );

  const handleDialogOpen = (insightIndex: number, insightsData: any) => {
    setInsightsState("isDialogOpen", true);
    setInsightsState("insightDetailIndex", insightIndex);
    setInsightsState("insightsData", insightsData);
  };

  return (
    <section className={styles["insights-section"]}>
      <div className="section-heading-holder">
        <h1>{pageData?.page?.insightsHeading}</h1>
        <MuiStyledButton
          endIcon={<ButtonArrowIcon />}
          variant="text"
          color="primary"
          onClick={() => navigate(RouteConfigurations.insights)}
        >
          SEE ALL
        </MuiStyledButton>
      </div>
      {slicedInsightData && !!slicedInsightData?.length && (
        <MantineCarousel
          slideSize={numOfItems(2.5)}
          breakpoints={[
            { maxWidth: "xl", slideSize: numOfItems(2) },
            { maxWidth: "lg", slideSize: numOfItems(2) },
            { maxWidth: "md", slideSize: numOfItems(2) },
            { maxWidth: "sm", slideSize: numOfItems(2) },
            { maxWidth: "xs", slideSize: numOfItems(1.3) },
          ]}
          slidesToScroll={slicedInsightData?.length <= 2 ? 1 : 2}
          // withControls={
          //   pageData?.pageManagementOrInsights?.length <= 2 ? false : true
          // }
        >
          {slicedInsightData.map((insight: any, insightIndex: number) => {
            return (
              <Carousel.Slide key={insight?.id}>
                <InsightsItem
                  insight={insight}
                  handlecardclick={() =>
                    handleDialogOpen(insightIndex, slicedInsightData)
                  }
                />
              </Carousel.Slide>
            );
          })}
        </MantineCarousel>
      )}
      <InsightView />
    </section>
  );
};

export default InsightsHomeScreen;
