import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isEmpty,
  isMobileNumberValid,
  isOnlyNumberAllowed,
} from "utils/ValidationUtils";
import { Constants } from "constants/Constants";
import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";
import { TroubleSigninAction } from "redux-container/login/trouble-signingin-redux/TroubleSigninginRedux";
import {
  troubleSigninPayload,
  TroubleSigninValidate,
} from "../LoginInitialValues";
import { PageTypeEnum } from "enumerations/PageTypeEnum";
import { getPageAction } from "redux-container/page-management-redux/PageManagementRedux";
import styles from "../Login.module.css";
import MuiTextField from "components/mui-textfield/MuiTextfield";
import GenerateOtpActions from "redux-container/login/generate-otp/GenerateOtpRedux";
import MuiSwitch from "components/mui-switch/MuiSwitch";
import MuiFormDialog from "components/mui-dialogs/MuiFormDialog";
import TermsAndCondition from "pages/authentication/TermsAndCondition";
import MuiActionStatusDialog from "components/mui-dialogs/MuiActionStatusDialog";
import TroubleSigningForm from "pages/authentication/TroubleSigningForm";
import useForm from "app/hooks/useForm";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";
import NumberListDialog from "components/number-list-dialog/NumberListDialog";
import { KeyCodeTypeEnum } from "enumerations/KeyCodeTypeEnum";

const actionDispatch = (dispatch: any) => {
  return {
    generateOtp: (data: any) =>
      dispatch(GenerateOtpActions.generateOtpRequest(data)),
    setGenerateOtpState: (key: any, data: any) =>
      dispatch(GenerateOtpActions.setGenerateOtpState(key, data)),
    addTroubleSigninRequest: (data: any) =>
      dispatch(TroubleSigninAction.addTroubleSigninRequest(data)),
    getPagesRequest: (pageType: string) =>
      dispatch(getPageAction.getPagesRequest(pageType)),
    setTroubleSigninState: (key: any, value: any) =>
      dispatch(TroubleSigninAction.setTroubleSigninState(key, value)),
  };
};

const MobileNumberHintText = () => {
  return (
    <span className={styles["form-textfield-hint"]}>
      <span>OTP</span> will be sent to this number to verify your account.
    </span>
  );
};

const GenerateOTP = ({ fullScreen }: any) => {
  const {
    generateOtp,
    setGenerateOtpState,
    addTroubleSigninRequest,
    setTroubleSigninState,
    getPagesRequest,
  } = actionDispatch(useDispatch());

  const mobileNumberErrorMessage = "Please enter a valid 10 digit number.";

  const {
    isResponseDialogOpen,
    pageData,
    isSuccess,
    isDialogOpen,
    message,
    errorMessage,
    enteredNumber,
    isLoading,
    isCountryCodeDialogOpen,
    isGenerateOtpLoading,
  } = useSelector((state: any) => ({
    isResponseDialogOpen: state?.troubleSignin?.isResponseDialogOpen,
    isDialogOpen: state?.troubleSignin?.isDialogOpen,
    isSuccess: state?.troubleSignin?.isSuccess,
    isLoading: state?.troubleSignin?.isLoading,
    message: state?.troubleSignin?.message,
    isCountryCodeDialogOpen: state?.troubleSignin?.isCountryCodeDialogOpen,
    errorMessage: state?.troubleSignin?.error,
    enteredNumber: state?.generateOtp.mobileNumber,
    pageData: state?.pageManagement?.pageData?.page,
    isGenerateOtpLoading: state?.generateOtp?.isLoading,
  }));

  const { handleSubmit, resetForm, valid, setFieldValue, ...formData } =
    useForm({
      initialValues: troubleSigninPayload,
      validate: TroubleSigninValidate,
    });
  const [popupState, setPopupState] = useState({
    TnCDialogOpen: false,
  });
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [isWhatsappCommunicationAllowed, setIsWhatsappCommunicationAllowed] =
    useState(false);
  const error = useSelector((state: any) => state?.generateOtp.error);

  const storedMobileNumber = useSelector(
    (state: any) => state?.generateOtp.mobileNumber
  );
  const storedIsWhatsappCommunicationAllowed = useSelector(
    (state: any) => state?.generateOtp.isWhatsappCommunicationAllowed
  );

  const clearCacheStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  };

  useEffect(() => {
    clearCacheStorage();
  }, []);

  useEffect(() => {
    setMobileNumber(storedMobileNumber);
    setIsWhatsappCommunicationAllowed(storedIsWhatsappCommunicationAllowed);

    let cancelEffect = true;
    if (cancelEffect) {
      getPagesRequest(PageTypeEnum.Profile);
    }
    return () => {
      cancelEffect = false;
    };
  }, []);

  const openCountryCodeDialog = () => {
    setTroubleSigninState("isCountryCodeDialogOpen", true);
  };

  const handleCloseSnackbar = () => {
    setGenerateOtpState("error", null);
  };

  const handleChangePhoneNumber = (event: any) => {
    if (isOnlyNumberAllowed(event.target.value)) {
      if (isEmpty(event.target.value)) {
        setIsWhatsappCommunicationAllowed(false);
      } else {
        setIsWhatsappCommunicationAllowed(true);
      }
      setGenerateOtpState("mobileNumber", event.target.value.toString());
      setMobileNumber(event.target.value.toString());
      if (event.target.value.toString()?.length <= 4) {
        setFormattedNumber(event.target.value.toString()?.slice(0, 3) + " ");
      }
    }
    if (event.target.value.length < 10) {
      setMobileNumberError(mobileNumberErrorMessage);
    } else {
      setMobileNumberError("");
    }
  };

  const handleOnBlurPhoneNumber = () => {};

  const handleFormDialogOpen = (type: string) => () => {
    if (type === "openFormDialog") {
      setFieldValue("phoneNumber", enteredNumber);
      setTroubleSigninState("isDialogOpen", true);
    } else {
      setPopupState((prevState) => {
        return { ...prevState, TnCDialogOpen: true };
      });
    }
  };

  const handleFormDialogClose = () => {
    setTroubleSigninState("isResponseDialogOpen", false);
    setTroubleSigninState("isDialogOpen", false);
    resetForm(troubleSigninPayload);
    setPopupState((prevState) => {
      return {
        ...prevState,
        TnCDialogOpen: false,
      };
    });
  };

  const onFormSubmit = (values: typeof troubleSigninPayload) => {
    addTroubleSigninRequest(values);
    setTroubleSigninState("isResponseDialogOpen", true);
    setTroubleSigninState("isDialogOpen", false);
  };

  const handleChangeIsWhatsappCommunicationAllowed = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsWhatsappCommunicationAllowed(event.target.checked);
  };

  const generateOTP = () => {
    if (!isMobileNumberValid(mobileNumber)) {
      setMobileNumberError(mobileNumberErrorMessage);
      return false;
    }
    setGenerateOtpState(
      "isWhatsappCommunicationAllowed",
      isWhatsappCommunicationAllowed
    );
    const requestBody = {
      phoneNumber: mobileNumber,
      countryCode: "+91",
      country: "IN",
    };
    generateOtp(requestBody);
  };

  const [formattedNumber, setFormattedNumber] = useState("");

  // const formateMobileNumber = () => {
  //   if (mobileNumber?.length > 0) {
  //     return setFormattedNumber(
  //       mobileNumber?.slice(0, 3) +
  //         " " +
  //         mobileNumber?.slice(3, 6) +
  //         " " +
  //         mobileNumber?.slice(6, 10)
  //     );
  //   } else {
  //     return mobileNumber;
  //   }
  // };

  // useEffect(() => {
  //   if (mobileNumber?.length > 0) formateMobileNumber();
  // }, [mobileNumber]);

  const handleOnEnterKeyPress = (event: any) => {
    if (event.code === KeyCodeTypeEnum.EnterKey) {
      generateOTP();
    }
  };

  return (
    <div className={styles["form-holder"]}>
      <h2 className={styles["form-heading"]}>Login</h2>

      <MuiTextField
        fullWidth
        label="Enter Your Phone Number"
        name="phoneNumber"
        autoFocus
        // focused
        value={mobileNumber}
        placeholder={"Type here"}
        onKeyPress={handleOnEnterKeyPress}
        onChange={handleChangePhoneNumber}
        onBlur={handleOnBlurPhoneNumber}
        sx={{
          ".MuiInputBase-input": {
            color:
              mobileNumberError.length > 0
                ? Constants.ERROR_COLOR
                : "common.black",
          },
        }}
        helperText={mobileNumberError}
        className={styles["form-textfield"]}
        inputProps={{
          maxLength: Constants.MAX_MOBILE_NUMBER_DIGITS,
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
        error={mobileNumberError.length > 0 ? true : false}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography
                onClick={openCountryCodeDialog}
                component={"span"}
                variant={"body2"}
                display="flex"
                alignItems={"center"}
                sx={{
                  cursor: "pointer",
                  color:
                    mobileNumberError.length > 0
                      ? Constants.ERROR_COLOR
                      : "common.black",
                }}
              >
                {"+91"}
                <IconButton edge="start" onClick={openCountryCodeDialog}>
                  <ExpandMoreIcon
                    fontSize="small"
                    sx={{
                      color:
                        mobileNumberError.length > 0
                          ? Constants.ERROR_COLOR
                          : "common.black",
                    }}
                  />
                </IconButton>
              </Typography>
            </InputAdornment>
          ),
        }}
      />
      <MobileNumberHintText />
      <div className={styles["switch-holder"]}>
        <MuiSwitch
          checked={isWhatsappCommunicationAllowed}
          onChange={handleChangeIsWhatsappCommunicationAllowed}
        />
        <span
          className={
            isEmpty(mobileNumber) || !isWhatsappCommunicationAllowed
              ? styles["inactive-allow-whatsapp-text"]
              : styles["active-allow-whatsapp-text"]
          }
        >
          Allow Whatsapp Communications
        </span>
      </div>

      <div className={styles["btn-holder"]}>
        <MuiLoadingButton
          loading={isGenerateOtpLoading}
          disabled={isEmpty(mobileNumber) ? true : false}
          variant="contained"
          onClick={generateOTP}
          autoFocus
          disableElevation
          className={
            isEmpty(mobileNumber) ? "btn btn-disabled" : "btn btn-dark"
          }
        >
          Get OTP
        </MuiLoadingButton>
      </div>

      <div>
        <p className={styles["terms-condition-privacy-policy-text"]}>
          By logging in, you agree to our
        </p>
        <p
          className={styles["highlight-text"]}
          onClick={handleFormDialogOpen("openTnCDialog")}
        >
          Terms and Conditions & Privacy Policy
        </p>
      </div>

      <MuiFormDialog
        fullScreen={fullScreen}
        maxWidth={"lg"}
        formheading="Terms & Conditions"
        open={popupState.TnCDialogOpen}
        handleclose={handleFormDialogClose}
        children={<TermsAndCondition pageData={pageData} />}
      />

      <div className={styles["trouble-signing-in-holder"]}>
        <button
          className={styles["trouble-signing-in"]}
          onClick={handleFormDialogOpen("openFormDialog")}
        >
          Trouble Signing in?
        </button>
      </div>

      <MuiFormDialog
        fullScreen={fullScreen}
        maxWidth={"lg"}
        formheading="Trouble Signing In?"
        open={isDialogOpen}
        handleclose={handleFormDialogClose}
        handleformsubmit={handleSubmit(onFormSubmit)}
        isformlayout={true}
        formbuttondisabled={!valid || !!formData.errors.description}
        children={
          <TroubleSigningForm
            formProps={{ ...formData, setFieldValue }}
            handleAdormentClick={openCountryCodeDialog}
          />
        }
      />
      {!isLoading && (
        <MuiActionStatusDialog
          maxWidth={"sm"}
          issuccess={isSuccess}
          isconfirmdialog={false}
          responsemessage={isSuccess ? message : errorMessage}
          message={"We will contact you shortly."}
          open={isResponseDialogOpen}
          handleclose={handleFormDialogClose}
        />
      )}

      <MuiSnackbar
        shouldOpen={error !== null ? true : false}
        message={error}
        isSuccess={false}
        closeHandler={handleCloseSnackbar}
      />

      <NumberListDialog
        open={isCountryCodeDialogOpen}
        onClick={() => setTroubleSigninState("isCountryCodeDialogOpen", false)}
      />
    </div>
  );
};

export default GenerateOTP;
