import { Outlet, Navigate } from "react-router-dom";
import { getItemFromLocalStorage } from "../utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
import Layout from "components/layout/Layout";

const ProtectedRoute = () => {
  const token = getItemFromLocalStorage(Constants.AUTHORIZATION_TOKEN);

  return token ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedRoute;
