import {
  FormControl,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
} from "@mui/material";

export function StyledRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      //   checkedIcon={<RadioCheckedIcon />}
      //   icon={<RadioIcon />}
      {...props}
    />
  );
}

const MuiRadioButton = (props: RadioGroupProps) => {
  const { children } = props;
  return (
    <FormControl fullWidth component="fieldset">
      <RadioGroup row {...props}>
        {children}
      </RadioGroup>
    </FormControl>
  );
};

export default MuiRadioButton;
