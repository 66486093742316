import { Grid, Paper, Theme, useMediaQuery } from "@mui/material";
import MediaAssets from "assets";
import styles from "pages/profile/my-services-dialog/MyServicesDialog.module.css";
import { Constants } from "constants/Constants";

const MyService = () => {
  const isMediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const openGooglePlayStore = () => {
    window.open(Constants.GOOGLE_PLAY_STORE_URL);
  };

  const openAppleAppStore = () => {
    window.open(Constants.APPLE_APP_STORE_URL);
  };
  return (
    <Grid
      component={Paper}
      elevation={0}
      alignItems={isMediumScreen ? "flex-start" : "center"}
      height={isMediumScreen ? "100vh" : "calc(100vh - 300px)"}
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        lg={7}
        xl={7}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        height={isMediumScreen ? "auto" : "100%"}
      >
        <div className={styles["stores-icon-holder"]}>
          <h1 className={styles["download-heading"]}>Download the app today</h1>
          <div>
            <img
              src={MediaAssets.ic_playstore_download}
              alt="Download app from Google Play Store"
              className={styles["playstore-icon"]}
              onClick={openGooglePlayStore}
            />
            <img
              src={MediaAssets.ic_appstore_download}
              alt="Download app from Apple App Store"
              className={styles["playstore-icon"]}
              onClick={openAppleAppStore}
            />
          </div>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        lg={5}
        xl={5}
        textAlign={"center"}
        height={isMediumScreen ? "auto" : "100%"}
      >
        <img
          className={styles["mobile-icon"]}
          src={MediaAssets.ic_mobile_app}
          alt=""
        />
      </Grid>
    </Grid>
  );
};

export default MyService;
