import {
  Box,
  Drawer,
  IconButton,
  Stack,
  Typography,
  Checkbox,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./ShareFeedback.module.css";
import MediaAssets from "assets";
import ShareFeedbackIconCard from "./ShareFeedbackIconCard";
import MuiTextField from "components/mui-textfield/MuiTextfield";
import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";
import { ProfileAction } from "redux-container/profile/ProfileRedux";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/Loader";
import MuiActionStatusDialog from "components/mui-dialogs/MuiActionStatusDialog";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";
import { Overlay } from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";

const actionDispatch = (dispatch: any) => {
  return {
    shareFeedback: (data: any) =>
      dispatch(ProfileAction.shareFeedbackRequest(data)),
    profileSetState: (key: any, value: any) =>
      dispatch(ProfileAction.setProfileState(key, value)),
  };
};

const ShareFeedback = (props: any) => {
  const { shareFeedback, profileSetState } = actionDispatch(useDispatch());
  const { open, handleClose, setOpenShareFeedbackDialog } = props;
  const error = useSelector((state: any) => state?.profile?.error);
  const openSuccessDialog: boolean = useSelector(
    (state: any) => state?.profile?.openSuccessDialog
  );

  const feedbackIconsList = [
    {
      id: 1,
      title: "Very Poor",
      activeIcon: MediaAssets.ic_active_poor,
      inActiveIcon: MediaAssets.ic_inactive_poor,
    },
    {
      id: 2,
      title: "Bad",
      activeIcon: MediaAssets.ic_active_bad,
      inActiveIcon: MediaAssets.ic_inactive_bad,
    },
    {
      id: 3,
      title: "Ok",
      activeIcon: MediaAssets.ic_active_ok,
      inActiveIcon: MediaAssets.ic_inactive_ok,
    },
    {
      id: 4,
      title: "Good",
      activeIcon: MediaAssets.ic_active_good,
      inActiveIcon: MediaAssets.ic_inactive_good,
    },
    {
      id: 5,
      title: "Excellent",
      activeIcon: MediaAssets.ic_active_excellent,
      inActiveIcon: MediaAssets.ic_inactive_excellent,
    },
  ];

  const [isHomeChecked, setIsHomeChecked] = useState(false);
  const [isInvestChecked, setIsInvestChecked] = useState(false);
  const [isPromisesChecked, setIsPromisesChecked] = useState(false);
  const [isPortfolioChecked, setIsPortfolioChecked] = useState(false);
  const [isProfileChecked, setIsProfileChecked] = useState(false);
  const [isOthersChecked, setIsOthersChecked] = useState(false);
  const [statusActiveId, setStatusActiveId] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [categories, setCategories] = useState([]);
  const isLoading = useSelector((state: any) => state?.profile?.isLoading);
  const isSuccess = useSelector((state: any) => state?.profile?.isSuccess);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const checkListItems = [
    {
      id: 1,
      title: "Home",
      isChecked: isHomeChecked,
      setState: setIsHomeChecked,
      checkValue: "5001",
    },
    {
      id: 2,
      title: "Invest",
      isChecked: isInvestChecked,
      setState: setIsInvestChecked,
      checkValue: "5002",
    },
    {
      id: 3,
      title: "Promises",
      isChecked: isPromisesChecked,
      setState: setIsPromisesChecked,
      checkValue: "5003",
    },
    {
      id: 4,
      title: "Portfolio",
      isChecked: isPortfolioChecked,
      setState: setIsPortfolioChecked,
      checkValue: "5004",
    },
    {
      id: 5,
      title: "Profile",
      isChecked: isProfileChecked,
      setState: setIsProfileChecked,
      checkValue: "5005",
    },
    {
      id: 6,
      title: "Others",
      isChecked: isOthersChecked,
      setState: setIsOthersChecked,
      checkValue: "5006",
    },
  ];

  useEffect(() => {
    let activeCategory: any = [];
    const activeCategoriesList = checkListItems?.filter(
      (item: any) => item?.isChecked
    );
    activeCategoriesList?.map((item: any) => {
      activeCategory.push(item?.checkValue);
    });
    setCategories(activeCategory);
  }, [
    isHomeChecked,
    isInvestChecked,
    isPromisesChecked,
    isPortfolioChecked,
    isProfileChecked,
    isOthersChecked,
  ]);

  const handleClick = (id: number) => {
    setStatusActiveId(id);
  };

  const handleSubmit = () => {
    if (statusActiveId === 0 && !description && categories?.length === 0) {
      profileSetState("error", "Fill Atleast One Field");
    } else {
      const params = {
        rating: statusActiveId,
        description: description,
        categories: categories,
      };
      shareFeedback(params);
    }
  };

  const handleFlashMessageClose = () => {
    setStatusActiveId(0);
    setCategories([]);
    setDescription("");
    setIsHomeChecked(false);
    setIsInvestChecked(false);
    setIsPromisesChecked(false);
    setIsPortfolioChecked(false);
    setIsProfileChecked(false);
    setIsOthersChecked(false);
    setOpenShareFeedbackDialog(false);
    profileSetState("openSuccessDialog", false);
  };

  const handleCloseSnackBar = () => {
    profileSetState("error", null);
  };

  const handleCloseDialog = () => {
    handleClose();
    handleClick(0);
    handleFlashMessageClose();
  };
  const ref = useClickOutside(handleCloseDialog, ["mouseup", "mousedown"]);
  return (
    <div>
      {open && (
        <Overlay
          onClick={handleCloseDialog}
          opacity={0.2}
          color="#000"
          blur={5}
        />
      )}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          ref: ref,
          sx: {
            width: isMobileScreen ? "100%" : "50%",
            padding: "20px 15px 120px 15px",
          },
        }}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Stack className={styles["heading-container"]}>
              <Stack>
                {isMobileScreen && (
                  <IconButton onClick={handleCloseDialog}>
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                )}

                <Typography className={styles["section-heading"]}>
                  Share your feedback
                </Typography>
              </Stack>

              {!isMobileScreen && (
                <IconButton onClick={handleCloseDialog}>
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>

            <Box className={styles["details-container"]}>
              <Typography className={styles["typography-medium"]}>
                Help us serve you better.
              </Typography>

              <Typography mt={3} className={styles["typography-small"]}>
                Please share your valuable feedback.
              </Typography>

              <Box mt={3}>
                <div className={styles["icon-list-content"]}>
                  {feedbackIconsList.map((icon: any, index: number) => (
                    <ShareFeedbackIconCard
                      key={index}
                      title={icon.title}
                      activeIcon={icon.activeIcon}
                      inActiveIcon={icon.inActiveIcon}
                      isActive={statusActiveId === icon.id ? true : false}
                      handleClick={() => handleClick(icon.id)}
                    />
                  ))}
                </div>
              </Box>

              <Box mt={5}>
                <Typography className={styles["typography-small-text"]}>
                  Select category for which you want to share feedback
                </Typography>

                <Box>
                  <Grid mt={3} container>
                    {checkListItems.map((item: any, index: number) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={2}
                        xl={2}
                      >
                        <Stack
                          className={styles["check-section-content"]}
                          key={index}
                          justifyContent={"start"}
                          spacing={isMobileScreen ? 2 : 0}
                        >
                          <Checkbox
                            checked={item.isChecked}
                            onChange={() => item.setState(!item.isChecked)}
                          />
                          <Typography className={styles["check-text-title"]}>
                            {item.title}
                          </Typography>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>

                  <Box mt={4}>
                    <MuiTextField
                      placeholder={"Describe your experience here"}
                      minRows={5}
                      multiline
                      inputProps={{ maxLength: 250 }}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Box>

                  <Box mt={3} textAlign={"center"}>
                    <MuiLoadingButton
                      onClick={handleSubmit}
                      sx={{
                        width:
                          isMobileScreen || isMediumScreen ? "270px" : "480px",
                      }}
                    >
                      Share Your Feedback
                    </MuiLoadingButton>
                  </Box>
                </Box>
              </Box>
            </Box>

            <MuiSnackbar
              shouldOpen={error !== null ? true : false}
              message={error}
              isSuccess={false}
              closeHandler={handleCloseSnackBar}
            />

            <MuiActionStatusDialog
              open={!error && openSuccessDialog}
              maxWidth={"xs"}
              issuccess={true}
              message={"Your feedBack has been submitted successfully"}
              handleclose={handleFlashMessageClose}
            />
          </>
        )}
      </Drawer>
    </div>
  );
};

export default ShareFeedback;
