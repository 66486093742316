import { CssBaseline, ThemeProvider } from "@mui/material";
import MuiActionStatusDialog from "components/mui-dialogs/MuiActionStatusDialog";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AppRoutes from "routes/AppRoutes";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { getItemFromLocalStorage } from "utils/LocalStorageUtils";
import OverridedThemes from "./mui-themes/ThemeOverrides";
import GenerateOtpActions from "redux-container/login/generate-otp/GenerateOtpRedux";
import VerifyOtpActions from "redux-container/login/verify-otp/VerifyOtpRedux";
import NewUserNameInfoActions from "redux-container/login/new-user-name-info/NewUserNameInfoRedux";

const actionDispatch = (dispatch: any) => {
  return {
    resetGenerateOtpState: () =>
      dispatch(GenerateOtpActions.resetGenerateOtpState()),
    resetVerifyOtpState: () => dispatch(VerifyOtpActions.resetVerifyOtpState()),
    resetNewUserNameInfoState: () =>
      dispatch(NewUserNameInfoActions.resetNewUserNameInfoState()),
  };
};

const App = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isLoggedIn = useSelector((state: any) => state?.verifyOtp.isLoggedIn);
  const isLoggedInAfterNewUsername = useSelector(
    (state: any) => state?.newUserNameInfo.isLoggedIn
  );

  const {
    resetGenerateOtpState,
    resetVerifyOtpState,
    resetNewUserNameInfoState,
  } = actionDispatch(useDispatch());

  const isSessionExpired = getItemFromLocalStorage("isSessionExpired");
  useEffect(() => {
    if (pathname === RouteConfigurations.login || pathname === "/") {
      navigate(RouteConfigurations.home);
    }
  }, [isLoggedIn, isLoggedInAfterNewUsername, isSessionExpired]);

  const handleLogout = () => {
    localStorage.clear();
    resetGenerateOtpState();
    resetVerifyOtpState();
    resetNewUserNameInfoState();
    navigate(RouteConfigurations?.login);
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={OverridedThemes}>
        <MuiActionStatusDialog
          maxWidth={"sm"}
          issuccess={false}
          isconfirmdialog={true}
          responsemessage={"Alert"}
          message={"Your Session has expired!"}
          open={isSessionExpired == "true" ? true : false}
          handleclose={handleLogout}
        />
        <AppRoutes />
      </ThemeProvider>
    </Fragment>
  );
};

export default App;
