import { Divider, Paper, Typography } from "@mui/material";
import React from "react";
import styles from "./AboutUs.module.css";

interface IStatsOverViewCard {
  price: string;
  title: string;
  description: string;
}

const StatsOverviewCard = (props: IStatsOverViewCard) => {
  const { price, title, description } = props;
  return (
    <Paper
      className={`${styles["carousel-item"]} ${styles.statsOverviewCard_Bg}`}
      elevation={2}
    >
      <Typography
        variant="body1"
        className={`${styles["typography-title"]} ${styles["line-ellipsis"]}`}
      >
        {price}
      </Typography>
      <Typography
        variant="body2"
        className={`${styles["typography-sub-title-gold"]}`}
      >
        {title}
      </Typography>
      <Divider className={`${styles.divider} ${styles["divider-color"]}`} />
      <Typography
        variant="body2"
        dangerouslySetInnerHTML={{ __html: description }}
      ></Typography>
    </Paper>
  );
};

export default StatsOverviewCard;
