import { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Stack, Typography, Box, Grid } from "@mui/material";
import styles from "./MyAccount.module.css";
import { useNavigate } from "react-router-dom";
import AccountDetailedCard from "./account-detailed-card/AccountDetailedCard";
import {
  ButtonArrowIcon,
  MuiLoadingButton,
} from "components/mui-buttons/MuiButtons";
import PaymentHistory from "./payment-history/PaymentHistory";
import MediaAssets from "assets";
import NoDetailCard from "./no-details-card/NoDetailsCard";
import { ProfileAction } from "redux-container/profile/ProfileRedux";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/Loader";
import NoDetailsCard from "./no-details-card/NoDetailsCard";
import {
  documentCategoryEnum,
  documentTypeEnum,
} from "enumerations/DocumentsEmun";
import AccountDetailsStatus from "./account-detailed-card/AccountDetailsStatus";
import { DocumentsActions } from "redux-container/documents/DocumentsRedux";
import { RouteConfigurations } from "routes/RouteConfigurations";
import ImageViewDialog from "./image-view-dialog/ImageViewDialog";
import MuiActionStatusDialog from "components/mui-dialogs/MuiActionStatusDialog";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";

const actionDispatch = (dispatch: any) => {
  return {
    getMyAccountDetails: () =>
      dispatch(ProfileAction.getMyAccountDetailsRequest()),
    downloadDoc: (path: any) =>
      dispatch(DocumentsActions.getDownloadDocDataRequest(path)),
    docUpload: (path: any) => dispatch(DocumentsActions.docUploadRequest(path)),
    setDocumentsState: (key: any, value: any) =>
      dispatch(DocumentsActions.setDocumentsState(key, value)),
    setProfileState: (key: any, value: any) =>
      dispatch(ProfileAction.setProfileState(key, value)),
  };
};

const MyAccount = () => {
  const {
    getMyAccountDetails,
    downloadDoc,
    docUpload,
    setDocumentsState,
    setProfileState,
  } = actionDispatch(useDispatch());

  const navigate = useNavigate();
  const myAccountDetails = useSelector(
    (state: any) => state?.profile?.myAccountDetails
  );
  const isLoading = useSelector((state: any) => state?.profile?.isLoading);
  const documentBaseString = useSelector(
    (state: any) => state?.documents?.downloadDocData
  );
  const isDocLoading = useSelector((state: any) => state?.documents?.isLoading);
  const showFlashMessage = useSelector(
    (state: any) => state?.documents?.showFlashMessage
  );
  const [open, setOpen] = useState(false);
  const [imageViewTitle, setImageViewTitle] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const docError = useSelector((state: any) => state?.documents?.error);
  const profileError = useSelector((state: any) => state?.profile?.error);
  const showPDFPreview = useSelector(
    (state: any) => state?.documents?.showPDFPreview
  );

  useEffect(() => {
    getMyAccountDetails();
  }, []);

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const handleViewReceipts = async (path: any) => {
    await downloadDoc(path);
    setShowPdf(true);
  };

  const dataURItoBlob = (dataURI: any) => {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  };

  useEffect(() => {
    const blob = dataURItoBlob(documentBaseString);
    const url = URL.createObjectURL(blob);

    if (showPDFPreview && showPdf) {
      window.open(url);
      setDocumentsState("showPDFPreview", false);
      setShowPdf(false);
    } else if (showPdf && !documentBaseString) {
      setDocumentsState("error", `Couldn't Fetch Data. Try Again!`);
    }
  }, [showPDFPreview]);

  const KYCDetailsList = myAccountDetails?.documents?.filter(
    (item: any) => item.documentCategory === documentCategoryEnum.KYC
  );
  const documentList = myAccountDetails?.documents?.filter(
    (item: any) => item.documentCategory !== documentCategoryEnum.KYC
  );

  let panDetails: any = {};
  let addressProofDetails: any = {};

  // Storing pan details and address proof details.
  KYCDetailsList?.filter((item: any) => {
    if (
      item?.documentType === documentTypeEnum.PAN_CARD ||
      item?.documentType === documentTypeEnum.UNVERIFIED_PAN_CARD
    ) {
      panDetails = item;
    } else if (
      item?.documentType === documentTypeEnum.ADDRESS_PROOF ||
      item?.documentType === documentTypeEnum.UNVERIFIED_ADDRESS_PROOF
    ) {
      addressProofDetails = item;
    }
  });

  const handleImageUploadClick = async (event: any, documentType: number) => {
    const file = event.target.files[0];
    const fileExtension = file.type.split("/")[1]; //getting file extension from file type

    const formData = new FormData();
    formData.append("file", file);
    formData.append("documentType", documentType.toString());
    formData.append("extension", fileExtension);
    await docUpload(formData);
  };

  const handleClickSeeAllPayments = (details: any) => {
    navigate(RouteConfigurations.paymentHistory, { state: details });
  };

  const handleSeeAllClick = (details: any) => {
    navigate(RouteConfigurations.documentDetails, { state: details });
  };

  const handleHandleImageViewClick = async (path: string, title: string) => {
    setImageViewTitle(title);
    await downloadDoc(path);
    setOpen(true);
  };

  const handleFlashMessageClose = () => {
    setDocumentsState("showFlashMessage", false);
  };

  const handleSnackBarclose = () => {
    setDocumentsState("error", null);
    setProfileState("error", null);
    setShowNoDocError(false);
  };

  const [showNoDocError, setShowNoDocError] = useState(false);
  const [openViewReceiptDrawer, setOpenViewReceiptDrawer] = useState(false);
  const noDocErrorMessage =
    "Please connect with your relationship manager for more details";

  const handleViewNoReceiptError = () => {
    setShowNoDocError(true);
  };

  return (
    <Box mb={6}>
      <MuiSnackbar
        shouldOpen={docError || profileError || showNoDocError ? true : false}
        message={
          docError
            ? docError
            : profileError
            ? profileError
            : showNoDocError
            ? noDocErrorMessage
            : ""
        }
        isSuccess={showNoDocError ? true : false}
        closeHandler={handleSnackBarclose}
      />

      <div>
        <div className={styles["page-heading"]}>
          <Stack spacing={2} justifyContent={"start"}>
            <KeyboardArrowLeftIcon
              className={styles["left-arrow"]}
              onClick={handleNavigateBack}
            />
            <Typography className={styles["section-heading-name"]}>
              My Account
            </Typography>
          </Stack>
        </div>

        {isLoading || (isDocLoading && !openViewReceiptDrawer) ? (
          <Loader />
        ) : (
          <>
            <MuiActionStatusDialog
              open={showFlashMessage}
              maxWidth={"xs"}
              issuccess={true}
              message={
                "Our Team Will Verify the uploaded document and reach you soon"
              }
              handleclose={handleFlashMessageClose}
            />
            <ImageViewDialog
              open={documentBaseString && open}
              title={imageViewTitle}
              handleClose={() => setOpen(false)}
              documentString={documentBaseString}
            />
            <Box className={styles["section-spacing"]}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Stack sx={{ height: "45px" }}>
                    <Typography className={styles["account-title-text"]}>
                      KYC
                    </Typography>
                  </Stack>
                  {addressProofDetails?.documentType ===
                  documentTypeEnum.ADDRESS_PROOF ? (
                    <AccountDetailedCard
                      contentName={"Address Proof"}
                      handleViewClick={() => {
                        if (addressProofDetails?.name?.slice(-3) === "pdf") {
                          handleViewReceipts(addressProofDetails?.path);
                        } else {
                          handleHandleImageViewClick(
                            addressProofDetails?.path,
                            addressProofDetails?.name
                          );
                        }
                      }}
                    />
                  ) : addressProofDetails?.documentType ===
                    documentTypeEnum.UNVERIFIED_ADDRESS_PROOF ? (
                    <AccountDetailsStatus
                      contentName={"Address Proof"}
                      handleViewClick={() => {
                        if (addressProofDetails?.name?.slice(-3) === "pdf") {
                          handleViewReceipts(addressProofDetails?.path);
                        } else {
                          handleHandleImageViewClick(
                            addressProofDetails?.path,
                            addressProofDetails?.name
                          );
                        }
                      }}
                      handleUploadClick={(event: any) =>
                        handleImageUploadClick(
                          event,
                          documentTypeEnum.UNVERIFIED_ADDRESS_PROOF
                        )
                      }
                    />
                  ) : (
                    <AccountDetailedCard
                      contentName={"Address Proof"}
                      isUpload={true}
                      handleViewClick={() =>
                        handleHandleImageViewClick(
                          addressProofDetails?.path,
                          addressProofDetails?.name
                        )
                      }
                      handleUploadClick={(event: any) =>
                        handleImageUploadClick(
                          event,
                          documentTypeEnum.UNVERIFIED_ADDRESS_PROOF
                        )
                      }
                    />
                  )}

                  {panDetails?.documentType === documentTypeEnum.PAN_CARD ? (
                    <AccountDetailedCard
                      contentName={"Pan Card"}
                      handleViewClick={() => {
                        if (panDetails?.name?.slice(-3) === "pdf") {
                          handleViewReceipts(addressProofDetails?.path);
                        } else {
                          handleHandleImageViewClick(
                            panDetails?.path,
                            panDetails?.name
                          );
                        }
                      }}
                    />
                  ) : panDetails?.documentType ===
                    documentTypeEnum.UNVERIFIED_PAN_CARD ? (
                    <AccountDetailsStatus
                      contentName={"Pan Card"}
                      handleViewClick={() => {
                        if (panDetails?.name?.slice(-3) === "pdf") {
                          handleViewReceipts(addressProofDetails?.path);
                        } else {
                          handleHandleImageViewClick(
                            panDetails?.path,
                            panDetails?.name
                          );
                        }
                      }}
                      handleUploadClick={(event: any) =>
                        handleImageUploadClick(
                          event,
                          documentTypeEnum.UNVERIFIED_PAN_CARD
                        )
                      }
                    />
                  ) : (
                    <AccountDetailedCard
                      contentName={"Pan Card"}
                      isUpload={true}
                      handleUploadClick={(event: any) =>
                        handleImageUploadClick(
                          event,
                          documentTypeEnum.UNVERIFIED_PAN_CARD
                        )
                      }
                    />
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Stack sx={{ height: "45px" }}>
                    <Typography className={styles["account-title-text"]}>
                      Documents
                    </Typography>
                    {documentList?.length > 0 && (
                      <MuiLoadingButton
                        endIcon={<ButtonArrowIcon />}
                        className={styles["see-all-btn"]}
                        variant="text"
                        color="inherit"
                        onClick={() => handleSeeAllClick(documentList)}
                      >
                        See All
                      </MuiLoadingButton>
                    )}
                  </Stack>

                  {documentList?.length > 0 ? (
                    <>
                      {documentList?.map((item: any, index: number) => {
                        if (index < 2) {
                          return (
                            <AccountDetailedCard
                              contentName={item?.name}
                              handleViewClick={() => {
                                if (!item?.path) {
                                  handleViewNoReceiptError();
                                } else {
                                  handleViewReceipts(item?.path);
                                }
                              }}
                              isViewDisabled={!item?.path ? true : false}
                            />
                          );
                        }
                      })}
                    </>
                  ) : (
                    <Box mt={2}>
                      <NoDetailsCard
                        icon={MediaAssets.ic_no_document}
                        message={
                          "The Uploaded Documents will be shown here. You can view them after uploading."
                        }
                        height={160}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>

            <Box mt={4}>
              <Stack>
                <Typography className={styles["title-text"]}>
                  Payment History
                </Typography>
                {myAccountDetails?.paymentHistory?.length > 0 && (
                  <MuiLoadingButton
                    endIcon={<ButtonArrowIcon />}
                    className={styles["see-all-btn"]}
                    variant="text"
                    color="inherit"
                    onClick={() =>
                      handleClickSeeAllPayments(
                        myAccountDetails?.paymentHistory
                      )
                    }
                  >
                    See All
                  </MuiLoadingButton>
                )}
              </Stack>

              {myAccountDetails?.paymentHistory?.length > 0 ? (
                <PaymentHistory
                  paymentDetails={myAccountDetails?.paymentHistory}
                  handleViewClick={handleViewReceipts}
                  paymentsReceipts={myAccountDetails?.paymentReceipts}
                  setOpenViewReceiptDrawer={setOpenViewReceiptDrawer}
                  openViewReceiptDrawer={openViewReceiptDrawer}
                  shouldShowSeeReceiptsButton={false}
                />
              ) : (
                <Box mt={3} mb={3}>
                  <NoDetailCard
                    shouldLeftAlign={true}
                    icon={MediaAssets.ic_payment_history}
                    message={
                      <p style={{ textAlign: "start" }}>
                        <p>No Payment is done yet,</p>
                        <p>
                          See your payment history after making your first
                          payment.
                        </p>
                      </p>
                    }
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </div>
    </Box>
  );
};

export default MyAccount;
