import { isMobileNumberValid } from "utils/ValidationUtils";

export const addReferralPayload = {
  name: "",
  phoneNumber: "",
};

export const addReferralValidate = (values: typeof addReferralPayload) => {
  const errors: any = {};

  if (!values.name) {
    return { ...errors, name: "First Name is required" };
  }
  if (!values.phoneNumber) {
    return { ...errors, phoneNumber: "Please enter a phone number" };
  } else if (values.phoneNumber.length < 10) {
    return { ...errors, phoneNumber: "Please enter a valid 10-digit number." };
  } else if (!isMobileNumberValid(values.phoneNumber)) {
    return { ...errors, phoneNumber: "Please enter a valid 10-digit number." };
  }

  return errors;
};
