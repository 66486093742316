import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Drawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NotificationAction } from "../../redux-container/notifications/NotificationsRedux";
import { NotificationTargetPageEnumUtils } from "enumerations/NotificationTargetPageEnum";
import { useNavigate } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/material/IconButton";
import Loader from "components/loader/Loader";
import NotificationItem from "./NotificationItem";
import styles from "./Notifications.module.css";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";
import { useClickOutside } from "@mantine/hooks";
import { Overlay } from "@mantine/core";

const actionDispatch = (dispatch: any) => {
  return {
    getNotifications: () => {
      dispatch(NotificationAction.getNotificationsRequest());
    },
    updateNotificationStatusRequest: (data: any) => {
      dispatch(NotificationAction.updateNotificationStatusRequest(data));
    },
    setNotificationState: (key: any, value: any) => {
      dispatch(NotificationAction.setNotificationState(key, value));
    },
  };
};

const Notifications = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const {
    getNotifications,
    updateNotificationStatusRequest,
    setNotificationState,
  } = actionDispatch(useDispatch());

  const notifications = useSelector(
    (state: any) => state?.notifications?.notifications
  );
  const isDialogOpen = useSelector(
    (state: any) => state?.notifications?.isDialogOpen
  );
  const isLoading = useSelector(
    (state: any) => state?.notifications?.isLoading
  );
  const error = useSelector((state: any) => state?.notifications?.error);
  const isNotificationStatusUpdated = useSelector(
    (state: any) => state?.notifications?.isNotificationStatusUpdated
  );

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (isDialogOpen) {
      getNotifications();
    }
    if (isNotificationStatusUpdated) {
      getNotifications();
    }
  }, [isDialogOpen, isNotificationStatusUpdated]);

  const toggleDrawer = () => {
    setNotificationState("isDialogOpen", false);
  };

  const handleMarkAllAsRead = () => {
    // For Mark all as read we need to send ID as Zero in the API.
    const requestBody = {
      id: 0,
    };
    updateNotificationStatusRequest(requestBody);
    getNotifications();
    setNotificationState("isDialogOpen", false);
  };

  const updateNotificationStatus = (
    notificationId: number,
    targetPage: number
  ) => {
    const requestBody = {
      id: notificationId,
    };
    updateNotificationStatusRequest(requestBody);

    const route =
      NotificationTargetPageEnumUtils.getPageRouteEnumText(targetPage);
    navigate(route);
    setNotificationState("isDialogOpen", false);
  };

  const notificationsData = useSelector(
    (state: any) => state?.notifications?.notifications
  );

  const checkForUnReadNotification = () => {
    let isUnReadMessageAvailable: boolean = false;

    for (let i = 0; i < notificationsData?.length; i++) {
      if (notificationsData[i]?.readStatus === false) {
        isUnReadMessageAvailable = true;
        break;
      }
    }
    return isUnReadMessageAvailable;
  };

  const ref = useClickOutside(toggleDrawer, ["mouseup"]);

  return (
    <>
      {isDialogOpen && <Overlay opacity={0.2} color="#000" blur={5} />}
      <Drawer
        anchor={"right"}
        open={isDialogOpen}
        onClose={toggleDrawer}
        PaperProps={{
          ref: ref,
          style: isMediumScreen
            ? {
                width: "100%",
                marginTop: "0px",
              }
            : {
                width: "50%",
                // marginTop: "125px",
              },
        }}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <Fragment>
            <div className={styles["heading-holder"]}>
              <h2>Notifications</h2>
              <div>
                <Button
                  disabled={!checkForUnReadNotification()}
                  variant="text"
                  onClick={handleMarkAllAsRead}
                >
                  Mark all as read
                </Button>
                <IconButton aria-label="close" onClick={toggleDrawer}>
                  <CloseRoundedIcon />
                </IconButton>
              </div>
            </div>

            <Box mt={2}>
              {notifications?.length > 0 ? (
                notifications?.map((notification: any) => {
                  return (
                    <NotificationItem
                      key={notification.id}
                      notificationData={notification}
                      updateNotificationStatus={updateNotificationStatus}
                    />
                  );
                })
              ) : (
                <Box className={styles["no-data-section"]}>
                  <Typography className={styles["no-data-text"]}>
                    No notifications to show.
                  </Typography>
                </Box>
              )}
            </Box>

            <MuiSnackbar
              shouldOpen={error !== null ? true : false}
              message={error}
              isSuccess={false}
              closeHandler={() => setNotificationState("error", null)}
            />
          </Fragment>
        )}
      </Drawer>
    </>
  );
};

export default Notifications;
