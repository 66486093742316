import {
  Box,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactNode } from "react";
import styles from "./CustomerRegistrationDetailsDialog.module.css";

export interface ICustomerRegistration {
  fieldTitle: string;
  value: string | number;
}

interface ICustomerRegistrationDetailsDialog {
  size?: any;
  shouldOpen: boolean;
  title?: string;
  content: ICustomerRegistration[];
  cancelHandler: (e?: any) => any;
  cancelBtnText?: string;
}

const CustomerRegistrationDetailsDialog = ({
  size = "xs",
  shouldOpen,
  title,
  content,
  cancelHandler,
  cancelBtnText = "OKAY",
}: ICustomerRegistrationDetailsDialog) => {
  const { breakpoints } = useTheme();
  const isSmallerScreen = useMediaQuery(breakpoints.down("sm"));

  const handleClose = () => {
    cancelHandler();
  };

  return (
    <Dialog
      maxWidth={size}
      fullWidth
      open={shouldOpen}
      disableEscapeKeyDown={true}
      sx={{ textAlign: "center" }}
    >
      <Stack justifyContent={"center"}>
        <DialogTitle>
          <Typography
            className={styles["typography-heading"]}
          >
            {title}
          </Typography>
        </DialogTitle>
      </Stack>

      <div className={styles["dashed-divider"]}></div>
      <DialogContent>
        <Box justifyContent={"center"}>
          {content?.map((item: any, index: any) => (
            <Box>
              <Stack
                spacing={1.5}
                justifyContent={"center"}
                flexDirection={isSmallerScreen ? "column" : "row"}
                className={index !== 0 ? "margin-top-20px" : ""}
              >
                <Typography
                  className={styles["typography-text"]}
                >
                  {item?.fieldTitle}
                </Typography>

                <Typography
                  className={styles["typography-title"]}
                >
                  {item?.value}
                </Typography>
              </Stack>

              {content?.length !== index + 1 && (
                <div className={styles["dashed-card-divider"]}></div>
              )}
            </Box>
          ))}
        </Box>
      </DialogContent>

      <Divider />
      <div className={styles["dialog-actions"]}>
        <Button
          className={styles["okay-btn"]}
          variant="text"
          color="inherit"
          onClick={handleClose}
        >
          {cancelBtnText}
        </Button>
      </div>
    </Dialog>
  );
};

export default CustomerRegistrationDetailsDialog;
