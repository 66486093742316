import { useSelector } from "react-redux";
import styles from "./PageFooter.module.css";

const PageFooter = () => {
  const { pageFooter } = useSelector((state: any) => ({
    pageFooter:
      state?.investmentDetails?.investmentDetails?.projectContent
        ?.opportunityDoc?.pageFooter,
  }));

  return (
    <div className={styles["page-footer-holder"]}>
      <div className={styles["page-footer"]}>{pageFooter}</div>
    </div>
  );
};

export default PageFooter;
