import {
  Dialog,
  DialogContent,
  Stack,
  IconButton,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./MediaViewCarousel.module.css";
import { useMemo } from "react";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import { numOfItems } from "utils/helperFunctions";
import { Carousel } from "@mantine/carousel";
import { Overlay } from "@mantine/core";
import MediaAssets from "assets";
interface IImageViewDialog {
  open: boolean;
  documentString?: string;
  handleClose: () => void;
  title?: string;
  imageUrl?: string;
  videoUrl?: string;
  mediaUrlObject?: any;
  contentType?: string;
  mediaContent: any;
  initialIndex: number;
}

const MediaViewCarousel = (props: IImageViewDialog) => {
  const {
    open,
    handleClose,
    contentType = "img",
    mediaContent,
    initialIndex,
  } = props;

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));

  const MainCarouselSlideComponent = (props: any) => {
    const { handleClose } = props;
    return (
      <>
        {mediaContent?.length > 0 &&
          mediaContent?.map((media: any, index: number) => (
            <Carousel.Slide key={media?.mediaContent?.key}>
              <Box
                sx={{ paddingBottom: "80px" }}
                className={
                  contentType === "video" ? "" : styles["slide-content-wrapper"]
                }
              >
                <Stack
                  justifyContent={"start"}
                  spacing={3}
                  mb={3}
                  ml={isMobileScreen ? 3 : 0}
                  sx={{ minHeight: "70px" }}
                >
                  <IconButton edge={"start"} onClick={handleClose}>
                    <Avatar
                      src={MediaAssets.ic_close_white}
                      sx={{ width: 20, height: 20 }}
                    />
                  </IconButton>

                  <Box>
                    <Typography className={styles["white-color"]}>
                      {media?.name}
                    </Typography>
                  </Box>
                </Stack>
                {contentType === "video" ? (
                  <iframe
                    allow="fullscreen"
                    className={styles["dialog-video"]}
                    src={media?.mediaContent?.value?.url}
                  />
                ) : (
                  <img
                    className={styles["dialog-image"]}
                    src={media?.mediaContent?.value?.url}
                  />
                )}
              </Box>
            </Carousel.Slide>
          ))}
      </>
    );
  };

  return (
    <div>
      {open && <Overlay opacity={0.2} color="#000" blur={5} />}
      <Dialog
        hideBackdrop
        open={open}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            paddingTop: isLargeScreen ? "80px" : "",
            overflow: "hidden",
          },
        }}
        className={styles["background-transparent"]}
        fullScreen={isMobileScreen ? true : false}
        maxWidth={!isMobileScreen && "md"}
        onClose={handleClose}
      >
        {mediaContent?.length > 0 && !isMobileScreen ? (
          <MantineCarousel
            align={"end"}
            containermargin={0}
            controlsOffset={0}
            slideGap={0}
            initialSlide={initialIndex}
            styles={{
              control: {
                "&[data-inactive]": {
                  opacity: 0,
                  cursor: "default",
                },
              },
            }}
          >
            <MainCarouselSlideComponent handleClose={handleClose} />
          </MantineCarousel>
        ) : (
          mediaContent?.length > 0 && (
            <Carousel
              align={"end"}
              controlsOffset={0}
              controlSize={36}
              slideGap={"sm"}
              initialSlide={initialIndex}
              styles={{
                control: {
                  "&[data-inactive]": {
                    opacity: 0,
                    cursor: "default",
                  },
                },
              }}
            >
              <MainCarouselSlideComponent handleClose={handleClose} />
            </Carousel>
          )
        )}
      </Dialog>
    </div>
  );
};

export default MediaViewCarousel;
