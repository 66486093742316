import { Box, Divider, useMediaQuery, useTheme } from "@mui/material";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import { ReactNode } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import styles from "./ApplyNowConfirmation.module.css";
import { numDifferentiation } from "utils/helperFunctions";

interface IApplyNowConfirmationProps {
  sku: any;
  shouldOpen: boolean;
  title?: string;
  content: string;
  okText?: string;
  cancelHandler: (e?: any) => any;
  okHandler?: (e?: any) => any;
  cancelBtnText?: string;
  isOkDisabled?: boolean;
  detailMessageContent?: ReactNode;
}

const ApplyNowConfirmation = ({
  sku,
  shouldOpen,
  content,
  okText,
  cancelHandler,
  okHandler,
  cancelBtnText = "Cancel",
  isOkDisabled,
  detailMessageContent,
}: IApplyNowConfirmationProps) => {
  const dialogContent = content ? content : "";
  const okBtnText = okText ? okText : "Okay";

  const handleClose = () => {
    cancelHandler();
  };

  const handleOkay = () => {
    okHandler ? okHandler() : cancelHandler();
  };

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog maxWidth={"sm"} open={shouldOpen} disableEscapeKeyDown={true}>
      <Box sx={{ padding: isSmallerScreen ? "" : "0 30px" }}>
        <DialogContent>
          <DialogContentText className={styles["content-text"]}>
            {dialogContent}
          </DialogContentText>
          <section className={styles["sku-detail-holder"]}>
            <div className={styles["name-area-holder"]}>
              <h3>{sku?.name}</h3>
              <span>{`${sku?.areaRange?.from} - ${sku?.areaRange?.to} Sqft`}</span>
            </div>
            <div className={styles["amount-holder"]}>
              <span className={styles["amount"]}>{`₹${numDifferentiation(
                sku?.priceRange?.from
              )} - ${numDifferentiation(sku?.priceRange?.to)}`}</span>
            </div>
            <div>
              <p className={styles["description"]}>{sku?.shortDescription}</p>
            </div>
          </section>
        </DialogContent>
        <Divider />
        <DialogActions className={styles["confirm-dialog-actions"]}>
          <MuiStyledButton
            variant="text"
            color="primary"
            size="large"
            sx={{ fontFamily: "Jost-Bold" }}
            onClick={handleClose}
          >
            {cancelBtnText}
          </MuiStyledButton>

          <MuiStyledButton
            variant="text"
            color="primary"
            size="large"
            sx={{ fontFamily: "Jost-Bold" }}
            onClick={handleOkay}
          >
            {okBtnText}
          </MuiStyledButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ApplyNowConfirmation;
