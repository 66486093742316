import { Typography } from "@mui/material";
import { splitOutPara } from "utils/helperFunctions";
import styles from "./UpdateItem.module.css";

const UpdateItem = (update: any) => {
  return (
    <>
      <div className={styles["update-card"]} onClick={update?.onClick}>
        <h2>{update?.update.displayTitle}</h2>

        <div>
          <span
            className={`${styles["project-name"]} ${styles["line-ellipsis"]}`}
          >
            {update?.update.subTitle}
          </span>
        </div>

        {update?.update?.detailedInfo[0]?.media?.value?.url && (
          <img
            src={update?.update?.detailedInfo[0]?.media?.value?.url}
            alt=""
          />
        )}

        <Typography
          mt={update?.update?.detailedInfo[0]?.media?.value?.url ? 0 : 2}
          className={
            update?.update?.detailedInfo[0]?.media?.value?.url
              ? styles["description-text-ellipsis"]
              : styles["description-text-ellipsis-4"]
          }
          dangerouslySetInnerHTML={
            update?.update?.detailedInfo[0]?.media?.value?.url
              ? splitOutPara(update?.update?.detailedInfo[0]?.description)
              : { __html: update?.update?.detailedInfo[0]?.description }
          }
        ></Typography>
      </div>
    </>
  );
};

export default UpdateItem;
