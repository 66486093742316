import { AccordionProps, AccordionSummaryProps, styled, Paper, Box } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordion from "@mui/material/Accordion";
import styles from './AccordionComponent.module.css';

interface IAccordianSummaryProps {
  outlined?: boolean;
}
const Accordion = styled((props: AccordionProps) => (
    <Box>
        <MuiAccordion disableGutters elevation={0} square {...props} />
    </Box>
))(({ theme }) => ({
  marginTop: "1rem",
  marginBottom: "1rem",
    boxShadow: "0 3px 16px 0 rgba(0, 0, 0, 0.07)",
  borderRadius: "10px",
  "&&&& .MuiAccordionDetails-root": {
    backgroundColor: 'white',
    marginLeft: "5px"
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled(
  (props: AccordionSummaryProps & IAccordianSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={
        <KeyboardArrowDownIcon className={styles["down-arrow"]} />
      }
      {...props}
    />
  )
)(({ theme, outlined = false }) => ({
  font: "600 0.5rem 'Jost-Regular'",
  minHeight: "64px",
  borderRadius: "8px",
  color: "black",
  backgroundColor: "white",
  textOverFlow:'ellipsis',
  fontFamily: 'Jost-Regular',

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "&& .MuiButtonBase-root-MuiAccordionSummary-root": {
    borderRadius: "8px",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  fontFamily: 'Jost-Regular',
}));

export { Accordion, AccordionSummary, AccordionDetails };
