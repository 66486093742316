import { useSelector } from "react-redux";
import { Carousel } from "@mantine/carousel";
import KeyPillarItem from "./KeyPillarItem";
import styles from "./KeyPillars.module.css";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";

const KeyPillars = () => {
  const { investmentDetails } = useSelector((state: any) => ({
    investmentDetails: state?.investmentDetails?.investmentDetails,
  }));

  return (
    <div className={styles["key-pillars-holder"]}>
      <h2>{investmentDetails?.projectContent?.keyPillars?.heading}</h2>
      {investmentDetails?.projectContent?.keyPillars?.values !== null &&
        investmentDetails?.projectContent?.keyPillars?.values?.length > 0 && (
          <MantineCarousel
            slideSize="33%"
            // height={300}
            slidesToScroll={1}
            align={"start"}
            slideGap={"xl"}
            breakpoints={[
              { maxWidth: "md", slideSize: "50%" },
              { maxWidth: "sm", slideSize: "70%" },
            ]}
          >
            {investmentDetails?.projectContent?.keyPillars?.values?.map(
              (keyPillar: any, index: any) => {
                return (
                  <Carousel.Slide key={index}>
                    <KeyPillarItem keyPillar={keyPillar} />
                  </Carousel.Slide>
                );
              }
            )}
          </MantineCarousel>
        )}
    </div>
  );
};

export default KeyPillars;
