import { AccordionProps, AccordionSummaryProps, styled } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordion from "@mui/material/Accordion";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginTop: "1rem",
  marginBottom: "1rem",
  border: `1px solid #bbbccd`,
  backgroundColor: theme.palette.common.white,
  borderRadius: "8px",
  "&&&& .MuiAccordionDetails-root": {
    padding: "0px ",
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <KeyboardArrowDownIcon
        sx={{ fontSize: "2rem", color: "secondary.main" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  padding: 0,
  font: "600 0.5rem 'Jost-Regular'",
  borderRadius: "8px",
  color: theme.palette.common.black,
  backgroundColor: theme.palette.grey[50],
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    padding: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: "1px solid #bbbccd",
}));

export { Accordion, AccordionSummary, AccordionDetails };
