import { DialogContent, DialogProps, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  FormDialog,
  FormDialogAction,
  FormDialogTitle,
} from "./MuiDialogs.style";
import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";

export interface IDialogCustomProps extends DialogProps {
  handleformsubmit?: (submitFunct: any) => void;
  isformlayout?: boolean;
  formbuttondisabled?: boolean;
  handleclose?: () => void;
  formheading?: string;
}

const MuiFormDialog = (props: IDialogCustomProps) => {
  const {
    open,
    maxWidth,
    handleclose,
    children,
    handleformsubmit,
    isformlayout = false,
    formheading = "",
    formbuttondisabled = false,
    fullScreen,
  } = props;

  return (
    <FormDialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={maxWidth}
      open={open}
      isformlayout={isformlayout}
    >
      <FormDialogTitle formheading={formheading}>
        {formheading}
        <IconButton aria-label="close" onClick={handleclose}>
          <CancelIcon color="error" />
        </IconButton>
      </FormDialogTitle>
      <DialogContent>{children}</DialogContent>
      {isformlayout && (
        <FormDialogAction>
          <MuiLoadingButton
            fullWidth
            disabled={formbuttondisabled}
            onClick={handleformsubmit}
          >
            Submit
          </MuiLoadingButton>
        </FormDialogAction>
      )}
    </FormDialog>
  );
};

export default MuiFormDialog;
