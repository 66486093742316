import { Stack, Grid, Typography, Divider, Box } from "@mui/material";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import React, { useEffect, useState } from "react";
import { formatDateTime, dateConstants } from "utils/DateFormatter";
import styles from "./PaymentHistory.module.css";
import { DocumentsActions } from "redux-container/documents/DocumentsRedux";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/Loader";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";
import ViewReceiptDrawer from "../view-receipt-drawer/ViewReceiptDrawer";

interface IPaymentHistory {
  paymentDetails: any;
  detailsCount?: number;
  handleViewClick?: any;
  paymentsReceipts?: any;
  setOpenViewReceiptDrawer?: any;
  openViewReceiptDrawer?: boolean;
  shouldShowSeeReceiptsButton?: boolean;
}

const actionDispatch = (dispatch: any) => {
  return {
    downloadDoc: (path: any) =>
      dispatch(DocumentsActions.getDownloadDocDataRequest(path)),
    setDocumentsState: (key: any, value: any) =>
      dispatch(DocumentsActions.setDocumentsState(key, value)),
  };
};

const PaymentHistory = (props: IPaymentHistory) => {
  const {
    paymentDetails,
    detailsCount = 4,
    paymentsReceipts,
    setOpenViewReceiptDrawer,
    openViewReceiptDrawer,
    shouldShowSeeReceiptsButton = true,
  } = props;

  const { downloadDoc, setDocumentsState } = actionDispatch(useDispatch());
  const isDocLoading = useSelector((state: any) => state?.documents?.isLoading);
  const showPDFPreview = useSelector(
    (state: any) => state?.documents?.showPDFPreview
  );
  const docError = useSelector((state: any) => state?.documents?.error);
  const [showPdf, setShowPdf] = useState(false);

  const documentBaseString = useSelector(
    (state: any) => state?.documents?.downloadDocData
  );

  const handleViewClick = async (path: any) => {
    await downloadDoc(path);
  };

  const dataURItoBlob = (dataURI: any) => {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  };

  useEffect(() => {
    if (showPDFPreview && showPdf) {
      const blob = dataURItoBlob(documentBaseString);
      const url = URL.createObjectURL(blob);
      window.open(url);
      setDocumentsState("showPDFPreview", false);
      setShowPdf(false);
    }
  }, [showPDFPreview]);

  const [showNoDocError, setShowNoDocError] = useState(false);

  const [viewReceiptsDocs, setViewReceiptDoc] = useState(paymentsReceipts);

  const handleCloseViewReceiptDrawer = () => {
    setOpenViewReceiptDrawer(false);
  };
  const noDocErrorMessage =
    "Please connect with your relationship manager for more details.";

  const handleViewNoReceiptError = () => {
    setShowNoDocError(true);
  };

  const handleCloseNoReceiptError = () => {
    setShowNoDocError(false);
  };

  const handleViewRecieptsDrawer = (id?: any) => {
    const getReceipts = paymentsReceipts?.filter(
      (receipt: any) => receipt?.crmBookingId === id
    );

    if (paymentsReceipts?.length === 0) {
      handleViewNoReceiptError();
    } else {
      setViewReceiptDoc(paymentsReceipts);
      setOpenViewReceiptDrawer(true);
    }
  };

  return (
    <div>
      <MuiSnackbar
        shouldOpen={showNoDocError ? true : false}
        message={showNoDocError ? noDocErrorMessage : docError ? docError : ""}
        isSuccess={true}
        closeHandler={handleCloseNoReceiptError}
      />
      <ViewReceiptDrawer
        open={openViewReceiptDrawer}
        handleClose={handleCloseViewReceiptDrawer}
        documents={viewReceiptsDocs}
        handleViewClick={handleViewClick}
      />
      {isDocLoading && !openViewReceiptDrawer ? (
        <Loader />
      ) : (
        <div>
          {paymentDetails.map((payment: any, index: number) => (
            <Box key={index}>
              {index < detailsCount && (
                <>
                  <Stack mt={3} className={styles["payment-card"]}>
                    <Grid container>
                      <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                        <Stack flexDirection={"column"} alignItems={"start"}>
                          <Typography
                            className={styles["typography-theme-text"]}
                          >
                            ₹{payment?.paidAmount ? payment?.paidAmount : "-"}
                          </Typography>
                          <div className={styles["content-sm-flex"]}>
                            <Typography
                              className={styles["typography-text-bold"]}
                            >
                              {payment?.launchName ? payment?.launchName : "-"}
                            </Typography>
                            <Typography
                              className={styles["typography-text-bold"]}
                            >
                              {payment?.crmInventory &&
                                "Land id: " + `${payment?.crmInventory}`}
                            </Typography>
                          </div>
                        </Stack>
                      </Grid>

                      <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                        <Stack flexDirection={"column"} alignItems={"end"}>
                          <Typography
                            className={styles["typography-text-italic"]}
                          >
                            {payment?.paymentDate
                              ? formatDateTime(
                                  payment?.paymentDate,
                                  dateConstants.dateFormatDDthmmYY
                                )
                              : "-"}
                          </Typography>
                          {shouldShowSeeReceiptsButton && (
                            <MuiStyledButton
                              className={styles["see-receipt-btn"]}
                              variant="text"
                              color={"inherit"}
                              onClick={() =>
                                handleViewRecieptsDrawer(payment?.crmBookingId)
                              }
                            >
                              See Receipt
                            </MuiStyledButton>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Divider />
                </>
              )}
              {/* {paymentDetails?.length !== index + 1 && <Divider />} */}
            </Box>
          ))}
        </div>
      )}
    </div>
  );
};

export default PaymentHistory;
