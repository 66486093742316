export const enum GenderTypeEnum {
  Male = "Male",
  Female = "Female",
}

export class GenderTypeEnumUtils {
  public static getEnumText(type: GenderTypeEnum): string {
    switch (type) {
      case GenderTypeEnum.Male:
        return "Male";
      case GenderTypeEnum.Female:
        return "Female";
    }
  }

  public static getGenderTypeEnums(): {
    label: string;
    value: GenderTypeEnum;
  }[] {
    const options: { label: string; value: GenderTypeEnum }[] = [
      {
        label: this.getEnumText(GenderTypeEnum.Male),
        value: GenderTypeEnum.Male,
      },
      {
        label: this.getEnumText(GenderTypeEnum.Female),
        value: GenderTypeEnum.Female,
      },
    ];
    return options;
  }
}
