import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import MuiTextField from "components/mui-textfield/MuiTextfield";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  MuiLoadingButton,
  MuiStyledButton,
} from "components/mui-buttons/MuiButtons";
import { ReferYourFriendStyleWrapper } from "./ReferYourFriend.style";
import { ReferYourFriendAction } from "redux-container/refer-your-friend/ReferYourFriendRedux";
import { useDispatch } from "react-redux";
import useForm from "app/hooks/useForm";
import {
  addReferralPayload,
  addReferralValidate,
} from "./ReferYourFriendInitialValues";
import {
  handleNumberFieldChange,
  handleTextInput,
} from "utils/formHelperUtils";
import { Constants } from "constants/Constants";

const actionDispatch = (dispatch: any) => {
  return {
    referYourFriendRequest: (data: any) =>
      dispatch(ReferYourFriendAction.referYourFriendRequest(data)),
    setReferYourFriendState: (key: any, value: any) =>
      dispatch(ReferYourFriendAction.setReferYourFriendState(key, value)),
  };
};

const ReferYourFriend = (props: { [key: string]: any }) => {
  const { handleAdormentClick } = props;
  const {
    handleSubmit,
    valid,
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
  } = useForm({
    initialValues: addReferralPayload,
    validate: addReferralValidate,
  });
  const { referYourFriendRequest, setReferYourFriendState } = actionDispatch(
    useDispatch()
  );

  const onSubmit = (values: typeof addReferralPayload) => {
    referYourFriendRequest(values);
    setReferYourFriendState("isDialogOpen", false);
    setReferYourFriendState("isResponseDialogOpen", true);
  };

  return (
    <ReferYourFriendStyleWrapper>
      <Box className="template-container" gap={2}>
        <Box gridArea={"image-bg"}>
          <Avatar
            alt="Remy Sharp"
            sx={{ width: "100%", height: "35vh" }}
            src={MediaAssets.ic_refer_friend}
          />
        </Box>
        <Box gridArea={"pop-title"}>
          <Typography
            variant="h4"
            color={"primary.main"}
            fontFamily={"Sea-Side"}
          >
            The Joy of Sharing begins here
          </Typography>
        </Box>
        <Box className={"form-field-section"} gridArea={"form-section"}>
          <MuiTextField
            label={"First Name *"}
            name={"name"}
            placeholder={"Type here"}
            value={values.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleTextInput(event, setFieldValue)
            }
            onBlur={handleBlur}
            error={errors.name && touched.name}
            helperText={errors.name && touched.name && errors.name}
          />
          <MuiTextField
            name={"phoneNumber"}
            label={"Enter Your Phone Number"}
            placeholder={"Type here"}
            value={values.phoneNumber}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleNumberFieldChange(event, setFieldValue)
            }
            inputProps={{
              maxLength: Constants.MAX_MOBILE_NUMBER_DIGITS,
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            onBlur={handleBlur}
            error={errors.phoneNumber && touched.phoneNumber}
            helperText={
              errors.phoneNumber && touched.phoneNumber && errors.phoneNumber
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={handleAdormentClick}
                    component={"span"}
                    variant={"body2"}
                    fontWeight={500}
                    fontFamily={"Jost-Medium"}
                  >
                    +91
                    <IconButton edge="start">
                      <ExpandMoreIcon
                        fontSize="small"
                        sx={{ color: "common.black" }}
                      />
                    </IconButton>
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box className="submit-button-section" gridArea={"button-section"}>
          <MuiStyledButton
            variant="contained"
            disabled={!valid}
            onClick={handleSubmit(onSubmit)}
          >
            Refer your Friend
          </MuiStyledButton>
        </Box>
      </Box>
    </ReferYourFriendStyleWrapper>
  );
};

export default ReferYourFriend;
