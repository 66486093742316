import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import { Carousel } from "@mantine/carousel";
import { useDispatch, useSelector } from "react-redux";
import { NewLaunchActions } from "redux-container/investments/new-launch/NewLaunchRedux";
import {
  IconButton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { numOfItems } from "utils/helperFunctions";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useEffect } from "react";
import MuiCard from "components/mui-card/MuiCard";
import LastFewPlotsDrawer from "./LastFewPlotsDrawer";
import styles from "./LastFewPlots.module.css";
import EastIcon from "@mui/icons-material/East";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";

const actionDispatch = (dispatch: any) => {
  return {
    setNewLaunchState: (key: any, value: any) =>
      dispatch(NewLaunchActions.setNewLaunchState(key, value)),
  };
};

const LastFewPlots = () => {
  const navigate = useNavigate();

  const { setNewLaunchState } = actionDispatch(useDispatch());

  const { pageData, collections } = useSelector((state: any) => ({
    pageData: state?.newLaunch?.newLaunchData?.page,
    collections:
      state?.newLaunch?.newLaunchData?.pageManagementsOrCollectionOneModels,
  }));

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    return () => {
      setNewLaunchState("isLastFewPlotsDialogOpen", false);
    };
  }, []);

  const handleNavigation = (id: number, launchname: string) => {
    navigate(RouteConfigurations.landSkuList, {
      state: { projectId: id, launchName: launchname },
    });
  };

  const handleNavigteToInvestmentDetails = (id: any) => {
    navigate(RouteConfigurations.investmentDetails, {
      state: { projectId: id },
    });
  };

  return (
    <section className={styles["last-few-plots-holder"]}>
      <div className={styles["section-heading-holder"]}>
        <div>
          <h1>{pageData?.collectionOne?.heading}</h1>
          <span>{pageData?.collectionOne?.subHeading}</span>
        </div>

        <MuiStyledButton
          endIcon={<EastIcon />}
          variant="text"
          color="primary"
          onClick={() => setNewLaunchState("isLastFewPlotsDialogOpen", true)}
        >
          SEE ALL
        </MuiStyledButton>
      </div>

      {Boolean(collections.length) && (
        <MantineCarousel
          slidesToScroll={
            pageData?.collectionOne?.totalProjectContentsToDisplay >= 2 ? 1 : 2
          }
          controlsOffset={0}
          slideSize={numOfItems(4)}
          breakpoints={[
            { maxWidth: "xl", slideSize: numOfItems(3.3) },
            { maxWidth: "lg", slideSize: numOfItems(2.3) },
            { maxWidth: "md", slideSize: numOfItems(2) },
            { maxWidth: "sm", slideSize: numOfItems(2) },
            { maxWidth: "xs", slideSize: numOfItems(1.2) },
          ]}
        >
          {collections
            ?.slice(0, pageData?.collectionOne?.totalProjectContentsToDisplay)
            .map((detail: typeof collections[0], detailIndex: number) => (
              <Carousel.Slide key={detailIndex}>
                <MuiCard
                  sx={{
                    height: "100%",
                  }}
                  key={detailIndex + 1}
                  src={detail?.projectCoverImages?.homePageMedia?.value?.url}
                  buttonwitharrow={true}
                  customheight={"25vh"}
                  detail={detail}
                  onbuttonclick={() =>
                    handleNavigation(detail?.id, detail?.launchName)
                  }
                >
                  <Stack columnGap={isSmallScreen ? "0.5rem" : "1rem"}>
                    <Typography
                      variant={isSmallScreen ? "body2" : "body1"}
                      color={"grey.A400"}
                      fontFamily={"Jost-Light"}
                    >
                      {detail?.shortDescription}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        handleNavigteToInvestmentDetails(detail?.id)
                      }
                      color="inherit"
                    >
                      <EastIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                </MuiCard>
              </Carousel.Slide>
            ))}
        </MantineCarousel>
      )}

      <LastFewPlotsDrawer />
    </section>
  );
};

export default LastFewPlots;
