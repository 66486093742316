import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "@mantine/carousel";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import SkuItem from "./SkuItem";
import EastIcon from "@mui/icons-material/East";
import ApplyNowConfirmation from "./ApplyNowConfirmation";
import { useState } from "react";
import { LandSkuListActions } from "redux-container/investments/land-sku-list/LandSkuListRedux";
import MuiActionStatusDialog from "components/mui-dialogs/MuiActionStatusDialog";
import { numOfItems } from "utils/helperFunctions";

const actionDispatch = (dispatch: any) => {
  return {
    getLandSkuList: (id: any) =>
      dispatch(LandSkuListActions.getLandSkuListRequest(id)),
    addInventory: (data: any) =>
      dispatch(LandSkuListActions?.postAddInventoryRequest(data)),
    setLandSkuList: (key: any, value: any) =>
      dispatch(LandSkuListActions.setLandSkuListState(key, value)),
  };
};

const Skus = () => {
  const navigate = useNavigate();

  const [selectedSku, setSelectedSku] = useState<any>(null);

  const { getLandSkuList, addInventory, setLandSkuList } = actionDispatch(
    useDispatch()
  );

  const { investmentDetails, landSkus, isApplySkuOpen, isInventoryAdded } =
    useSelector((state: any) => ({
      investmentDetails: state?.investmentDetails?.investmentDetails,
      landSkus:
        state?.landSkuList?.landSkuListData?.projectContent
          ?.inventoryBucketContents,
      isApplySkuOpen: state?.landSkuList?.isApplySkuOpen,
      isInventoryAdded: state?.landSkuList?.isInventoryAdded,
    }));

  const navigateToLandSkus = () => {
    navigate(RouteConfigurations.landSkuList, {
      state: {
        projectId: investmentDetails?.projectContent?.id,
        launchName: investmentDetails?.projectContent?.launchName,
      },
    });
  };

  const handleCloseConfirmDialog = () => {
    setLandSkuList("isApplySkuOpen", false);
  };

  const onClickApplyNow = (sku: any) => {
    setSelectedSku(sku);
    setLandSkuList("isApplySkuOpen", true);
  };

  const handleAddToInventory = () => {
    const requestBody = {
      inventoryBucketId: selectedSku?.id,
      launchPhaseId: investmentDetails?.projectContent?.id,
    };
    addInventory(requestBody);
    handleCloseConfirmDialog();
  };

  const handleFormDialogClose = () => {
    setLandSkuList("isInventoryAdded", false);
    getLandSkuList(investmentDetails?.projectContent?.id);
  };

  return (
    <div className="section-holder">
      <div className="section-heading-holder">
        <h1>
          {investmentDetails?.projectContent?.otherSectionHeadings
            ?.inventoryBucketContents?.sectionHeading !== null
            ? investmentDetails?.projectContent?.otherSectionHeadings
                ?.inventoryBucketContents?.sectionHeading
            : "Choose SKUs and Apply"}
        </h1>
        <MuiStyledButton
          endIcon={<EastIcon />}
          variant="text"
          color="primary"
          onClick={navigateToLandSkus}
        >
          SEE ALL
        </MuiStyledButton>
      </div>

      {investmentDetails?.projectContent?.inventoryBucketContents &&
        investmentDetails?.projectContent?.inventoryBucketContents?.length >
          0 && (
          <MantineCarousel
            dragFree={false}
            slideSize={numOfItems(4.5)}
            withControls={
              investmentDetails?.projectContent?.inventoryBucketContents
                ?.length <= 4
                ? false
                : true
            }
            slidesToScroll={2}
            align={"start"}
            slideGap={"xl"}
            breakpoints={[
              { maxWidth: "xl", slideSize: numOfItems(4.5) },
              { maxWidth: "lg", slideSize: numOfItems(3.4) },
              { maxWidth: "md", slideSize: numOfItems(2.3) },
              { maxWidth: "sm", slideSize: numOfItems(2) },
              { maxWidth: "xs", slideSize: numOfItems(2) },
            ]}
          >
            {landSkus?.map((sku: any, index: any) => {
              return (
                <Carousel.Slide key={index}>
                  <SkuItem sku={sku} applyNowSku={onClickApplyNow} />
                </Carousel.Slide>
              );
            })}
          </MantineCarousel>
        )}

      <ApplyNowConfirmation
        sku={selectedSku}
        shouldOpen={isApplySkuOpen}
        content="Are you sure you want to apply for this SKU?"
        okText="Yes"
        cancelBtnText="No"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={handleAddToInventory}
      />

      <MuiActionStatusDialog
        maxWidth={"sm"}
        issuccess={isInventoryAdded ? true : false}
        isconfirmdialog={false}
        responsemessage={"Thank you for your interest!"}
        message={"Our Project Manager will reach out to you in 24 hours!"}
        open={isInventoryAdded ? true : false}
        handleclose={handleFormDialogClose}
      />
    </div>
  );
};

export default Skus;
