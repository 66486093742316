import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentHistory from "./payment-history/PaymentHistory";
import { Box, Stack, Typography } from "@mui/material";
import styles from "./MyAccount.module.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useSelector } from "react-redux";
import {
  ButtonArrowIcon,
  MuiLoadingButton,
  MuiStyledButton,
} from "components/mui-buttons/MuiButtons";

const AllPayments = () => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const myAccountDetails = useSelector(
    (state: any) => state?.profile?.myAccountDetails
  );

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const [openViewReceiptDrawer, setOpenViewReceiptDrawer] = useState(false);

  return (
    <div>
      <div className={styles["page-heading"]}>
        <Stack spacing={3} justifyContent={"space-between"}>
          <Stack spacing={1} justifyContent={"start"}>
            <KeyboardArrowLeftIcon onClick={handleNavigateBack} />
            <Typography className={styles["section-heading-name"]}>
              Payment History
            </Typography>
          </Stack>

          <MuiStyledButton
            className={styles["see-all-receipt-btn"]}
            variant="text"
            color="inherit"
            disabled={myAccountDetails?.paymentReceipts?.length === 0}
            onClick={() => setOpenViewReceiptDrawer(true)}
          >
            See All Receipts
          </MuiStyledButton>
        </Stack>
      </div>

      <Box>
        <PaymentHistory
          detailsCount={location?.state?.length}
          paymentDetails={location?.state}
          paymentsReceipts={myAccountDetails?.paymentReceipts}
          openViewReceiptDrawer={openViewReceiptDrawer}
          setOpenViewReceiptDrawer={setOpenViewReceiptDrawer}
          shouldShowSeeReceiptsButton={false}
        />
      </Box>
    </div>
  );
};

export default AllPayments;
