import { Fragment } from "react";
import { Drawer, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { InvestmentDetailsActions } from "redux-container/investments/investment-details/InvestmentDetailsRedux";
import ProjectSearchDetails from "../../search/projects/ProjectDetailedCard";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styles from "./SimiliarInvestments.module.css";
import { useClickOutside } from "@mantine/hooks";
import { Overlay } from "@mantine/core";

const actionDispatch = (dispatch: any) => {
  return {
    setInvestmentDetailsState: (key: any, value: any) =>
      dispatch(InvestmentDetailsActions.setInvestmentDetailsState(key, value)),
  };
};

const SimiliarInvestmentsDrawer = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { setInvestmentDetailsState } = actionDispatch(useDispatch());

  const { isSimiliarInvestmentDrawerOpen, investmentDetails } = useSelector(
    (state: any) => ({
      isSimiliarInvestmentDrawerOpen:
        state?.investmentDetails?.isSimiliarInvestmentDrawerOpen,
      investmentDetails: state?.investmentDetails?.investmentDetails,
    })
  );

  const toggleDrawer = () => {
    setInvestmentDetailsState("isSimiliarInvestmentDrawerOpen", false);
  };
  const ref = useClickOutside(toggleDrawer, ["mouseup", "mousedown"]);
  return (
    <>
      {isSimiliarInvestmentDrawerOpen && (
        <Overlay opacity={0.2} color="#000" blur={5} />
      )}
      <Drawer
        anchor={"right"}
        open={isSimiliarInvestmentDrawerOpen}
        onClose={() =>
          setInvestmentDetailsState("isSimiliarInvestmentDrawerOpen", false)
        }
        PaperProps={{
          ref: ref,
          style: isMediumScreen
            ? {
                width: "100%",
                marginTop: "0px",
                padding: "20px 20px 120px 20px",
              }
            : {
                width: "50%",
                padding: "20px 20px 120px 20px",
              },
        }}
      >
        <div className={styles["heading-holder"]}>
          <h2>
            {investmentDetails?.projectContent?.similarInvestmentSectionHeading}
          </h2>
          <IconButton aria-label="close" onClick={toggleDrawer}>
            <CloseRoundedIcon />
          </IconButton>
        </div>

        <Fragment>
          {investmentDetails?.projectContent?.similarInvestments &&
            investmentDetails?.projectContent?.similarInvestments.length &&
            investmentDetails?.projectContent?.similarInvestments.map(
              (project: any, index: any) => {
                return (
                  <ProjectSearchDetails
                    key={index}
                    launchName={project?.launchName}
                    city={project?.address?.city}
                    state={project?.address?.state}
                    mediaUrl={
                      project?.projectCoverImages?.homePageMedia?.value?.url
                    }
                    appreciation={
                      project?.generalInfoEscalationGraph?.estimatedAppreciation
                    }
                    startingPrice={project?.priceStartingFrom}
                    startingArea={project?.areaStartingFrom}
                    description={project?.shortDescription}
                    id={project?.id}
                    isSoldout={project?.isSoldOut}
                  />
                );
              }
            )}
        </Fragment>
      </Drawer>
    </>
  );
};

export default SimiliarInvestmentsDrawer;
