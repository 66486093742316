import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TestimonialsActions } from "../../redux-container/testimonials/TestimonialsRedux";
import TestimonialItem from "./TestimonialItem";
import PageHeader from "components/page-header/PageHeader";
import ReferralSection from "pages/home/referral-section/ReferralSection";
import { useMemoizedValue } from "app/hooks/useMemoizedValue";

const actionDispatch = (dispatch: any) => {
  return {
    getTestimonials: () =>
      dispatch(TestimonialsActions.getTestimonialsRequest()),
    setTestimonialsState: (key: any, data: any) =>
      dispatch(TestimonialsActions.setTestimonialsState(key, data)),
  };
};

const AllTestimonials = () => {
  const { getTestimonials } = actionDispatch(useDispatch());

  const { pageData, testimonials } = useSelector((state: any) => ({
    pageData: state?.pageManagement?.pageData?.page,
    testimonials: state?.testimonials.testimonials,
  }));

  const slicedTestimonialData = useMemoizedValue(
    testimonials,
    pageData?.totalTestimonialsOnListView
  );

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    getTestimonials();
  };

  return (
    <Fragment>
      <PageHeader
        title={pageData?.testimonialsHeading}
        subtitle={pageData?.testimonialsSubHeading}
      />
      {slicedTestimonialData.map((testimonial: any) => {
        return (
          <TestimonialItem key={testimonial.id} testimonial={testimonial} />
        );
      })}
      <ReferralSection />
    </Fragment>
  );
};

export default AllTestimonials;
