import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import CancelIcon from "@mui/icons-material/Cancel";

interface IActionDialogCustomProps extends DialogProps {
  isconfirmdialog?: boolean;
  issuccess?: boolean;
  handleclose?: () => void;
  responsemessage?: string;
  message?: string;
}

const MuiActionStatusDialog = (props: IActionDialogCustomProps) => {
  const {
    open,
    maxWidth,
    handleclose,
    children,
    isconfirmdialog = false,
    issuccess = false,
    message = "",
    responsemessage = "",
  } = props;
  return (
    <Dialog open={open} fullWidth={true} maxWidth={maxWidth}>
      {!isconfirmdialog && (
        <DialogTitle sx={{ paddingBottom: 0, textAlign: "end" }}>
          <IconButton aria-label="close" onClick={handleclose}>
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent>
        <Stack justifyContent={"center"}>
          {issuccess ? (
            <CheckCircleRoundedIcon
              sx={{
                fontSize: { xs: "5rem", lg: "3.5rem", xl: "3.5rem" },
                color: "success.light",
              }}
            />
          ) : (
            <CancelIcon
              sx={{ fontSize: { xs: "5rem", lg: "3.5rem", xl: "3.5rem" } }}
              color="error"
            />
          )}
        </Stack>
        <Typography
          textAlign={"center"}
          fontFamily={"Jost-Medium"}
          variant={"body1"}
          gutterBottom
          mt={"1rem"}
        >
          {responsemessage}
        </Typography>
        <Typography
          color={"grey.A200"}
          textAlign={"center"}
          variant={"body2"}
          gutterBottom
        >
          {message}
        </Typography>
        {children}
      </DialogContent>
      {isconfirmdialog && (
        <>
          <Divider variant="middle" sx={{ color: "#b6b3d0" }} />
          <DialogActions sx={{ justifyContent: "space-around" }}>
            <MuiStyledButton
              variant="text"
              color="primary"
              size="large"
              sx={{ fontFamily: "Jost-Bold" }}
              onClick={handleclose}
            >
              OKAY
            </MuiStyledButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default MuiActionStatusDialog;
