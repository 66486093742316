import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import { Carousel } from "@mantine/carousel";
import {
  Stack,
  IconButton,
  Typography,
  useMediaQuery,
  Theme,
  styled,
} from "@mui/material";
import ActionItem from "components/action-item/ActionItem";
import PanToolIcon from "@mui/icons-material/PanTool";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";

const ActionItemStyleWrapper = styled("div")`
  & .section-title {
    font: 500 1.875rem "Jost-Medium";
    ${({ theme }) => theme.breakpoints.down("sm")} {
      font-size: 16px;
    }
  }
`;

const ActionItemSection = () => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const navigate = useNavigate();
  const { actionItemData } = useSelector((state: any) => ({
    actionItemData: state?.userManagement?.userData,
  }));

  const handleSeeAllClick = (item: typeof actionItemData[0]) => {
    if (item?.actionItemType === 50) {
      navigate(RouteConfigurations.bookingJourney, {
        state: {
          investment: item?.investmentId,
          project: item,
          crmInventoryName: item?.inventoryId,
          primaryOwner: item?.primaryOwner,
          isBookingComplete: item?.bookingStatus === 225360010,
        },
      });
    } else {
      navigate(RouteConfigurations.profile);
    }
  };

  return (
    <ActionItemStyleWrapper>
      <Stack my={isSmallScreen ? "0.5rem" : "2rem"}>
        <Stack spacing={3} paddingLeft={isSmallScreen ? "0" : "2.8rem"}>
          <PanToolIcon sx={{ rotate: "y 180deg" }} />
          <Typography className="section-title">
            The following items need your attention
          </Typography>
        </Stack>
      </Stack>
      {actionItemData && !!actionItemData.length && (
        <MantineCarousel
          mx={"auto"}
          align={"center"}
          slideSize={"100%"}
          slideGap={"sm"}
          includeGapInSize={false}
          containermargin={isSmallScreen ? "" : "1rem 0.4rem 4rem"}
          controlsOffset={0}
          withIndicators
          sx={{
            ".mantine-Carousel-controls": {
              top: "calc(50% - 50px)",
            },
          }}
        >
          {actionItemData?.map(
            (item: typeof actionItemData[0], index: number) => (
              <Carousel.Slide key={index + 1}>
                <ActionItem
                  actionstatus={item?.cardTitle}
                  title={item?.displayTitle}
                  description={item?.displayText}
                  onseeallclick={() => handleSeeAllClick(item)}
                />
              </Carousel.Slide>
            )
          )}
        </MantineCarousel>
      )}
    </ActionItemStyleWrapper>
  );
};

export default ActionItemSection;
