import { useSelector } from "react-redux";
import LocationInfrastructureItem from "./LocationInfrastructureItem";
import { Carousel } from "@mantine/carousel";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { Box } from "@mui/material";
import { numOfItems } from "utils/helperFunctions";

const LocationInfrastructure = () => {
  const navigate = useNavigate();

  const { investmentDetails } = useSelector((state: any) => ({
    investmentDetails: state?.investmentDetails?.investmentDetails,
  }));

  const handleNavigateToMaps = (infra: any) => {
    navigate(RouteConfigurations.investmentLocation, {
      state: { selectedInfra: infra },
    });
  };

  return (
    <div className="section-holder">
      <div className="section-heading-holder">
        <h1>
          {investmentDetails?.projectContent?.locationInfrastructure?.heading}
        </h1>
        <MuiStyledButton
          endIcon={<EastIcon />}
          variant="text"
          color="primary"
          onClick={() => navigate(RouteConfigurations.investmentLocation)}
        >
          SEE ALL
        </MuiStyledButton>
      </div>

      {investmentDetails?.projectContent?.locationInfrastructure?.values
        ?.length > 0 ? (
        <MantineCarousel
          slideSize={numOfItems(4.5)}
          breakpoints={[
            { maxWidth: "xl", slideSize: numOfItems(4.5) },
            { maxWidth: "lg", slideSize: numOfItems(4.5) },
            { maxWidth: "md", slideSize: numOfItems(4.5) },
            { maxWidth: "sm", slideSize: numOfItems(4) },
            { maxWidth: "xs", slideSize: numOfItems(1.5) },
          ]}
          slidesToScroll={
            investmentDetails?.projectContent?.locationInfrastructure?.values
              ?.length >= 4
              ? 2
              : 1
          }
          align={"start"}
          slideGap={"xl"}
          mx={"auto"}
          controlsOffset={0}
        >
          {investmentDetails?.projectContent?.locationInfrastructure?.values?.map(
            (infra: any, index: any) => {
              return (
                <Carousel.Slide key={index}>
                  <Box onClick={() => handleNavigateToMaps(infra)}>
                    <LocationInfrastructureItem infra={infra} />
                  </Box>
                </Carousel.Slide>
              );
            }
          )}
        </MantineCarousel>
      ) : null}
      {/* <LocationInfrastructureItem /> */}
    </div>
  );
};

export default LocationInfrastructure;
