import {
  Avatar,
  Grid,
  Typography,
  useMediaQuery,
  Theme,
  Box,
} from "@mui/material";
import MediaAssets from "assets";
import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";
import ShareIcon from "@mui/icons-material/Share";
import MuiFormDialog from "components/mui-dialogs/MuiFormDialog";
import { useState } from "react";
import ReferYourFriend from "../refer-your-friend/ReferYourFriend";
import ShareDialog from "../share-dialog/ShareDialog";
import { useDispatch, useSelector } from "react-redux";
import { ReferYourFriendAction } from "redux-container/refer-your-friend/ReferYourFriendRedux";
import MuiActionStatusDialog from "components/mui-dialogs/MuiActionStatusDialog";
import { TroubleSigninAction } from "redux-container/login/trouble-signingin-redux/TroubleSigninginRedux";
import NumberListDialog from "components/number-list-dialog/NumberListDialog";
import { ReferralSectionStyleWrapper } from "./ReferralSection.style";

const actionDispatch = (dispatch: any) => {
  return {
    setReferYourFriendState: (key: any, value: any) =>
      dispatch(ReferYourFriendAction.setReferYourFriendState(key, value)),
    setTroubleSigninState: (key: any, value: any) =>
      dispatch(TroubleSigninAction.setTroubleSigninState(key, value)),
  };
};

const ReferralSection = () => {
  const { setReferYourFriendState, setTroubleSigninState } = actionDispatch(
    useDispatch()
  );
  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  const {
    isResponseDialogOpen,
    isSuccess,
    error,
    message,
    isDialogOpen,
    isCountryCodeDialogOpen,
  } = useSelector((state: any) => ({
    isResponseDialogOpen: state?.referYourFriend?.isResponseDialogOpen,
    isDialogOpen: state?.referYourFriend?.isDialogOpen,
    isSuccess: state?.referYourFriend?.isSuccess,
    error: state?.referYourFriend?.error,
    isCountryCodeDialogOpen: state?.troubleSignin?.isCountryCodeDialogOpen,
    message: state?.referYourFriend?.message,
  }));
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const handleDialogOpen = () => {
    setReferYourFriendState("isDialogOpen", true);
  };

  const openCountryCodeDialog = () => {
    setTroubleSigninState("isCountryCodeDialogOpen", true);
  };

  const handleFormDialogClose = () => {
    setReferYourFriendState("isResponseDialogOpen", false);
  };

  const handleDialogClose = () => {
    setReferYourFriendState("isDialogOpen", false);
  };

  const onClickShareDialog = () => {
    setShareDialogOpen(true);
  };
  const onCloseShareDialog = () => {
    setShareDialogOpen(false);
  };
  return (
    <ReferralSectionStyleWrapper
      container
      sx={{ margin: isSmallScreen ? "0 0 5rem" : "5rem 0" }}
      gap={{ sm: 0, xs: 0, md: 0, lg: 3, xl: 4 }}
    >
      <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
        <Avatar
          src={MediaAssets.ic_refer_friend}
          sx={{ width: "100%", height: isSmallScreen ? "30vh" : "40vh" }}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={5} xl={4}>
        <Typography variant="h3" className="joy-share-text">
          The Joy of Sharing begins here
        </Typography>
        <MuiLoadingButton size="large" onClick={handleDialogOpen}>
          Refer Now
        </MuiLoadingButton>
      </Grid>
      <Grid mt={3} item xs={12} lg={7} xl={7}>
        <Box className="box-group" onClick={onClickShareDialog}>
          <Box className="box-item">
            Share <strong> HOABL APP</strong> with your friends
          </Box>
          <Box className="box-item">
            <ShareIcon fontSize="small" />
            SHARE
          </Box>
        </Box>
        <MuiFormDialog
          maxWidth={"md"}
          open={isDialogOpen}
          children={
            <ReferYourFriend handleAdormentClick={openCountryCodeDialog} />
          }
          handleclose={handleDialogClose}
        />
        {isResponseDialogOpen && (isSuccess || error) && (
          <MuiActionStatusDialog
            maxWidth={"sm"}
            isconfirmdialog={true}
            issuccess={isSuccess}
            responsemessage={isSuccess ? message : error ? error : ""}
            open={isResponseDialogOpen}
            handleclose={handleFormDialogClose}
          />
        )}

        {isShareDialogOpen && (
          <ShareDialog onShareDialogClose={onCloseShareDialog} />
        )}
        <NumberListDialog
          open={isCountryCodeDialogOpen}
          onClick={() =>
            setTroubleSigninState("isCountryCodeDialogOpen", false)
          }
        />
      </Grid>
    </ReferralSectionStyleWrapper>
  );
};

export default ReferralSection;
