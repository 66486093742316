import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import React from "react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { IPortfolioComponentProps } from "../Portfolio";
import { Constants } from "../../../constants/Constants";

const mapContainerStyle = {
  width: "100%",
  height: "100vh",
};

const InvestmentSummaryMap = () => {
  const location = useLocation();
  const { state }: IPortfolioComponentProps = location;
  const investmentLocation = useMemo(() => {
    let updatedValue;
    updatedValue = {
      lat: Number(state?.investmentLocation?.latitude),
      lng: Number(state?.investmentLocation?.longitude),
    };
    return updatedValue;
  }, [state?.investmentLocation]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: Constants.GOOGLE_MAP_KEY,
  });

  if (!isLoaded) {
    return <div>Loading</div>;
  }
  
  return (
    <GoogleMap
      center={investmentLocation ? investmentLocation : undefined}
      zoom={18}
      mapContainerStyle={mapContainerStyle}
      options={{
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        keyboardShortcuts: false,
      }}
    >
      {investmentLocation ? (
        <MarkerF position={investmentLocation} visible={true} />
      ) : null}
    </GoogleMap>
  );
};

export default InvestmentSummaryMap;
