import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import CancelIcon from "@mui/icons-material/Cancel";
import Stories from "react-insta-stories";
import MediaAssets from "assets";
import React, { Fragment, useEffect, useState } from "react";
import Loader from "components/loader/Loader";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import styles from "./UpdateDetails.module.css";
import { Overlay } from "@mantine/core";
import ChevronRightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import ChevronLeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";

interface IUpdateDetails {
  open: boolean;
  maxWidth?: string;
  handleclose: any;
  storiesData: any;
  storyIndex?: number;
}

const UpdateDetails = (props: IUpdateDetails) => {
  const { open, maxWidth, handleclose, storiesData, storyIndex = 1 } = props;

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const pushedStories: any = [];

  const [openStories, setOpenStories] = useState<boolean>(false);

  const [currentId, setCurrentId] = useState(storyIndex);
  const [arrowIndex, setArrowIndex] = useState<number>(storyIndex);

  const handleClickRightArrow = () => {
    if (arrowIndex + 1 < pushedStories?.length) {
      setArrowIndex(arrowIndex + 1);
    }
  };

  const handleClickLeftArrow = () => {
    if (arrowIndex > 0) {
      setArrowIndex(arrowIndex - 1);
    }
  };

  const handleDialogclose = () => {
    setOpenStories(false);
    handleclose();
  };

  useEffect(() => {
    setArrowIndex(storyIndex);
    if (open) {
      setOpenStories(true);
    }
  }, [open]);

  const mainStories = storiesData?.map((story: any, indedx: number) => {
    // variable not used but story content is pushed to an array to reach requirements.
    let content = {
      content: (props: any) => (
        <Box
          className={styles["stories-content-section"]}
          mt={20}
          key={indedx + 1}
        >
          <Box mt={isMobileScreen ? -7 : -10}>
            <Stack spacing={1}>
              {storiesData?.map((item: any, index: number) => (
                <Fragment key={index + 1}>
                  {index <= currentId ? (
                    <div className={styles["active-stepper"]}></div>
                  ) : (
                    <div className={styles["inactive-stepper"]}></div>
                  )}
                </Fragment>
              ))}
            </Stack>
          </Box>
          <Stack>
            <Stack spacing={1} mt={2} textAlign={"start"}>
              {isMobileScreen && (
                <IconButton
                  onClick={handleDialogclose}
                  onMouseDown={handleDialogclose}
                  sx={{
                    color: "gray",
                    zIndex: 10000,
                  }}
                >
                  <ChevronLeft />
                </IconButton>
              )}
              <div className={styles["stories-heading-content"]}>
                <Typography className={`${styles["typography-title-text"]}`}>
                  {story.displayTitle}
                </Typography>
                <Typography
                  className={`${styles["typography-title-sub-text-italic"]}`}
                >
                  {story.subTitle}
                </Typography>
              </div>
            </Stack>
            {!isMobileScreen && (
              <Button
                onClick={handleDialogclose}
                variant={"text"}
                sx={{ color: "gray", zIndex: 10000 }}
                onMouseDown={handleDialogclose}
              >
                <CloseIcon />
              </Button>
            )}
          </Stack>
          {story?.detailedInfo?.map((item: any, index: number) => (
            <Box mt={2} key={index + 1}>
              {item.media?.value?.url && (
                <img
                  className={styles["story-content-image"]}
                  src={item.media?.value?.url}
                  alt=""
                />
              )}
              <Typography
                mt={2}
                className={styles["stories-details-content"]}
                dangerouslySetInnerHTML={{ __html: item.description }}
              ></Typography>
            </Box>
          ))}
        </Box>
      ),
    };
    pushedStories.push(content);
  });

  return (
    <>
      {openStories && <Overlay opacity={0.2} color="#000" blur={5} />}

      <Dialog
        hideBackdrop
        open={openStories}
        maxWidth={"lg"}
        color={"white"}
        fullScreen
        fullWidth={isMobileScreen ? true : false}
        style={{ backgroundColor: "transparent" }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            height: isMobileScreen ? "100vh" : "95vh",
          },
        }}
      >
        <div
          style={
            isMobileScreen
              ? {}
              : {
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }
          }
        >
          {!isMobileScreen && (
            <Box sx={{ visibility: arrowIndex === 0 ? "hidden" : "visible" }}>
              <IconButton
                style={{ paddingRight: "40px" }}
                onClick={handleClickLeftArrow}
              >
                <ChevronLeftIcon className={styles["left-arrow"]} />
              </IconButton>
            </Box>
          )}
          <Box sx={isMobileScreen ? {} : { width: "50%" }}>
            <DialogContent className={styles["dialog-content"]}>
              <Box
                className={isMobileScreen ? `${styles["dialog-content"]}` : ""}
              >
                <Box mt={-10}>
                  <Stories
                    // loop
                    stories={pushedStories}
                    currentIndex={Math.abs(arrowIndex)}
                    defaultInterval={999999999999999}
                    storyContainerStyles={{
                      backgroundColor: "white",
                      overflow: "hidden",
                      textAlign: "center",
                    }}
                    width="100%"
                    height="100%"
                    keyboardNavigation={true}
                    onAllStoriesEnd={(s: any, st: any) => {
                      setCurrentId(s);
                    }}
                    onStoryStart={(s: any, st: any) => {
                      setCurrentId(s);
                      setArrowIndex(s);
                    }}
                  />
                </Box>
              </Box>
            </DialogContent>
          </Box>
          {!isMobileScreen && (
            <Box
              sx={{
                visibility:
                  arrowIndex === pushedStories?.length - 1
                    ? "hidden"
                    : "visible",
              }}
            >
              <IconButton
                style={{ paddingLeft: "40px" }}
                onClick={handleClickRightArrow}
              >
                <ChevronRightIcon className={styles["right-arrow"]} />
              </IconButton>
            </Box>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default UpdateDetails;
