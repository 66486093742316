import { Avatar, Grid } from "@mui/material";

const PromotionCard = ({
  cardurl,
  oncardclick,
}: {
  cardurl: string;
  oncardclick?: () => void;
}) => {
  return (
    <Grid container my={"2rem"} alignItems={"center"} justifyContent={"center"}>
      <Grid item xs={10} sm={10} md={6} lg={6} xl={6} onClick={oncardclick}>
        <Avatar
          src={cardurl}
          sx={{ width: "100%", height: "60%", cursor: "pointer" }}
        />
      </Grid>
    </Grid>
  );
};

export default PromotionCard;
