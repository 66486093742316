import { RouteConfigurations } from "routes/RouteConfigurations";

export const enum NotificationTargetPageEnum {
  HOME = 1,
  UPDATES = 2,
  INSIGHTS = 3,
  INVESTMENT = 4,
  PORTFOLIO = 5,
  PROMISE = 6,
  PROFILE = 7,
  CHAT = 8,
  NA = 0,
}

export class NotificationTargetPageEnumUtils {
  public static getPageRouteEnumText(value: NotificationTargetPageEnum) {
    switch (value) {
      case NotificationTargetPageEnum.HOME:
        return RouteConfigurations.home;
      case NotificationTargetPageEnum.UPDATES:
        return RouteConfigurations.updates;
      case NotificationTargetPageEnum.INSIGHTS:
        return RouteConfigurations.insights;
      case NotificationTargetPageEnum.INVESTMENT:
        return RouteConfigurations.investments;
      case NotificationTargetPageEnum.PORTFOLIO:
        return RouteConfigurations.portfolio;
      case NotificationTargetPageEnum.PROMISE:
        return RouteConfigurations.promise;
      case NotificationTargetPageEnum.PROFILE:
        return RouteConfigurations.profile;
      case NotificationTargetPageEnum.CHAT:
        return RouteConfigurations.chat;
      case NotificationTargetPageEnum.NA:
        return RouteConfigurations.home;
    }
  }
}
