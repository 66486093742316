import { Fragment, useEffect } from "react";
import { InvestmentDetailsActions } from "../../redux-container/investments/investment-details/InvestmentDetailsRedux";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getPageAction } from "redux-container/page-management-redux/PageManagementRedux";
import { PageTypeEnum } from "enumerations/PageTypeEnum";
import { LandSkuListActions } from "redux-container/investments/land-sku-list/LandSkuListRedux";
import InvestmentMap from "./investment-map/InvestmentMap";
import InvestmentOverview from "./investment-overview/InvestmentOverview";
import InvestmentPromotions from "./investment-promotions/InvestmentPromotions";
import KeyPillars from "./key-pillars/KeyPillars";
import PriceTrends from "./price-trends/PriceTrends";
import ProjectAmenities from "./project-amenities/ProjectAmenities";
import Skus from "./skus/Skus";
import OpportunityDoc from "./opportunity-doc/OpportunityDoc";
import LocationInfrastructure from "./location-infrastructure/LocationInfrastructure";
import StillNotConvinced from "./still-not-convinced/StillNotConvinced";
import Testimonials from "pages/home/testimonials/Testimonials";
import VideosSection from "./videos-section/VideosSection";
import SimilarInvestments from "./similar-investments/SimilarInvestments";
import ProjectFaq from "./project-faq/ProjectFaq";
import ProjectPromises from "./project-promises/ProjectPromises";
import Loader from "components/loader/Loader";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";

const actionDispatch = (dispatch: any) => {
  return {
    getPromises: (pageType: string) =>
      dispatch(getPageAction.getPromiseRequest(pageType)),
    getInvestmentDetails: (id: number) =>
      dispatch(InvestmentDetailsActions.getInvestmentDetailsRequest(id)),
    getAllWatchlist: () =>
      dispatch(InvestmentDetailsActions.getAllWatchlistRequest()),
    getLandSkuList: (id: any) =>
      dispatch(LandSkuListActions.getLandSkuListRequest(id)),
    setInvestmentDetailsState: (key: any, value: any) =>
      dispatch(InvestmentDetailsActions.setInvestmentDetailsState(key, value)),
  };
};

const InvestmentDetails = () => {
  const location: any = useLocation();

  const {
    getPromises,
    getInvestmentDetails,
    getAllWatchlist,
    getLandSkuList,
    setInvestmentDetailsState,
  } = actionDispatch(useDispatch());

  const {
    error,
    investmentDetails,
    isLoading,
    isPromiseLoading,
    isLandSkuLoading,
    isWatchlistLoading,
  } = useSelector((state: any) => ({
    error: state?.investmentDetails?.error,
    investmentDetails: state?.investmentDetails?.investmentDetails,
    isLoading: state?.investmentDetails?.isLoading,
    isPromiseLoading: state?.pageManagement?.isLoading,
    isLandSkuLoading: state?.landSkuList?.isLoading,
    isWatchlistLoading: state?.investmentDetails?.isWatchlistLoading,
  }));

  useEffect(() => {
    if (location.state?.projectId) {
      const projectId = location.state?.projectId;
      getInvestmentDetails(projectId);
      getLandSkuList(projectId);
    }
    getAllWatchlist();
    getPromises(PageTypeEnum.Promises);
    window.scroll(0, 0);
  }, [location.state?.projectId]);

  return isLoading ||
    isPromiseLoading ||
    isLandSkuLoading ||
    isWatchlistLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <InvestmentOverview />
      <OpportunityDoc />
      {investmentDetails?.projectContent?.address?.isMapDetailsActive && (
        <InvestmentMap />
      )}
      {investmentDetails?.projectContent?.isEscalationGraphActive && (
        <PriceTrends />
      )}
      {investmentDetails?.projectContent?.isKeyPillarsActive && <KeyPillars />}
      {investmentDetails?.projectContent?.isMediaGalleryActive && (
        <VideosSection />
      )}

      {investmentDetails?.projectContent?.isOffersAndPromotionsActive && (
        <InvestmentPromotions />
      )}
      {investmentDetails?.projectContent?.isInventoryBucketActive && <Skus />}
      {investmentDetails?.projectContent?.opportunityDoc
        ?.isProjectAminitiesActive && (
        <ProjectAmenities showAllAmenities={false} />
      )}
      {investmentDetails?.projectContent?.isLocationInfrastructureActive && (
        <LocationInfrastructure />
      )}
      <ProjectPromises />
      <StillNotConvinced
        launchName={investmentDetails?.projectContent?.launchName}
        projectId={investmentDetails?.projectContent?.id}
      />
      {investmentDetails?.projectContent?.isMappedFaqSectionHeaderActive && (
        <ProjectFaq />
      )}
      <Testimonials />
      {investmentDetails?.projectContent?.isSimilarInvestmentActive && (
        <SimilarInvestments />
      )}

      <MuiSnackbar
        shouldOpen={error !== null ? true : false}
        message={error}
        isSuccess={false}
        closeHandler={() => setInvestmentDetailsState("error", null)}
      />
    </Fragment>
  );
};

export default InvestmentDetails;
