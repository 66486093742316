import MediaAssets from "assets";
import styles from "./ProjectAmenities.module.css";

const ProjectAmenitiesItem = (amenity: any) => {
  return (
    <div className={styles["project-amenity-card"]}>
      <img src={amenity?.amenity?.icon?.value?.url} alt="" />
      <span>{amenity?.amenity?.name}</span>
    </div>
  );
};

export default ProjectAmenitiesItem;
