import { Button, Divider } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import styles from "./Skus.module.css";
import MediaAssets from "assets";
import { numDifferentiation } from "utils/helperFunctions";

interface ISkuItemProps {
  sku: any;
  applyNowSku: (sku: any) => void;
}

const SkuItem = ({ sku, applyNowSku }: ISkuItemProps) => {
  const applyNow = () => {
    applyNowSku(sku);
  };

  return (
    <div
      className={`${styles["sku-card"]} ${
        sku?.isSoldOut && !sku?.isApplied
          ? styles["sold-out-card"]
          : styles["apply-card"]
      }`}
    >
      <div className={styles["sku-content"]}>
        <h2
          className={`${
            sku?.isSoldOut && !sku?.isApplied
              ? styles["sold-out-name-text"]
              : styles["apply-now-name-text"]
          }`}
        >
          {sku?.name}
        </h2>
        <div>
          <span
            className={`${styles["starting-at"]} ${
              sku?.isSoldOut && !sku?.isApplied
                ? styles["sold-out-starting-at-text"]
                : styles["apply-now-starting-at-text"]
            }`}
          >
            Starting at
          </span>
          <span
            className={`${styles["amount"]} ${
              sku?.isSoldOut && !sku?.isApplied
                ? styles["sold-out-amount"]
                : styles["apply-now-amount"]
            }`}
          >
            ₹{numDifferentiation(sku?.priceRange?.from)}
          </span>
        </div>
        <span
          className={`${
            sku?.isSoldOut && !sku?.isApplied
              ? styles["sold-out-area-text"]
              : styles["apply-now-area-text"]
          }`}
        >{`${sku?.areaRange?.from} - ${sku?.areaRange?.to} Sqft`}</span>
      </div>

      <Divider sx={{ marginBottom: "10px" }} />

      {/* 
            //   Sold Out Logic
            if applied (true ) = show applied
            if applied ( false ) & is sold out ( true ) = show soldout
            if applied ( false ) & is sold out ( false ) = show apply now
      */}
      {sku?.isApplied === true ? (
        <div className={styles["applied-btn"]}>
          <img src={MediaAssets.ic_green_tick} alt="" />
          <span>Applied</span>
        </div>
      ) : sku?.isApplied === false &&
        sku?.isSoldOut &&
        sku?.isApplied !== true ? (
        <div className={styles["sold-out-btn-holder"]}>
          <Button className={"btn btn-gray"} disableElevation>
            Sold Out
          </Button>
        </div>
      ) : (
        <Button
          className={styles["apply-now-btn"]}
          variant="text"
          endIcon={<EastIcon />}
          onClick={applyNow}
        >
          Apply Now
        </Button>
      )}

      {/* {sku?.isApplied === false && sku?.isSoldOut && sku?.isApplied !== true ? (
        <div className={styles["sold-out-btn-holder"]}>
          <Button className={"btn btn-gray"} disableElevation>
            Sold Out
          </Button>
        </div>
      ) : null*/}

      {/* {sku?.isApplied === false && sku?.isSoldOut === false ? (
        <Button
          className={styles["apply-now-btn"]}
          variant="text"
          endIcon={<EastIcon />}
          onClick={applyNow}
        >
          Apply Now
        </Button>
      ) : null} */}
    </div>
  );
};

export default SkuItem;
