import React from "react";
import { Fragment, useEffect, useState } from "react";
import {
  Drawer,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styles from "./NewLaunch.module.css";
import { useDispatch, useSelector } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { NewLaunchActions } from "redux-container/investments/new-launch/NewLaunchRedux";
import ProjectSearchDetails from "../../search/projects/ProjectDetailedCard";
import { useClickOutside } from "@mantine/hooks";
import { Overlay } from "@mantine/core";

const actionDispatch = (dispatch: any) => {
  return {
    setNewLaunchState: (key: any, value: any) =>
      dispatch(NewLaunchActions.setNewLaunchState(key, value)),
  };
};

const NewLaunchesDrawer = (investments: any) => {
  const { setNewLaunchState } = actionDispatch(useDispatch());

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const newLaunches = investments?.investments?.pageManagementsOrNewInvestments;

  const { isSeeAllNewLaunchDialogOpen, newLaunch } = useSelector(
    (state: any) => ({
      isSeeAllNewLaunchDialogOpen: state.newLaunch?.isSeeAllNewLaunchDialogOpen,
      newLaunch: state.newLaunch?.newLaunchData,
    })
  );

  const toggleDrawer = () => {
    setNewLaunchState("isSeeAllNewLaunchDialogOpen", false);
  };
  const ref = useClickOutside(
    () => setNewLaunchState("isSeeAllNewLaunchDialogOpen", false),
    ["mouseup", "mousedown"]
  );
  return (
    <>
      {isSeeAllNewLaunchDialogOpen && (
        <Overlay opacity={0.2} color="#000" blur={5} />
      )}
      <Drawer
        anchor={"right"}
        open={isSeeAllNewLaunchDialogOpen}
        onClose={() => setNewLaunchState("isSeeAllNewLaunchDialogOpen", false)}
        PaperProps={{
          ref: ref,
          style: isMediumScreen
            ? {
                width: "100%",
                marginTop: "0px",
                padding: "20px 2px 120px 2px",
                backgroundColor: "common.white",
              }
            : {
                width: "50%",
                marginTop: "125px",
                padding: "20px 20px 120px 20px",
                backgroundColor: "common.white",
              },
        }}
      >
        <div className={styles["heading-holder"]}>
          <h2>{newLaunch?.page?.newInvestments?.displayName}</h2>
          <IconButton aria-label="close" onClick={toggleDrawer}>
            <CloseRoundedIcon />
          </IconButton>
        </div>

        <Fragment>
          {newLaunches &&
            newLaunches.length &&
            newLaunches.map((project: any, index: any) => {
              return (
                <ProjectSearchDetails
                  key={index}
                  launchName={project?.launchName}
                  city={project?.address?.city}
                  state={project?.address?.state}
                  mediaUrl={
                    project?.projectCoverImages?.homePageMedia?.value?.url
                  }
                  appreciation={
                    project?.generalInfoEscalationGraph?.estimatedAppreciation
                  }
                  startingPrice={project?.priceStartingFrom}
                  startingArea={project?.areaStartingFrom}
                  description={project?.shortDescription}
                  id={project?.id}
                  isSoldout={project?.isSoldOut}
                />
              );
            })}
        </Fragment>
      </Drawer>
    </>
  );
};

export default NewLaunchesDrawer;
