import MediaAssets from "assets";
import React from "react";
import styles from "./TourismAround.module.css";

const TourismAroundItem = (tourism: any) => {
  return (
    <div className={styles["tourism-around-card"]}>
      <img src={tourism?.tourism?.media?.value?.url} alt="" />
      <div>
        <h2>{tourism?.tourism?.title}</h2>
        <p
          dangerouslySetInnerHTML={{ __html: tourism?.tourism?.description }}
        ></p>
      </div>
    </div>
  );
};

export default TourismAroundItem;
