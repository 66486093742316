import { Paper, Stack, Avatar, Typography, Box } from '@mui/material'
import MediaAssets from 'assets';
import { MuiStyledButton } from 'components/mui-buttons/MuiButtons';
import React from 'react';
import styles from './Documents.module.css';

const DocumentsSearch = (props: any) => {
    const {title, viewTitle='View', handleViewClick, isViewDisabled} = props;
  return (
    <Box className={styles["card-content"]}>
        <Stack>
            <Stack spacing={3} justifyContent={'start'}>
                <Avatar className={styles["doc-icon-img"]} src={MediaAssets.ic_docs} />
                <Typography className={styles["typography-title"]}>
                    {title}
                </Typography>
            </Stack>
            <MuiStyledButton
                className={styles["typography-view"]}
                variant="text"
                color="inherit"
                onClick={handleViewClick}
                disabled={isViewDisabled}
                >
                {viewTitle}
            </MuiStyledButton>
        </Stack>
    </Box>
  )
}

export default DocumentsSearch