import { useState, useEffect } from "react";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import { profileMenus } from "./ProfileConstants";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useNavigate } from "react-router-dom";
import { ProfileAction } from "redux-container/profile/ProfileRedux";
import { useDispatch, useSelector } from "react-redux";
import { EditProfileActions } from "../../redux-container/profile/EditProfileRedux";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import EditProfile from "./edit-profile/EditProfile";
import GenerateOtpActions from "../../redux-container/login/generate-otp/GenerateOtpRedux";
import MediaAssets from "assets";
import ProfileMenuItem from "./profile-menu-item/ProfileMenuItem";
import VerifyOtpActions from "../../redux-container/login/verify-otp/VerifyOtpRedux";
import NewUserNameInfoActions from "../../redux-container/login/new-user-name-info/NewUserNameInfoRedux";
import styles from "./Profile.module.css";
import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import MyServicesDialog from "./my-services-dialog/MyServicesDialog";
import EastIcon from "@mui/icons-material/East";
import Loader from "components/loader/Loader";
import { Overlay } from "@mantine/core";

const actionDispatch = (dispatch: any) => {
  return {
    logoutRequest: () => dispatch(ProfileAction.logoutRequest()),
    setGenerateOtpState: (key: any, data: any) =>
      dispatch(GenerateOtpActions.setGenerateOtpState(key, data)),
    setVerifyOtpState: (key: any, data: any) =>
      dispatch(VerifyOtpActions.setVerifyOtpState(key, data)),
    setEditProfileState: (key: any, data: any) =>
      dispatch(EditProfileActions.setEditProfileState(key, data)),
    getProfileDetails: () => dispatch(ProfileAction.getProfileDetailsRequest()),
    getCountries: () => dispatch(EditProfileActions.getCountriesRequest()),

    resetGenerateOtpState: () =>
      dispatch(GenerateOtpActions.resetGenerateOtpState()),
    resetVerifyOtpState: () => dispatch(VerifyOtpActions.resetVerifyOtpState()),
    resetNewUserNameInfoState: () =>
      dispatch(NewUserNameInfoActions.resetNewUserNameInfoState()),
    getStates: (countryCode: any) =>
      dispatch(EditProfileActions.getStatesRequest(countryCode)),
    getCities: (countryCode: any, stateIsoCode: any) =>
      dispatch(EditProfileActions.getCitiesRequest(countryCode, stateIsoCode)),
  };
};

const Profile = () => {
  const {
    logoutRequest,
    setEditProfileState,
    getProfileDetails,
    resetGenerateOtpState,
    resetVerifyOtpState,
    resetNewUserNameInfoState,
    getCities,
    getStates,
    getCountries,
  } = actionDispatch(useDispatch());

  const {
    profileDetails,
    isEditProfileDialogOpen,
    isProfilePicLoading,
    isLoading,
    states,
    cities,
    countries,
  } = useSelector((state: any) => ({
    profileDetails: state?.profile?.profileDetails,
    isEditProfileDialogOpen: state?.editProfile?.isEditProfileDialogOpen,
    isProfilePicLoading: state?.editProfile?.isProfilePicLoading,
    isLoading: state?.profile?.isLoading,
    cities: state?.editProfile?.cities,
    states: state?.editProfile?.states,
    countries: state?.editProfile?.countries,
  }));

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [profileDisplayName, setProfileDisplayName] = useState("");
  const [countryIsoCode, setCountryIsoCode] = useState("");
  const [stateIsoCode, setStateIsoCode] = useState("");

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const openEditProfile = () => {
    setEditProfileState("isEditProfileDialogOpen", true);
  };

  const logout = () => {
    logoutRequest();
    resetGenerateOtpState();
    resetVerifyOtpState();
    resetNewUserNameInfoState();
    localStorage.clear();
    navigate(RouteConfigurations.login);
  };

  const handleCloseLogoutDialog = () => {
    setIsLogoutDialogOpen(false);
  };

  const handleLogout = () => {
    setIsLogoutDialogOpen(true);
  };

  const displayFullName = () => {
    let fullName = "";
    if (profileDetails.firstName !== null && profileDetails.lastName !== null) {
      fullName = `${profileDetails.firstName} ${profileDetails.lastName}`;
    } else {
      fullName = `${profileDetails.firstName}`;
    }
    return fullName;
  };

  const getProfilePicture = () => {
    if (profilePicture === "" || profilePicture === null) {
      if (
        profileDetails?.firstName !== "" &&
        profileDetails?.firstName !== null &&
        profileDetails?.lastName !== "" &&
        profileDetails?.lastName !== null
      ) {
        return `${profileDetails?.firstName
          ?.toUpperCase()
          ?.charAt(0)}${profileDetails?.lastName?.toUpperCase()?.charAt(0)}`;
      } else {
        if (
          profileDetails?.firstName !== null &&
          profileDetails?.firstName.length > 0
        ) {
          return `${profileDetails?.firstName
            ?.toUpperCase()
            ?.charAt(0)}${profileDetails?.firstName?.toLowerCase()?.charAt(1)}`;
        }
      }
    }
  };

  const getSelectedStateDetails = async () => {
    if (!states?.length && profileDetails?.country) {
      await countries.find((country: any) => {
        if (country.name === profileDetails?.country) {
          setCountryIsoCode(country.isoCode);
          // to get states options for editprofile page
          getStates(country.isoCode);
        }
      });
    }
  };

  const getSelectedCitiesDetails = async () => {
    if (!cities?.length && profileDetails?.state) {
      await states.find((state: any) => {
        if (state.name === profileDetails?.state) {
          // to get cities options for editprofile page
          getCities(countryIsoCode, state?.isoCode);
        }
      });
    }
  };

  useEffect(() => {
    getCountries();
    setProfilePicture(profileDetails?.profilePictureUrl);
    getProfilePicture();
  }, [
    profileDetails?.profilePictureUrl,
    profileDisplayName,
    profilePicture,
    // isEditProfileDialogOpen,
  ]);

  useEffect(() => {
    if (!isEditProfileDialogOpen) {
      getProfileDetails();
    }
    setProfilePicture(profileDetails?.profilePictureUrl);
    getProfilePicture();
  }, [isEditProfileDialogOpen]);

  useEffect(() => {
    getSelectedStateDetails();
    getSelectedCitiesDetails();
  }, [
    profileDetails?.state,
    profileDetails?.country,
    states?.length,
    isEditProfileDialogOpen,
  ]);

  return (
    <>
      {isLoading && !isEditProfileDialogOpen ? (
        <Loader />
      ) : (
        <Box mb={6}>
          <div className={styles["user-info-holder"]}>
            <div className={styles["profile-image-container"]}>
              {isProfilePicLoading && !isEditProfileDialogOpen ? (
                <CircularProgress
                  color="inherit"
                  className={styles["profile-pic-loader"]}
                />
              ) : profilePicture ? (
                <img src={profilePicture} alt="" />
              ) : (
                <div>
                  <h1>{getProfilePicture()}</h1>
                </div>
              )}
            </div>
            <div>
              <h2>{displayFullName()}</h2>
              <div
                className={styles["edit-profile-holder"]}
                onClick={openEditProfile}
              >
                <img
                  src={MediaAssets.ic_edit}
                  alt="Pencil"
                  className={styles["edit-icon"]}
                />
                <span className={styles["edit-profile-link"]}>
                  Edit Profile
                </span>
              </div>
            </div>
          </div>

          {profileMenus.map((menu: any, index: number) => {
            return (
              <ProfileMenuItem
                key={index}
                title={menu.title}
                subtitle={menu.subtitle}
                icon={menu.icon}
                route={menu.route}
                currentIndex={index}
                maxIndex={profileMenus?.length}
              />
            );
          })}
          <div className={styles["menu"]} onClick={handleClickOpen}>
            <div className={styles["menu-info-holder"]}>
              <img src={MediaAssets.ic_my_services} alt="" />
              <div className={styles["menu-info"]}>
                <h3>{"My Services"}</h3>
                <p>{"Manage your land, book, etc"}</p>
              </div>
            </div>

            <EastIcon className={styles["east-icon"]} />
          </div>
          <div className={styles["logout-btn-holder"]}>
            <MuiStyledButton
              size="large"
              variant="contained"
              onClick={handleLogout}
              autoFocus
              disableElevation
              className={styles["logout-btn"]}
            >
              Logout
            </MuiStyledButton>
          </div>

          {isLogoutDialogOpen && (
            <Overlay
              onClick={handleCloseLogoutDialog}
              opacity={0.6}
              color="#000"
              blur={5}
            />
          )}

          <ConfirmationDialog
            shouldOpen={isLogoutDialogOpen}
            content={<strong>Are you sure you want to logout?</strong>}
            okText="Yes"
            cancelBtnText="No"
            cancelHandler={handleCloseLogoutDialog}
            okHandler={logout}
            showContainedDialog={!isSmallerScreen}
            hideBackdrop={true}
          />

          {isEditProfileDialogOpen && <EditProfile />}
          <MyServicesDialog open={open} handleclose={handleClose} />
        </Box>
      )}
    </>
  );
};

export default Profile;
