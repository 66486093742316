import { Grid, styled } from "@mui/material";

export const DiscoverAllInvestmentStyleWrapper = styled(Grid)`
  margin: 1rem 0 5rem;
  .discover-investment-carousel > .react-multi-carousel-track {
    & > .react-multi-carousel-item {
      margin: 1.3rem;
      ${({ theme }) => theme.breakpoints.down("sm")} {
        margin: 0.5rem;
      }
    }
  }
  & .custom-arrow {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.59);
  }
  & .left {
    left: 0.5rem;
  }
  & .right {
    right: 0.5rem;
  }
  & .newGenerationLand-text {
    padding: 0.5rem 0 1rem;
    position: relative;
  }
  & .investment-click-button {
    margin-top: 2rem;
  }
  ${({ theme }) => theme.breakpoints.down("sm")}{
    margin-bottom: 2rem;
    & .investment-click-button {
    margin-top: 1rem;
  }
  }
`;
