import { styled, Switch } from "@mui/material";

export const StyledSwitch = styled(Switch)(({ theme }) => `
  width: 35px;
  height: 20px;
  padding: 0;
  display: flex;
  & .MuiSwitch-switchBase {
    padding: 2px;
    &.Mui-checked {
      transform: translateX(16px);
      & + .MuiSwitch-track {
        opacity: 1;
        background-color: ${theme.palette.success.main};
        border: 1px solid ${theme.palette.success.main};

      }
      & .MuiSwitch-thumb {
        opacity: 1;
        background-color: ${theme.palette.common.white};
      }
    }
  }
  & .MuiSwitch-thumb {
    background-color: ${theme.palette.common.white};
    box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
    width: 15px;
    height: 15px;
    border-radius: 56px;
    transition: ${theme.transitions.create(["width"])};
    transition-duration: 200;
  }
  & .MuiSwitch-track {
    border: 1px solid ${theme.palette.grey[600]};
    background-color: ${theme.palette.grey[600]};
    border-radius: 16px;
    opacity: 1;
    box-sizing: border-box;
  };
`
);
