import React from "react";
import { Stack, Typography, Paper, Button, Box } from "@mui/material";
import styles from "./AccountDetailedCard.module.css";
import { MuiStyledButton } from "components/mui-buttons/MuiButtons";
import MediaUpload from "components/media-upload/MediaUpload";
interface IAccountDetailedCard {
  handleViewClick?: any;
  contentName?: string;
  isUpload?: boolean;
  handleUploadClick?: any;
  isViewDisabled?: boolean;
}

const AccountDetailedCard = (props: IAccountDetailedCard) => {
  const { handleViewClick, contentName, isUpload, handleUploadClick, isViewDisabled } = props;
  return (
    <div>
      <Box
        className={`${styles["content-padding"]} ${styles["card-content"]}`}
      >
        <Stack>
          <Typography className={styles["typography-content-name"]}>
            {contentName}
          </Typography>
          {isUpload ? (
            <MuiStyledButton className={styles["view-btn"]} variant="text">
              <MediaUpload
                uploadTitle={"Upload"}
                onChange={handleUploadClick}
              />
            </MuiStyledButton>
          ) : (
            <MuiStyledButton
              className={styles["view-btn"]}
              variant="text"
              color="inherit"
              onClick={handleViewClick}
            >
              View
            </MuiStyledButton>
          )}
        </Stack>
      </Box>
    </div>
  );
};

export default AccountDetailedCard;
