import { styled, Tabs, Tab, IconButton, Box, Stack, useTheme, createTheme, useMediaQuery } from '@mui/material'
import React, { Fragment, useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import TabsComponent from './tabs-component/TabsComponent'
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './MediaGallery.module.css'

const MediaGallery = () => {

  const location : any = useLocation();
  const mediaGalleryOrProjectContent = location?.state?.galleryData;
  const tabIndex = location?.state?.tabIndex ? location.state?.tabIndex : 0

  const navigate = useNavigate()

  const handleNavigateBack = () => {
    navigate(-1)
  }

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  
  return (
    <Fragment>
      <Box>
        <Box className={styles["media-content-wrapper"]} mt={1} ml={isSmallScreen ? -3 : 0}>
            <IconButton className={styles['nav-back-icon']} onClick={handleNavigateBack}>
                <KeyboardArrowLeftIcon />
            </IconButton>
        </Box>

        <Box>
            <TabsComponent 
              galleryData={mediaGalleryOrProjectContent}
              tabIndex={tabIndex}
            />
        </Box>
      </Box>
        
    </Fragment>
  )
}

export default MediaGallery