import { isOnlyNumberAllowed } from "./ValidationUtils";

const handleNumberFieldChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: any
) => {
  if (isOnlyNumberAllowed(event.target.value)) {
    setFieldValue(`${event.target.name}`, event.target.value);
  }
};

const handleTextInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: any
) => {
  setFieldValue(
    `${event.target.name}`,
    event.target.value.trimStart().replace(/[^A-Za-z ]/gi, "")
  );
};

export { handleNumberFieldChange, handleTextInput };
