import React from "react";
import { Box, Typography, Stack, Grid } from "@mui/material";
import styles from "./NoDetailsCard.module.css";

interface INoDetailsCard {
  icon: any;
  message: any;
  height?: string | number;
  shouldLeftAlign?: boolean;
}

const NoDetailsCard = (props: INoDetailsCard) => {
  const { icon, message, height = 160, shouldLeftAlign = false } = props;
  return (
    <Box className={styles["card-content"]} style={{ height: height }}>
      <Grid container justifyContent={"space-between"}>
        <Grid
          item
          xs={9}
          sm={9}
          md={shouldLeftAlign ? 5 : 9}
          lg={shouldLeftAlign ? 5 : 10}
          className={
            shouldLeftAlign
              ? styles["no-data-message-section-with-align"]
              : styles["no-data-message-section"]
          }
          justifyContent={"start"}
        >
          <Typography className={styles["no-data-message"]}>
            {message}
          </Typography>
        </Grid>

        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={2}
          className={styles["no-data-icon-section"]}
        >
          <img
            className={styles["no-data-icon"]}
            src={icon}
            alt={"No Data Text"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default NoDetailsCard;
