import { Divider } from "@mui/material";
import styles from "./KeyPillars.module.css";

const KeyPillarItem = (keyPillar: any) => {
  return (
    <div className={styles["key-pillar-card"]}>
      <div className={styles["heading-holder"]}>
        <img src={keyPillar?.keyPillar?.icon?.value?.url} alt="" />
        <h3>{keyPillar?.keyPillar?.name}</h3>
      </div>
      <Divider />
      <div>
        {keyPillar?.keyPillar?.points &&
        keyPillar?.keyPillar?.points.length > 0 ? (
          <p>{keyPillar?.keyPillar?.points[0]}</p>
        ) : null}
      </div>
    </div>
  );
};

export default KeyPillarItem;
