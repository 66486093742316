import React, { Fragment } from "react";
import styles from "./StillNotConvinced.module.css";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { InvestmentDetailsActions } from "redux-container/investments/investment-details/InvestmentDetailsRedux";
import { useDispatch, useSelector } from "react-redux";
import { TroubleSigninAction } from "redux-container/login/trouble-signingin-redux/TroubleSigninginRedux";
import { caseTypeEnum } from "enumerations/CaseTypeEnums";
import MuiActionStatusDialog from "components/mui-dialogs/MuiActionStatusDialog";
import { NotificationAction } from "redux-container/notifications/NotificationsRedux";
import { ButtonArrowIcon } from "components/mui-buttons/MuiButtons";
interface IStillNotConvinced {
  launchName: string;
  projectId: string | number;
  responseMessageTitle?: string;
}

const actionDispatch = (dispatch: any) => {
  return {
    setInvestmentDetailsState: (key: any, value: any) =>
      dispatch(InvestmentDetailsActions.setInvestmentDetailsState(key, value)),
    scheduleCall: (data: any) =>
      dispatch(TroubleSigninAction.addTroubleSigninRequest(data)),
    getNotifications: () => {
      dispatch(NotificationAction.getNotificationsRequest());
    },
  };
};

const StillNotConvinced = (props: IStillNotConvinced) => {
  const { launchName, projectId, responseMessageTitle } = props;

  const { setInvestmentDetailsState, scheduleCall, getNotifications } =
    actionDispatch(useDispatch());

  const { isResponseDialogOpen } = useSelector((state: any) => ({
    isResponseDialogOpen: state?.investmentDetails?.isResponseDialogOpen,
    error: state?.investmentDetails?.error,
  }));

  const handleScheduleCall = () => {
    const payloadForCall = {
      caseType: caseTypeEnum.CALL,
      description: `I Want to know more about the project ${launchName}`,
      issueType: "Schedule a video call",
      projectId: projectId,
    };
    scheduleCall(payloadForCall);
    setInvestmentDetailsState("isResponseDialogOpen", true);
  };

  const handleFormDialogClose = () => {
    setInvestmentDetailsState("isResponseDialogOpen", false);
    getNotifications();
  };

  return (
    <Fragment>
      <div onClick={handleScheduleCall} className={styles["card-dimension"]}>
        <div>
          <h1>Still not convinced?</h1>
          <p>
            Schedule a video call with our Project Managers, to answer any
            further questions you may have.
          </p>
        </div>
        <ButtonArrowIcon fillcolor="#ffffff" />
      </div>
      <MuiActionStatusDialog
        maxWidth={"sm"}
        issuccess={true}
        isconfirmdialog={isResponseDialogOpen}
        responsemessage={"Video Call request sent successfully."}
        message={"Our Project Manager will reach out to you soon!"}
        open={isResponseDialogOpen}
        handleclose={handleFormDialogClose}
      />
    </Fragment>
  );
};

export default StillNotConvinced;
