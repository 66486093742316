import { Box, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import MediaAssets from "assets";
import LineChart from "components/line-chart/LineChart";
import { useSelector } from "react-redux";
import styles from "./PriceTrends.module.css";

const PriceTrends = () => {
  const { graphDetails } = useSelector((state: any) => ({
    graphDetails:
      state?.investmentDetails?.investmentDetails?.projectContent
        ?.generalInfoEscalationGraph,
  }));

  return (
    <div className={styles["price-trends-holder"]}>
      <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}
          className={styles["price-trend-info"]}
        >
          <h2>
            {graphDetails.title !== null ? graphDetails.title : "Price Trends"}
          </h2>
          <div>
            <span className={styles["price-rise-heading"]}>
              (Rise in last 1 Year)
            </span>
            <span className={styles["price-rise"]}>
              {graphDetails?.estimatedAppreciation}%
              <img
                className={styles["upward-icon"]}
                src={MediaAssets.ic_upwards_arrow}
                alt=""
              />
            </span>
          </div>
          <p>
            We ensure a completely secure experience for our members by keeping
            track of every single nuance of the ownership journey to put your
            mind at ease.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
          <LineChart
            graphheight={400} // added for height issue(bug raised by harshith)
            showWithContainer={true}
            customizableGraphData={graphDetails}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PriceTrends;
