import { Constants } from "constants/Constants";
import { DialogTitle, DrawerProps, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import MediaAssets from "assets";
import styles from "./MyServicesDialog.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const MyServicesDialog = (
  props: DrawerProps & { handleclose?: () => void }
) => {
  const { open = false, handleclose } = props;

  const openGooglePlayStore = () => {
    window.open(Constants.GOOGLE_PLAY_STORE_URL);
  };

  const openAppleAppStore = () => {
    window.open(Constants.APPLE_APP_STORE_URL);
  };
  return (
    <Dialog maxWidth={"lg"} open={open} {...props}>
      <DialogTitle sx={{ paddingBottom: 0, textAlign: "end" }}>
        <IconButton aria-label="close" onClick={handleclose}>
          <CloseRoundedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <div className={styles["stores-icon-holder"]}>
              <h1 className={styles["download-heading"]}>
                Download the app today
              </h1>
              <div>
                <img
                  src={MediaAssets.ic_playstore_download}
                  alt="Download app from Google Play Store"
                  className={styles["playstore-icon"]}
                  onClick={openGooglePlayStore}
                />
                <img
                  src={MediaAssets.ic_appstore_download}
                  alt="Download app from Apple App Store"
                  className={styles["playstore-icon"]}
                  onClick={openAppleAppStore}
                />
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{ marginTop: "30px", marginBottom: "30px" }}
          >
            <img src={MediaAssets.ic_mobile_app} alt="" />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MyServicesDialog;
