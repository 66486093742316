import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { LandSkuListActions } from "redux-container/investments/land-sku-list/LandSkuListRedux";
import { useDispatch, useSelector } from "react-redux";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import LandSkuItem from "./LandSkuItem";
import styles from "./LandSku.module.css";
import Loader from "components/loader/Loader";
import ApplyNowConfirmation from "pages/investment-details/skus/ApplyNowConfirmation";
import StillNotConvinced from "pages/investment-details/still-not-convinced/StillNotConvinced";
import MuiActionStatusDialog from "components/mui-dialogs/MuiActionStatusDialog";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";

const actionDispatch = (dispatch: any) => {
  return {
    getLandSkuList: (id: any) =>
      dispatch(LandSkuListActions.getLandSkuListRequest(id)),
    addInventory: (data: any) =>
      dispatch(LandSkuListActions?.postAddInventoryRequest(data)),
    setLandSkuList: (key: any, value: any) =>
      dispatch(LandSkuListActions.setLandSkuListState(key, value)),
  };
};

const LandSku = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  const [selectedSku, setSelectedSku] = useState<any>(null);
  const [availableSkuCount, setAvailableSkuCount] = useState(0);

  const { getLandSkuList, addInventory, setLandSkuList } = actionDispatch(
    useDispatch()
  );

  const {
    error,
    landSkus,
    landSkuHeadings,
    isLoading,
    isApplySkuOpen,
    isInventoryAdded,
    projectId,
    launchName,
  } = useSelector((state: any) => ({
    launchName: state?.landSkuList?.launchName,
    projectId: state?.landSkuList?.projectId,
    isInventoryAdded: state?.landSkuList?.isInventoryAdded,
    isApplySkuOpen: state?.landSkuList?.isApplySkuOpen,
    error: state?.landSkuList?.error,
    isLoading: state?.landSkuList?.isLoading,
    landSkus:
      state?.landSkuList?.landSkuListData?.projectContent
        ?.inventoryBucketContents,
    landSkuHeadings:
      state?.landSkuList?.landSkuListData?.projectContent?.otherSectionHeadings
        ?.inventoryBucketContents,
  }));

  useEffect(() => {
    if (location.state?.projectId && location.state?.launchName) {
      setLandSkuList("projectId", location.state?.projectId);
      setLandSkuList("launchName", location.state?.launchName);
      getLandSkuList(location.state?.projectId);
    }
  }, []);

  useEffect(() => {
    getAvailableInventoryBuckets();
  }, [landSkus]);

  const getAvailableInventoryBuckets = () => {
    const availableSkus =
      landSkus &&
      landSkus.filter((sku: any) => {
        return sku?.isApplied === false && sku?.isSoldOut === false;
      });
    setAvailableSkuCount(availableSkus && availableSkus.length);
  };

  const handleCloseConfirmDialog = () => {
    setLandSkuList("isApplySkuOpen", false);
  };

  const onClickApplyNow = (sku: any) => {
    setSelectedSku(sku);
    setLandSkuList("isApplySkuOpen", true);
  };

  const handleAddToInventory = () => {
    const requestBody = {
      inventoryBucketId: selectedSku?.id,
      launchPhaseId: projectId,
    };
    addInventory(requestBody);
    handleCloseConfirmDialog();
  };

  const handleFormDialogClose = () => {
    setLandSkuList("isInventoryAdded", false);
    getLandSkuList(projectId);
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className={styles["heading-holder"]}>
            <IconButton onClick={() => navigate(-1)}>
              <ChevronLeft />
            </IconButton>
            <div>
              <h2>
                {landSkuHeadings?.sectionHeading !== null
                  ? landSkuHeadings?.sectionHeading
                  : "Land SKUs"}
                {` (${availableSkuCount})`}
              </h2>
              <span>
                {landSkuHeadings?.subHeading !== null
                  ? landSkuHeadings?.subHeading
                  : "You can apply for multiple SKUs."}
              </span>
            </div>
          </div>

          <div>
            {landSkus &&
              landSkus.length > 0 &&
              landSkus.map((sku: any) => {
                return (
                  <LandSkuItem
                    sku={sku}
                    key={sku?.id}
                    applyNowSku={onClickApplyNow}
                  />
                );
              })}
          </div>

          <StillNotConvinced launchName={launchName} projectId={projectId} />

          <ApplyNowConfirmation
            sku={selectedSku}
            shouldOpen={isApplySkuOpen}
            content="Are you sure you want to apply for this SKU?"
            okText="Yes"
            cancelBtnText="No"
            cancelHandler={handleCloseConfirmDialog}
            okHandler={handleAddToInventory}
          />

          <MuiActionStatusDialog
            maxWidth={"sm"}
            issuccess={isInventoryAdded ? true : false}
            isconfirmdialog={false}
            responsemessage={"Thank you for your interest!"}
            message={"Our Project Manager will reach out to you in 24 hours!"}
            open={isInventoryAdded ? true : false}
            handleclose={handleFormDialogClose}
          />

          <MuiSnackbar
            shouldOpen={error !== null ? true : false}
            message={error}
            isSuccess={false}
            closeHandler={() => setLandSkuList("error", null)}
          />
        </div>
      )}
    </Fragment>
  );
};

export default LandSku;
