import {
  Box,
  Divider,
  Paper,
  Stack,
  Typography,
  Grid,
  Avatar,
  Button,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import styles from "./AboutUs.module.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import { Carousel } from "@mantine/carousel";
import { getPageAction } from "redux-container/page-management-redux/PageManagementRedux";
import { useDispatch, useSelector } from "react-redux";
import StatsOverviewCard from "./StatsOverviewCard";
import IEAGraphContent from "./graph-component/IEAGraphContent";
import Loader from "components/loader/Loader";
import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";
import MediaAssets from "assets";
import { getProjectContentsAction } from "redux-container/project-contents/ProjectContents";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "routes/RouteConfigurations";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";
import { PageTypeEnum } from "enumerations/PageTypeEnum";

const actionDispatch = (dispatch: any) => {
  return {
    getPageDetails: (params: any) =>
      dispatch(getPageAction.getProfilePageRequest(params)),
    getProjectContentList: (params: any) =>
      dispatch(getProjectContentsAction.getProjectContentsRequest(params)),
    setPagemanagementState: (key: any, value: any) =>
      dispatch(getPageAction.setPageState(key, value)),
  };
};

const AboutUs = () => {
  const [graphTabValue, setGrapghTabValue] = useState(0);
  const { getPageDetails, getProjectContentList, setPagemanagementState } =
    actionDispatch(useDispatch());
  const navigate = useNavigate();
  const aboutUsData = useSelector(
    (state: any) => state?.pageManagement?.profilePageData?.page?.aboutUs
  );
  const isLoading = useSelector(
    (state: any) => state?.pageManagement?.isLoading
  );
  const error = useSelector((state: any) => state?.pageManagement?.error);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const pageType = PageTypeEnum.Profile;
    getPageDetails(pageType);
    getProjectContentList({ status: "1001" });
  }, []);

  const handleGraphTabChange = (event: React.SyntheticEvent, newValue: any) => {
    setGrapghTabValue(newValue);
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const handleCloseSnackBar = () => {
    setPagemanagementState("error", null);
  };

  return (
    <Box mb={isMobileScreen ? 1 : 6}>
      {isLoading ? (
        <Loader />
      ) : (
        <section className={styles["about-us-section"]}>
          <div>
            <Stack
              spacing={2}
              justifyContent={"start"}
              alignItems={"center"}
              textAlign={"start"}
            >
              <KeyboardArrowLeftIcon
                className={styles["left-arrow"]}
                fontSize={"medium"}
                onClick={handleNavigateBack}
              />
              <Typography className={styles["section-heading-name"]}>
                About Us
              </Typography>
            </Stack>
          </div>

          <Box
            p={isMobileScreen ? "" : "0 25px"}
            className={styles["section-main-content"]}
          >
            <div className={styles["sections-spacing"]}>
              {aboutUsData?.isFoundersVisionActive && (
                <>
                  <Typography className={styles["content-heading"]}>
                    {aboutUsData?.foundersVision?.sectionHeading}
                  </Typography>

                  <Paper
                    elevation={0}
                    className={`${styles["card-section"]} ${styles["paper-sides-padding"]}`}
                  >
                    <Typography className={styles["typography-title"]}>
                      {aboutUsData?.foundersVision?.founderName}
                    </Typography>
                    <Typography
                      variant={"body1"}
                      className={`margin-top-15px ${styles["typography-paragraph-contents"]} `}
                      dangerouslySetInnerHTML={{
                        __html: aboutUsData?.foundersVision?.description,
                      }}
                    ></Typography>
                  </Paper>
                </>
              )}

              {aboutUsData?.isAboutHoablActive && (
                <Box
                  className={`${styles["card-section-2"]} ${styles["paper-sides-padding"]}`}
                >
                  <Typography className={styles["typography-title"]}>
                    {aboutUsData?.aboutHoabl?.sectionHeading}
                  </Typography>
                  <Typography
                    className={`margin-top-15px ${styles["typography-paragraph-contents"]}`}
                    dangerouslySetInnerHTML={{
                      __html: aboutUsData?.aboutHoabl?.description,
                    }}
                  ></Typography>
                </Box>
              )}
            </div>

            {aboutUsData?.isCorporatePhilosophyActive && (
              <div className={`${styles["carousels-container"]}`}>
                <Typography className={styles["content-heading-2"]}>
                  {aboutUsData?.corporatePhilosophy?.sectionHeading}
                </Typography>

                {aboutUsData?.corporatePhilosophy?.detailedInformation?.length <
                  4 && !isMobileScreen ? (
                  <Grid
                    container
                    columnGap={2}
                    className={styles["corporate-content-3"]}
                  >
                    {aboutUsData?.corporatePhilosophy?.detailedInformation?.map(
                      (item: any, index: number) => {
                        return (
                          <Grid
                            item
                            md={3}
                            lg={3}
                            xl={3}
                            key={index}
                            className={`${styles["carousel-container"]} ${styles["carousel-item"]}`}
                          >
                            <Box>
                              <Stack
                                flexDirection={"column"}
                                columnGap={2}
                                justifyContent={"start"}
                                textAlign="start"
                                alignItems={"start"}
                              >
                                <img
                                  src={item?.media?.value?.url}
                                  alt={item?.media?.value?.key}
                                  className={styles["carousel-item-image"]}
                                />
                                <Typography
                                  mt={0.3}
                                  variant={"body1"}
                                  fontFamily={"Jost-Medium"}
                                  className={` ${styles["line-ellipsis"]}`}
                                >
                                  {item?.displayName}
                                </Typography>
                              </Stack>
                              <Typography
                                className={`margin-top-15px ${styles["typography-paragraph-contents"]} ${styles["description-ellipsis"]}`}
                                dangerouslySetInnerHTML={{
                                  __html: item?.description,
                                }}
                              ></Typography>
                            </Box>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                ) : (
                  <Box className={styles["carousel-content-section"]}>
                    <MantineCarousel
                      slideSize="25%"
                      slidesToScroll={1}
                      align={"start"}
                      slideGap={"md"}
                      controlsOffset={"xl"}
                    >
                      {aboutUsData?.corporatePhilosophy?.detailedInformation?.map(
                        (item: any, index: number) => {
                          return (
                            <Carousel.Slide
                              key={index}
                              className={`${styles["carousel-container"]} ${styles["carousel-item"]}`}
                            >
                              <Box>
                                <Stack
                                  flexDirection={"column"}
                                  columnGap={2}
                                  justifyContent={"start"}
                                  textAlign="start"
                                  alignItems={"start"}
                                >
                                  <img
                                    src={item?.media?.value?.url}
                                    alt={item?.media?.value?.key}
                                    className={styles["carousel-item-image"]}
                                  />
                                  <Typography
                                    mt={0.3}
                                    variant={"body1"}
                                    fontFamily={"Jost-Medium"}
                                    className={`${styles["line-ellipsis"]}`}
                                  >
                                    {item?.displayName}
                                  </Typography>
                                </Stack>
                                <Typography
                                  variant="body2"
                                  className={`margin-top-15px ${styles["description-ellipsis"]}`}
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                ></Typography>
                              </Box>
                            </Carousel.Slide>
                          );
                        }
                      )}
                    </MantineCarousel>
                  </Box>
                )}
              </div>
            )}

            {aboutUsData?.isProductCategoryActive && (
              <div className={styles["sections-spacing-30"]}>
                <Typography className={styles["typography-title"]}>
                  {aboutUsData?.productCategory?.sectionHeading}
                </Typography>
                {aboutUsData?.productCategory?.detailedInformation?.map(
                  (item: any, index: number) => (
                    <Box
                      key={index}
                      className={styles["project-category-details-card"]}
                    >
                      <Box>
                        <Stack mb={3} justifyContent={"start"} spacing={2}>
                          <img
                            src={item?.media?.value?.url}
                            alt={" "}
                            className={styles["product-category-img"]}
                          />
                          <Typography
                            className={`${styles["typography-sub-title"]} ${styles["line-ellipsis"]}`}
                          >
                            {item?.displayName}
                          </Typography>
                        </Stack>
                        {isMobileScreen && (
                          <Divider className={styles.divider} />
                        )}
                      </Box>
                      <Typography
                        className={`margin-top-15px ${styles["typography-paragraph-contents"]} ${styles["line-clamp-3"]}`}
                        dangerouslySetInnerHTML={{ __html: item?.description }}
                      ></Typography>
                    </Box>
                  )
                )}
              </div>
            )}

            {aboutUsData?.isStatsOverviewActive && (
              <div className={styles["sections-spacing-30"]}>
                <Typography className={styles["typography-title"]}>
                  {aboutUsData?.statsOverview?.sectionHeading}
                </Typography>

                <div className={styles["sections-spacing"]}>
                  <Grid container spacing={1}>
                    {aboutUsData?.statsOverview?.detailedInformation?.map(
                      (item: any, index: number) => (
                        <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                          <StatsOverviewCard
                            price={item.value}
                            title={item.title}
                            description={item.description}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                </div>
              </div>
            )}

            <div className={styles["sections-spacing-30"]}>
              <Typography className={styles["typography-title"]}>
                Explore Growth
              </Typography>
              <div className={styles["sections-spacing"]}>
                <IEAGraphContent
                  tabValue={graphTabValue}
                  handleChange={handleGraphTabChange}
                />
              </div>
            </div>

            <div
              className={`${styles["margin-top-30px"]} ${styles["content-center"]}`}
            >
              <Grid
                mt={isMobileScreen ? -3 : 6}
                container
                spacing={3}
                className={styles["content-center"]}
                justifyContent={"space-evenly"}
              >
                <Grid item xs={12} sm={12} md={5} lg={5} xl={4}>
                  <MuiLoadingButton
                    onClick={() => navigate(RouteConfigurations.investments)}
                    style={{ width: "100%" }}
                    className={styles["btn-text"]}
                    startIcon={
                      <Avatar
                        className={styles["profit-icon"]}
                        src={MediaAssets.ic_profit}
                      />
                    }
                  >
                    Invest with us today
                  </MuiLoadingButton>
                </Grid>

                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                  <Button
                    onClick={() => navigate(RouteConfigurations.chat)}
                    className={`${styles["btn-outlined"]} ${styles["btn-text"]}`}
                    color={"inherit"}
                    variant={"outlined"}
                    style={{ width: "100%" }}
                  >
                    Have any questions reach out to us
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Box>

          <MuiSnackbar
            shouldOpen={error !== null ? true : false}
            message={error}
            isSuccess={false}
            closeHandler={handleCloseSnackBar}
          />
        </section>
      )}
    </Box>
  );
};

export default AboutUs;
