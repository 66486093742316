import {
  Box,
  Grid,
  Stack,
  styled,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { IPortfolioComponentProps } from "../Portfolio";
import InfoIconComponent from "components/info-icon-component/InfoIconComponent";

const DetailCardsStyleWrapper = styled(Grid)<IPortfolioComponentProps>`
  .detail-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1rem;
    padding: 1rem;
    cursor: pointer;
    background-image: ${({ theme, iea }) =>
      iea
        ? theme.palette.gradient?.blackShades
        : theme.palette.gradient?.yellowShades};
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    & .substring-text {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    & .MuiTypography-body2,
    .MuiTypography-body1 {
      column-gap: 0.3rem;
      align-items: center;
      justify-content: flex-start;
    }
    && .MuiTypography-root:first-child {
      font-family: "Jost-Medium";
    }
    & .MuiTypography-root {
      color: ${({ theme, iea }) =>
        iea ? theme.palette.common.white : theme.palette.common.black};
    }
    & .iea-value {
      color: #999cff;
    }
    & .info-icon {
      cursor: pointer;
      font-size: 1rem;
      vertical-align: middle;
      ${({ theme }) => theme.breakpoints.up("xl")} {
        font-size: 1.5rem;
      }
    }
    ${({ theme }) => theme.breakpoints.down("sm")} {
      row-gap: 0.5rem;
    }
  }
`;

const InvestmentDetailCards = (props: IPortfolioComponentProps) => {
  const { investment, iea, onClick } = props;
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const commaSeparatedAmount = (amount: number) => {
    return amount.toLocaleString();
  };
  return (
    <DetailCardsStyleWrapper container spacing={2} iea={!!iea}>
      <Grid onClick={onClick} item xs={6}>
        <Box className="detail-card">
          <Typography
            className="substring-text"
            variant={isSmallScreen ? "body2" : "body1"}
          >
            No. of Products
          </Typography>
          <Typography variant="body1">{investment?.count}</Typography>
        </Box>
      </Grid>
      <Grid onClick={onClick} item xs={6}>
        <Box className="detail-card">
          <Typography variant={isSmallScreen ? "body2" : "body1"}>
            {!!iea ? "Area in sqft" : "Sqft Applied"}
          </Typography>
          <Typography variant="body1">
            {commaSeparatedAmount(investment?.areaSqFt)}
          </Typography>
        </Box>
      </Grid>
      <Grid onClick={onClick} item xs={6}>
        <Box className="detail-card">
          <Typography
            className="substring-text"
            component={Stack}
            variant={isSmallScreen ? "body2" : "body1"}
          >
            Amount {!!iea ? "Invested" : "Paid"}
            <InfoIconComponent
              infoContent={!!iea ? "Invested" : "Paid"}
              placement="top-start"
              className={"info-icon"}
            />
          </Typography>
          <Typography component={Stack} variant="body1">
            {`₹ ${
              !!iea
                ? commaSeparatedAmount(investment?.amountInvested)
                : commaSeparatedAmount(investment?.amountPaid)
            }`}
          </Typography>
        </Box>
      </Grid>
      <Grid onClick={onClick} item xs={6}>
        {!!iea ? (
          <Box className="detail-card">
            <Typography
              className="substring-text"
              variant={isSmallScreen ? "body2" : "body1"}
            >
              Avg Estimated Appreciation
              <InfoIconComponent
                infoContent={"Owner Estimated Appreciation"}
                placement="top-start"
                className={"info-icon"}
              />
            </Typography>

            <Stack>
              <Typography variant="body1" className={"iea-value"}>
                {`+ ${iea} OEA`}
              </Typography>
              <Typography mb={0.5}>
                <InfoIconComponent
                  infoContent={"Owner Estimated Appreciation"}
                  placement="top-start"
                  className={"info-icon"}
                />
              </Typography>
            </Stack>
          </Box>
        ) : (
          <Box className="detail-card">
            <Typography
              className="substring-text"
              component={Stack}
              variant={isSmallScreen ? "body2" : "body1"}
            >
              Amount Pending
              <InfoIconComponent
                infoContent={"Excluding taxes & other charges"}
                placement="top-start"
                className={"info-icon"}
              />
            </Typography>
            <Typography variant="body1">{`₹ ${commaSeparatedAmount(
              investment?.amountPending
            )}`}</Typography>
          </Box>
        )}
      </Grid>
    </DetailCardsStyleWrapper>
  );
};

export default InvestmentDetailCards;
