import MediaAssets from "assets";
import React from "react";
import styles from "./UpcomingInfra.module.css";

const UpcomingInfraItem = (infra: any) => {
  return (
    <div className={styles["upcoming-infra-card"]}>
      <div className={styles["image-title-holder"]}>
        <img src={infra?.infra?.media?.value?.url} alt="" />
        <div>
          <p>{infra?.infra?.title}</p>
        </div>
      </div>
      <p dangerouslySetInnerHTML={{ __html: infra?.infra?.description }}></p>
    </div>
  );
};

export default UpcomingInfraItem;
