import { useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ButtonArrowIcon,
  MuiStyledButton,
} from "components/mui-buttons/MuiButtons";
import { LatestUpdatesStyleWrapper } from "./LatestUpdates.style";
import MuiCard from "components/mui-card/MuiCard";
import { useSelector } from "react-redux";
import { numOfItems, splitOutPara } from "utils/helperFunctions";
import { RouteConfigurations } from "routes/RouteConfigurations";
import { useNavigate } from "react-router-dom";
import MantineCarousel from "components/mantine-carousel/MantineCarousel";
import { Carousel } from "@mantine/carousel";
import LatestUpdatesViewDialog from "pages/updates/update-details/UpdateDetails";
import styles from "./LatestUpdates.module.css";
import { useMemoizedValue } from "app/hooks/useMemoizedValue";

const LatestUpdates = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { pageData, totalUpdatesToShow } = useSelector((state: any) => ({
    pageData: state?.pageManagement?.pageData,
    totalUpdatesToShow:
      state?.pageManagement?.pageData?.page?.totalUpdatesOnHomeScreen,
  }));

  const slicedPageData = useMemoizedValue(
    pageData?.pageManagementOrLatestUpdates,
    totalUpdatesToShow
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [storiesIndex, setStoriesIndex] = useState(0);

  const { breakpoints } = useTheme();
  const isMediumScreen = useMediaQuery(breakpoints.down("md"));

  return (
    <LatestUpdatesStyleWrapper>
      <Stack mb={isSmallScreen ? "0.5rem" : "2rem"}>
        <Typography className="section-title">
          {pageData?.page?.latestUpdates?.heading}
        </Typography>
        <MuiStyledButton
          endIcon={<ButtonArrowIcon />}
          variant="text"
          color="primary"
          onClick={() => navigate(RouteConfigurations.updates)}
        >
          SEE ALL
        </MuiStyledButton>
      </Stack>
      <LatestUpdatesViewDialog
        storyIndex={storiesIndex}
        storiesData={pageData?.pageManagementOrLatestUpdates?.slice(
          0,
          totalUpdatesToShow
        )}
        open={openDialog}
        handleclose={() => setOpenDialog(false)}
      />
      {(slicedPageData &&
        isMediumScreen &&
        pageData?.pageManagementOrLatestUpdates &&
        pageData?.pageManagementOrLatestUpdates?.length > 0) ||
      (totalUpdatesToShow > 4 &&
        pageData?.pageManagementOrLatestUpdates?.length > 0 &&
        slicedPageData) ? (
        <MantineCarousel
          breakpoints={[
            { maxWidth: "lg", slideSize: "33%" },
            { maxWidth: "md", slideSize: "50%" },
            { maxWidth: "sm", slideSize: "75%" },
            { maxWidth: "xs", slideSize: "75%" },
          ]}
          slideSize={numOfItems(4)}
          slidesToScroll={1}
        >
          {slicedPageData &&
            slicedPageData
              ?.slice(0, totalUpdatesToShow)
              .map((updates: any, index: number) => (
                <Carousel.Slide
                  key={updates?.id}
                  onClick={() => {
                    setStoriesIndex(index);
                    setOpenDialog(true);
                  }}
                >
                  <MuiCard
                    sx={{ height: "385px", cursor: "pointer" }}
                    src={updates?.detailedInfo[0]?.media?.value?.url}
                  >
                    <Box
                      className={styles["mui-card-description-details-section"]}
                    >
                      <Typography
                        className={`${styles["typography-card-title"]} ${styles["line-ellipsis"]}`}
                      >
                        {updates?.displayTitle}
                      </Typography>
                      <Typography
                        className={`${styles["typography-card-sub-title"]} ${styles["line-ellipsis"]}`}
                      >
                        {updates?.subTitle}
                      </Typography>
                      <Typography
                        variant="body2"
                        color={"grey.A400"}
                        fontFamily={"Jost-Light"}
                        textAlign={"center"}
                        mt={
                          updates?.detailedInfo[0]?.media?.value?.url ? 1.5 : 3
                        }
                        className={
                          updates?.detailedInfo[0]?.media?.value?.url
                            ? styles["description-text"]
                            : styles["description-text-ellipsis-4"]
                        }
                        dangerouslySetInnerHTML={
                          updates?.detailedInfo[0]?.media?.value?.url
                            ? splitOutPara(
                                updates?.detailedInfo[0]?.description
                              )
                            : splitOutPara(
                                updates?.detailedInfo[0]?.description,
                                180
                              )
                        }
                      ></Typography>
                    </Box>
                  </MuiCard>
                </Carousel.Slide>
              ))}
        </MantineCarousel>
      ) : (
        totalUpdatesToShow <= 4 && (
          <Box>
            <Grid container columnGap={3} justifyContent={"center"}>
              {pageData?.pageManagementOrLatestUpdates
                ?.slice(0, totalUpdatesToShow)
                .map((updates: any, index: number) => (
                  <Grid
                    item
                    md={3}
                    lg={2.5}
                    xl={2.5}
                    key={index + 1}
                    onClick={() => {
                      setStoriesIndex(index);
                      setOpenDialog(true);
                    }}
                  >
                    <MuiCard
                      sx={{ height: "385px", cursor: "pointer" }}
                      src={updates?.detailedInfo[0]?.media?.value?.url}
                      customheight={160}
                    >
                      <Box
                        className={
                          styles["mui-card-description-details-section"]
                        }
                      >
                        <Typography
                          className={`${styles["typography-card-title"]} ${styles["line-ellipsis"]}`}
                        >
                          {updates?.displayTitle}
                        </Typography>
                        <Typography
                          className={`${styles["typography-card-sub-title"]} ${styles["line-ellipsis"]}`}
                        >
                          {updates?.subTitle}
                        </Typography>
                        <Typography
                          variant="body2"
                          color={"grey.A400"}
                          fontFamily={"Jost-Light"}
                          textAlign={"center"}
                          mt={
                            updates?.detailedInfo[0]?.media?.value?.url
                              ? 1.5
                              : 3
                          }
                          className={
                            updates?.detailedInfo[0]?.media?.value?.url
                              ? styles["description-text"]
                              : styles["description-text-ellipsis-4"]
                          }
                          dangerouslySetInnerHTML={
                            updates?.detailedInfo[0]?.media?.value?.url
                              ? splitOutPara(
                                  updates?.detailedInfo[0]?.description
                                )
                              : splitOutPara(
                                  updates?.detailedInfo[0]?.description,
                                  180
                                )
                          }
                        ></Typography>
                      </Box>
                    </MuiCard>
                  </Grid>
                ))}
            </Grid>
          </Box>
        )
      )}
    </LatestUpdatesStyleWrapper>
  );
};

export default LatestUpdates;
