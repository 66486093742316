import { Avatar, Tooltip, TooltipProps } from "@mui/material";
import MediaAssets from "../../assets";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useState } from "react";
import styles from './InfoIconComponent.module.css';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface IInfoIconProps {
  infoContent?: string | NonNullable<React.ReactNode>;
  placement?: TooltipProps["placement"];
  fontSize?: string;
  className?:any;
}

const InfoIconComponent = (props: IInfoIconProps) => {
  const { infoContent = "Tooltip content here", placement = "top-start", fontSize, className } =
    props;

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        placement={placement}
        title={infoContent}
        onClose={handleTooltipClose}
        open={open}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        PopperProps={{
          disablePortal: true,
        }}
      >
        {className ? (
          <InfoOutlinedIcon
          sx={{ marginLeft: "0.3rem" }}
          className={className}
        />
        ) : (
        <Avatar
          sx={{
            width: fontSize ? fontSize : "1rem",
            height: fontSize ? fontSize : "1rem",
            cursor: "pointer",
            marginTop: "0.10rem",
            marginBottom: "0.10rem",
          }}
          src={MediaAssets.ic_info}
          variant="rounded"
          onClick={handleTooltipOpen}
        />
        )}
      </Tooltip>
    </ClickAwayListener>
  );
};

export default InfoIconComponent;
