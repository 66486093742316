import { Box, CardMedia, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { handleUrlLink } from "utils/helperFunctions";
import styles from "./VideosSection.module.css";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

const VideoCard = (props: any) => {
  const { handleOnClick, contentType, mediaUrl, title } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box className={styles["container"]} onClick={handleOnClick}>
      <CardMedia
        component="img"
        className={
          contentType === "video"
            ? styles["card-media-mui-thumbnail"]
            : styles["card-media-mui"]
        }
        image={handleUrlLink(mediaUrl, true)}
        alt={title ? title : mediaUrl}
      />

      {mediaUrl && (
        <>
          {contentType === "video" && (
            <Box className={styles["card-video-icon-content"]}>
              <PlayCircleOutlineIcon
                fontSize={isSmallScreen ? "small" : "large"}
                className={styles["video-play-icon"]}
                color={"inherit"}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default VideoCard;
