import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MediaAssets from "assets";
import GenerateOTP from "./generate-otp/GenerateOTP";
import NewUserNameInfo from "./new-user-name-info/NewUserNameInfo";
import VerifyOTP from "./verify-otp/VerifyOTP";
import {
  useMediaQuery,
  Theme,
  Typography,
  Box,
  Stack,
  CardMedia,
} from "@mui/material";
import Stories from "react-insta-stories";
import styles from "./Login.module.css";
import RegistrationSuccessDialog from "./new-user-name-info/RegistrationSuccessDialog";

const stories = [
  {
    content: () => (
      <Stack flexDirection={"column"}>
        <div>
          <Typography className={styles["stories-heading"]}>
            New Generation Land is for everyone. Explore our latest offerings.
          </Typography>
        </div>
        <div className={styles["preposition-holder"]}>
          <img
            className={styles["preposition-icon"]}
            src={MediaAssets.ic_preposition_one}
            alt=""
          />
        </div>
      </Stack>
    ),
  },
  {
    content: () => {
      return (
        <Stack flexDirection={"column"}>
          <div>
            <Typography className={styles["stories-heading"]}>
              When you buy land from us - Rest assured in our promise.
            </Typography>
          </div>
          <div className={styles["preposition-holder"]}>
            <img
              className={styles["preposition-icon"]}
              src={MediaAssets.ic_preposition_two}
              alt=""
            />
          </div>
        </Stack>
      );
    },
  },
  {
    content: () => {
      return (
        <Stack flexDirection={"column"}>
          <div>
            <Typography className={styles["stories-heading"]}>
              Just like a bank - Your portfolio is safe and secured with us.
            </Typography>
          </div>
          <div className={styles["preposition-holder"]}>
            <img
              className={styles["preposition-icon-three"]}
              src={MediaAssets.ic_preposition_three}
              alt=""
            />
          </div>
        </Stack>
      );
    },
  },
];

const Login = () => {
  const fullScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [showPropositionScreen, setShowPropositionScreen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const isOtpGenerated = useSelector(
    (state: any) => state?.generateOtp.isOtpGenerated
  );
  const shouldShowNewUserName = useSelector(
    (state: any) => state?.verifyOtp.shouldShowNewUserName
  );
  const isRegistrationSuccessDialogOpen = useSelector(
    (state: any) => state?.newUserNameInfo?.isRegistrationSuccessDialogOpen
  );
  const isOtpVerified = useSelector(
    (state: any) => state?.verifyOtp.isOtpVerified
  );

  useEffect(() => {
    if (window.screen.width < 500) {
      setIsMobile(true);
    }

    if (!isOtpVerified) {
      const timer = setTimeout(() => {
        setShowPropositionScreen(true);
      }, 2000);
      return () => clearTimeout(timer);
    } else {
      setShowPropositionScreen(true);
    }
  }, []);

  const RenderForm = () => {
    if (isOtpGenerated && !shouldShowNewUserName) {
      return <VerifyOTP fullScreen={fullScreen} />;
    } else if (isOtpGenerated && shouldShowNewUserName) {
      return <NewUserNameInfo fullScreen={fullScreen} />;
    } else {
      return <GenerateOTP fullScreen={fullScreen} />;
    }
  };

  return (
    <div className={styles["login-holder"]}>
      <div className={styles["left-split"]}>
        {!showPropositionScreen || isMobile ? (
          <img
            src={MediaAssets.ic_logo_white}
            alt="The House of Abhinandan Lodha - New Generation Land"
            className={styles["logo"]}
          />
        ) : (
          <Stories
            loop
            keyboardNavigation
            defaultInterval={5000}
            stories={stories}
            storyContainerStyles={{
              backgroundColor: "#676ac0",
              overflow: "hidden",
              alignItems: "center",
              marginTop: "20px",
            }}
            width="80%"
            height="100%"
          />
        )}
      </div>

      <div className={styles["right-split"]}>
        <RenderForm />
      </div>

      <RegistrationSuccessDialog shouldOpen={isRegistrationSuccessDialogOpen} />
    </div>
  );
};

export default Login;
