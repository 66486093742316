const isEmpty = (str: string) => {
  return str.length === 0 ? true : false;
};

const isEmailValid = (email: string) => {
  const regX = new RegExp(
    /^[a-zA-Z0-9_]+(\.[_a-zA-Z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/
  );
  return regX.test(email);
};

const isMobileNumberValid = (mobileNumber: string) => {
  const regX = new RegExp("^(0|[1-9][0-9]*)$");
  return regX.test(mobileNumber);
};

const isOtpValid = (otp: string) => {
  const regX = new RegExp(/^((?!(0))[0-9]{6})$/);
  return regX.test(otp);
};

const isOnlyNumberAllowed = (number: string) => {
  const regX = new RegExp(/^\d*[.]?\d*$/);
  return regX.test(number.toString());
};

export {
  isEmpty,
  isMobileNumberValid,
  isOtpValid,
  isOnlyNumberAllowed,
  isEmailValid,
};
