import { Grid } from "@mui/material";
import UpcomingInfraItem from "./UpcomingInfraItem";
import styles from "./UpcomingInfra.module.css";
import { useSelector } from "react-redux";

const UpcomingInfra = () => {
  const { upcomingInfraStory } = useSelector((state: any) => ({
    upcomingInfraStory:
      state?.investmentDetails?.investmentDetails?.projectContent
        ?.opportunityDoc?.upcomingInfraStory,
  }));

  return (
    <div>
      <h2 className={styles["section-heading"]}>
        {upcomingInfraStory && upcomingInfraStory?.heading
          ? upcomingInfraStory.heading
          : "Upcoming Infra Story"}
      </h2>
      <div>
        <Grid container spacing={2} rowGap={6}>
          {upcomingInfraStory &&
            upcomingInfraStory?.stories &&
            upcomingInfraStory?.stories.map(
              (
                detail: typeof upcomingInfraStory.stories[0],
                detailIndex: number
              ) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  key={detailIndex}
                >
                  <UpcomingInfraItem infra={detail} />
                </Grid>
              )
            )}
        </Grid>
      </div>
    </div>
  );
};

export default UpcomingInfra;
