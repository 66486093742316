import {
  Box,
  Card,
  CardActions,
  CardActionsProps,
  CardContent,
  CardHeader,
  CardHeaderProps,
  CardMedia,
  CardMediaProps,
  CardProps,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";
import { CardStyleWrapper, OverlayContentStyleWrapper } from "./Card.style";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import React from "react";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { numDifferentiation } from "utils/helperFunctions";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

type IcustomCardProps = CardProps &
  CardMediaProps &
  CardActionsProps &
  CardHeaderProps;
export interface cardPropsType {
  customheight?: string | number;
  cardtitle?: string | React.ReactNode;
  buttonwitharrow?: boolean;
  disableshadow?: boolean;
  componenttype?: React.ElementType;
  issmallscreen?: boolean;
  onbuttonclick?: React.MouseEventHandler<HTMLButtonElement>;
  oncontentclick?: React.MouseEventHandler<HTMLButtonElement>;
  oncardClick?: any;
  bgimgsrc?: string;
  detail?: {
    launchName: string;
    isSoldOut: boolean;
    address?: {
      city: string;
      state: string;
    };
    fomoContent?: {
      noOfViews: number | undefined;
      isNoOfViewsActive: boolean;
      isTargetTimeActive: boolean;
      targetTime?: {
        hours: string;
        minutes: string;
        seconds: string;
      };
    };
    estimatedAppreciation: number | string;
    generalInfoEscalationGraph?: {
      estimatedAppreciation: number | string;
    };
    priceStartingFrom?: any;
    areaStartingFrom?: number | string;
  };
}

const OverlayContent = ({ bgimgsrc, detail, issmallscreen }: cardPropsType) => {
  return (
    <OverlayContentStyleWrapper bgimgsrc={bgimgsrc} detail={detail}>
      <Box className="overlay-container">
        {detail && (
          <>
            <Stack className="chip-section">
              {detail?.fomoContent?.isTargetTimeActive ? (
                <Chip
                  className="timer-chip"
                  icon={<AccessTimeIcon fontSize="small" />}
                  label={`${detail?.fomoContent?.targetTime?.hours}:${detail?.fomoContent?.targetTime?.minutes}:${detail?.fomoContent?.targetTime?.seconds} Hrs Left`}
                />
              ) : (
                <Box></Box>
              )}
              {detail?.fomoContent?.isNoOfViewsActive && (
                <Chip
                  className="view-count-chip"
                  icon={<RemoveRedEyeOutlinedIcon fontSize="small" />}
                  label={numDifferentiation(detail?.fomoContent?.noOfViews)}
                />
              )}
            </Stack>
            <Box>
              <Stack className="address-detail-section">
                <Box>
                  <Typography
                    variant="body2"
                    px={issmallscreen ? "0.5rem" : 2}
                    color={"common.white"}
                    textAlign={"left"}
                    className={"line-ellipsis"}
                  >
                    {detail?.launchName}
                  </Typography>
                  <Typography
                    px={issmallscreen ? "0.5rem" : 2}
                    variant={"caption"}
                    color={"rgba(255, 255, 255, 0.71)"}
                    textAlign={"left"}
                  >
                    {`${detail?.address?.city}, ${detail?.address?.state}`}
                  </Typography>
                </Box>
                <Chip
                  className="estimate-badge"
                  icon={<KeyboardDoubleArrowUpIcon fontSize="small" />}
                  label={
                    <Typography
                      variant="caption"
                      color={"success.light"}
                      fontFamily={"Jost-Bold"}
                    >
                      {!!detail?.generalInfoEscalationGraph
                        ?.estimatedAppreciation
                        ? detail?.generalInfoEscalationGraph
                            ?.estimatedAppreciation
                        : detail?.estimatedAppreciation}
                      %
                    </Typography>
                  }
                />
              </Stack>
              <Stack className="price-detail-section">
                <Typography
                  variant="caption"
                  fontFamily={"Jost-Medium"}
                  component={"span"}
                  color={"common.white"}
                  sx={{ opacity: 0.7 }}
                  textAlign={"left"}
                >
                  ₹{numDifferentiation(detail?.priceStartingFrom)}{" "}
                  <Typography
                    variant="caption"
                    fontFamily={"Jost-Light"}
                    component={"span"}
                    color={"common.white"}
                  >
                    Onwards
                  </Typography>
                </Typography>
                <Typography
                  variant="caption"
                  fontFamily={"Jost-Medium"}
                  component={"span"}
                  color={"common.white"}
                  sx={{ opacity: 0.7 }}
                  textAlign={"left"}
                >
                  {`${detail?.areaStartingFrom} Sqft `}
                  <Typography
                    variant="caption"
                    fontFamily={"Jost-Light"}
                    component={"span"}
                    color={"common.white"}
                  >
                    Onwards
                  </Typography>
                </Typography>
              </Stack>
            </Box>
          </>
        )}
      </Box>
    </OverlayContentStyleWrapper>
  );
};

const MuiCard = (props: IcustomCardProps & cardPropsType) => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const {
    children,
    src,
    customheight = 140,
    buttonwitharrow = false,
    cardtitle,
    action,
    subheader,
    titleTypographyProps,
    componenttype = "img",
    onbuttonclick,
    oncontentclick,
    oncardClick,
    sx,
    detail,
    disableshadow = false,
  } = props;

  return (
    <CardStyleWrapper
      buttonwitharrow={buttonwitharrow}
      disableshadow={disableshadow}
      detail={detail}
    >
      <Card sx={sx} className="card-root">
        {!buttonwitharrow && (
          <>
            <CardHeader
              titleTypographyProps={titleTypographyProps}
              title={cardtitle}
              action={action}
              subheader={subheader}
            />
            {src && (
              <CardMedia
                className="card-media-style"
                component={componenttype}
                height={customheight}
                src={src}
                alt="land image"
              />
            )}
          </>
        )}
        <CardContent onClick={oncardClick}>
          {buttonwitharrow && (
            <OverlayContent
              bgimgsrc={src}
              detail={detail}
              issmallscreen={isSmallScreen}
            />
          )}
          <Box
            component={"span"}
            onClick={oncontentclick}
            padding={isSmallScreen ? "0.5rem" : "1rem"}
            className={"child-content"}
          >
            {children}
          </Box>
        </CardContent>
        {buttonwitharrow && !detail?.isSoldOut ? (
          <CardActions className="card-action-section">
            <MuiLoadingButton
              sx={{ fontFamily: "Jost-Medium" }}
              variant="text"
              size="medium"
              color="inherit"
              onClick={onbuttonclick}
            >
              Apply Now
            </MuiLoadingButton>
          </CardActions>
        ) : (
          detail?.isSoldOut !== undefined && (
            <Typography
              textAlign={"center"}
              color={"common.white"}
              variant={"body1"}
            >
              Sold Out
            </Typography>
          )
        )}
      </Card>
    </CardStyleWrapper>
  );
};

export default MuiCard;
